import React from "react";
import internal from "stream";
import styles from "@/views/clients/Clients.module.less";

const agreement = [
  {
    title: "Aspen Digital Account Management Authorization Agreement",
    content: [
      {
        secondaryTitle: "Purpose",
        secondaryContent: [
          "This Authorization Agreement describes the terms and conditions associated with granting certain types of account authority (this “<strong>Agreement</strong>”). In this Agreement, “Aspen Digital,” “us,” and “we” refers to all parties that run Aspen Digital, including but not limited to legal persons, unincorporated organizations and teams that provide Aspen Digital services and are responsible for such services. “Account Owner” refers to the registered owner(s) of the account or to any new account applicant (as distinct from an Authorized Account Manager, save that nothing in this Agreement prohibits a User of Aspen Digital to be both an Authorized Account Manager and an Account Owner as separate capacities). “Authorized Account Manager” is the individual identified as such in an account management authorization application (the “<strong>Application</strong>”). “account” refers to the Aspen Digital account under the name of the Account Owner. “You” refers to the Account owner(s) and/or the Authorized Account Manager, as the context may require. "
        ]
      }
    ]
  },
  {
    title: "Terms and Conditions — Authorized Account Manager",
    content: [
      {
        secondaryTitle: "Your Commitments to Aspen Digital",
        secondaryContent: [
          "By submitting the application for the Account Owner to give you certain authorization in relation to managing their Account on Aspen Digital (the “Application”), you, the Authorized Account Manager: (i) acknowledge that you have received and read this Agreement, and that you understand and agree to the terms and conditions stipulated herein; (ii) agree to be solely responsible to the Account Owner(s) for all investment decisions, trading strategies, and instructions placed on the account; (iii) agree to act on the account only as specifically authorized by the Account Owner(s) and in compliance with all applicable laws, regulations, and Aspen Digital policies; (iv) permit us to obtain credit information, verify information you have provided, and perform a background check on you, and to reject or remove you as Authorized Account Manager from this or any other account, at any time and for any reason we see fit; (v) agree to notify us in writing immediately upon the death or disability of the last surviving Account Owner or that you have ceased to have such authorization from the Account Owner in relation to the granting of trading authorization; and (vi) acknowledge that you will not be paid by us for the investment management of the account(s) unless specifically agreed with us in writing otherwise."
        ]
      }
    ]
  },
  {
    title: "Terms and Conditions — Account Owner",
    content: [
      {
        secondaryTitle: "Aspen Digital’s Commitments to You",
        secondaryContent: [
          "When we accept your Application, we are agreeing to accept orders on your account from your designated third party — the Authorized Account Manager — according to the terms and conditions stipulated herein. For the avoidance of doubt, we assume no responsibility for reviewing or monitoring any investment decision or activity of the Authorized Account Manager, nor do we provide any tax, legal, or investment advice or recommendations."
        ]
      },
      {
        secondaryTitle: "Your Commitments to Aspen Digital",
        secondaryContent: [
          "By submitting the Application, you, the Account Owner: (i) acknowledge that you have received and read this Agreement, and that you understand and agree to the terms and conditions stipulated herein; (ii) authorize us to accept instructions from your Authorized Account Manager consistent with the scope of authority you are granting; (iii) accept full responsibility for determining whether any trading decision, investment or strategy is appropriate for you, based on your investment objectives and financial resources; (iv) accept full responsibility for understanding the risks associated with granting authority, and assume sole liability for the financial, tax, and other consequences of all actions and instructions of the Authorized Account Manager; (v) acknowledge and agree that any and all disclosures, required or otherwise, may be provided solely to the individual acting on your behalf as part of the scope of his or her authority; and (vi) acknowledge that your account may be charged for actions executed in your account, including orders placed by an Authorized Account Manager, and you agree to pay these charges."
        ]
      },
      {
        secondaryTitle: "Scope of authority",
        secondaryContent: [
          "<header>1. Trading access</header> <p>The Authorized Account Manager may inquire into your account, and also act on your behalf to place or orders in respect of the same range of digital assets and/or other investments that you have access to through your account, without obtaining direct instructions from you. The Authorized Account Manager may incur margin debt and may buy, sell, or exercise any instruments, to the extent that your account is approved for these activities. For the avoidance of doubt, trading authorization does not allow your Authorized Account Manager to remove money or assets from your account(s). </p>",
          "<header>2. Yield management</header> <p>The Authorized Account Manager may act on your behalf to subscribe or redeem yield products, and start or cancel automatic investment plan through your account. A verification code shall be sent to you to effect such operation.</p>",
          "<header>3. Strategy management</header> <p>The Authorized Account Manager may act on your behalf to subscribe or redeem strategies through the Account. A verification code shall be sent to you to effect such operation.</p>"
        ]
      }
    ]
  },
  {
    title: "Terms and Conditions — All Parties",
    content: [
      {
        secondaryTitle: "Termination of the authority of the Authorized Account Manager",
        secondaryContent: [
          "Once the authority is granted to an Authorized Account Manager, it will remain in effect until any of the following events occurs:",
          "(i) We receive written notice signed by an Account Owner withdrawing authority;",
          "(ii) We receive written notice of resignation from the Authorized Account Manager;",
          "(iii) We receive written notice of the death or incapacity of the last surviving Account Owner or the Authorized Account Manager; or",
          "(iv) We decide, at any time and for any reason, to remove such authority. "
        ]
      },
      {
        secondaryTitle: "Limitation of Liability",
        secondaryContent: [
          "All Account Owners and the Authorized Account Manager agree that we are not responsible for any losses you incur (meaning claims, damages, actions, demands, investment losses, or other losses, as well as any costs, charges, attorneys’ fees, or other fees and expenses) as a result of any actions, or failures to act, on the part of the Authorized Account Manager."
        ]
      }
    ]
  },
  {
    title: "Terms concerning this Agreement",
    content: [
      {
        secondaryTitle: "Governing Law",
        secondaryContent: [
          "Unless specifically stated otherwise, this Agreement shall be governed in all respects, including as to validity, interpretation, and effect, by the laws of Hong Kong, without giving effect to its principles or rules of conflict of laws, to the extent such principles or rules are not mandatorily applicable by statute and would permit or require the application of the laws of another jurisdiction."
        ]
      },
      {
        secondaryTitle: "Entire agreement",
        secondaryContent: [
          "This Agreement is in addition to any other agreements between the Account Owner(s) or the Authorized Account Manager and Aspen Digital, and does not restrict any rights that may be created, either now or in the future, by such other agreements or by any law. We reserve the right to amend or terminate this Agreement from time to time."
        ]
      },
      {
        secondaryTitle: "Disputes Resolution — Arbitration",
        secondaryContent: [
          "In the event of any dispute, controversy, or claim arising out of or relating to this Agreement or the construction, interpretation, performance, breach, termination, enforceability or validity thereof, or intend to commence or initiate any legal proceedings or arbitration against us relating to the defaults of Aspen Digital, you shall first contact us, no later than sixty (60) days from the date of its discovery of the dispute, controversy or claim, by giving a default notice outlining the nature and basis of the claim or dispute and the specific relief sought to us at legal@aspendigital.co and we will attempt to resolve the same dispute internally as soon as possible. You agree to negotiate in good faith to resolve the dispute (which discussions shall remain confidential and be subject to applicable rules protecting settlement discussions from use as evidence in any legal proceeding). In the event the dispute cannot be resolved satisfactorily within thirty (30) Business Days of receipt of the default notice, the parties shall resolve the dispute by referring to the internal dispute resolution procedure. The mechanism contained in the internal dispute resolution procedure shall be a prerequisite to commencement of an arbitration proceeding (or any other legal proceeding). During the arbitration, the amount of any settlement offer made by Aspen Digital shall not be disclosed to the arbitrator.",
          "All parties agree that any dispute which cannot otherwise be resolved as provided in the the internal dispute resolution procedure shall be referred to and finally resolved by arbitration administered by the Hong Kong International Arbitration Centre (“<strong>HKIAC</strong>”) under the UNCITRAL Arbitration Rules in force when the Notice of Arbitration is submitted, as modified by the HKIAC Procedures for the Administration of International Arbitration. The law of this arbitration clause shall be Hong Kong law. The place of arbitration shall be Hong Kong. Unless the parties agree otherwise, there shall be only one arbitrator appointed in accordance with the HKIAC Rules. Any arbitration will be conducted in the English language.",
          "Any arbitration against us must be commenced by filing a request for arbitration within one (1) year (inclusive of the internal dispute resolution procedure) after the date you first know or reasonably should know of the act, omission or default giving rise to the claim. There shall be no right to any remedy for any claim not asserted within that time period. If applicable law prohibits a one-year limitation period for asserting claims, any claim must be asserted within the shortest time period permitted by applicable law.",
          "You agree that any claims relating to this Agreement (whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and whether the claims arise during or after the termination of the terms stipulated herein) shall be brought against the other party in an arbitration on an individual basis only and not as a plaintiff or class member in a purported class or representative action. You further agree to waive any right for such claims to be brought, heard, or arbitrated as a class, collective, representative, or private attorney general action, to the extent permissible by applicable law. Combining or consolidating individual arbitrations into a single arbitration is not permitted without the consent of all parties, including Aspen Digital.",
          "Even if we delay in enforcing the rights of this Agreement, it should not constitute a waiver of our rights and we can still enforce the same in the future for as long as permitted under the applicable law. If we do not immediately request or insisted that you do or cease to do anything that may be required or prohibited under this Agreement, or if we delay in taking steps against you in respect of your breaching any terms of this Agreement, that shall not constitute a waiver of your obligations under this Agreement and shall not prevent us taking steps against you in the future."
        ]
      }
    ]
  }
];

const AuthorizationAgreement = () => {
  return (
    <div className={styles.authorizationAgreement}>
      {agreement.map((item, index) => {
        return (
          <div key={index}>
            <div className={styles.title}>{item.title}</div>
            {item.content.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    className={styles.secondaryTitle}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: item.secondaryTitle }}
                  />
                  {item.secondaryContent.map((item, index) => {
                    return (
                      <div
                        className={styles.secondaryContent}
                        key={index}
                        dangerouslySetInnerHTML={{ __html: item }}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default AuthorizationAgreement;
