import React, { useState, useEffect, Suspense } from "react";
import { Intl } from "@/model/index";
import { Spin, Row, Col, Table, Button, Modal, message, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styles from "./Savings.module.less";
import { myAutoInvestList, cancelAutomaticInvestmentPlan } from "@/services/request";
import { transformTime, decimalPointNoMoreX, convertUSD2USDC, resetRate } from "@/utils";
import {
  My_Automatice_Investment,
  Automatice_Investment_Cycle_Code,
  USER_ROLE_POWER
} from "@/config/constants";

interface IProps {}

const WithEmptyContent = React.lazy(
  () => import(/* webpackChunkName: "WithEmptyContent" */ "@/components/emptyContent/EmptyContent")
);
const ModalAutomaticInvestment = React.lazy(
  () => import(/* webpackChunkName: "ModalSubscrible" */ "./components/ModalAutomaticInvestment")
);
const ModalSafetyVerification = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ModalSafetyVerification" */ "@/components/modalSafetyVerification/ModalSafetyVerification"
    )
);

export function InvestmentPlan(props: IProps) {
  const intl: Intl = window.__intl__.messages;
  const my_title_text = intl["savings.automatic.my.title"];
  const loadMore = intl["button.loadMore"];
  const failTips_text = intl["savings.automatic.my.fail.tips"];
  const date_text = intl["column.date"];
  const name_text = intl["column.name"];
  const status_text = intl["column.status"];
  const apy_text = intl["column.apy"];
  const action_text = intl["column.action"];
  const plan_details_text = intl["column.yield.invest.plan.details"];
  const investment_details_text = intl["column.yield.invest.investment.details"];
  const history_btn_text = intl["savings.automatic.history.btn"];
  const submitInfo = {
    cycle: "",
    amount: "",
    fee: "",
    revenueRate: "",
    recentDate: 0,
    payCoin: "USD",
    rateList: []
  };
  const [showSafetyVerificationModal, setShowSafetyVerificationModal] = useState<boolean>(false);
  const [nextToken, setNextToken] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [isTransferYield, setIsTransferYield] = useState<boolean>(false);
  const [confirmInfo, setConfirmInfo] = useState(submitInfo);
  const [cancelVisible, setCancelVisible] = useState<boolean>(false);
  const [cancelId, setCancelId] = useState<string>("");
  const [showAutoFinancing, setShowAutoFinancing] = useState<boolean>(false);

  useEffect(() => {
    getInitData();
  }, []);
  // 初始化数据,
  const getInitData = (type?: string) => {
    fetchAutoInvestList(type);
  };
  const fetchAutoInvestList = (type?: string) => {
    setLoading(true);
    const param = { limit: 10, nextToken: nextToken };
    //接口返回数据结构可优化 @后端
    myAutoInvestList(param)
      .then((res) => {
        if (res?.code == "0") {
          if (type === "loadMore") {
            setDataSource(dataSource.concat(res.data?.investmentContracts));
          } else {
            setDataSource(res.data?.investmentContracts);
          }
          setNextToken(res.data?.nextToken);
        }
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const showOrCloseModal = () => {
    setModalVisible(!modalVisible);
  };

  const getDetailsInfo = (param) => {
    setConfirmInfo((prevInfo) => {
      prevInfo.cycle = Automatice_Investment_Cycle_Code[param?.period];
      prevInfo.amount = param?.amount;
      prevInfo.fee = param?.feeRate;
      prevInfo.revenueRate = param?.revenueRate;
      prevInfo.recentDate = param?.nextInvestTime;
      prevInfo.payCoin = param?.payCurrency;
      prevInfo.rateList = [...resetRate(param?.investmentProportion?.currencyProportion)];
      return { ...prevInfo };
    });
    setIsTransferYield(param.autoFinancing === 1); //是否客户开启了自动转活期理财
    setShowAutoFinancing(param.canAutoFinancing === 1); //是否管理端开启了自动转活期理财
    showOrCloseModal();
  };

  //正在进行中的定投
  const columns = [
    {
      title: date_text,
      dataIndex: "created",
      render: (record) => <span>{transformTime(record)}</span>
    },
    {
      title: name_text,
      dataIndex: "productNameKey",
      render: (record) => (
        <span>
          <span>
            {window.__intl__.formatMessage(
              { id: "savings.automatic.name" },
              {
                coin: record?.split(".")?.slice(1, -1)?.join(",").toUpperCase()
              }
            )}
          </span>
        </span>
      )
    },
    {
      title: status_text,
      dataIndex: "status",
      render: (record) => (
        <div>
          {record === 2 ? (
            <div>
              <span>{My_Automatice_Investment[record]}</span>
              <Tooltip title={failTips_text} style={{ width: 200 }}>
                <InfoCircleOutlined style={{ paddingLeft: "5px", cursor: "pointer" }} />
              </Tooltip>
            </div>
          ) : (
            <span>{My_Automatice_Investment[record]}</span>
          )}
        </div>
      )
    },
    {
      title: apy_text,
      dataIndex: "revenueRate",
      render: (record) => <span>{decimalPointNoMoreX(record * 100, 2)}%</span>
    },
    {
      title: plan_details_text,
      render: (value, row) => (
        <Button className={styles.actionBtn} onClick={() => getDetailsInfo(row)}>
          {intl["button.view"]}
        </Button>
      )
    },
    {
      title: investment_details_text,
      render: (value, row) => (
        <Button
          className={styles.actionBtn}
          onClick={() =>
            window.__history__.push({
              pathname: "/yield/investmentHistory",
              state: { id: row.id }
            })
          }>
          {intl["button.view"]}
        </Button>
      )
    },
    {
      title: action_text,
      render: (value, row) => (
        <div>
          {row.status != 3 && (
            <Button
              className={styles.actionBtn}
              // disabled={row.status === 2} //目前都可以取消
              onClick={() => handleShowCancelVisible(row.id)}>
              {intl["button.cancel"]}
            </Button>
          )}
        </div>
      )
    }
  ];
  //取消定投
  const handleShowCancelVisible = (id?: string) => {
    setCancelId(id);
    setCancelVisible(true);
  };
  const handleCloseCancelVisible = () => {
    setCancelVisible(false);
  };

  //验证码
  const hideOK = (code: string) => {
    fetchCancel(code);
  };

  const handleCancel = () => {
    if (USER_ROLE_POWER[localStorage.getItem("user_auth")]?.actionForOthers) {
      setShowSafetyVerificationModal(true);
    } else {
      fetchCancel();
    }
  };
  const fetchCancel = (emailCode?: string) => {
    const param = {
      contractId: cancelId,
      verifyCode: emailCode || null
    };
    setLoading(true);
    cancelAutomaticInvestmentPlan(param)
      .then((res) => {
        if (res?.code == "0") {
          if (res.data === true) {
            handleCloseCancelVisible();
            setShowSafetyVerificationModal(false);
            //删除后清空分页重新获取数据
            setNextToken(null);
            message.destroy();
            message.success("Cancel Successful", 1).then(() => {
              getInitData();
            });
          } else {
            message.destroy();
            message.error(intl?.[res.msg] ?? res.msg);
          }
        } else {
          message.destroy();
          message.error(intl?.[res.msg] ?? res.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section className={styles.savings + " " + "customer"}>
      <div className="customerTop">
        <Row className="top" justify="space-between">
          <Col>
            <p className="title">{my_title_text}</p>
          </Col>
          <Col>
            <Button
              size="large"
              type="primary"
              className={styles.investmentPlanBtn}
              onClick={() => {
                window.__history__.push("/yield/investmentHistory");
              }}>
              {history_btn_text}
            </Button>
          </Col>
        </Row>
      </div>
      <div className={styles.tableWrap}>
        <div className="section" style={{ width: "100%", marginTop: 0 }}>
          <div className={styles.myInvestmentPlan} style={{ marginTop: 0 }}>
            <div className={styles.myInvestmentPlannArea}>
              <Suspense
                fallback={
                  <div className={styles.marketsCard + " middleAlign"}>{/* <Spin /> */}</div>
                }>
                <WithEmptyContent showEmpty={true}>
                  <Spin spinning={loading}>
                    <Table
                      className={styles.table}
                      rowKey={(record) => record?.id}
                      bordered={false}
                      dataSource={dataSource}
                      columns={columns}
                      pagination={false}
                      rowClassName={styles.sales}
                    />
                    {nextToken && dataSource.length >= 10 && (
                      <div className={styles.loadMore} onClick={() => getInitData("loadMore")}>
                        {loadMore}
                      </div>
                    )}
                  </Spin>
                </WithEmptyContent>
              </Suspense>
              <Modal
                className={styles.autoModal}
                visible={cancelVisible}
                confirmLoading={loading}
                title={intl["savings.automatic.modal.cancel.title"]}
                width={480}
                onOk={handleCancel}
                onCancel={handleCloseCancelVisible}
                okText={intl["button.confirm"]}
                cancelText={intl["button.cancel"]}
                destroyOnClose={true}>
                <p>{intl["savings.automatic.modal.cancel.content"]}</p>
              </Modal>
              <Suspense
                fallback={<div className={styles.history + " middleAlign"}>{/* <Spin /> */}</div>}>
                <ModalAutomaticInvestment
                  type="show"
                  visible={modalVisible}
                  checked={isTransferYield}
                  handleCancel={showOrCloseModal}
                  info={confirmInfo}
                  showAutoFinancing={showAutoFinancing}
                />
              </Suspense>
              <Suspense
                fallback={
                  <div className={styles.history + " middleAlign"}>
                    <Spin />
                  </div>
                }>
                <ModalSafetyVerification
                  confirmLoading={loading}
                  visible={showSafetyVerificationModal}
                  handleCancel={() => {
                    setShowSafetyVerificationModal(false);
                  }}
                  onOk={hideOK}
                  businessType={-1}
                  isCustomerValidate={true}
                />
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InvestmentPlan;
