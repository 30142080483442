import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import styles from "@/views/login/Login.module.less";
import { forgetResetPassword, googleV3Record, getConfigInfo } from "@/services/request";
import EmailCodeLayout from "@/components/withVerifyEmailCode/WithEmailCodeLayout";
import { Intl } from "@/model/index";
import {
  PASSWORD_MAX_LENGTH,
  MD5_FIXED_STRING,
  RECAPTCHA_V2_KEY_INVISIBLE,
  VerifyCode_MAX_LENGTH
} from "@/config/constants";
import {
  passwordValidator,
  passwordConfirmValidator,
  grecaptchaToken,
  isEmail
} from "@/utils/index";
import md5 from "md5";
import ReCAPTCHA from "react-google-recaptcha";
import Bugsnag from "@bugsnag/js";

interface IProps {
  countdown: number;
  loading: boolean;
  sendMailBtn: string;
  getEmailCode: (businessType: number, email?: string) => void;
  verifyEmailInputCode: (
    verifyCode: string,
    email?: string
  ) => { consecutiveErrors?: boolean; verifyStatus?: string | number; data?: { result?: string } };
  handleBlur: (event: { target: { value: any }; currentTarget: any }) => void;
  handleFocus: (event: { target: { value: any }; currentTarget: any }) => void;
}

const ResetPasswordAll: React.FC<IProps> = (props: IProps) => {
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const intl: Intl = window.__intl__.messages;
  const inputRef = React.useRef<any>(null);
  const [disabledNext, setDisabledNext] = useState<boolean>(true);
  const [showRecaptcha, setShowRecaptcha] = useState<boolean>(false);
  const recaptchaRef = React.createRef<any>();
  const [email, setEmail] = useState<string>("");
  const [verifyCode, setVerifyCode] = useState<string>("");

  useEffect(() => {
    getConfigInfo().then((res) => {
      if (res?.code == 0) {
        setShowRecaptcha(res.data?.isRecaptcha);
      }
    });
  }, []);

  const handleSubmit: () => void = async () => {
    // if (showRecaptcha) {
    //   try {
    //     recaptchaRef?.current?.execute();
    //   } catch (e) {
    //     message.destroy();
    //     message.error(intl["login.network.error"]);
    //     Bugsnag.notify(new Error("[ws.send(/api/alive)] -- [error]: " + "recaptcha loaded error"));
    //   }
    // } else {
    //   fetchSubmit();
    // }
    fetchSubmit();
  };
  const fetchSubmit = async (recaptchaValue2?: string) => {
    // 当用户重置密码时，先保存用户邮箱，上报信息时使用
    localStorage.setItem("user", email);
    // const clientTokenV3 = await grecaptchaToken();
    setLoading(true);
    const params = {
      clientToken: showRecaptcha ? recaptchaValue2 : null,
      userName: email,
      emailCode: verifyCode,
      newPassword: md5(confirmPassword + MD5_FIXED_STRING),
      resetToken: null
    };
    // googleV3Record({ clientToken: clientTokenV3 });
    await forgetResetPassword(params)
      .then((res) => {
        if (res?.code == "0") {
          window.__history__.push("/login");
        } else {
          // window.grecaptcha.reset();
          const msg = res?.msg ? intl?.[res?.msg] : res?.msg;
          message.destroy();
          message.error(msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleChangeCode = (e) => {
    const val = e.target.value;
    const onlyNumber = val.replace(/[^0-9]/gi, "");
    setVerifyCode(onlyNumber);
  };

  useEffect(() => {
    if (password && confirmPassword && password == confirmPassword) setDisabledNext(false);
  }, [password, confirmPassword]);

  //render(): React.ReactNode {
  const messagePassword: string = intl["error.password.format"];
  return (
    <section className={styles.container}>
      <p className={styles.title}>{intl["reset.login"]}</p>
      <Form
        autoComplete="off"
        name="basic"
        layout="vertical"
        className={styles.registerStep2}
        initialValues={{ remember: true }}
        style={{ marginTop: -22 }}
        onFinish={handleSubmit}>
        {/* 防止自动填充邮箱验证码 */}
        <input className="hiddenInput" />
        <input type="password" className="hiddenInput" />
        <Form.Item
          name="email"
          style={{ marginBottom: 25 }}
          rules={[
            {
              type: "email",
              message: intl["register.email.incorrect"]
            },
            {
              required: true,
              message: "Email required"
            }
          ]}>
          <div>
            <label className={styles.label}> {intl["forget.email.label"]} </label>
            <Input
              className={email ? styles.inputHover : ""}
              autoComplete="off"
              ref={inputRef}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={props.handleFocus}
              onBlur={props.handleBlur}
            />
          </div>
        </Form.Item>
        <div className={styles.eamilGroup} style={{ marginBottom: -22 }}>
          <Form.Item
            style={{ marginBottom: 24 }}
            name="verify"
            rules={[{ required: true, message: intl["customer.email.code.incorrect"] }]}>
            <div>
              <label className={styles.label}> {intl["register.verify"]} </label>
              <Input
                className={verifyCode ? styles.inputHover : ""}
                autoComplete="new-password"
                onChange={handleChangeCode}
                value={verifyCode}
                onFocus={props.handleFocus}
                onBlur={props.handleBlur}
                maxLength={VerifyCode_MAX_LENGTH}
              />
            </div>
          </Form.Item>
          <Form.Item style={{ marginBottom: 24 }}>
            <Button
              loading={props.loading}
              disabled={!email || !isEmail(email) || props.countdown != 60}
              className={styles.sendEmail}
              onClick={() => props.getEmailCode(1, email)}>
              {props.sendMailBtn}
            </Button>
          </Form.Item>
        </div>
        {/* 防止自动填充邮箱验证码 */}
        <input className="hiddenInput" />
        <input type="password" className="hiddenInput" />
        <Form.Item
          name="password"
          style={{ marginBottom: 25 }}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                return passwordValidator(value, messagePassword);
              }
            })
          ]}>
          <div>
            <label className={styles.label}> {intl["new.password"]} </label>
            <Input
              className={password ? styles.inputHover : ""}
              type="password"
              maxLength={PASSWORD_MAX_LENGTH}
              onFocus={props.handleFocus}
              onBlur={props.handleBlur}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 25 }}
          name="confirmPassword"
          dependencies={["password"]}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                return passwordConfirmValidator(
                  value,
                  getFieldValue("password"),
                  messagePassword,
                  intl["password.not.match"]
                );
              }
            })
          ]}>
          <div>
            <label className={styles.label}> {intl["reset.login.confirm"]} </label>
            <Input
              className={confirmPassword ? styles.inputHover : ""}
              type="password"
              maxLength={PASSWORD_MAX_LENGTH}
              onFocus={props.handleFocus}
              onBlur={props.handleBlur}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </Form.Item>
        <Button
          disabled={disabledNext}
          className={styles.submit}
          type="primary"
          htmlType="submit"
          loading={loading}>
          {intl["button.submit"]}
        </Button>

        {/* <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          hl="en"
          theme="dark"
          sitekey={RECAPTCHA_V2_KEY_INVISIBLE}
          onChange={(value) => {
            fetchSubmit(value);
          }}
        /> */}
        <Row className="text-center" style={{ marginTop: 30 }}>
          <Col span={24}>
            <Link to="/login" className={styles.toLogin}>
              {intl["link.to.login"]}
            </Link>
          </Col>
        </Row>
      </Form>
    </section>
  );
  //}
};

export default EmailCodeLayout(ResetPasswordAll);
