import React, { Suspense } from "react";
import { Intl } from "@/model/index";
import { Row, Col, Breadcrumb, Input, Spin, Select, Modal, message, Button } from "antd";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styles from "./Wallet.module.less";
import { Link } from "react-router-dom";
import { InfoCircleFilled } from "@ant-design/icons";
import {
  getDepisitCoinList,
  showDepositAddress,
  getDepositAddress,
  getAssetCurrencyConfig
} from "@/services/request";
import KYCInfoLayout from "@/components/withKycInfo/WithKYCInfo";
import Bugsnag from "@bugsnag/browser";
import { convertUSD2USDC, formatBugsnagMessage, scientificToNumber } from "@/utils";

const { Option } = Select;

const History = React.lazy(() => import(/* webpackChunkName: "History" */ "./components/History"));

interface IProps {
  showGuideModal: (isClose) => void;
  intl: Intl; //
}

interface IState {
  depositAddress: string;
  coinList: any[];
  currentCoin: string;
  currentTag: string;
  loading: boolean;
  showTips: boolean;
  showKycModal: boolean;
  chainList: any[];
  currentChain: string;
  availableNetwork: number;
  depositNetwork: number;
}
class Deposit extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      depositAddress: "",
      coinList: [],
      currentCoin: "",
      currentTag: "",
      loading: false,
      showTips: false,
      showKycModal: props?.kycInfo?.auditStatus == 1 ? false : true,
      chainList: [],
      currentChain: "",
      availableNetwork: 0,
      depositNetwork: 0
    };
  }

  componentDidMount() {
    if (this.state.showKycModal) {
      this.props.showGuideModal(true);
    } else {
      this.getCoinList();
    }
  }

  getCoinList: () => void = async () => {
    const intl: Intl = window.__intl__.messages;
    this.setState({
      loading: true
    });
    await getDepisitCoinList()
      .then((res) => {
        if (res?.code == "0") {
          const list = res?.data?.filter((item) => {
            if (item.currency === "USD") {
              // 判断usd下是否有USDC的链，如果没有则不显示该币
              return item?.assets?.some((item) => item.asset.includes("USDC"));
            }
            return true;
          });
          this.setState({
            coinList: list || []
          });
          if (list.length > 0) {
            if (window?.__history__?.location?.query?.currency) {
              if (list.some((e) => e?.currency == window?.__history__?.location?.query?.currency)) {
                // this.showDepositAddress(this.props?.location?.query?.currency);
                this.setState({
                  currentCoin: window?.__history__?.location?.query?.currency || list[0]?.currency
                });
              } else {
                this.setState({
                  showTips: true,
                  currentCoin: list[0]?.currency
                });
                // this.showDepositAddress(list[0]?.currency);
              }
            } else {
              this.setState({
                currentCoin: list[0]?.currency
              });
              // this.showDepositAddress(list[0]?.currency);
            }
          } else {
            this.setState({
              loading: false
            });
            Bugsnag.notify(
              formatBugsnagMessage(
                "no Coin list" + JSON.stringify(res, null, "\t"),
                "Action Deposit"
              )
            );
          }
        } else {
          this.setState({
            loading: false
          });
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch((e) => {})
      .finally(() => {});
    this.fetchChainList(this.state.currentCoin);
  };
  //获取链地址
  fetchChainList = (currency) => {
    const intl = window.__intl__.messages;
    const param = {
      currency: currency
    };
    currency &&
      getAssetCurrencyConfig(param)
        .then((res) => {
          if (res?.code == 0) {
            const chainList = res.data?.assets?.filter((item) => item.chainType !== "FIAT") || [];
            this.setState(
              {
                chainList,
                currentChain: chainList[0]?.asset || "",
                availableNetwork: res.data?.assets?.[0]?.availableNetwork || 0,
                depositNetwork: res.data?.assets?.[0]?.depositNetwork || 0
              },
              () => {
                this.showDepositAddress(this.state.currentChain);
              }
            );
          } else {
            message.destroy();
            message.error(intl?.[res?.msg] ?? res?.msg);
          }
        })
        .finally(() => {
          this.setState({
            loading: false
          });
        });
  };

  //先获取已生成电子货币地址 没有则请求生成电子货币地址（多链传入coincode为链的asset）
  showDepositAddress(coincode) {
    let value: string = coincode;
    // if (value == "USDT") {
    //   value = "EUSDT";
    // } //钱包跳转进来usdt要请求eusdt拿到address
    this.setState({
      loading: true
    });
    const params = { currency: value };
    showDepositAddress(params).then((res) => {
      if (res?.code == "0") {
        if (res?.data?.address) {
          this.setState({
            depositAddress: res.data.address,
            currentTag: res.data?.memo && res.data?.memo,
            loading: false
          });
        } else {
          getDepositAddress(params)
            .then((res) => {
              if (res?.code == "0") {
                this.setState({
                  depositAddress: res.data.address,
                  currentTag: res.data?.memo && res.data?.memo
                });
              }
            })
            .finally(() => this.setState({ loading: false }));
        }
      } else {
        this.setState({
          depositAddress: "",
          loading: false
        });
      }
    });
  }

  getText(): { [propName: string]: any } {
    const intl: Intl = window.__intl__.messages;
    const location: string = intl["kyc.certify.location"];
    const wallet: string = intl["wallet"];
    const information: string = intl["wallet.deposit.btc"];
    const title: string = intl["wallet.deposit.btc.title"];
    const select: string = intl["wallet.deposit.btc.select"];
    const address = intl["wallet.deposit.btc.address"];
    const tag = intl["wallet.deposit.btc.tag"];
    const qrcodeTips = intl["wallet.deposit.btc.qrcode.tips"];
    const tips1 = intl["wallet.deposit.btc.tips1"];
    const tips2 = intl["wallet.deposit.btc.tips2"];
    const notice = intl["wallet.deposit.btc.notice"];
    const modalTitle = intl["wallet.deposit.modal.title"];
    const modalContent = intl["wallet.deposit.modal.content"];
    const okText: string = `${intl["button.confirm"]}`;
    const cancelText: string = `${intl["button.cancel"]}`;
    const copyText: string = `${intl["button.copy"]}`;

    return {
      location,
      wallet,
      title,
      select,
      information,
      address,
      tag,
      qrcodeTips,
      tips1,
      tips2,
      notice,
      modalTitle,
      modalContent,
      okText,
      cancelText,
      copyText
    };
  }

  handleChange = (value) => {
    // this.showDepositAddress(value);
    this.fetchChainList(value);
    this.setState({
      depositAddress: "",
      currentCoin: value
    });
  };
  handleCancleTips = () => {
    this.setState({
      showTips: false
    });
  };
  handleChangeChain = (value) => {
    this.showDepositAddress(value);
    this.setState({
      currentChain: value
    });
  };
  onCopy() {
    const intl = window.__intl__.messages;
    message.destroy();
    message.success(intl["copied"]);
  }

  render(): React.ReactNode {
    const {
      location,
      wallet,
      title,
      information,
      select,
      address,
      tag,
      qrcodeTips,
      tips1,
      tips2,
      notice,
      modalTitle,
      modalContent,
      okText,
      cancelText,
      copyText
    } = this.getText();
    const {
      depositAddress,
      coinList,
      loading,
      currentCoin,
      currentTag,
      showTips,
      showKycModal,
      chainList,
      currentChain,
      availableNetwork,
      depositNetwork
    } = this.state;
    // 获取当前链数据
    const currentChainData = chainList.find((item) => item.asset == currentChain);

    return (
      <section className={styles.deposit}>
        <Spin spinning={loading}>
          <Breadcrumb>
            <Breadcrumb.Item>{location}:</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/wallet">{wallet}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{convertUSD2USDC(currentCoin) + information}</Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.depositContent}>
            <p className={styles.title}>{convertUSD2USDC(currentCoin) + information}</p>
            <Row>
              <Col flex="auto" className={styles.depositLeft}>
                <div className={styles.currentCoin}>
                  <div className={styles.currentCoinLeft}>
                    <p className={styles.label}>{select}</p>
                    <Select
                      className={styles.selectCoin}
                      value={currentCoin}
                      style={{ width: 100 }}
                      onChange={(e) => this.handleChange(e)}>
                      {coinList.map((item, index) => {
                        return (
                          <Option key={index} value={item.currency}>
                            {convertUSD2USDC(item.currency)}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className={styles.currentCoinRight}>
                    <p className={styles.label}>Select Network</p>
                    <Select
                      className={styles.selectCoin}
                      value={currentChain}
                      style={{ width: 100 }}
                      onChange={(e) => this.handleChangeChain(e)}>
                      {chainList.map((item, index) => {
                        return (
                          <Option key={index} value={item.asset}>
                            {item.chainName}
                          </Option>
                        );
                      })}
                    </Select>
                    {/* <div className={styles.chainType}>ERC20</div> */}
                  </div>
                </div>
                <div style={{ marginBottom: 20 }}>
                  <p className={styles.label}>Minimum Deposit: </p>
                  <p className={styles.minDepositAmount}>
                    {currentChainData?.minDepositAmount
                      ? `${scientificToNumber(
                          currentChainData?.minDepositAmount
                        )} ${convertUSD2USDC(currentCoin)}`
                      : "--"}
                  </p>
                </div>
                <p className={styles.label}>{convertUSD2USDC(currentCoin) + address}</p>
                <div className={styles.copyArea}>
                  <Input type="text" value={depositAddress} disabled />
                  <CopyToClipboard text={depositAddress} onCopy={this.onCopy}>
                    <span>{copyText}</span>
                  </CopyToClipboard>
                </div>
                {currentCoin == "XRP" && (
                  <div>
                    <p className={styles.label}>{currentCoin + tag}</p>
                    <div className={styles.copyArea}>
                      <Input type="text" value={currentTag} disabled />
                      <CopyToClipboard text={currentTag} onCopy={this.onCopy}>
                        <span>{copyText}</span>
                      </CopyToClipboard>
                    </div>
                  </div>
                )}
                <Row>
                  <Col flex="20px">
                    <InfoCircleFilled
                      style={{ backgroundColor: "#fff", borderRadius: "50%", color: "#ff5351" }}
                    />
                  </Col>
                  <Col flex="auto">
                    <p className={styles.tips}>{tips1}</p>
                    <p className={styles.tips}>
                      {window.__intl__.formatMessage(
                        { id: "wallet.deposit.btc.tips2" },
                        {
                          currentCoin: convertUSD2USDC(currentCoin)
                        }
                      )}
                    </p>
                  </Col>
                </Row>
              </Col>
              {!showKycModal && (
                <Col
                  flex="300px"
                  style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <p className={styles.label}>
                    {window.__intl__.formatMessage(
                      { id: "wallet.deposit.btc.qrcode.tips" },
                      {
                        currentCoin: convertUSD2USDC(currentCoin)
                      }
                    )}
                  </p>
                  <div className={styles.QRCode}>
                    <QRCode value={depositAddress} size={170} />
                  </div>
                </Col>
              )}
            </Row>
            <div className={styles.noticeContent}>
              <p className={styles.title}>{notice}</p>
              <p>
                {window.__intl__.formatMessage(
                  { id: "wallet.deposit.btc.notice.first" },
                  {
                    currentCoin: convertUSD2USDC(currentCoin)
                  }
                )}
              </p>
              <p>
                {window.__intl__.formatMessage(
                  { id: "wallet.deposit.btc.notice.second" },
                  {
                    depositNetwork,
                    availableNetwork
                  }
                )}
              </p>
            </div>
          </div>
        </Spin>

        <Suspense fallback={<div className={styles.history + " middleAlign"} />}>
          <History showViewAll={true} type={1} currency={currentCoin} fiat={false} />
        </Suspense>
        <Modal
          title={modalTitle}
          wrapClassName={styles.modal}
          visible={showTips}
          onOk={this.handleCancleTips}
          onCancel={this.handleCancleTips}
          footer={[
            <Button type="primary" key="cancle" onClick={this.handleCancleTips}>
              {okText}
            </Button>
          ]}>
          <p>
            {window.__intl__.formatMessage(
              { id: "wallet.deposit.modal.content" },
              {
                currency: window?.__history__?.location?.query?.currency
              }
            )}
          </p>
        </Modal>
      </section>
    );
  }
}

export default KYCInfoLayout(Deposit);
