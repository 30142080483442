import React from "react";
import Auth from "@/components/auth/Auth";
import Header from "@/components/header/Header";
import AgencyTipsArea from "@/components/agencyTipsArea/AgencyTipsArea";
import Footer from "@/components/footer/Footer";
import { Affix, Modal } from "antd";
import { isMobile } from "@/utils";
import { USER_ROLE_POWER } from "@/config/constants";
import { withRouter } from "react-router-dom";
import { checkSupportWebp } from "@/utils";
import ReactGA from "react-ga4";
import { apikeyGa } from "@/config/";

const gotoGoogle = require("@/assets/images/gotoGoogle.svg");

import { IHistory, ILocation, Intl } from "@/model/index";
import { injectIntl } from "react-intl";
interface IProps {
  history: IHistory;
  location: ILocation;
  intl: Intl;
}
interface IState {
  showModal: boolean;
}
class App extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: true
    };
    this.handleOk = this.handleOk.bind(this);
    window.__intl__ = this.props.intl;
    window.__history__ = this.props.history;
    window.__location__ = this.props.location;
  }

  handleOk() {
    this.setState({ showModal: false });
  }
  renderModal() {
    return (
      <Modal
        visible={this.state.showModal}
        onOk={this.handleOk}
        onCancel={this.handleOk}
        centered={true}>
        <div style={{ margin: "30px 0 20px 0", fontSize: 14 }}>
          Welcome to Aspen Digital. Mobile access to the Aspen Digital platform is currently not
          supported. Please access via PC web browsers.
        </div>
      </Modal>
    );
  }

  render() {
    let isAuthenticated =
      (localStorage.getItem("isAuthenticated") &&
        USER_ROLE_POWER[localStorage.getItem("user_auth")]) ||
      false;
    if (location.pathname.includes("login")) {
      isAuthenticated = false;
    }
    if (isAuthenticated) {
      return (
        <>
          {isMobile() && this.renderModal()}
          <Header />
          <AgencyTipsArea />
          <div className="App">
            <Auth {...this.props} />
            <Affix style={{ position: "fixed", bottom: 20, right: 20, zIndex: 9 }}>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScTitYq7cQ1TqWkjg9b_UVkJVCFM5pDyEu5eTWX7IVtdkwuZA/viewform"
                target="_blank">
                <div
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: "50%",
                    backgroundColor: "#F17E00",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0 0 8px 0 rgba(0,0,0,0.8)"
                  }}>
                  <img src={gotoGoogle} style={{ width: 20, height: 20 }} alt="" />
                </div>
              </a>
            </Affix>
          </div>
          <Footer />
        </>
      );
    } else {
      return (
        <>
          {isMobile() && this.renderModal()}
          <Auth {...this.props} />
        </>
      );
    }
  }
  componentDidMount() {
    window.__history__ = this.props.history;
    window.__location__ = this.props.location;

    // 判断浏览器是否支持webp格式的图片
    checkSupportWebp("lossy", (result) => {
      localStorage.setItem("formatwebp", result ? "" : "format,png");
    });
    console.info(apikeyGa);

    if (apikeyGa) {
      // 绑定初始化ga
      ReactGA.initialize(apikeyGa);
      // ga监听路由变化变化
      window.__history__.listen((location, action) => {
        const pathname = location.pathname;
        ReactGA.send({ hitType: "pageview", page: pathname });
      });
    }
  }
}

export default injectIntl(withRouter(App));
