import React from "react";
import { apiKeyBugsnag } from "@/config/";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { Alert } from "antd";
import { ignoreBugsnagError, DOMAIN_GREEN, DOMAIN_GREEN_PARTNER } from "@/config/constants";

interface IProps {
  children: React.ReactNode;
}
interface IState {
  hasError: boolean;
  onLine: boolean;
}
let ErrorBoundary;

if (process.env.NODE_ENV == "production") {
  // 初始化错误监控服务
  Bugsnag.start({
    apiKey: apiKeyBugsnag,
    plugins: [new BugsnagPluginReact()],
    user: {
      id: localStorage.getItem("customerId"),
      name: "",
      email: localStorage.getItem("user")
    },
    releaseStage: process.env.NODE_ENV,
    autoTrackSessions: false,
    // 自定义错误报告 https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#groupinghash
    onError: (event) => {
      // 忽略的错误 ignoreBugsnagError
      const isIgnoreError = ignoreBugsnagError.some((item) =>
        String(event.errors?.[0]?.errorMessage)?.includes(item)
      );
      // 绿环境不上报错误
      const hostname: string = location.hostname;
      if (isIgnoreError || hostname === DOMAIN_GREEN || hostname === DOMAIN_GREEN_PARTNER) {
        return false;
      }
      event.context = `${window.location.pathname} - email: ${localStorage.getItem("user")}`;
      event.groupingHash = String(new Date().getTime()); // 覆盖默认的分组信息 https://docs.bugsnag.com/product/error-grouping/#custom-grouping-hash
    }
  });
  ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
}

// 异常上报
class WithLogging extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      onLine: true
    };
  }
  componentDidMount() {
    //fundebug.apikey = apikeyFundebug;
    window.addEventListener("offline", () => {
      this.setState({
        onLine: false
      });
    });
    window.addEventListener("online", () => {
      this.setState({
        onLine: true
      });
    });
    // Error log statistic
    window.addEventListener("error", function onError(e) {
      // Ignore ResizeObserver error
      if (e.message === "ResizeObserver loop limit exceeded") {
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
    });
  }
  static getDerivedStateFromError(error) {
    // 更新 state 使下一次渲染可以显降级 UI
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    if (String(error).includes("Loading") && String(error).includes("chunk")) {
      window.location.reload();
    }
  }

  // componentDidCatch(error, info): void {
  //   let hostname: string = location.hostname;
  //   if (String(error).includes("Loading chunk")) {
  //     window.location.reload();
  //   }
  //   fundebug production环境上报
  //   if (hostname.indexOf("aspendigital.co") == -1) {
  //     // 将component中的报错发送到Fundebug
  //     fundebug.notifyError(error, {
  //       metaData: {
  //         info: info
  //       }
  //     });
  //   }
  // }

  componentWillUnmount() {
    this.setState = () => false;
  }

  render(): React.ReactNode {
    if (process.env.NODE_ENV == "production") {
      if (this.state.hasError) {
        return (
          <ErrorBoundary>
            <h1>Something went wrong.</h1>
          </ErrorBoundary>
        );
      }
      return (
        <ErrorBoundary>
          {!this.state.onLine && (
            <Alert
              style={{ textAlign: "center" }}
              showIcon
              message="No network, please check the network link! 😢"
              type="warning"
              closable
            />
          )}
          {this.props.children}
        </ErrorBoundary>
      );
    } else {
      if (this.state.hasError) {
        return (
          <>
            <h1 style={{ textAlign: "center", marginTop: "10%" }}>
              Something went wrong, Please reload again.
            </h1>
          </>
        );
      }
      return (
        <>
          {!this.state.onLine && (
            <Alert
              style={{ textAlign: "center" }}
              showIcon
              message="No network, please check the network link! 😢"
              type="warning"
              closable
            />
          )}
          {this.props.children}
        </>
      );
    }
  }
}
export default WithLogging;
