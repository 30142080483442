import React from "react";
import App from "@/App";
import { useAppSelector } from "@/store/hooks";
// 自定义文案国际化
// https://formatjs.io/docs/getting-started/installation/
// https://formatjs.io/docs/react-intl/
import { IntlProvider } from "react-intl";
import en from "@/locales/en";
import { selectedIntl } from "@/slices/IntlSlice";

import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
// import zhCN from "antd/lib/locale/zh_CN";

// locale是传递需要国际化的语言的缩写，通过这个参数可以确定格式化日期，数字，量词的时候按照哪一种语言的规则，这个是规则是intl提供的，一般浏览器会内置这个库，但是在Safari和IE11之前需要自己安装，
// messages是用于传递刚刚我们配置文件的
function IntlWrapper(props) {
  const language = useAppSelector(selectedIntl);
  const translationsForUsersLocale = (lang: string) => {
    let res;
    switch (lang) {
      case "zh":
        res = en;
        break;
      case "en":
        res = en;
        break;
      default:
        res = en;
    }
    return res;
  };

  return (
    <IntlProvider locale={language} messages={translationsForUsersLocale(language)}>
      <ConfigProvider locale={enUS}>
        <App {...props} />
      </ConfigProvider>
    </IntlProvider>
  );
}

export default IntlWrapper;
