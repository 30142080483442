import React, { useEffect, useState, Suspense } from "react";
import { Table, Row, Col, Spin } from "antd";
import styles from "./History.module.less";
import { transformTime, decimalPointNoMoreX, convertUSD2USDC } from "@/utils";
import { accountHistory } from "@/services/request";
import { BUSINESS_TYPE } from "@/config/constants";
import { Intl } from "@/model/index";
import { partial } from "lodash";

interface IProps {}
const WithEmptyContent = React.lazy(
  () => import(/* webpackChunkName: "WithEmptyContent" */ "@/components/emptyContent/EmptyContent")
);

const AccountHistory = (props: IProps) => {
  const intl: Intl = window.__intl__.messages;

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [offset, setOffset] = useState(0);

  const { currency } = window?.__history__?.location?.state || { currency: "USD" };

  useEffect(() => {
    fetchData(offset);
  }, []);

  const columns = [
    {
      title: intl["column.date"],
      dataIndex: "created",
      render: (value: number) => <span>{transformTime(value)}</span>
    },
    {
      title: intl["column.type"],
      dataIndex: "businessType",
      render: (type) => <div style={{ textTransform: "capitalize" }}>{BUSINESS_TYPE[type]}</div>
    },
    {
      title: intl["column.total"],
      dataIndex: "coldMoney",
      render: (coldMoney, { operateType }) => {
        let symbol = "";
        if (operateType === 4 || operateType === 6) {
          symbol = "+";
        } else if (operateType === 5 || operateType === 3) {
          symbol = "-";
        }
        return (
          <div style={{ textTransform: "capitalize" }}>
            {symbol}
            {decimalPointNoMoreX(coldMoney)}
          </div>
        );
      }
    }
  ];
  const fetchData = (offset) => {
    setLoading(true);
    const params = {
      currency,
      offset,
      limit: 10
    };
    accountHistory(params)
      .then((res) => {
        if (res?.code == "0") {
          const { rows = [], offset } = res?.data;
          setDataSource(showLoadMore ? dataSource.concat(rows) : rows);

          setShowLoadMore(rows.length < 10 ? false : true);
          setOffset(offset);
        }
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const loadMore = intl["button.loadMore"];
  return (
    <section className={styles.history + " " + "customer"}>
      <div className="customerTop">
        <Row className="top">
          <Col span={24}>
            <p className="title">{`Spot Account History-${convertUSD2USDC(currency)}`}</p>
          </Col>
        </Row>
      </div>
      <div className={styles.tableWrap}>
        <div className="section" style={{ width: "100%", marginTop: 0 }}>
          <div className={styles.history} style={{ marginTop: 0 }}>
            <Spin spinning={loading}>
              <div className={styles.historyArea}>
                <Suspense
                  fallback={
                    <div className={styles.marketsCard + " middleAlign"}>
                      <Spin />
                    </div>
                  }>
                  <WithEmptyContent showEmpty={true}>
                    <Table
                      className={styles.table}
                      bordered={false}
                      dataSource={dataSource}
                      columns={columns}
                      pagination={false}
                      rowClassName={styles.sales}
                    />
                  </WithEmptyContent>
                </Suspense>
                {showLoadMore && (
                  <div className={styles.loadMore} onClick={partial(fetchData, offset + 10)}>
                    {loadMore}
                  </div>
                )}
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountHistory;
