import React, { useState, useEffect, Suspense } from "react";
import { IkycInfo } from "@/model/index";
import { Breadcrumb, Button, Anchor, Image } from "antd";
import styles from "./Strategy.module.less";
import { Link as RouterLink } from "react-router-dom";
import {
  strategiesSubscribe,
  customerStrategy,
  subscribeIntention,
  redeemIntention,
  getMock,
  getStrategiesList
} from "@/services/request";
import KYCInfoLayout from "@/components/withKycInfo/WithKYCInfo";
import { partial, find, map, findIndex } from "lodash";
import Subscribe from "./components/Subscribe";
import { getQueryValue } from "@/utils/index";
import { strategyListOffLine } from "@/assets/offlineData/strategyList";

import { STRATEGIES_OSS_Url } from "@/config/constants";

const ModalSubscribeResult = React.lazy(
  () => import(/* webpackChunkName: "ModalSubscribeResult" */ "./components/ModalSubscribeResult")
);

const ModalSafetyVerification = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ModalSafetyVerification" */ "@/components/modalSafetyVerification/ModalSafetyVerification"
    )
);

const [SUBSCIBE, REDEEM] = [1, 2];
interface IProps {
  kycInfo: IkycInfo;
  showGuideModal: () => void;
}
function Detail(props: IProps) {
  // 声明多个 state 变量
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [showVerfifyModal, setShowVerfifyModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [modalType, setModalType] = useState(null);
  const [canSubscribe, setCanSubscribe] = useState(false);
  const [canRedeem, setCanRedeem] = useState(false);
  const [imgBg2, setImgBg2] = useState(null);
  const [info, setInfo] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const id = getQueryValue("id");
  const code = getQueryValue("code");

  const imgBg = `${STRATEGIES_OSS_Url}/images/${info.bgImgTop}`;
  const handleLoadImg = () => {
    const imgBg2 = `${STRATEGIES_OSS_Url}/images/${info.bgImgBottom}`;
    setImgBg2(imgBg2);
  };

  useEffect(() => {
    if (id) {
      const params = { strategyId: id };
      customerStrategy(params)
        .then((res) => {
          if (res?.data) {
            setCanRedeem(res?.data?.canRedeem);
            setCanSubscribe(res?.data?.canSubscribe);
          }
        })
        .catch((e) => {});
    }
    window?.__history__?.location?.query?.redeem && handleClick(2);
    if (window?.__history__?.location?.state?.info) {
      setInfo(window?.__history__?.location?.state?.info);
    } else {
      fetchDetail();
    }
  }, []);

  //从外部进入策略
  const fetchDetail = () => {
    getMock().then((response) => {
      let strategyList = response?.strategyList || strategyListOffLine;
      const res = strategyList[strategyList?.findIndex((e) => e.code == code)];
      if (res && id) {
        res.id = id;
      }
      setInfo(res || {});
    });
  };

  const handleClick = (type) => {
    //判断kyc验证是否通过
    if (props.kycInfo.auditStatus != 1) {
      props.showGuideModal();
    } else {
      setShowSubscribeModal(true);
      setModalType(type);
    }
  };
  const handleCancelSubscribeModal = () => {
    setShowSubscribeModal(false);
  };
  const handleOKSubscribeModal = (amount) => {
    //组件中处理
    // const params = {
    //   strategyId: id,
    //   amount
    // };
    // if (modalType === SUBSCIBE) {
    //   subscribeIntention(params);
    //   return;
    // }
    // if (modalType === REDEEM) {
    //   redeemIntention(params);
    // }
  };

  const handleOKConfirmModal = () => {
    subscribe();
  };
  const handleCancelConfirmModal = () => {
    setShowVerfifyModal(false);
  };
  const handleCancelResultModal = () => {
    setShowResultModal(false);
  };

  //申购dispatch
  const subscribe = () => {
    setLoading(true);
    const params = {
      strategyId: id,
      currency: info.thirdValue,
      amount: amount
    };
    strategiesSubscribe(params)
      .then((res) => {
        if (res?.code == "0") {
          setShowResultModal(true);
          setShowSubscribeModal(false);
          setShowVerfifyModal(false);
        }
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const intl: any = window.__intl__.messages;
  const strategies: string = intl["strategies.detail.strategies"];
  const details: string = intl["strategies.detail.details"];
  const subscribeData = {
    amount: amount,
    date: date,
    product: `${info.name}(${info.strategyId})`
  };
  const formatwebp = localStorage.getItem("formatwebp") || "";
  const strategyFormat = formatwebp ? `?x-oss-process=image/${formatwebp}` : "";

  return (
    <div className={styles.detail}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <RouterLink to="/strategies">{strategies}</RouterLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{details}</Breadcrumb.Item>
      </Breadcrumb>
      <section id="title">
        <p className={styles.title}>{info.name}</p>
        <div className={styles.categroy}>{info.category}</div>
      </section>
      <div className={styles.detailContentFaker}>
        <Image
          preview={false}
          src={`${imgBg}${strategyFormat}`}
          placeholder={
            <img
              src={`${imgBg}?x-oss-process=image/blur,r_10,s_10/quality,q_1/resize,m_mfit,w_1200/${formatwebp}`}
            />
          }
          alt=""
          onLoad={handleLoadImg}
        />
        {imgBg2 && (
          <Image
            preview={false}
            src={`${imgBg2}${strategyFormat}`}
            alt=""
            placeholder={
              <img
                src={`${imgBg2}?x-oss-process=image/blur,r_10,s_10/quality,q_1/resize,m_mfit,w_1200/${formatwebp}`}
              />
            }
          />
        )}
        <div className={styles.subBtnWrap}>
          {info.canSubscribe && canSubscribe && (
            <Button
              type="primary"
              className={styles.subscribeBtn}
              onClick={partial(handleClick, SUBSCIBE)}>
              Subscribe
            </Button>
          )}
          {info.canRedeem && canRedeem && (
            <Button
              type="primary"
              className={styles.redeemBtn}
              onClick={partial(handleClick, REDEEM)}>
              Redeem
            </Button>
          )}
        </div>
      </div>

      <Suspense fallback={<div className={"middleAlign"} />}>
        {showSubscribeModal && (
          <Subscribe
            visible={showSubscribeModal}
            handleOK={handleOKSubscribeModal}
            handleCancel={handleCancelSubscribeModal}
            type={modalType}
          />
        )}

        <ModalSafetyVerification
          confirmLoading={loading}
          visible={showVerfifyModal}
          onOk={handleOKConfirmModal}
          type={modalType}
          handleCancel={handleCancelConfirmModal}
        />
        <ModalSubscribeResult
          visible={showResultModal}
          data={subscribeData}
          type={modalType}
          handleCancel={handleCancelResultModal}
        />
      </Suspense>
    </div>
  );
}

export default KYCInfoLayout(Detail);
