import { Intl, IkycInfo } from "@/model/index";
import styles from "./Savings.module.less";
import React, { useEffect, useState, Suspense } from "react";
import { Table, Row, Col, Spin, Button } from "antd";
import { transformTime, decimalPointNoMoreX, convertUSD2USDC } from "@/utils";
import { autoInvestList } from "@/services/request";
import KYCInfoLayout from "@/components/withKycInfo/WithKYCInfo";

interface IProps {
  kycInfo: IkycInfo;
  showGuideModal: () => void;
  intl: Intl; //
}
const WithEmptyContent = React.lazy(
  () => import(/* webpackChunkName: "WithEmptyContent" */ "@/components/emptyContent/EmptyContent")
);

export function InvestmentPlan(props: IProps) {
  const intl: Intl = window.__intl__.messages;
  const name = intl["column.name"];
  const denomination = intl["column.currency"];
  const miniInvestmentAmount = intl["column.yield.auto.purchase.mini.investment.amount"];
  const investmentAmount = intl["column.yield.auto.purchase.investment.amount"];
  const estimatedReturnRate = intl["column.yield.auto.purchase.estimated_return_rate"];
  const action = intl["column.action"];
  const loadMore = intl["button.loadMore"];
  useEffect(() => {
    getInitData();
  }, []);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [showKycModal, setShowKycModal] = useState<boolean>(
    props?.kycInfo?.auditStatus == 1 ? false : true
  );

  // 初始化数据,
  const getInitData: () => void = async () => {
    setLoading(true);
    const param = { limit: 10, nextToken: nextToken };
    //接口返回数据结构可优化 @后端
    autoInvestList(param)
      .then((res) => {
        if (res?.code == "0") {
          setDataSource(
            dataSource.length > 0
              ? dataSource.concat(res.data?.investmentProducts)
              : res.data.investmentProducts
          );
          setNextToken(res.data?.nextToken);
        }
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const columns = [
    {
      title: name,
      dataIndex: "nameKey",
      render: (record) => (
        <span>
          {window.__intl__?.formatMessage(
            { id: "savings.automatic.name" },
            {
              coin: record.split(".").slice(1, -1).join(",").toUpperCase()
            }
          )}
        </span>
      )
    },
    // {
    //   title: Cryptocurrency,
    //   dataIndex: "",
    //   render: (record) => (
    //     //后端接口可优化（目前没有）
    //     <div>
    //       {Object.keys(record.proportionList[0].currencyProportion).map((item, index) => {
    //         if (index < 2) {
    //           if (Object.keys(record.proportionList[0].currencyProportion).length === index + 1) {
    //             return <span key={index}>{item}</span>;
    //           } else {
    //             return <span key={index}>{item},</span>;
    //           }
    //         } else if (index === 2) {
    //           if (Object.keys(record.proportionList[0].currencyProportion).length === 3) {
    //             return <span key={index}>{item}</span>;
    //           } else {
    //             return <span key={index}>{item}...</span>;
    //           }
    //         }
    //       })}
    //     </div>
    //   )
    // },
    {
      title: miniInvestmentAmount,
      render: (record) => (
        <div>
          {decimalPointNoMoreX(record?.payOptionList[0]?.minAmount)}{" "}
          {convertUSD2USDC(record?.payOptionList[0]?.currency)}
        </div>
      )
    },
    {
      title: investmentAmount,
      render: (record) => (
        <div>
          {decimalPointNoMoreX(record?.payOptionList[0]?.maxAmount)}{" "}
          {convertUSD2USDC(record?.payOptionList[0]?.currency)}
        </div>
      )
    },
    {
      title: estimatedReturnRate,
      dataIndex: "revenueRate",
      render: (record) => <span>{(record * 100)?.toFixed(2)}%</span>
    },
    {
      title: action,
      render: (value, row) => (
        <Button
          className={styles.actionBtn}
          disabled={row?.subscribed}
          onClick={() => {
            gotoInvest(row);
          }}>
          {intl["button.invest"]}
        </Button>
      )
    }
  ];
  const gotoInvest = (info) => {
    if (showKycModal) {
      props.showGuideModal();
    } else {
      window.__history__.push({
        pathname: "/yield/addInvestmentPlan",
        state: { info: info }
      });
    }
  };
  return (
    // <section className={styles.savings + " " + "customer"}>
    //   <div className="customerTop">
    //     <Row className="top" justify="space-between">
    //       <Col>
    //         <p className="title">{intl["overview.savings.automatic.title"]}</p>
    //       </Col>
    //       <Col>
    //         <Button
    //           size="large"
    //           type="primary"
    //           className={styles.investmentPlanBtn}
    //           onClick={() => {
    //             window.__history__.push("/yield/myInvestmentPlan");
    //           }}>
    //           {intl["savings.automatic.my.btn"]}
    //         </Button>
    //       </Col>
    //     </Row>
    //   </div>
    //   <div className={styles.tableWrap}>
    //     <div className="section" style={{ width: "100%", marginTop: 0 }}>
    //       <div className={styles.investmentPlan} style={{ marginTop: 0 }}>
    //         <div className={styles.investmentPlanArea}>
    //           <Suspense
    //             fallback={
    //               <div className={styles.marketsCard + " middleAlign"}>
    //                 <Spin />
    //               </div>
    //             }>
    //             <WithEmptyContent showEmpty={true}>
    //               <Spin spinning={loading}>
    //                 <Table
    //                   className={styles.table}
    //                   rowKey={(record) => record?.id}
    //                   bordered={false}
    //                   dataSource={dataSource}
    //                   columns={columns}
    //                   pagination={false}
    //                   rowClassName={styles.sales}
    //                 />
    //                 {nextToken && dataSource.length >= 10 && (
    //                   <div className={styles.loadMore} onClick={getInitData}>
    //                     {loadMore}
    //                   </div>
    //                 )}
    //               </Spin>
    //             </WithEmptyContent>
    //           </Suspense>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <section className={styles.savingTable}>
      <WithEmptyContent showEmpty={true}>
        <Spin spinning={loading}>
          <Table
            className={styles.table}
            rowKey={(record) => record?.id}
            bordered={false}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            rowClassName={styles.sales}
          />
          {nextToken && dataSource.length >= 10 && (
            <div className={styles.loadMore} onClick={getInitData}>
              {loadMore}
            </div>
          )}
        </Spin>
      </WithEmptyContent>
    </section>
  );
}

export default KYCInfoLayout(InvestmentPlan);
