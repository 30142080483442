import React, { useEffect, useState, Suspense } from "react";
import styles from "./Savings.module.less";
import { Link } from "react-router-dom";
import { Intl, ICurrency } from "@/model/index";
import {
  Row,
  Col,
  Spin,
  Breadcrumb,
  message,
  Input,
  Divider,
  Checkbox,
  Tooltip,
  Button
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { getFixedProductsDetail, availableSpot, subscribeFixedProducts } from "@/services/request";
import {
  transformTime,
  decimalPointNoMoreX,
  decimalPointNoMoreXNoFill,
  convertUSD2USDC,
  mathMultiply,
  getQueryValue
} from "@/utils";
import { USER_ROLE_POWER } from "@/config/constants";
import WithCoinList from "@/components/withCoinList/WithCoinList";

const ModalSafetyVerification = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ModalSafetyVerification" */ "@/components/modalSafetyVerification/ModalSafetyVerification"
    )
);

interface IProps {
  coinList: ICurrency[];
}
type AnyStepType = {
  min: number;
  max: number;
  apy: number;
};

export function SubscribeFixedYield(props: IProps) {
  // 产品详情、币种图标
  const id = getQueryValue("id") ?? "";
  const formatwebp = localStorage.getItem("formatwebp") || "";
  const supportWebp = formatwebp ? `?x-oss-process=image/${formatwebp}` : "";

  const intl: Intl = window.__intl__.messages;
  const location_text: string = intl["kyc.certify.location"];
  const apyTipFirst = intl["wallet.fixed.modal.apy.tip.first"];
  const apyTipSecond = intl["wallet.fixed.modal.apy.tip.second"];
  const apyTipThird = intl["wallet.fixed.modal.apy.tip.third"];

  const [loading, setLoading] = useState<boolean>(false);
  const [dataInfo, setDataInfo] = useState<any>();
  const [available, setAvailable] = useState<number>(0);
  const [amount, setAmount] = useState<number | string>("");
  const [check, setCheck] = useState<boolean>(false);
  const [profit, setProfit] = useState<number | string>("- -");
  const [showSafetyVerificationModal, setShowSafetyVerificationModal] = useState<boolean>(false);

  const apyTip = (
    <div>
      <p>{apyTipFirst}</p>
      <p>{apyTipSecond}</p>
      <p>{apyTipThird}</p>
    </div>
  );

  useEffect(() => {
    initData();
  }, []);
  const initData = () => {
    setLoading(true);
    const param = {
      id: id
    };

    getFixedProductsDetail(param)
      .then((res) => {
        if (res?.code == 0) {
          setDataInfo(res?.data);
          const params = {
            currency: res.data?.currency
          };
          availableSpot(params)
            .then((res) => {
              if (res?.code == 0) {
                setAvailable(res.data?.balance);
              }
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);

          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
    calculateProfit(e.target.value);
  };

  // 梯度年化数据渲染
  const stepsApyContent = (type: string) =>
    dataInfo.apySteps.map((item, index) => (
      <Col key={item.apy + index}>
        {type === "amount" && `${item.min} ~ ${item.max ?? "+∞"}`}
        {type === "fixed" && `${item.apy}%`}
        {type === "flexible" && `${dataInfo.financingApy}%`}
      </Col>
    ));

  //最大申购份额
  const subscribeUnitMax = () => {
    const {
      shareAmount,
      personalShareLimit,
      shareQuantity,
      totalShareQuantity,
      usedShareQuantity
    } = dataInfo;
    const canLots = Math.floor(available / shareAmount);
    const remainingLots = totalShareQuantity ? totalShareQuantity - usedShareQuantity : Infinity;
    const personalLots = personalShareLimit - shareQuantity;
    return Math.min(canLots, personalLots, remainingLots);
  };
  //校验金额是否正确
  const checkAmount = (param?: string) => {
    const amountCheck = param ?? amount;
    const reg = /^[1-9]\d*$/;
    if (
      amountCheck === "" ||
      (reg.test(String(amountCheck)) &&
        Number(amountCheck) >= dataInfo?.personalShareMinLimit &&
        Number(amountCheck) <= subscribeUnitMax())
    ) {
      return true;
    }
    return false;
  };
  //输入金额错误提示文案
  const errAmountTips = () => {
    const {
      shareAmount,
      personalShareLimit,
      totalShareQuantity,
      usedShareQuantity,
      shareQuantity,
      personalShareMinLimit
    } = dataInfo;
    const canLots = Math.floor(available / shareAmount);
    const remainingLots = totalShareQuantity ? totalShareQuantity - usedShareQuantity : Infinity;
    const reg = /^[1-9]\d*$/;
    if (!reg.test(String(amount))) {
      return intl["savings.fixed.error.tips.first"];
    }
    if (Number(amount) > canLots) {
      return intl["savings.fixed.error.tips.second"];
    }
    if (Number(amount) > remainingLots) {
      return window.__intl__.formatMessage(
        { id: "savings.fixed.error.tips.third" },
        { remainingLots: remainingLots }
      );
    }
    if (Number(amount) > personalShareLimit - shareQuantity) {
      return window.__intl__.formatMessage(
        { id: "savings.fixed.error.tips.fourth" },
        { personalShareLimit: personalShareLimit - shareQuantity }
      );
    }
    if (Number(amount) < personalShareMinLimit) {
      return window.__intl__.formatMessage(
        { id: "savings.fixed.error.tips.fifth" },
        { personalShareMinLimit: personalShareMinLimit }
      );
    }
    return;
  };
  //计算预计收益
  const calculateProfit = (amount) => {
    if (amount === "" || !checkAmount(amount)) {
      setProfit("- -");
      return;
    }
    const { lockStartTime, financingApy, lockPeriod, shareAmount, apySteps, apy } = dataInfo;
    const principal = Number(amount) * shareAmount; // 本金
    // 活期利息计算，活期每日利率  ((apy+1)^(1/365)-1)
    const financingDays = dayjs(lockStartTime).diff(
      dayjs().add(1, "day").format("YYYY-MM-DD"),
      "day"
    );
    const financingProfit =
      principal * (Math.pow(1 + financingApy * 0.01, 1 / 365) - 1) * financingDays;
    // 定期利息计算，定期每日利率  ((apy+1)^(N/365)-1) / N  -- N为锁仓时长
    let fixedProfit = 0;
    if (apySteps && apySteps.length > 1) {
      //梯度年化
      let totalAmount = principal;
      let apyArr = JSON.parse(JSON.stringify(apySteps));
      apyArr.forEach((item: AnyStepType) => {
        if (!item.max) {
          item.max = Infinity;
        }
        if (principal > item.max) {
          fixedProfit +=
            (item.max - item.min) * (Math.pow(1 + item.apy * 0.01, lockPeriod / 365) - 1);
          totalAmount = principal - item.max;
        } else {
          fixedProfit += totalAmount * (Math.pow(1 + item.apy * 0.01, lockPeriod / 365) - 1);
          totalAmount = 0;
        }
      });
    } else {
      //单一年化
      fixedProfit = principal * (Math.pow(1 + apy * 0.01, lockPeriod / 365) - 1);
    }
    setProfit(decimalPointNoMoreX(financingProfit + fixedProfit));
  };

  const fetchSubscribe = (emailCode?: string) => {
    const params = {
      id: dataInfo?.id,
      quantity: amount,
      autoFinancing: check,
      verifyCode: emailCode || null
    };
    setLoading(true);
    subscribeFixedProducts(params)
      .then((res) => {
        if (res?.code == 0) {
          setShowSafetyVerificationModal(false);
          message.success("Subscription Successful", 1, () => {
            window.__history__.push({
              pathname: "/yield",
              query: { tabType: "1" }
            });
          });
        } else {
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  //验证码
  const hideOK = (code: string) => {
    fetchSubscribe(code);
  };

  const handleSubscribe = () => {
    if (USER_ROLE_POWER[localStorage.getItem("user_auth")]?.actionForOthers) {
      setShowSafetyVerificationModal(true);
    } else {
      fetchSubscribe();
    }
  };

  return (
    <section className={styles.subFixedYield}>
      <Breadcrumb>
        <Breadcrumb.Item>{location_text}: </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={{ pathname: "/yield", query: { tabType: "1" } }}>
            {intl["savings.fixed.lockUp"]}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{intl["savings.fixed.lockUp.subscribe"]}</Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={loading}>
        <div className={styles.container}>
          {dataInfo && (
            <div className={styles.area}>
              <p className={styles.title}>
                <img
                  className={styles.icon}
                  src={props?.coinList[dataInfo.currency]?.picturePath + supportWebp}
                />
                <span>{dataInfo.name}</span>
              </p>
              <div className={styles.content}>
                <div className={styles.leftContent}>
                  <p className={styles.secTitle}>{intl["overview.savings.subscribe"]}</p>
                  <p className={styles.thirdTitle}>{intl["savings.automatic.add.available"]}</p>
                  <p className={styles.amount}>
                    {decimalPointNoMoreX(available)} {convertUSD2USDC(dataInfo?.currency)}
                  </p>
                  <p className={styles.thirdTitle}>{intl["savings.fixed.subscription.unit"]}</p>
                  <div className={styles.inputContent}>
                    <Input
                      bordered={false}
                      value={amount}
                      placeholder={intl["savings.fixed.input.placeholder"]}
                      className={styles.amountInput}
                      onChange={handleChangeAmount}
                    />
                    <span
                      className={styles.maxBtn}
                      onClick={() => {
                        calculateProfit(subscribeUnitMax());
                        setAmount(subscribeUnitMax());
                      }}>
                      {intl["savings.fixed.max"]}
                    </span>
                  </div>
                  <p
                    style={{ visibility: checkAmount() ? "hidden" : "visible", height: 22 }}
                    className={styles.errorTips}>
                    {errAmountTips()}
                  </p>

                  <div className={styles.availableArea}>
                    <div className={styles.availableKye}>
                      <span style={{ marginRight: 4 }}>{intl["savings.fixed.available.unit"]}</span>
                      <Tooltip
                        title={intl["savings.fixed.available.unit.tips"]}
                        style={{ marginLeft: 4, width: 200 }}>
                        <InfoCircleOutlined
                          style={{ cursor: "pointer", color: "rgba(255, 255, 255, 0.6)" }}
                        />
                      </Tooltip>
                    </div>
                    <div className={styles.availablevalue}>
                      {Math.floor(available / dataInfo?.shareAmount)} {intl["savings.fixed.lots"]}
                    </div>
                  </div>
                  <Divider style={{ margin: "8px 0 22px" }} />
                  <div className={styles.availableArea}>
                    <div className={styles.availableKye}>{intl["savings.fixed.total.amount"]}</div>
                    <div className={styles.availablevalue}>
                      {amount !== "" && checkAmount()
                        ? mathMultiply(Number(amount), dataInfo?.shareAmount)
                        : "- -"}{" "}
                      {convertUSD2USDC(dataInfo?.currency)}
                    </div>
                  </div>
                  <div className={styles.availableArea}>
                    <div className={styles.availableKye}>
                      {intl["savings.fixed.max.subscription.unit"]}
                    </div>
                    <div className={styles.availablevalue}>
                      {dataInfo?.personalShareLimit - dataInfo?.shareQuantity}{" "}
                      {intl["savings.fixed.lots"]}
                    </div>
                  </div>
                  <div className={styles.availableArea}>
                    <div className={styles.availableKye}>
                      <span style={{ marginRight: 4 }}>
                        {intl["savings.fixed.estimated.interest"]}
                      </span>
                      <Tooltip
                        title={intl["savings.fixed.estimated.interest.tips"]}
                        style={{ marginLeft: 4, width: 200 }}>
                        <InfoCircleOutlined
                          style={{ cursor: "pointer", color: "rgba(255, 255, 255, 0.6)" }}
                        />
                      </Tooltip>
                    </div>
                    <div className={styles.availablevalue}>
                      {profit} {convertUSD2USDC(dataInfo?.currency)}
                    </div>
                  </div>
                  {dataInfo?.autoFinancing && (
                    <div>
                      <Divider style={{ margin: "10px 0 22px" }} />
                      <div className={styles.checkbox}>
                        <Checkbox
                          style={{ marginBottom: 18, color: "#fff" }}
                          onChange={() => {
                            setCheck(!check);
                          }}
                        />
                        <span className={styles.checkboxText}>
                          {intl["savings.fixed.transfer.flexible"]}
                        </span>
                        <Tooltip
                          title={intl["savings.fixed.transfer.flexible.tips"]}
                          style={{ width: 200 }}>
                          <InfoCircleOutlined
                            style={{ cursor: "pointer", color: "rgba(255, 255, 255, 0.6)" }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  )}

                  <div className={styles.btnArea}>
                    <Button
                      style={{ width: 128, marginRight: 16 }}
                      className={styles.actionBtn}
                      onClick={() => {
                        window.__history__.push({
                          pathname: "/yield",
                          query: { tabType: "1" }
                        });
                      }}>
                      {intl["button.back"]}
                    </Button>
                    <Button
                      style={{ width: 128 }}
                      disabled={!checkAmount() || amount == ""}
                      type="primary"
                      onClick={handleSubscribe}>
                      {intl["button.subscribe"]}
                    </Button>
                  </div>
                </div>
                <div className={styles.rightContent}>
                  <p className={styles.secTitle}>{intl["savings.fixed.details"]}</p>
                  <div className={styles.timeArea}>
                    <div className={styles.first}>
                      <p className={styles.firstTitle}>{intl["savings.fixed.subscription"]}</p>
                      <div className={styles.firstContent}>
                        <p>{intl["savings.fixed.cut.off"]}</p>
                        <p>{transformTime(dataInfo?.lockStartTime)}</p>
                      </div>
                    </div>
                    <div className={styles.second}>
                      <p className={styles.secondTitle}>{intl["savings.fixed.lockUp"]}</p>
                      <div className={styles.secondContent}>
                        <p>
                          {intl["savings.fixed.start"]}
                          {transformTime(dataInfo?.lockStartTime)}
                        </p>
                        <p>
                          {intl["savings.fixed.end"]}
                          {transformTime(
                            dataInfo?.lockStartTime +
                              dataInfo?.lockPeriod * 24 * 60 * 60 * 1000 -
                              1000
                          )}
                        </p>
                      </div>
                    </div>
                    <div className={styles.third}>
                      <p className={styles.thirdTitle}>{intl["savings.fixed.interests"]}</p>
                      <div className={styles.thirdContent}>
                        <p>{intl["savings.fixed.Transfer.time"]}</p>
                      </div>
                    </div>
                  </div>
                  <div className={styles.availableArea}>
                    <div className={styles.availableKye}>{intl["savings.fixed.lock.period"]}</div>
                    <div className={styles.availablevalue}>
                      {dataInfo?.lockPeriod} {intl["savings.fixed.days"]}
                    </div>
                  </div>
                  {dataInfo?.totalShareQuantity && (
                    <div className={styles.availableArea}>
                      <div className={styles.availableKye}>
                        {intl["savings.fixed.total.available.amount"]}
                      </div>
                      <div className={styles.availablevalue}>
                        {decimalPointNoMoreXNoFill(
                          mathMultiply(dataInfo?.usedShareQuantity, dataInfo?.shareAmount)
                        )}
                        /
                        {decimalPointNoMoreXNoFill(
                          mathMultiply(dataInfo?.totalShareQuantity, dataInfo?.shareAmount)
                        )}{" "}
                        {convertUSD2USDC(dataInfo?.currency)}
                      </div>
                    </div>
                  )}
                  <div className={styles.availableArea}>
                    <div className={styles.availableKye}>{intl["savings.fixed.per.unit"]}</div>
                    <div className={styles.availablevalue}>
                      {decimalPointNoMoreXNoFill(dataInfo?.shareAmount)}{" "}
                      {convertUSD2USDC(dataInfo?.currency)}
                    </div>
                  </div>
                  {dataInfo?.apySteps?.length == 1 ? (
                    <div>
                      <div className={styles.availableArea}>
                        <div className={styles.availableKye}>
                          {intl["savings.fixed.depisit.apy"]}
                        </div>
                        <div className={styles.availablevalue}>{dataInfo?.apy}%</div>
                      </div>
                      <div className={styles.availableArea}>
                        <div className={styles.availableKye}>
                          {intl["savings.fixed.flexible.deposit.apy"]}
                        </div>
                        <div className={styles.availablevalue}>{dataInfo?.financingApy}%</div>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.apySteps}>
                      <div className={styles.apyStepsTitle}>
                        <span>{intl["savings.fixed.tiered.apy"]}</span>
                        <Tooltip title={apyTip}>
                          <InfoCircleOutlined style={{ paddingLeft: "10px", cursor: "pointer" }} />
                        </Tooltip>
                      </div>
                      <div className={styles.apyStepTableFlex}>
                        <div className={styles.apyStepTableFlexTitle}>
                          <Row>
                            <Col>{intl["savings.fixed.subscription.amount"]}</Col>
                          </Row>
                          <Row>
                            <Col>{intl["savings.fixed.depisit.apy"]}</Col>
                          </Row>
                          <Row>
                            <Col>{intl["savings.fixed.flexible.deposit.apy"]}</Col>
                          </Row>
                        </div>
                        <div className={styles.apyStepTableFlexContent}>
                          <Row>{stepsApyContent("amount")}</Row>
                          <Row>{stepsApyContent("fixed")}</Row>
                          <Row>{stepsApyContent("flexible")}</Row>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <Suspense
            fallback={
              <div className={styles.history + " middleAlign"}>
                <Spin />
              </div>
            }>
            <ModalSafetyVerification
              confirmLoading={loading}
              visible={showSafetyVerificationModal}
              handleCancel={() => {
                setShowSafetyVerificationModal(false);
              }}
              onOk={hideOK}
              businessType={-1}
              isCustomerValidate={true}
            />
          </Suspense>
        </div>
      </Spin>
    </section>
  );
}
export default WithCoinList(SubscribeFixedYield);
