import React, { Suspense } from "react";
import { injectIntl } from "react-intl";
import { Button, message } from "antd";
import { IInjectIntlProps, Intl } from "@/model/index";
import styles from "./AgencyTipsArea.module.less";
import { withRouter } from "react-router-dom";
import { USER_ROLE_POWER } from "@/config/constants";
import { cancelOperateCustomer, cancelManagerOperate } from "@/services/request";

interface IState {}

interface IProps {}

class AgencyTipsArea extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
  }

  goClient = () => {
    const intl = window.__intl__.messages;
    if (localStorage.getItem("user_auth") === "partnercustomer") {
      cancelOperateCustomer().then((res) => {
        if (res?.code == 0) {
          localStorage.setItem("user_auth", "partner");
          localStorage.removeItem(`${location.origin}agentOperatingToken`);
          localStorage.removeItem("operateCustomerEmail");
          window.__history__.push("/clients");
        } else {
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      });
    } else if (localStorage.getItem("user_auth") === "managercustomer") {
      cancelManagerOperate().then((res) => {
        if (res?.code == 0) {
          localStorage.setItem("user_auth", "manager");
          localStorage.removeItem(`${location.origin}agentOperatingToken`);
          localStorage.removeItem("operateCustomerEmail");
          window.__history__.push("/clients");
        } else {
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      });
    }
  };
  shouldComponentUpdate(nextProps, nextState): boolean {
    //点击浏览器回退按钮 如果存在代理删除代理信息
    if (
      location.pathname.includes("clients") &&
      localStorage.getItem(`${location.origin}agentOperatingToken`)
    ) {
      if (localStorage.getItem("user_auth") === "partnercustomer") {
        cancelOperateCustomer();
        localStorage.setItem("user_auth", "partner");
      }
      if (localStorage.getItem("user_auth") === "managercustomer") {
        cancelManagerOperate();
        localStorage.setItem("user_auth", "manager");
      }

      localStorage.removeItem(`${location.origin}agentOperatingToken`);
      localStorage.removeItem("operateCustomerEmail");
    }
    return true;
  }

  render(): React.ReactNode {
    const intl: Intl = window.__intl__.messages;
    const user_auth = localStorage.getItem("user_auth");
    return (
      <div>
        {USER_ROLE_POWER[user_auth]?.actionForOthers && (
          <div className={styles.content}>
            {(user_auth == "partnercustomer" || user_auth == "managercustomer") && (
              <Button type="primary" className={styles.btn} onClick={this.goClient}>
                {intl["button.back"]}
              </Button>
            )}
            <div className={styles.title}>
              <span className={styles.text}>
                {user_auth == "subcustomer"
                  ? intl["agency.tips.text.sub"]
                  : intl["agency.tips.text"]}
              </span>
              <span className={styles.email}> {localStorage.getItem("operateCustomerEmail")}</span>.
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default AgencyTipsArea;
