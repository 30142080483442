export const strategyListOffLine = [
  {
    code: "10001",
    name: "Market Neutral Arbitrage Absolute Return Strategy",
    id: "",
    type: "strategy",
    category: "Quantitative",
    brief:
      "The overall investment thesis is to provide passive income through a safely managed portfolio of cryptocurrency assets. The manager may take contract arbitrage strategies to only invest in situations where pricing, perception and asset value provide a large margin of safety. Highly volatile markets exhibit frequent contango and backwardation switches that generate greater profit than traditional industries. A combination of inflation-based cash extraction strategies and full hedging to construct a risk averse investment product. Trading platform establishes direct access to leading crypto exchanges through API and manages orders, positions and risks systematically.",
    fields: [
      {
        key: "Annual Return",
        value: "32.09%"
      },
      {
        key: "Subscription",
        value: "Monthly"
      },
      {
        key: "Denomination",
        value: "USDT"
      }
    ],
    img: "image_card_10001.webp",
    logo: "image_logo_10001.webp",
    bgImgTop: "bgImgTop_10001.webp",
    bgImgBottom: "bgImgBottom_10001.webp",
    imgApp: "img_app_10001.webp",
    bgTopApp: "bgTop_app_10001.webp",
    bgBottomApp: "bgBottom_app_10001.webp",
    hasMask: false,
    canSubscribe: true,
    canRedeem: true
  },
  {
    code: "10011",
    name: "DeFi Yield Farming Hedged Strategy",
    id: "",
    type: "strategy",
    category: "Quantitative",
    brief:
      "Decentralised Finance is one the fastest growing sectors within the asset class and currently has over $200bn in Total Value Locked (TVL). Many platforms and protocols have emerged within the space to offer very attractive yields and returns for participation but at the same time present additional risks, both familiar and unfamiliar to traditional finance.",
    fields: [
      {
        key: "Annual Return",
        value: "60.5%"
      },
      {
        key: "Subscription",
        value: "Monthly"
      },
      {
        key: "Denomination",
        value: "USDT"
      }
    ],
    img: "image_card_10011.webp",
    logo: "image_logo_10011.webp",
    bgImgTop: "bgImgTop_10011.webp",
    bgImgBottom: "bgImgBottom_10011.webp",
    imgApp: "img_app_10011.webp",
    bgTopApp: "bgTop_app_10011.webp",
    bgBottomApp: "bgBottom_app_10011.webp",
    hasMask: false,
    canSubscribe: true,
    canRedeem: true
  },
  {
    code: "10004",
    name: "Blockchain tracker strategy",
    id: "",
    type: "strategy",
    category: "Tracker",
    brief:
      "Layer One protocols are the core of the digital asset market that construct the foundation of the blockchain and cryptocurrency industry. They are represented by the most well known cryptocurrencies including Bitcoin and Ethereum and make up the majority of the overall digital asset market. The strategy is designed to give broad exposure to the asset class by investing a basket of the most well known “blue chip” assets while also including some of the more promising blockchains that have already grown to a significant scale.",
    fields: [
      {
        key: "Annual Return",
        value: "--"
      },
      {
        key: "Subscription",
        value: "Monthly"
      },
      {
        key: "Denomination",
        value: "USDT"
      }
    ],
    img: "image_card_10004.webp",
    logo: "image_logo_10004.webp",
    bgImgTop: "bgImgTop_10004.webp",
    bgImgBottom: "bgImgBottom_10004.webp",
    imgApp: "img_app_10004.webp",
    bgTopApp: "bgTop_app_10004.webp",
    bgBottomApp: "bgBottom_app_10004.webp",
    hasMask: false,
    canSubscribe: true,
    canRedeem: true
  },
  {
    code: "10003",
    name: "Metaverse Tracker Strategy",
    id: "",
    type: "strategy",
    category: "Tracker",
    brief:
      "Metaverses are emerging market virtual world economics with a continually developing complex mix of digital goods, services, and assets that generate real-world value for users. The emergence of GameFi and NFTs and the utility within the metaverse have seen the market hit the mainstream prominence, more recently with record purchases of virtual land within The Sandbox and Decentraland.The strategy is designed to give broad exposure to the Metaverse by investing a basket of the most well known assets while also including some of the more promising platforms that have already grown to a significant scale. By investing in the strategy, it allows investors to gain access to the market without the challenges of buying, storing and transferring the assets.The Metaverse Tracker Strategy is a passive monthly volume weighted basket of the top Metaverse digital assets. It focuses on three main areas including GameFi, NFT/Collectibles and Virtual Worlds. The basket will be rebalanced on a monthly basis to allow for new assets to be included and exiting underperforming ones on a relative basis.",
    fields: [
      {
        key: "Annual Return",
        value: "--"
      },
      {
        key: "Subscription",
        value: "Monthly"
      },
      {
        key: "Denomination",
        value: "USDT"
      }
    ],
    img: "image_card_10003.webp",
    logo: "image_logo_10003.webp",
    bgImgTop: "bgImgTop_10003.webp",
    bgImgBottom: "bgImgBottom_10003.webp",
    imgApp: "img_app_10003.webp",
    bgTopApp: "bgTop_app_10003.webp",
    bgBottomApp: "bgBottom_app_10003.webp",
    hasMask: false,
    canSubscribe: true,
    canRedeem: true
  },
  {
    code: "10002",
    name: "DeFi Tracker Strategy",
    id: "",
    type: "strategy",
    category: "Tracker",
    brief:
      "Decentralized finance (DeFi) has been one of the key themes that has emerged within the digital asset space in 2021. DeFi is referred to as financial applications built on blockchain technologies (Bitcoin, Ethereum), typically using smart contract which are automated enforceable agreements that do not need intermediaries to execute. DeFi is still in early stage of innovation and institutions will have a prominent role to play in developing the ecosystem which presents significant opportunities as the overall market continues to grow.The strategy is designed to give broad exposure to the DeFi market by investing a basket of the most well known assets while also including some of the more promising platforms that have already grown to a significant scale. By investing in the strategy, it allows investors to gain access to the market without the challenges of buying, storing and transferring the assets.The DeFi Tracker Strategy is a passive market capitalisation weighted basket of the top 10 DeFi digital assets. It focuses on three main areas of decentralised exchanges, lending protocols and derivatives. The basket will be rebalanced on a monthly basis to allow for new assets to be included and exiting underperforming ones on a relative basis.",
    fields: [
      {
        key: "Annual Return",
        value: "--"
      },
      {
        key: "Subscription",
        value: "Monthly"
      },
      {
        key: "Denomination",
        value: "USDT"
      }
    ],
    img: "image_card_10002.webp",
    logo: "image_logo_10002.webp",
    bgImgTop: "bgImgTop_10002.webp",
    bgImgBottom: "bgImgBottom_10002.webp",
    imgApp: "img_app_10002.webp",
    bgTopApp: "bgTop_app_10002.webp",
    bgBottomApp: "bgBottom_app_10002.webp",
    hasMask: false,
    canSubscribe: true,
    canRedeem: true
  },
  {
    code: "10006",
    name: "Smart Contract Tracker Strategy",
    id: "",
    type: "strategy",
    category: "Tracker",
    brief:
      "Smart contract allow automatic execution of transactions between any party without the need for an intermediary. Platform which have incorporated the ability to utilise smart contract functionality have enabled the next wave of innovation in the blockchain and cryptocurrency sector. With Bitcoin often criticised for lack of utility, platforms such as Ethereum and Solana have seen rapid adoption to become blue chip assets in their own right. The smart contract technology has been the main driver is the significant growth in decentralised.",
    fields: [
      {
        key: "Annual Return",
        value: "--"
      },
      {
        key: "Subscription",
        value: "Monthly"
      },
      {
        key: "Denomination",
        value: "USDT"
      }
    ],
    img: "image_card_10006.webp",
    logo: "image_logo_10006.webp",
    bgImgTop: "bgImgTop_10006.webp",
    bgImgBottom: "bgImgBottom_10006.webp",
    imgApp: "img_app_10006.webp",
    bgTopApp: "bgTop_app_10006.webp",
    bgBottomApp: "bgBottom_app_10006.webp",
    hasMask: false,
    canSubscribe: true,
    canRedeem: true
  },
  {
    code: "10005",
    name: "BITCOIN High Yield Fixed Deposit",
    id: "",
    type: "strategy",
    category: "Fixed Income",
    brief:
      "Bitcoin is the original ‘blue chip’ of crypto and is at the core of the asset class. Investors who are buying and holding Bitcoin are increasingly looking to generate additional yield on the asset. The objective of this high yield fixed deposit is to offer a fixed annual rate of 4.5% over a fixed monthly period. The interest will be paid in bitcoin.",
    fields: [
      {
        key: "Fixed APY",
        value: "4.5%"
      },
      {
        key: "Subscription",
        value: "Monthly"
      },
      {
        key: "Denomination",
        value: "BTC"
      }
    ],
    img: "image_card_10005.webp",
    logo: "image_logo_10005.webp",
    bgImgTop: "bgImgTop_10005.webp",
    bgImgBottom: "bgImgBottom_10005.webp",
    imgApp: "img_app_10005.webp",
    bgTopApp: "bgTop_app_10005.webp",
    bgBottomApp: "bgBottom_app_10005.webp",
    hasMask: false,
    canSubscribe: true,
    canRedeem: true
  },
  {
    code: "10010",
    name: "Ethereum High Yield Fixed Deposit",
    id: "",
    type: "strategy",
    category: "Fixed Income",
    brief:
      "Ethereum is the original and largest smart contract platform with $350 billion market cap. It is currently in transition to a proof of stake network and has 55% dominance in DeFi and over 90% dominance in NFT markets. The objective of this high yield fixed deposit is to offer an annual rate of 4.5% over a fixed monthly period. The interest will be paid in Ethereum.",
    fields: [
      {
        key: "Fixed APY",
        value: "4.5%"
      },
      {
        key: "Subscription",
        value: "Monthly"
      },
      {
        key: "Denomination",
        value: "ETH"
      }
    ],
    img: "image_card_10010.webp",
    logo: "image_logo_10010.webp",
    bgImgTop: "bgImgTop_10010.webp",
    bgImgBottom: "bgImgBottom_10010.webp",
    imgApp: "img_app_10010.webp",
    bgTopApp: "bgTop_app_10010.webp",
    bgBottomApp: "bgBottom_app_10010.webp",
    hasMask: false,
    canSubscribe: true,
    canRedeem: true
  },
  {
    code: "10007",
    name: "Animoca Brands Share Purchase Opportunity",
    id: "",
    type: "deal",
    category: "Venture Capital Secondaries",
    brief: "Animoca Brands is a blockchain gaming developer and investor based in Hong Kong.",
    fields: [
      {
        key: "Block size",
        value: "$15.5M US"
      },
      {
        key: "Stage",
        value: "Mid-Stage"
      }
    ],
    img: "image_card_10007.webp",
    logo: "image_logo_10007.webp",
    bgImgTop: "bgImgTop_10007.webp",
    bgImgBottom: "bgImgBottom_10007.webp",
    imgApp: "img_app_10007.webp",
    bgTopApp: "bgTop_app_10007.webp",
    bgBottomApp: "bgBottom_app_10007.webp",
    hasMask: false,
    canSubscribe: false,
    canRedeem: false
  },
  {
    code: "10008",
    name: "Animoca Ventures Metaverse Fund",
    id: "",
    type: "deal",
    category: "Venture Capital Fund",
    brief:
      "Animoca Ventures Metaverse Fund, an extension of Animoca Brands, will leverage its existing network and invest in early projects to support the metaverse ecosystem.",
    fields: [
      {
        key: "Block size",
        value: "$5M US"
      },
      {
        key: "Stage",
        value: "Early Stage"
      }
    ],
    img: "image_card_10008.webp",
    logo: "image_logo_10008.webp",
    bgImgTop: "bgImgTop_10008.webp",
    bgImgBottom: "bgImgBottom_10008.webp",
    imgApp: "img_app_10008.webp",
    bgTopApp: "bgTop_app_10008.webp",
    bgBottomApp: "bgBottom_app_10008.webp",
    hasMask: false,
    canSubscribe: false,
    canRedeem: false
  },
  {
    code: "10009",
    name: "Dunamu Secondary Share Purchase Opportunity",
    id: "",
    type: "deal",
    category: "Venture Capital Secondaries",
    brief:
      "Dunamu Inc. is the owner and operator of Upbit, the largest cryptocurrency exchange in South Korea.",
    fields: [
      {
        key: "Block size",
        value: "$22.1M US"
      },
      {
        key: "Stage",
        value: "Late Stage"
      }
    ],
    img: "image_card_10009.webp",
    logo: "image_logo_10009.webp",
    bgImgTop: "bgImgTop_10009.webp",
    bgImgBottom: "bgImgBottom_10009.webp",
    imgApp: "img_app_10009.webp",
    bgTopApp: "bgTop_app_10009.webp",
    bgBottomApp: "bgBottom_app_10009.webp",
    hasMask: false,
    canSubscribe: false,
    canRedeem: false
  },
  {
    code: "10013",
    name: "Flow Staking",
    id: "",
    type: "staking",
    category: "Staking",
    brief:
      "Flow is an eco-friendly Layer-1 blockchain created by theNFT gaming studio Dapper Labs. Dapper Labs is also thecompany behind CryptoKitties and NBA Top Shot, focusing",
    fields: [
      {
        key: "APY",
        value: "6-9%"
      },
      {
        key: "Subscription",
        value: "Monthly"
      },
      {
        key: "Denomination",
        value: "Flow"
      }
    ],
    img: "image_card_10013.webp",
    logo: "image_logo_10013.webp",
    bgImgTop: "bgImgTop_10013.webp",
    bgImgBottom: "bgImgBottom_10013.webp",
    imgApp: "img_app_10013.webp",
    bgTopApp: "bgTop_app_10013.webp",
    bgBottomApp: "bgBottom_app_10013.webp",
    hasMask: false,
    canSubscribe: false,
    canRedeem: false
  }
];
