import {
  BENCHMARK_CURRENCY,
  RECAPTCHA_V3_KEY,
  RECAPTCHA_V2_KEY_CHECKBOX,
  AppkeyAlicloud
} from "@/config/constants";
import { routeConfig } from "@/router/index";
import Bugsnag from "@bugsnag/js";
import { Intl } from "@/model";

/**
 * 数字千分位化
 * 每隔三位加逗号
 * @param number 要处理的数字
 * @returns {string}
 */
const numberToThousands: (number: number | string) => number | string = (
  number
): number | string => {
  if (!number) {
    return number;
  }
  number = scientificToNumber(number).toString();
  const list = number.split(".");
  const prefix = list[0].charAt(0) === "-" ? "-" : "";
  let num = prefix ? list[0].slice(1) : list[0];
  let result = "";
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
};

// 密码格式，数字+小写字母+大写字母，6位以上，不包含空格
const passwordRegExp = /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}$/;
/**
 * @description: 校验密码格式是否正确
 * @params {
 *  password：密码，
 *  message：格式或者长度错误提示，
 * }
 * @return {Promise}
 */
const passwordValidator: (password: string, message: string) => Promise<any> = (
  password: string,
  message: string
) => {
  if (!password || !passwordRegExp.test(password)) {
    return Promise.reject(new Error(message));
  }
  return Promise.resolve();
};

/**
 * @description: 校验确认密码格式以及是否和密码一致，重置密码和设置密码使用
 * @params {
 *  confirmPassword：确认密码，
 *  password：密码，
 *  message：格式或者长度错误提示，
 *  notMatch：两次密码不一致时的提示
 * }
 * @return {Promise}
 */
const passwordConfirmValidator: (
  confirmPassword: string,
  password: string,
  message: string,
  notMatch: string
) => Promise<any> = (
  confirmPassword: string,
  password: string,
  message: string,
  notMatch: string
) => {
  if (!confirmPassword) {
    return Promise.reject(new Error(message));
  }
  if (password !== confirmPassword) {
    return Promise.reject(new Error(notMatch));
  }
  if (
    !confirmPassword ||
    password.length !== confirmPassword.length ||
    !passwordRegExp.test(confirmPassword)
  ) {
    return Promise.reject(new Error(message));
  }
  return Promise.resolve();
};

const getQueryValue = (queryName: string, location?: string) => {
  const reg = new RegExp("(^|&)" + queryName + "=([^&]*)(&|$)", "i");
  const r = location || window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURI(r[2]);
  } else {
    return null;
  }
};

/**
 * 判断是否是邮箱格式
 * @param str
 * @returns {string}
 */
const isEmail = (str: string) => {
  var string = str.replace(/\s|&nbsp;/g, ""); //先去除用户输入的无效字符
  var reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
  if (reg.test(string)) {
    return true;
  } else {
    return false;
  }
};

/**
 * 时间戳转化为日期格式
 * @param num
 * @returns {string}
 */
const transformTime = (timestamp: number = +new Date()) => {
  if (timestamp) {
    const time = new Date(timestamp);
    const y = time.getFullYear();
    const M = time.getMonth() + 1;
    const d = time.getDate();
    const h = time.getHours();
    const m = time.getMinutes();
    const s = time.getSeconds();
    return (
      y +
      "-" +
      addZero(M) +
      "-" +
      addZero(d) +
      " " +
      addZero(h) +
      ":" +
      addZero(m) +
      ":" +
      addZero(s)
    );
  } else {
    return "";
  }
};
/**
 * 时间戳转化为日期格式(没有时分秒)
 * @param num
 * @returns {string}
 */
const transformDate = (timestamp: number = +new Date()) => {
  return transformTime(timestamp).split(" ")[0];
};
const addZero = (m: number) => {
  return m < 10 ? "0" + m : m;
};

const toNonExponential = (num: number) => {
  try {
    const m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/) || [];
    const _len = (m[1] || "").length - Number(m[2]);
    return num.toFixed(Math.max(0, _len > 20 ? 20 : _len));
  } catch (e) {
    return num.toString();
  }
};

/**
 * 保留指定位小数, placeorder在用
 * @param {numOrString} 需要处理的数值或者字符串
 * @param {digital} 指定的位数
 * @returns {string}
 */
const formatFixNumString = (numOrString: number | string, digital?: number) => {
  const numberValue: number = Number(numOrString || 0);
  let numberStr = toNonExponential(numberValue) ?? 0;
  if (Number(digital) < 0) {
    digital = 0;
  } else if (Number(digital) > 20) {
    digital = 20;
  }
  try {
    if (Number(numberStr) === 0) {
      numberStr = Number(numberStr).toFixed(Math.abs(Number(digital ?? 0)));
    } else if (Number(numberStr) < 0.00001 && Number(numberStr) > 0) {
      const str1 = Number(numberStr).toFixed(Math.abs(Number(digital)) + 4);
      numberStr = str1.substr(0, Number(digital) + 2);
    } else if (Math.abs(Number(numberStr)) < 0.00001 && Number(numberStr) < 0) {
      console.log(numberStr);
      numberStr = numberStr + "0000";
      numberStr = numberStr.substr(0, Number(digital) + 3);
    } else {
      // numberStr = '0.' + (Number(numOrString).toString() + '00000000').substr(2, digital)
      const arr1 = Number(numberStr).toString().split(".");

      const splitPointStr = Number(digital) > 0 ? "." : "";
      if (arr1.length === 1) {
        arr1[1] = "00000000";
      } else {
        arr1[1] += "00000000";
      }
      numberStr = arr1[0] + splitPointStr + arr1[1].substr(0, digital);
    }
  } catch (e) {
    //
  }

  return numberStr;
};

/**
 * @description: 处理数字，小数点后最多X位, 默认是8, 截取而不是四舍五入
 * @params {
 * value：需要处理的数字（或者数字字符串）
 * }
 * @return {string}
 */
const decimalPointNoMoreX = (value, x = 8) => {
  if (isNaN(Number(value))) return value;
  if (typeof value === "number" || typeof value === "string") {
    let str = "" + value;
    if (/e/i.test(str) && typeof value === "number") {
      value = value.toFixed(18).replace(/\.?0+$/, ""); //转换科学技术法
    }
    value = value.toString();
    const pos = value.indexOf(".");
    // 判断是否有小数点
    if (pos > -1) {
      if (x == 0) return value.split(".")[0];
      value.substring(pos + 1).length > x ? (value = value.substring(0, pos + x + 1)) : null;
      const valueDicimalLength = value.split(".")[1].length;
      if (valueDicimalLength < x) {
        value = value + "0".repeat(x - valueDicimalLength);
      }
    } else {
      if (x > 0) {
        value = `${value}.` + "0".repeat(x);
      }
    }
    return value;
  }
  return;
};

/**
 * @description: 获取指定名称的cookie值
 * @params {
 * name: 需要获取的cookie的key
 * }
 * @return {string}
 */
const getCookie = (name) => {
  // (^| )name=([^;]*)(;|$),match[0]为与整个正则表达式匹配的字符串，match[i]为正则表达式捕获数组相匹配的数组；
  let arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"));
  if (arr != null) {
    return decodeURI(arr[2]);
  }
  return null;
};

/**
 * @description: 处理数字，小数点后最多X位, 默认是8, 截取而不是四舍五入，注意！不会自动补充小数点后x位
 * @params {
 * value：需要处理的数字（或者数字字符串）
 * }
 * @return {string}
 */
const decimalPointNoMoreXNoFill = (value, x = 8) => {
  if (typeof value === "number" || typeof value === "string") {
    let str = "" + value;
    if (/e/i.test(str) && typeof value === "number") {
      value = value.toFixed(18).replace(/\.?0+$/, ""); //转换科学技术法
    }
    value = value.toString();
    const pos = value.indexOf(".");
    // 判断是否有小数点
    if (pos > -1) {
      value.substring(pos + 1).length > x ? (value = value.substring(0, pos + x + 1)) : null;
    }

    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === "") {
      return value;
    }
  }
  return;
};

/**
 * @description:
 * @params {
 * value：需要处理的数字（或者数字字符串）
 * }
 * @return {string}
 */

const formatHandicapNumber = (value, symbolList, record, precision) => {
  console.log(symbolList?.[`${record.baseCurrency}_${record.quoteCurrency}`]);
  return numberToThousands(
    decimalPointNoMoreX(
      value,
      symbolList?.[`${record.baseCurrency}_${record.quoteCurrency}`]?.[precision]
    )
  );
};

/**
 * @description: 处理APR
 * @params {
 * value：APR
 * }
 * @return {string}
 */
const processAPR = (value) => {
  let val = parseFloat((Number(value) * 100).toPrecision(12));

  const valStr = val.toString();
  if (val.toString().indexOf(".") > -1) {
    // 存在小数点
    const pos = valStr.indexOf(".");
    return valStr.substring(0, pos + 3);
  }
  return val;
};

/**
 * 单独使用方法会有些问题，如果输入框仅支持数字和一个小数点，可以参考交易页面Plcace order的输入框， 后续会删除此方法
 * @description: only number is sopported and just one '.' at the end, '.' at the before is not allowed
 * @params e：需要处理的数字 或者字符串
 * @return {string}
 */
const onlyNumbersAndDecimalPoint = (e: string | number) => {
  const RegxNumber = /^\d*(\.\d*)?$/;
  let currenValue = e.toString();

  if (
    isNaN(Number(currenValue)) ||
    !RegxNumber.test(currenValue) ||
    currenValue == "" ||
    currenValue == "-"
  ) {
    return currenValue.slice(0, -1);
  }
  // '.' at the end
  if (currenValue.charAt(currenValue.length - 1) == ".") {
    //currenValue.replace(/0*(\d+)/, '$1')
    //currenValue = currenValue.slice(0, -1);
  }
  return currenValue;
};

/**
 * @description: 计算离x最近的y的倍数
 * @params {
 * x:
 * y:
 * }
 * @return {number}
 */
const nearestMultiple = (x, y) => {
  let val = x / y;
  let valStr = val.toString();
  const pos = valStr.indexOf(".");
  if (pos > -1) {
    valStr = valStr.substring(0, pos);
  }

  const result = parseFloat((Number(valStr) * y).toPrecision(12));
  return result;
};

/**
 * @description: 伪造唯一设备ID
 * @params 不需要入参数
 * @return string 唯一设备Id
 */
const createUUID: () => string = () =>
  "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c: string): string {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

/**
 * @description: 转化科学计数法
 * @params value 需要处理的数字/字符串
 * @return string 处理后的数值
 */
const scientificToNumber: (value: number | string) => number | string = (value) => {
  if (isNaN(Number(value)) || !/e/i.test(value.toString())) {
    return value;
  } else {
    return Number(value)
      .toFixed(18)
      .replace(/\.?0+$/, "");
  }
};

/**
 * @description: 小数相乘精度丢失解决方法
 * @params arg1,arg2 需要相乘的数字
 * @return string 处理后的数值
 */
const mathMultiply = (arg1, arg2) => {
  let m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  try {
    m += s1.split(".")[1].length;
  } catch (e) {
    console.log(e);
  }
  try {
    m += s2.split(".")[1].length;
  } catch (e) {
    console.log(e);
  }
  return (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) / Math.pow(10, m);
};

/**
 * @description: 获取昨天，前天，明天，后天，类似的日期
 * @params offset: +N，-N, N默认为+, 天
 * @return string 日期字符串
 */
const getSpecifiedDate = (offset: number) => {
  const today = new Date();
  let offseTime = 0;
  let flag = "+";
  let _offset = offset.toString();
  if (_offset.includes("+")) {
    _offset = _offset.split("+")[1];
  }
  if (_offset.includes("-")) {
    _offset = _offset.split("-")[1];
    flag = "-";
  }
  offseTime = Number(_offset) * 24 * 60 * 60 * 1000;
  if (flag == "-") {
    today.setTime(today.getTime() - offseTime);
  } else {
    today.setTime(today.getTime() + offseTime);
  }
  return transformTime(today.getTime());
};

/**
 * @description: 判断用户当前设备是否移动端
 * @params
 * @return boolean, 是否移动端
 */

const isMobile = () => {
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    return true;
  }
  return false;
};

const appendScript = (src: string) => {
  const script = document.createElement("script");
  script.src = src;
  document.body.appendChild(script);
};

const convertUSD2USDC: (symbol: string) => string = (symbol) => {
  let str = symbol;
  if (str?.toLowerCase()?.includes("usd") && str?.endsWith("USD")) {
    str = str.replace(/(USD)$/, BENCHMARK_CURRENCY);
  }
  return str;
};
// recaptcha v3根据公钥生成token
const grecaptchaToken: (action?: string) => Promise<string> = (action) => {
  return new Promise((resolve, reject) => {
    window?.grecaptcha?.ready(() => {
      window.grecaptcha.execute(RECAPTCHA_V3_KEY, { action: action }).then((token) => {
        resolve(token);
      });
    });
  });
};

// 提供id
const grecaptchaCheckbox: (html_element: string) => Promise<string> = (html_element) => {
  return new Promise((resolve, reject) => {
    window?.grecaptcha?.ready(() => {
      window.grecaptcha.render(html_element, {
        sitekey: RECAPTCHA_V2_KEY_CHECKBOX,
        theme: "dark",
        size: "normal", //尺寸规则，有normal与compact两个值可选
        callback: (token) => {
          // 接入后端校验
          resolve(token);
        },
        "expired-callback": (err) => {
          reject("expired");
        }, //验证过期回调
        "error-callback": (err) => {
          reject("error");
        } //验证错误回调
      });
    });
  });
};
// 判断当前用户是否有指定路由访问权限， 路由命名： /xxx
const checkAuth: (currentRoute: string) => Boolean = (currentRoute) => {
  //user_auth只存在一种角色
  const currentRole = localStorage?.getItem("user_auth") ?? "";
  // 不需要登陆权限的，不判断登陆的auth
  if (!routeConfig?.[currentRoute]?.needAuthenticated) return true;

  if (routeConfig?.[currentRoute]?.role?.length > 0) {
    return routeConfig?.[currentRoute]?.role?.includes(currentRole) ? true : false;
  }

  if (
    routeConfig?.[currentRoute]?.role?.length == 0 ||
    !routeConfig?.[currentRoute]?.needAuthenticated ||
    !routeConfig?.[currentRoute]?.role
  ) {
    return true;
  }
  return false;
};
/**
 * @description: 重构比例数据格式
 * @params {BTC:1,ETH:1,XRP:1}
 * @return []
 */
const resetRate = (params: any) => {
  const planCoinList: string[] = Object.keys(params);
  const rateValueList: number[] = Object.values(params);
  let sum = 0;
  rateValueList.map((item: number, index: number) => {
    sum = sum + item;
  });
  interface rateModel {
    denomintaion: string;
    rate: number;
    percent: string;
  }
  let rateList: rateModel[] = [];
  let percent = 10000; //百分比后两位小数
  for (let i = 0; i < planCoinList.length; i++) {
    const percentItem = Number(Math.floor((rateValueList[i] / sum) * 10000));
    if (i === planCoinList.length - 1) {
      rateList.push({
        denomintaion: planCoinList[i],
        rate: percent / 10000,
        percent: `${(percent / 100).toFixed(2)}%`
      });
    } else {
      percent = percent - percentItem;
      rateList.push({
        denomintaion: planCoinList[i],
        rate: percentItem / 10000,
        percent: `${(percentItem / 100).toFixed(2)}%`
      });
    }
  }
  return rateList;
};

/**
 * @description: 格式化Bugsnag的错误信息展示
 * @params { error, type: 'XHR-request' | 'Ws...' | 'Action...' }
 * @return Error
 */
const formatBugsnagMessage = (error: string, type: string = "XHR-request") => {
  try {
    // 接口错误，解析json数据，方便获取接口路径和状态
    let xhrErrorStr = "";
    const errorData = JSON.parse(error);

    let formatError = error;
    if (errorData?.config) {
      // 隐藏密码
      if (errorData?.config?.data?.includes("password")) {
        try {
          // JSON 对象类型
          const requestData = JSON.parse(errorData?.config?.data);
          requestData.password = "******";
          errorData.config.data = JSON.stringify(requestData);
        } catch (e) {
          // 字符串拼接参数类型
          errorData.config.data = errorData.config.data.replace(
            /password=.+?(?=(&|$))/,
            "password=******"
          );
        }
      }

      // 隐藏jwtToken
      if (errorData?.config?.headers?.jwtToken) {
        errorData.config.headers.jwtToken = "******";
      }

      formatError = JSON.stringify(errorData, null, "\t");
    }

    if (type === "XHR-request") {
      // 获取接口路径
      const path = errorData?.data?.path || errorData?.config?.url || errorData?.url;
      xhrErrorStr = `-- [path: ${path}]`;

      // 获取接口返回状态
      if (errorData?.status) xhrErrorStr = xhrErrorStr + `-- [status: ${errorData?.status}]`;

      // 加上错误信息
      const msg =
        errorData?.message ||
        errorData?.data?.msg ||
        errorData?.data?.message ||
        errorData?.statusText;
      if (msg) xhrErrorStr = xhrErrorStr + `-- [message: ${msg}]`;
    }

    // 错误展示格式 [type] (接口错误时加上-- [path] -- [status] -- [message])
    const message = `[${type}] ${xhrErrorStr} -- [Error]:
    ${formatError}`;

    return new Error(message);
  } catch (e) {
    return new Error(`[${type}] -- [Error]:
    ${error}`);
  }
};

/**
 * @description: 判断浏览器是否支持webp格式的图片
 * @params callback 返回结果
 * @return Boolean
 */
const checkSupportWebp = (feature, callback) => {
  const kTestImages = {
    // lossy:“有损”,lossless:“无损”,alpha:“α”或animation:“动画”。
    lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
    lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
    alpha:
      "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
    animation:
      "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
  };

  const img = new Image();
  img.onload = function () {
    const result = !!(img.width > 0 && img.height > 0);
    callback(result);
  };
  img.onerror = function () {
    callback(false);
  };
  img.src = "data:image/webp;base64," + kTestImages[feature];
};

/**
 * @description: 初始化阿里云人机校验无痕验证
 * @params scene 使用场景标识
 * @return void
 */
const NVCCaptcha = {
  init: (scene) => {
    window.AWSC.use("nvc", (state, module) => {
      // state标识状态，首次实例化会加载外部js，timeout为获取超时，loaded为已加载
      if (state === "timeout") {
        console.log("alicloud nvc loading timeout");
        Bugsnag.notify(
          formatBugsnagMessage(
            "Alicloud sliding verification JS loading timeout",
            "Action-sliding-verification"
          )
        );
      }

      // 初始化 调用module.init进行初始化
      window.nvc = module.init({
        appkey: AppkeyAlicloud,
        scene
      });
    });
  },
  getVal: (callback) => {
    window.nvc?.getNVCValAsync((nvcVal) => {
      callback(nvcVal);
    });
  }
};

export {
  grecaptchaCheckbox,
  grecaptchaToken,
  convertUSD2USDC,
  appendScript,
  getSpecifiedDate,
  getCookie,
  scientificToNumber,
  formatFixNumString,
  numberToThousands,
  passwordConfirmValidator,
  passwordValidator,
  getQueryValue,
  isEmail,
  transformTime,
  transformDate,
  decimalPointNoMoreX,
  decimalPointNoMoreXNoFill,
  nearestMultiple,
  processAPR,
  onlyNumbersAndDecimalPoint,
  createUUID,
  formatHandicapNumber,
  isMobile,
  mathMultiply,
  checkAuth,
  resetRate,
  formatBugsnagMessage,
  checkSupportWebp,
  NVCCaptcha
};
