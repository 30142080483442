import React, { useEffect } from "react";
import { Modal, Button } from "antd";
import styles from "../Strategy.module.less";

interface IProps {
  visible: boolean;
  type: string;
  handleClose: () => void;
}

const CheckEmailModal = (props: IProps) => {
  const email = localStorage.getItem("operateCustomerEmail") || localStorage.getItem("user");
  const handleCheckEmail = () => {
    // 打开邮箱的登录页面
    if (email.indexOf("@gmail") > -1) {
      window.open("https://mail.google.com");
    } else if (email.indexOf("@outlook") > -1 || email.indexOf("@hotmail") > -1) {
      window.open("https://login.live.com/");
    } else if (email.indexOf("@yahoo") > -1) {
      window.open("https://login.yahoo.com/");
    }
    props.handleClose();
  };
  const con = props.type.toLocaleLowerCase();

  return (
    <Modal
      wrapClassName={styles.subscribe}
      title={`Your ${con} request has been submitted`}
      visible={props.visible}
      footer={null}
      width={480}
      onCancel={props.handleClose}>
      <div className={styles.payAttenti}>Please check the email:</div>
      <div className={styles["email-red"]}>{email}</div>
      <div style={{ marginTop: 22 }}>
        The {con} request confirmation will be sent to the email address above. Please kindly
        confirm it in the email.
      </div>
      <div className={styles.submitWrap} style={{ marginTop: 60, textAlign: "right" }}>
        <Button className={`${styles.btn}  ${styles.active}`} onClick={handleCheckEmail}>
          Confirm
        </Button>
        {/* <Button
          className={`${styles.btn} ${styles.normal}`}
          style={{ marginLeft: 16 }}
          onClick={props.handleClose}>
          Cancel
        </Button> */}
      </div>
    </Modal>
  );
};
export default CheckEmailModal;
