import React, { useEffect, useState, Suspense, useRef } from "react";
import { Row, Col, Spin, Checkbox, Button, message } from "antd";
import styles from "./Clients.module.less";
import { applyPartner } from "@/services/request";
import {} from "@/config/constants";
import { Intl, IInjectIntlProps } from "@/model/index";
import { injectIntl } from "react-intl";
import { useScrollToBottomHook } from "@/components/useScrollToBottom/UseScrollToBottom";
import AuthorizationAgreement from "./AuthorizationAgreement";

interface IProps extends IInjectIntlProps {}

const PartnerAuthApplication = (props: IProps) => {
  const intl: Intl = window.__intl__.messages;
  const [loading, setLoading] = useState(false);
  const [periodtype, setPeriodtype] = useState<Number>(0); //0:3个月 1:半年 2:一年
  const [hasAgreement, setHasAgreement] = useState(false);
  const [hasreadAllAgreement, setHasreadAllAgreement] = useState(false);

  const cusInfo = props.location?.state?.record;

  useEffect(() => {}, []);

  const handleChangePeriod = (e, value) => {
    setPeriodtype(value);
  };
  const handleCheckAgreement = (e) => {
    setHasAgreement(e.target.checked);
  };

  //时间间隔转化为日期时间戳
  const periodToDate = () => {
    let startDate = new Date(new Date().setHours(0, 0, 0, 0)); //当前零点日期时间
    let date = new Date();
    const toMonth = {
      "0": 3,
      "1": 6,
      "2": 12
    };
    //开始时间选择当天0点，结束时间选择选择自然月份之后的前一天的23:59:59
    const startTime = startDate.getTime();
    const endTime = startDate.setMonth(date.getMonth() + toMonth[periodtype.toString()]) - 1000;
    return [startTime, endTime];
  };

  const handleSubmit = () => {
    if (!hasreadAllAgreement) {
      message.warning(intl["clients.application.agreement.read.tips"], 2);
      return;
    }
    fetchApply();
  };

  const fetchApply = () => {
    setLoading(true);
    const dateArr = periodToDate();
    const params = {
      apiCategory: "Trade,Yield,Strategies", //目前写三类
      customerId: cusInfo.id,
      effectiveDate: dateArr?.[0] ?? 0,
      expirationDate: dateArr?.[1] ?? 0,
      authorizeUrl: `${location.origin}/profile?anchorPoint=authorize`.replace("partner", "portal") //需要发送客户端域名
    };
    applyPartner(params).then((res) => {
      if (res?.code == 0) {
        message.destroy();
        message.success(intl["system.success"], 2, () => {
          setLoading(false);
          props.history.go(-1);
        });
      } else {
        setLoading(false);
        message.destroy();
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
    });
  };
  const scrollRef = useRef();
  const readAll = () => {
    if (!hasreadAllAgreement) {
      setHasreadAllAgreement(true);
    }
  };

  useScrollToBottomHook(scrollRef, readAll);

  return (
    <section className={styles.customerManagment + " " + "customer"}>
      <div className="customerTop">
        <Row className="top">
          <Col span={24}>
            <p className="title">{intl["clients.application.title"]}</p>
          </Col>
        </Row>
      </div>
      <Spin spinning={loading}>
        <div className="section">
          <div className={styles.applicationInfo}>
            <p className={styles.title}>{intl["clients.application.information"]}</p>
            <div className={styles.dividerLine} />
            <div>
              <div className={styles.accountInfo + " " + styles.marginBottom24}>
                <div className={styles.key}>{intl["clients.application.name"]}</div>
                <div className={styles.value}>{cusInfo.userName}</div>
              </div>
              <div className={styles.accountInfo}>
                <div className={styles.key}>{intl["clients.application.email"]}</div>
                <div className={styles.value}>{cusInfo.email}</div>
              </div>
            </div>
            <div className={styles.dividerLine} />
            <p className={styles.title}>{intl["clients.application.authorization"]}</p>
            <div className={styles.authorizatioTh}>
              <div className={styles.authorizatioThFirst}>
                {intl["clients.application.authorization"]}
              </div>
              <div>{intl["clients.application.description"]}</div>
            </div>
            {intl["clients.application.authorization.detail"].map((item, index) => {
              return (
                <div key={index}>
                  <div className={styles.authorizatioTr}>
                    <div className={styles.authorizatioTrFirst}>{item.key}</div>
                    <div className={styles.authorizatioTrSecond}>{item.detail}</div>
                  </div>
                  <div className={styles.dividerLine} />
                </div>
              );
            })}
            <p>{intl["clients.application.period"]}</p>
            <div className={styles.periodCheck}>
              {intl["profile.partner.authorization.period.arr"].map((item, index) => {
                return (
                  <Checkbox
                    key={index}
                    checked={periodtype == index}
                    style={{ marginRight: 24 }}
                    onChange={(e) => handleChangePeriod(e, index)}>
                    {item}
                  </Checkbox>
                );
              })}
            </div>
          </div>
          <div className={styles.agreementContent}>
            <p className={styles.title}>{intl["clients.application.agreement"]}</p>
            <div className={styles.dividerLine} />
            <div className={styles.agreementArea} ref={scrollRef}>
              <AuthorizationAgreement />
            </div>
            <Checkbox style={{ margin: "24px 0", color: "#fff" }} onChange={handleCheckAgreement}>
              {intl["clients.application.agreement.read"]}
            </Checkbox>
            <div className={styles.btnContent}>
              <Button
                className={styles.btnLeft}
                onClick={() => {
                  props.history.go(-1);
                }}>
                {intl["button.close"]}
              </Button>
              <Button
                className={styles.btnRight}
                disabled={!hasAgreement}
                type="primary"
                onClick={handleSubmit}>
                {intl["button.submit.application"]}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </section>
  );
};

export default PartnerAuthApplication;
