import React, { useEffect, useState, Suspense } from "react";
import { Table, Row, Col, Spin, message, Breadcrumb } from "antd";
import styles from "./History.module.less";
import { transformTime, decimalPointNoMoreX, convertUSD2USDC, numberToThousands } from "@/utils";
import { Link } from "react-router-dom";
import { getCommissionList } from "@/services/request";
import { Intl } from "@/model/index";

interface IProps {}
const WithEmptyContent = React.lazy(
  () => import(/* webpackChunkName: "WithEmptyContent" */ "@/components/emptyContent/EmptyContent")
);

const CustomerManagementBillingHisory = (props: IProps) => {
  const intl: Intl = window.__intl__.messages;
  const location_text: string = intl["kyc.certify.location"];
  const loadMore = intl["button.loadMore"];
  const title_text = intl["clients.billing.history.title"];
  const date_text = intl["clients.billing.history.column.date"];
  const currency_text = intl["column.currency"];
  const amount_text = intl["clients.billing.history.column.amount"];
  const status_text = intl["column.status"];

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [nextToken, setNextToken] = useState(null);

  useEffect(() => {
    initData();
  }, []);
  const initData = () => {
    setLoading(true);
    let param = {
      limit: 10,
      nextToken: nextToken
    };
    getCommissionList(param)
      .then((res) => {
        if (res?.code == "0") {
          setDataSource(
            dataSource?.length > 0 ? dataSource.concat(res?.data?.rows || []) : res?.data?.rows
          );
          setNextToken(res.data?.nextToken);
        } else {
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const columns = [
    {
      title: date_text,
      dataIndex: "commissionDate",
      render: (value) => <span>{value?.substring(0, 4) + "-" + value?.substring(4, 6)}</span> //目前前端截取
    },
    {
      title: currency_text,
      dataIndex: "currency",
      render: (value) => <span>{convertUSD2USDC(value) || "USDC"}</span>
    },
    {
      title: amount_text,
      dataIndex: "amount",
      render: (value) => <span>{numberToThousands(decimalPointNoMoreX(value, 2))}</span>
    },
    {
      title: status_text,
      dataIndex: "status",
      render: (value) => <span>{value == 3 ? intl["settled"] : intl["unsettled"]}</span> //0、1、2：未结算，3:已结算
    }
  ];

  return (
    <section className={styles.history + " " + "customer"}>
      <div className="customerTop">
        <Row className="top">
          <Col span={24}>
            <p className="title" style={{ marginBottom: 5 }}>
              {title_text}
            </p>
            <Breadcrumb>
              <Breadcrumb.Item>{location_text}: </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/clients">{"Clients"}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{"Billing history"}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </div>

      <div className={styles.tableWrap}>
        <div className="section" style={{ width: "100%", marginTop: 0 }}>
          <div className={styles.history} style={{ marginTop: 0 }}>
            <Spin spinning={loading}>
              <div className={styles.historyArea}>
                <Suspense
                  fallback={
                    <div className={styles.marketsCard + " middleAlign"}>
                      <Spin />
                    </div>
                  }>
                  <WithEmptyContent showEmpty={true}>
                    <Table
                      className={styles.table}
                      bordered={false}
                      dataSource={dataSource}
                      columns={columns}
                      pagination={false}
                      rowClassName={styles.sales}
                    />
                  </WithEmptyContent>
                </Suspense>
                {nextToken && dataSource?.length >= 10 && (
                  <div className={styles.loadMore} onClick={() => initData()}>
                    {loadMore}
                  </div>
                )}
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomerManagementBillingHisory;
