import React, { Component } from "react";
import {
  IMarketPushItemType,
  IPrecisions,
  IOrderBookItem,
  IDepthItem,
  IDepth,
  ISymbolDataType
} from "@/model/index";
import { connect } from "react-redux";
import {
  updateOrderBook,
  updateSymbolInfo,
  updateOfflineSymbolInfo,
  updateSymbolNames,
  updateDepth,
  updateOpenOrder
} from "@/slices/TradeSlice";
import { fecthSymbolList, fetchOfflineSymbolList } from "@/services/request";
import { websocket } from "@/config/index";
import { WS } from "@kikitrade/api-gateway-client";
import { formatFixNumString, createUUID } from "@/utils/index";
import Bugsnag from "@bugsnag/js";
import { HOME_SYMBOLS } from "@/config/constants";
import { formatBugsnagMessage } from "@/utils";

interface IProps {
  [key: string]: any; // todo @elaine
  updateDepth?: ({ depthData: IDepth }) => void;
  updateSymbolInfo?: ({ symbolInfoList: ISymbolDataType, isWsMessage: Boolean }) => void;
  updateSymbolNames?: ({ symbolNames: Array }) => void;
  updateOpenOrder?: (open: any) => void;
  updateCurrentOrderBook?: (coinCode: string, orderBook: IDepth) => void;
  marketSymbolsList?: {
    [key: string]: IMarketPushItemType;
  };
  offlineSymbolsList?: {
    [key: string]: IPrecisions;
  };
}

type IState = {
  lockConnect: boolean;
  wsRoom: object;
  coinCode: string;
  lastWsPushTime: any;
  marketItemUpdateTs: any;
  componentDidMount: boolean;
};

interface IPrecisionItem {
  [key: string]: IPrecisions;
}

const mapStateToProps = (state) => {
  const { marketSymbolsList, offlineSymbolsList, symbolNames, orderBook, depthData, openOrder } =
    state.marketSpotAndTradeInfo;
  return {
    marketSymbolsList: marketSymbolsList, // 现货币种价格/交易量等信息
    offlineSymbolsList, // 离线的盘口精度信息
    symbolNames: symbolNames, // 支持的所有币种
    orderBook: orderBook, // 当前币种的orderbook数据
    depthData: depthData, // 当前币种的深度数据
    openOrder: openOrder
  };
};
const mapDispatchToProps = () => {
  return {
    updateSymbolInfo: (payload) => updateSymbolInfo(payload),
    updateOfflineSymbolInfo: (payload) => updateOfflineSymbolInfo(payload),
    updateSymbolNames: (payload) => updateSymbolNames(payload),
    updateOrderBook: (payload) => updateOrderBook(payload),
    updateDepth: (payload) => updateDepth(payload),
    updateOpenOrder: (payload) => updateOpenOrder(payload)
  };
};
const homeSymbols = HOME_SYMBOLS;
export default (WrappedComponent) => {
  class WithFiatAndSpotTrade extends Component<IProps, IState> {
    constructor(props: IProps) {
      super(props);
      this.state = {
        componentDidMount: false,
        lastWsPushTime: new Date().getTime(),
        lockConnect: false,
        coinCode: "BTC_USDT",
        marketItemUpdateTs: new Date().getTime(),
        wsRoom: {
          BTC_USDT: {
            room: "orderbook",
            symbol: "BTC_USDT",
            fromSymbol: ""
          }
        }
      };

      this.fecthSymboInfoList = this.fecthSymboInfoList.bind(this);
      this.fetchOfflineSymbolInfoList = this.fetchOfflineSymbolInfoList.bind(this);
      this.websocketListener = this.websocketListener.bind(this);
      this.registerWS = this.registerWS.bind(this);
      this.unregisterWS = this.unregisterWS.bind(this);
      this.updateAllSymbolInfoList = this.updateAllSymbolInfoList.bind(this);
      this.updateCurrentOrderBook = this.updateCurrentOrderBook.bind(this);
      this.updateOb = this.updateOb.bind(this);
      this.sendOrderBookWsData = this.sendOrderBookWsData.bind(this);
      this.updateCoinCode = this.updateCoinCode.bind(this);
      this.getCurrentCoinCode = this.getCurrentCoinCode.bind(this);
    }

    updateCoinCode: (coinCode: string) => void = (coinCode) => {
      // TODO check set失败
      this.setState({
        coinCode: coinCode
      });
    };

    // updateCoinCode有时候setState无效的暂时处理办法, 😭😭😭
    getCurrentCoinCode: () => string = () => {
      const stateCoinCode = this.state.coinCode;
      const lcoalState = localStorage.getItem("coinCode");
      if (stateCoinCode != lcoalState) {
        this.setState({
          coinCode: lcoalState
        });
      }
      return lcoalState;
    };

    fecthSymboInfoList: () => void = async () => {
      fecthSymbolList()
        .then((res) => {
          if (res?.code == 0) {
            if (res?.data?.length < 1) {
              Bugsnag.notify(
                formatBugsnagMessage(
                  "Error market/symbols API no data. \n" + JSON.stringify(res, null, "\t")
                )
              );
            }
            this.updateAllSymbolInfoList(res?.data ?? []);
          }
        })
        .finally(() => {});
    };

    // 获取离线盘口信息
    fetchOfflineSymbolInfoList: () => void = async () => {
      fetchOfflineSymbolList().then((res) => {
        if (res?.code == 0) {
          this.updateOfflineSymbolInfoList(res?.data ?? []);
        }
      });
    };

    updateCurrentOrderBook: (coinCode: string, data: any) => void = (coinCode: string, data) => {
      if (!this.state.componentDidMount) return;
      this.setState({
        coinCode: coinCode
      });
      this.updateOb(data);
      this.props.updateDepth({ depthData: this.reOrgDepthData(data) });
    };

    reOrgDepthData: (data: { bids: Array<IDepthItem>; asks: Array<IDepthItem> }) => IDepth =
      (data: { bids: Array<IDepthItem>; asks: Array<IDepthItem> }) => {
        //深度线需要转化为number 否则报错
        let numData = JSON.parse(JSON.stringify(data));
        let bids = [];
        let asks = [];
        numData?.asks.map((item: IDepthItem) => {
          asks.push([Number(item.p), Number(item.v)]);
        });
        numData?.bids.map((item: IDepthItem) => {
          bids.push([Number(item.p), Number(item.v)]);
        });
        asks = asks.reverse();
        return {
          bids,
          asks
        };
      };

    updateOb: (dataAll: IOrderBookItem) => void = (dataAll: IOrderBookItem) => {
      let data = dataAll;
      data.asks = data?.asks.slice(0, 10);
      data.bids = data?.bids.slice(0, 10);
      const coinCode = this.getCurrentCoinCode();
      const coinDetail = this.props?.marketSymbolsList?.[coinCode];
      let sumBids = 0;
      let sumAsks = 0;
      let ask = {
        pLenth: coinDetail?.tradePrecision ?? 0,
        vLenth: coinDetail?.tradeInputPrecision ?? 5
      };
      let bid = {
        pLenth: coinDetail?.tradePrecision ?? 0,
        vLenth: coinDetail?.tradeInputPrecision ?? 5
      };
      data?.bids.forEach((item: { v: string; p: string }) => {
        sumBids += parseFloat(item.v);
      });
      data?.asks.forEach((item: { v: string; p: string }) => {
        sumAsks += parseFloat(item.v);
      });
      const max = Math.max(sumBids, sumAsks);
      data?.bids.forEach(
        (item: { v: string; p: string; sum: number; barPercent: number }, index: number) => {
          data.bids[index].sum = Number(item.v) + Number((data.bids[index - 1] || {})?.sum ?? 0);
          data.bids[index].p = formatFixNumString(item.p, bid.pLenth).toString();
          data.bids[index].v = formatFixNumString(item.v, bid.vLenth).toString();
          data.bids[index].barPercent =
            Number((data.bids[index].sum * 100) / max) >= 100
              ? 100
              : Number((data.bids[index].sum * 100) / max);
        }
      );
      data?.asks.forEach(
        (item: { v: string; p: string; sum: number; barPercent: number }, index: number) => {
          data.asks[index].sum = Number(item.v) + Number((data.asks[index - 1] || {})?.sum ?? 0);
          data.asks[index].p = formatFixNumString(item.p, ask.pLenth).toString();
          data.asks[index].v = formatFixNumString(item.v, ask.vLenth).toString();
          data.asks[index].barPercent =
            Number((data.asks[index].sum * 100) / max) >= 100
              ? 100
              : Number((data.asks[index].sum * 100) / max);
        }
      );
      this.props.updateOrderBook({
        orderBook: {
          bids: data?.bids, // 买方深度
          asks: data?.asks?.reverse() // 卖方深度
        }
      });
    };

    sendOrderBookWsData: (symbol: string, fromSymbol?: string) => void = (symbol, fromSymbol) => {
      const room = this.state.wsRoom;
      let eventData = {};
      if (symbol) {
        eventData = {
          room: "orderbook",
          symbol: symbol,
          fromSymbol: fromSymbol
        };
        this.setState({
          coinCode: symbol
        });
      }
      // this.updateOb({ asks: [], bids: [] });
      room[symbol] = eventData;
      const interval = setInterval(() => {
        if (!location.href.includes("trade") || !this.state.componentDidMount) {
          clearInterval(interval);
          return;
        }
        if (window.ws && window.ws.registered) {
          console.log("subscrible orderbook");
          clearInterval(interval);
          try {
            if (symbol) {
              window?.ws?.send("POST", "/api/room", "COMMON", eventData);
              this.setState((state, props) => ({
                wsRoom: room
              }));
            } else {
              const { wsRoom } = this.state;
              Object.keys(wsRoom).forEach((key: string) => {
                console.log("sendWsData" + JSON.stringify(eventData));
                window?.ws?.send("POST", "/api/room", "COMMON", wsRoom[key]);
              });
            }
          } catch (e) {
            console.log(e);
            Bugsnag.notify(formatBugsnagMessage(e.toString(), "ws.send(/api/room)"));
          }
        }
      }, 1 * 10);
    };

    updateAllSymbolInfoList: (data: any[]) => void = (data) => {
      let newSymbolList = {};
      let symbolNames = [];
      data.map((item) => {
        const symbol: IMarketPushItemType = {
          coinCode: item.coinCode, // 盘口名称
          highPrice: item.highPrice, // 最高价
          lowPrice: item.lowPrice, // 最低价
          volume: item.volume, // 交易量
          priceLast: item.priceLast, // 最新价
          riseAndFall: item.riseAndFall, // 涨跌比率
          orderQuoteMax: item.orderQuoteMax,
          orderQuoteMin: item.orderQuoteMin,
          volumePrecision: item.volumePrecision,
          orderBaseMax: item.orderBaseMax,
          orderBaseMin: item.orderBaseMin,
          ask: item.ask, // 卖一价(longPrice /ask)
          bid: item.bid, // // 买一价, (shortPirce/bid )
          orderMin: item.orderMin, // 最小下单量（USD衡量
          orderMax: item.orderMax, // 最大下单量（USD衡量
          tradeVolumePrecision: item.tradeVolumePrecision, // 成交量实际精度
          symbolPrecision: item.symbolPrecision, // 市场价格展示精度
          tradePrecision: item.tradePrecision, // 价格输入精度
          tradeInputPrecision: item.tradeInputPrecision // 交易币输入精度
        };
        newSymbolList[item.coinCode] = symbol;
        symbolNames.push(item.coinCode);
      });
      this.props.updateSymbolInfo({ symbolInfoList: newSymbolList, isWsMessage: false });
      this.props.updateSymbolNames({ symbolNames: symbolNames });
    };

    // 更新离线盘口信息
    updateOfflineSymbolInfoList: (data: any[]) => void = (data) => {
      let newOfflineSymbolList: IPrecisionItem = {};
      data.map((item) => {
        const symbol: IPrecisions = {
          coinCode: item.coinCode, // 盘口名称
          volumePrecision: item.volumePrecision,
          tradeVolumePrecision: item.tradeVolumePrecision, // 成交量实际精度
          symbolPrecision: item.symbolPrecision, // 市场价格展示精度
          tradePrecision: item.tradePrecision, // 价格输入精度
          tradeInputPrecision: item.tradeInputPrecision // 交易币输入精度
        };
        newOfflineSymbolList[item.coinCode] = symbol;
      });
      this.props.updateOfflineSymbolInfo({
        offlineSymbolInfoList: newOfflineSymbolList
      });
    };

    websocketListener: (data: ISymbolDataType) => void = (data) => {
      const now = new Date().getTime();
      if (this.state.componentDidMount && window.ws.registered) {
        this.setState({
          lastWsPushTime: now
        });
      }
      console.log(`coinCode: ${this.state.coinCode}`);
      if (document.visibilityState != "visible") return;
      const currentWsCoinCode = data.data.symbol;
      const currentCoinCode = this.getCurrentCoinCode();

      switch (data.type) {
        case "orderbook":
          console.log(
            "received ws message orderbook, symbol:前" +
              currentWsCoinCode +
              "\n" +
              JSON.stringify(data.data)
          );
          if (currentCoinCode == currentWsCoinCode && location.href.includes("trade")) {
            console.log(
              "received ws message orderbook, symbol:" +
                currentWsCoinCode +
                "\n" +
                JSON.stringify(data.data)
            );
            // 判断两次数据是否相同，相同的则不推送，上报报警信息
            if (
              JSON.stringify(data.data.asks) === JSON.stringify(this.props.orderBook.asks) &&
              JSON.stringify(data.data.bids) === JSON.stringify(this.props.orderBook.bids)
            ) {
              Bugsnag.notify(
                formatBugsnagMessage(
                  "received the same ws message orderbook: " +
                    currentWsCoinCode +
                    "\n" +
                    JSON.stringify(data.data, null, "\t"),
                  "ws-received-orderbook-message"
                )
              );
            } else {
              this.updateOb(data.data);
              this.props.updateDepth({ depthData: this.reOrgDepthData(data.data) });
            }
          }
          break;
        case "market-new":
          console.log(
            `received ws message market ${currentWsCoinCode} unhanlded: priceLast: ${
              data.data.mid
            } high: ${data.data.high} low: ${data.data.low} volume:  ${
              data.data.volume
            } all: ${JSON.stringify(data.data)}`
          );
          // 当前选中盘口或者是首页盘口，最小间隔1s， 更新盘口
          if (
            currentWsCoinCode == currentCoinCode ||
            (homeSymbols.includes(currentWsCoinCode) && location.href.includes("home"))
          ) {
            if (new Date().getTime() - this.state.marketItemUpdateTs <= 1 * 1000) return;
            this.setState({
              marketItemUpdateTs: new Date().getTime()
            });
            // let oldData = JSON.parse(JSON.stringify(this.props.marketSymbolsList));
            // oldData[currentWsCoinCode].volume = data.data.volume;
            // oldData[currentWsCoinCode].highPrice = data.data.high;
            // oldData[currentWsCoinCode].lowPrice = data.data.low;
            // oldData[currentWsCoinCode].ask = data.data.ask;
            // oldData[currentWsCoinCode].bid = data.data.bid;
            // oldData[currentWsCoinCode].riseAndFall = data.data.percentage;

            const current = data.data;
            this.props.updateSymbolInfo({ symbolInfoList: current, isWsMessage: true });
            console.log(
              `received ws message market ${currentWsCoinCode} current: priceLast: ${
                data.data.mid
              } high: ${data.data.high} low: ${data.data.low} volume:  ${
                data.data.volume
              } all: ${JSON.stringify(data.data)}`
            );
          } else {
            // 其他盘口，防止websocket频率太高，一秒更新一次, 暂不进行更新，防止刷新太快，导致页面卡死
            // if (new Date().getTime() - this.state.marketItemUpdateTs > 2000) {
            //   // eslint-disable-next-line no-case-declarations
            //   let oldData = JSON.parse(JSON.stringify(this.props.marketSymbolsList));
            //   if (oldData[currentWsCoinCode]) {
            //     oldData[currentWsCoinCode].mid = data.data.mid;
            //     oldData[currentWsCoinCode].volume = data.data.volume;
            //     oldData[currentWsCoinCode].highPrice = data.data.high;
            //     oldData[currentWsCoinCode].lowPrice = data.data.low;
            //     oldData[currentWsCoinCode].ask = data.data.ask;
            //     oldData[currentWsCoinCode].bid = data.data.bid;
            //     oldData[currentWsCoinCode].riseAndFall = data.data.percentage;
            //     oldData[currentWsCoinCode].priceLastRise =
            //       Number(data.data.mid) > Number(oldData[currentWsCoinCode].mid)
            //         ? 1
            //         : Number(data.data.mid) < Number(oldData[currentWsCoinCode].mid)
            //         ? -1
            //         : 0;
            //   }
            //   this.props.updateSymbolInfo({ symbolInfoList: oldData });
            //   this.setState({
            //     marketItemUpdateTs: new Date().getTime()
            //   });
            //   console.log(
            //     `update hidden market-new data for ${currentWsCoinCode}:  ${JSON.stringify(data)}`
            //   );
            // }
          }
          break;
        case "order":
          if (location.href.includes("trade")) {
            this.props.updateOpenOrder(data.data);
            console.log("received ws message order" + "\n" + JSON.stringify(data.data));

            /* TODO: 因除了trade和home模块有ws推送，其它页面没有，所以当用户下完单，跳到其他页面时，我们没办法监听到数据变化
            try {
              const marketInfo = localStorage.getItem("marketOrderInfo");
              const marketOrderInfo = marketInfo ? JSON.parse(marketInfo) : [];
              const marketOrderIds = marketOrderInfo.map((item) => item.orderId);

              if (marketOrderIds.includes(data.data.orderId)) {
                const updateMarketOrder = marketOrderInfo.filter((order) => {
                  // 已成交的订单 删除存储的订单信息；
                  if (order.orderId === data.data.orderId && data.data.orderStatus === "filled") {
                    return false;
                  }

                  // 未完全成交的订单，更新时间和状态
                  order.startTime = new Date().getTime();
                  order.orderStatus = data.data.orderStatus;

                  return true;
                });

                // 更新市价订单信息
                localStorage.setItem("marketOrderInfo", updateMarketOrder);
              }
            } catch (e) {
              console.log(e.toString());
            } */
          }
          break;
        default:
          break;
      }
    };

    unregisterWS: () => void = () => {
      // todo, unregister 程序不报错，ws仍可以收到推送消息, @elaine
      this.setState({
        lockConnect: false
      });
      if (window.wstimer) {
        clearInterval(window.wstimer);
      }
      if (window.reconnectTimer) {
        clearInterval(window.reconnectTimer);
      }
      window.ws = null;
      try {
        const interval = setInterval(() => {
          if (window.ws?.registered) {
            window.ws.unregister();
            clearInterval(interval);
            window.ws = null;
            console.log("unregister ws, ws is closed");
          }
        }, 1000);
      } catch (e) {
        Bugsnag.notify(formatBugsnagMessage(e.toString(), "ws.unregister"));
      }
    };

    registerWS: () => void = async () => {
      if (window.ws?.registered) return;
      window.ws = new WS({
        url: websocket.host,
        authType: "appCode",
        appCode: websocket.appCode,
        stage: websocket.stage,
        registerPath: websocket.registerPath,
        unregisterPath: websocket.unregisterPath
      });
      const pathname = window.location.pathname;
      if (
        !this.state.lockConnect &&
        (pathname.includes("home") || pathname.includes("trade") || pathname.includes("clients"))
      ) {
        if (!window.ws?.registered) {
          window.ws = new WS({
            url: websocket.host,
            authType: "appCode",
            appCode: websocket.appCode,
            stage: websocket.stage,
            registerPath: websocket.registerPath,
            unregisterPath: websocket.unregisterPath
          });
        }
        console.log("register ws");
        const deviceId = createUUID();
        try {
          let token =
            localStorage.getItem(`${location.origin}agentOperatingToken`) ??
            localStorage.getItem(`${location.origin}jwtToken`);
          window.ws.register(this.websocketListener, deviceId, {
            room: "marketData",
            // 传入userData， 如果有对应的委托单推送，则自动进行推送， 不需要推送订单可不传
            value: JSON.stringify({
              jwtToken: token ?? ""
            })
          });
          this.setState({
            lockConnect: true
          });
        } catch (e) {
          Bugsnag.notify(formatBugsnagMessage(e.toString(), "ws.register"));
        }
        // 心跳检测
        window.wstimer = setInterval(() => {
          try {
            window?.ws?.send("POST", "/api/alive", "COMMON");
          } catch (e) {
            Bugsnag.notify(formatBugsnagMessage(e.toString(), "ws.send(/api/alive)"));
          }
        }, 5 * 1000);

        // 60s 轮询一次，如果检查超过1分钟没有收到websokcet消息，进行重试
        window.reconnectTimer = setInterval(() => {
          if (!this.state.componentDidMount) return;
          const now = new Date().getTime();
          const { lastWsPushTime, coinCode } = this.state;
          if ((lastWsPushTime > 0 && now - lastWsPushTime > 60 * 1000) || !lastWsPushTime) {
            console.log("ws closed: 超过1分钟没有收到websokcet消息");
            if (window.ws) {
              try {
                this.unregisterWS();
              } catch (e) {
                Bugsnag.notify(formatBugsnagMessage(e.toString(), "ws.reconnect"));
              }
            }
            console.log("ws closed，will try to reconnect");
            this.registerWS();
            this.sendOrderBookWsData(coinCode);
          }
        }, 60 * 1000);
      }
    };

    render() {
      return (
        <>
          <WrappedComponent
            {...this.props}
            registerWS={this.registerWS}
            unregisterWS={this.unregisterWS}
            updateAllSymbolInfoList={this.updateAllSymbolInfoList}
            updateCurrentOrderBook={this.updateCurrentOrderBook}
            sendOrderBookWsData={this.sendOrderBookWsData}
            updateCoinCode={this.updateCoinCode}
          />
        </>
      );
    }

    componentDidMount() {
      this.setState({
        componentDidMount: true
      });
      if (
        !location.href.includes("trade") &&
        !location.href.includes("home") &&
        !location.href.includes("clients")
      )
        return;
      document.onreadystatechange = () => {
        if (document.readyState == "complete") {
          const pathname = window.location.pathname;
          this.fecthSymboInfoList();
          this.fetchOfflineSymbolInfoList();
          if (
            (pathname.includes("home") || pathname.includes("trade")) &&
            !this.state.lockConnect
          ) {
            this.registerWS();
          }
        }
      };
      // if (window.ws?.registered) return;
      // window.ws = new WS({
      //   url: websocket.host,
      //   authType: "appCode",
      //   appCode: websocket.appCode,
      //   stage: websocket.stage,
      //   registerPath: websocket.registerPath,
      //   unregisterPath: websocket.unregisterPath
      // });
    }

    componentWillUnmount() {
      this.setState({
        componentDidMount: false
      });
      this.setState = () => false;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps())(WithFiatAndSpotTrade);
};
