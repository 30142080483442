import apikeyGa from "@/config/ga";
import devConfig from "./host_dev";
import betaConfig from "./host_beta";
import prodConfig from "./host_prod";
import redConfig from "./host_red";
import {
  DOMAIN_BETA,
  DOMAIN_PROD,
  DOMAIN_DEV,
  DOMAIN_RED,
  DOMAIN_BETA_PARTNER,
  DOMAIN_PROD_PARTNER,
  DOMAIN_DEV_PARTNER,
  DOMAIN_RED_PARTNER,
  DOMAIN_BETA_MANAGER,
  DOMAIN_PROD_MANAGER,
  DOMAIN_DEV_MANAGER,
  DOMAIN_RED_MANAGER
} from "@/config/constants";

let config = devConfig;
if (
  location.origin.includes(DOMAIN_DEV) ||
  location.origin.includes(DOMAIN_DEV_PARTNER) ||
  location.origin.includes(DOMAIN_DEV_MANAGER) ||
  process.env.HOST_ENV == "dev" ||
  process.env.REACT_APP_HOST_ENV == "dev"
) {
  config = devConfig;
}
if (
  location.origin.includes(DOMAIN_BETA) ||
  location.origin.includes(DOMAIN_BETA_PARTNER) ||
  location.origin.includes(DOMAIN_BETA_MANAGER) ||
  process.env.HOST_ENV == "beta" ||
  process.env.REACT_APP_HOST_ENV == "beta"
) {
  config = betaConfig;
}
if (
  location.origin.includes(DOMAIN_RED) ||
  location.origin.includes(DOMAIN_RED_PARTNER) ||
  location.origin.includes(DOMAIN_RED_MANAGER) ||
  process.env.HOST_ENV == "red" ||
  process.env.REACT_APP_HOST_ENV == "red"
) {
  config = redConfig;
}
if (
  location.origin.includes(DOMAIN_PROD) ||
  location.origin.includes(DOMAIN_PROD_PARTNER) ||
  location.origin.includes(DOMAIN_PROD_MANAGER) ||
  process.env.HOST_ENV == "prod" ||
  process.env.REACT_APP_HOST_ENV == "prod"
) {
  config = prodConfig;
}

/**
 * 谨慎改动，注意check， 建议在根目录创建.env并配置REACT_APP_HOST_ENV = dev/beta/prod 来切换不同的api
 * 或者在此修改
 */
if (process.env.NODE_ENV != "production") {
  config = betaConfig;
}

let { klineChartUrl, tvChartUrl, env, host, tradingViewUrl, strategiesUrl, apiKeyBugsnag } = config;
let websocket: {
  host: string;
  appCode: string;
  stage: "TEST" | "RELEASE";
  registerPath: string;
  unregisterPath: string;
} = {
  host: config.websocket.host,
  stage: window.location.href.includes("aspendigital.co") ? "RELEASE" : "TEST",
  appCode: "c022929d0e1f4b0d8eace9cb9934bf0d",
  registerPath: "/api/register",
  unregisterPath: "/api/unregister"
};

export {
  websocket,
  host,
  klineChartUrl,
  tvChartUrl,
  tradingViewUrl,
  apikeyGa,
  env,
  strategiesUrl,
  apiKeyBugsnag
};
