import { apikeyBugsnagBeta } from "./constants";

const host = "http://portal-red.beta.test-aspendigital.co/";
const red = {
  env: "red",
  host: "http://api-red.beta.test-aspendigital.co/",
  klineChartUrl:
    process.env.NODE_ENV === "development"
      ? `${host}klinechart/index.html`
      : "klinechart/index.html",
  tradingViewUrl:
    process.env.NODE_ENV === "development"
      ? `${host}tradingview_dark_aspen/index.html`
      : "tradingview_dark_aspen/index.html",
  tvChartUrl:
    process.env.NODE_ENV === "development"
      ? `${host}tradingview-react-javascript/index.html`
      : "tradingview-react-javascript/index.html",
  websocket: {
    host: "ws://161.117.178.192:18090/" // aspen beta
  },
  strategiesUrl: `${
    process.env.NODE_ENV === "development" ? host : window.origin
  }/strategies/mock/strategyList.json`,
  apiKeyBugsnag: apikeyBugsnagBeta
};
export default red;
