import React, { Suspense } from "react";
import { IInjectIntlProps } from "@/model/index";
import styles from "@/views/login/Login.module.less";
import LoginLayout from "./components/LoginLayout";
import RegisterSubAccount from "./components/RegisterSubAccount";
import { Spin } from "antd";
import { getQueryValue } from "@/utils/index";

interface IProps extends IInjectIntlProps {
  handleBlur: (event: { target: { value: any }; currentTarget: any }) => void;
  handleFocus: (event: { target: { value: any }; currentTarget: any }) => void;
}
interface IState {}
class RegisterSub extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }
  render(): React.ReactNode {
    const {} = this.state;
    return (
      <div className={styles.form}>
        <Suspense
          fallback={
            <div className={styles.form + " middleAlign"}>
              <Spin />
            </div>
          }>
          <RegisterSubAccount
            history={this.props.history}
            handleFocus={this.props.handleFocus}
            handleBlur={this.props.handleBlur}
          />
        </Suspense>
      </div>
    );
  }
}

export default LoginLayout(RegisterSub);
