import React from "react";
import { Route, Redirect } from "react-router-dom";
import { routeConfig } from "@/router/index";
import { message } from "antd";
import { checkAuth } from "@/utils";
import { USER_ROLE_POWER } from "@/config/constants";

interface IProps {}

// 路由守卫配置提取
class Auth extends React.PureComponent<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  render(): React.ReactNode {
    const pathname = window.location.pathname;
    let targetRouterConfig = routeConfig?.[pathname];
    // 是否已经登陆
    const isLogin: boolean | string =
      (localStorage.getItem("isAuthenticated") && localStorage.getItem("user_auth")) || false;
    const SpecificComponent = targetRouterConfig?.component;
    // 默认跳转页面和默认登出页面
    // const toLogoutPath = location.origin.includes("pratner") ? "/agentLogin" : "/login";
    const toLogoutPath = "/login";
    const toLoginPath =
      (localStorage.getItem("user_auth") &&
        USER_ROLE_POWER[localStorage.getItem("user_auth")]?.loginToPath) ||
      "/home";
    // 默认跳转至home页面, 如果路由不存在跳转至home
    if (pathname === "/" || !targetRouterConfig) {
      targetRouterConfig = routeConfig?.[toLoginPath];
      return (
        <Redirect
          to={toLoginPath}
          component={targetRouterConfig.component}
          render={(props: any) => <SpecificComponent {...this.props} />}
        />
      );
    }
    if (pathname.includes("login")) {
      return (
        <Route
          exact
          path={pathname}
          render={(props: any) => <SpecificComponent {...this.props} />}
        />
      );
    }
    if (isLogin) {
      // 登陆状态，判断当前角色是否有访问当前路由的权限
      if (checkAuth(pathname)) {
        return (
          <Route
            exact
            path={pathname}
            render={(props: any) => <SpecificComponent {...this.props} />}
          />
        );
      } else {
        message.destroy();
        message.error(window.__intl__.messages["global.access.denied"]);
        //登陆状态下 user_auth被修改，如果权限存在跳入默认登陆后页面，不存在跳入login页面
        if (USER_ROLE_POWER[localStorage.getItem("user_auth")]) {
          return (
            <Redirect
              to={toLoginPath}
              component={targetRouterConfig.component}
              render={(props: any) => <SpecificComponent {...this.props} />}
            />
          );
        } else {
          return (
            <Redirect
              to="/login"
              component={targetRouterConfig.component}
              render={(props: any) => <SpecificComponent {...this.props} />}
            />
          );
        }
      }
    } else {
      if (targetRouterConfig?.needAuthenticated) {
        return (
          <Redirect
            exact
            to={toLogoutPath}
            component={targetRouterConfig.component}
            {...this.props}
          />
        );
      } else {
        return (
          <Route
            exact
            path={pathname}
            render={(props: any) => <SpecificComponent {...this.props} />}
          />
        );
      }
    }
  }
}

export default Auth;
