import { apikeyBugsnagProd } from "./constants";

const host = "https://portal.aspendigital.co/";
const prod = {
  env: "prod",
  host: "https://api.aspendigital.co/",
  klineChartUrl:
    process.env.NODE_ENV === "development"
      ? `${host}klinechart/index.html`
      : "klinechart/index.html",
  tradingViewUrl:
    process.env.NODE_ENV === "development"
      ? `${host}tradingview_dark_aspen/index.html`
      : "tradingview_dark_aspen/index.html",
  tvChartUrl:
    process.env.NODE_ENV === "development"
      ? `${host}tradingview-react-javascript/index.html`
      : "tradingview-react-javascript/index.html",
  websocket: {
    host: "wss://ws.aspendigital.co:8080"
  },
  strategiesUrl: `${
    process.env.NODE_ENV === "development" ? host : window.origin
  }/strategies/mock/strategyList.json`,
  apiKeyBugsnag: apikeyBugsnagProd
};
export default prod;
