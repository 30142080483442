import React, { Suspense } from "react";
import { Intl, IkycInfo, IVerifyParam } from "@/model/index";
import { Breadcrumb, Input, Select, Button, message, Spin } from "antd";
import styles from "./Wallet.module.less";
import { Link } from "react-router-dom";
import {
  getBanks,
  getWithdrawOtcOrder,
  applyOtcWithdraw,
  applyWithdraw,
  getAssetCurrencyConfig
} from "@/services/request";
import { numberToThousands, decimalPointNoMoreX, convertUSD2USDC, mathMultiply } from "@/utils";
import { ModalLoadingContext } from "@/store/context";

const { Option } = Select;

const ModalAddBank = React.lazy(
  () => import(/* webpackChunkName: "ModalAddBank" */ "@/views/profile/components/ModalAddBank")
);

const WithEmailOrGoogleVerify = React.lazy(
  () =>
    import(
      /* webpackChunkName: "WithEmailOrGoogleVerify" */ "@/components/withEmailOrGoogleVerify/WithEmailOrGoogleVerify"
    )
);
const History = React.lazy(() => import(/* webpackChunkName: "History" */ "./components/History"));

interface IProps {
  intl: Intl; //
  kycInfo: IkycInfo;
  handleCheckVerify: () => {};
}
interface IState {
  withdrawType: number;
  loading: boolean;
  confirmLoading: boolean;
  bankList: any[];
  addressInfo: string;
  withdrawAmount: string | number;
  showAddBankModal: boolean;
  octOrderId: string;
  showSafetyVerificationModal: boolean; //提去邮箱认证
  showGoogleAuthModal: boolean;
  oneTimePaceNum: number; // 每次提币的最大数量
  oneDayPaceNum: number; // 每天提币的最大数量
  leastPaceNum: number; // 每次提币的最小数量
  balance: number; //当前币种余额
  xrpTag: string;
  feeType: string; //当前费率收取类型- PERCENTAGE:按照百分比, FIXED:按照固定值, MIXED:混合计算取两者较大的一项
  FeeRate: number; //按比例收取时的费率
  feeCurrecy: string; //费用固定时的金额
  chainList: any[];
  currentChain: string;
}

class Withdraw extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      withdrawType: 0,
      loading: false,
      confirmLoading: false,
      bankList: [],
      addressInfo: "",
      withdrawAmount: "",
      octOrderId: "",
      showAddBankModal: false,
      showSafetyVerificationModal: false,
      showGoogleAuthModal: false,
      oneTimePaceNum: 0,
      oneDayPaceNum: 0,
      leastPaceNum: 0,
      balance: 0,
      xrpTag: "",
      feeType: "",
      FeeRate: 0,
      feeCurrecy: "",
      chainList: [],
      currentChain: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.initData(window?.__history__?.location?.state?.currency);
  }

  //初始化数据
  initData = (currency) => {
    this.setState({
      loading: true
    });
    this.fetchAssetList(currency);
  };
  // 获取银行卡列表
  getBankList: () => void = async () => {
    const param = {
      page: 1,
      limit: 100 //默认全部显示
    };
    getBanks(param)
      .then((res) => {
        if (res?.code == "0") {
          const _list = res?.data || [];
          this.setState({
            bankList: _list
          });
        } else {
          const intl: Intl = window.__intl__.messages;
          message.destroy();
          message.error(intl["wallet.withdraw.get.bank"]);
        }
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };
  //获取链列表
  fetchAssetList = (currency) => {
    const intl = window.__intl__.messages;
    const param = {
      currency: currency
    };
    getAssetCurrencyConfig(param)
      .then((res) => {
        if (res?.code == 0) {
          const chainList = res.data?.assets?.filter((item) => item.chainType !== "FIAT") || [];
          chainList?.[0] && this.setLimitInfo(chainList[0]);
          this.setState({
            chainList,
            currentChain: chainList?.[0]?.asset || "",
            balance: decimalPointNoMoreX(res.data?.available)
          });
        } else {
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  //获取提币限额
  setLimitInfo = (info) => {
    const oneTimeLimitInfo = info.quotas[0] || {};
    const oneDayLimitInfo = info.quotas[1] || {};
    this.setState({
      leastPaceNum: oneTimeLimitInfo?.minAmount || 0,
      oneTimePaceNum: oneTimeLimitInfo?.maxAmount || 0,
      oneDayPaceNum: oneDayLimitInfo?.maxAmount || 0,
      feeType: info?.withdrawFeeType,
      FeeRate: info?.withdrawFeeRate,
      feeCurrecy: info?.withdrawFeeAmount
    });
  };
  //获取法币交易订单id
  getWithdrawOctOrderId = () => {
    getWithdrawOtcOrder()
      .then((res) => {
        if (res?.code == "0") {
          this.setState({
            octOrderId: res?.data?.otcOrder
          });
        }
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  getText(): { [propName: string]: any } {
    const intl: Intl = window.__intl__.messages;
    const location: string = intl["kyc.certify.location"];
    const wallet: string = intl["wallet"];
    const limit: string = intl["wallet.withdraw.limit"];
    const amount: string = intl["wallet.withdraw.amount"];
    const max: string = intl["wallet.withdraw.max"];
    const amountPlaceholder: string = intl["wallet.withdraw.amount.placeholder"];
    const confirm: string = intl["button.confirm"];
    const noticeTitle: string = intl["wallet.withdraw.notice"];
    const noticeList: string = intl["wallet.withdraw.notice.content"];

    const withdrawBtcAddress: string = intl["wallet.withdraw.btc.address"];
    const withdrawBtcAddressPlaceholder: string = intl["wallet.withdraw.btc.address.placeholder"];
    const withdrawFiatAddress: string = intl["wallet.withdraw.fiat.address"];
    const withdrawFiatBind: string = intl["wallet.withdraw.fiat.bind"];
    const withdrawFiatPlaceholder: string = intl["wallet.withdraw.fiat.placeholder"];
    const withdrawSuccess: string = intl["wallet.withdraw.success.tips"];
    const withdrawFailed: string = intl["wallet.withdraw.failed.tips"];
    const tagText: string = intl["wallet.deposit.btc.tag"];
    const availableText: string = intl["available"];
    const dayText: string = intl["overview.myAsset.day"];
    return {
      location,
      wallet,
      limit,
      amount,
      max,
      amountPlaceholder,
      confirm,
      noticeTitle,
      noticeList,
      withdrawBtcAddress,
      withdrawBtcAddressPlaceholder,
      withdrawFiatAddress,
      withdrawFiatBind,
      withdrawFiatPlaceholder,
      withdrawSuccess,
      withdrawFailed,
      tagText,
      availableText,
      dayText
    };
  }
  //修改电子货币地址
  handleChangeAddress = (e) => {
    this.setState({
      addressInfo: e.target.value
    });
  };

  //修改银行卡地址
  handleChangeFiatAddress = (value) => {
    this.setState({
      addressInfo: this.state.bankList[value]?.id
    });
  };
  //绑定银行卡
  handleAddBank = () => {
    this.setState({
      showAddBankModal: true
    });
  };
  hideModal = () => {
    this.setState({
      showAddBankModal: false
    });
  };

  //XRP Tag输入
  handleChangeXrpTag = (e) => {
    this.setState({
      xrpTag: e.target.value
    });
  };

  //修改金额
  handleChangeAmount = (e) => {
    let params = e.target.value.replace(/[^\d.]/g, "").replace(/\.{2,}/g, "."); //数字
    const value = params.replace(/^(-)*(\d+)\.(\d{1,8}).*$/, "$1$2.$3"); //八位小数
    this.setState({ withdrawAmount: value });
  };
  handleMaxAmount = () => {
    this.setState({ withdrawAmount: this.state.balance });
  };

  //提交信息
  submitInfo = (param: IVerifyParam) => {
    this.setState({
      confirmLoading: true
    });
    this.withdrawFetch(param);
  };
  handleChangeChain = (value, option) => {
    const asset = this.state.chainList[option.key];
    this.setLimitInfo(asset);
    this.setState({
      currentChain: value
    });
  };

  //法币提取
  withdrawFiatFetch = (verifyParam: IVerifyParam) => {
    const { withdrawAmount, addressInfo, octOrderId } = this.state;
    const currency = window?.__history__?.location?.state?.currency;
    const { withdrawSuccess, withdrawFailed } = this.getText();
    const { verifyCode, verifyType, businessType } = verifyParam;
    const param = {
      verifyCode: verifyCode, // 邮箱验证码或google验证器验证码 ,
      verifyType: verifyType, // 验证类型1:邮箱 2：google验证器
      businessType: businessType, // 邮箱4， 谷歌身份验证器 4
      currency: currency,
      otcOrderId: octOrderId, //充值参数id
      paymentWayId: addressInfo, //银行卡生成id
      asset: "",
      requestAmount: withdrawAmount //金额
    };
    applyOtcWithdraw(param).then((res) => {
      if (res?.code == "0") {
        this.verifyRef?.current?.hideVerifyModal();
        message.destroy();
        message.success(withdrawSuccess, 2).then(() => {
          this.setState({
            loading: false
          });
          window.__history__.push(`/wallet`);
        });
      } else {
        const intl: Intl = window.__intl__.messages;
        let msg = intl?.[res?.msg];
        if (!msg) {
          msg = intl?.[res?.code] ?? withdrawFailed;
        }
        message.destroy();
        message.error(msg);
        this.setState({
          loading: false
        });
      }
    });
  };
  //电子货币提取
  withdrawFetch = (verifyParam: IVerifyParam) => {
    const { withdrawAmount, addressInfo, xrpTag, currentChain } = this.state;
    let currency = window?.__history__?.location?.state?.currency;
    const { withdrawSuccess, withdrawFailed } = this.getText();
    //USDT提取入参要传EUDT
    // if (currency == "USDT") {
    //   currency = "EUSDT";
    // }
    const { verifyCode, verifyType, businessType } = verifyParam;
    const param = {
      verifyCode: verifyCode, // 邮箱验证码或google验证器验证码 ,
      verifyType: verifyType, // 验证类型1:邮箱 2：google验证器
      businessType: businessType, // 邮箱4， 谷歌身份验证器 4
      // currency: currency,
      currency: currentChain, //传入链的地址
      address: addressInfo, //地址
      outerBusinessId: "",
      memo: xrpTag, //备注 xrp
      amount: withdrawAmount //金额
    };
    applyWithdraw(param)
      .then((res) => {
        if (res?.code == "0") {
          this.verifyRef?.current?.hideVerifyModal();
          message.destroy();
          message.success(withdrawSuccess, 2).then(() => {
            window.__history__.push(`/wallet`);
          });
        } else {
          const intl: Intl = window.__intl__.messages;
          let msg = intl?.[res?.msg];
          if (!msg) {
            msg = intl?.[res?.code] ?? withdrawFailed;
          }
          message.destroy();
          message.error(msg);
        }
      })
      .finally(() => {
        this.setState({
          confirmLoading: false
        });
      });
  };

  //判断输入金额是否符合规范
  checkAmount = (value) => {
    const intl: Intl = window.__intl__.messages;
    const currency = window?.__history__?.location?.state?.currency;
    const { leastPaceNum, oneTimePaceNum, oneDayPaceNum, balance } = this.state;
    if (value < leastPaceNum) {
      return `${intl["wallet.withdraw.amount.error.tips.first"]} ${decimalPointNoMoreX(
        leastPaceNum
      )}`;
    }
    const max = Math.min(oneTimePaceNum, oneDayPaceNum, balance);
    if (value > max) {
      return `${intl["wallet.withdraw.amount.error.tips.second"]} ${decimalPointNoMoreX(
        max
      )} ${currency}`;
    }
    return "success";
  };

  //计算输入金额费率
  calculateFee = () => {
    const { withdrawAmount, feeType, FeeRate, feeCurrecy } = this.state;
    let count: number;
    switch (feeType) {
      case "PERCENTAGE":
        count = mathMultiply(Number(withdrawAmount), FeeRate);
        break;
      case "FIXED":
        count = Number(feeCurrecy);
        break;
      case "MIXED":
        count = Math.max(mathMultiply(Number(withdrawAmount), FeeRate), Number(feeCurrecy));
        break;
    }
    return count?.toFixed(8);
  };

  verifyRef = React.createRef<any>();

  handleSubmit() {
    this.verifyRef?.current?.handleCheckVerify();
  }

  render(): React.ReactNode {
    const {
      location,
      wallet,
      limit,
      amount,
      max,
      amountPlaceholder,
      confirm,
      noticeTitle,
      noticeList,
      withdrawBtcAddress,
      withdrawBtcAddressPlaceholder,
      withdrawFiatAddress,
      withdrawFiatBind,
      withdrawFiatPlaceholder,
      tagText,
      availableText,
      dayText
    } = this.getText();
    const {
      withdrawType,
      bankList,
      loading,
      confirmLoading,
      showSafetyVerificationModal,
      showGoogleAuthModal,
      oneTimePaceNum,
      oneDayPaceNum,
      leastPaceNum,
      balance,
      withdrawAmount,
      addressInfo,
      chainList,
      currentChain
    } = this.state;
    const currency = window?.__history__?.location?.state?.currency;
    return (
      <section className={styles.withdraw}>
        <Spin spinning={loading}>
          <Breadcrumb>
            <Breadcrumb.Item>{location}:</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/wallet">{wallet}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {window.__intl__.formatMessage(
                { id: "wallet.withdraw.title" },
                {
                  currentCoin: convertUSD2USDC(window?.__history__?.location?.state?.currency)
                }
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.withdrawContent}>
            <p className={styles.title}>
              {window.__intl__.formatMessage(
                { id: "wallet.withdraw.title" },
                {
                  currentCoin: convertUSD2USDC(window?.__history__?.location?.state?.currency)
                }
              )}
            </p>
            <div className={styles.limitArea}>
              <span>{limit}</span>
              <span>
                {decimalPointNoMoreX(oneDayPaceNum) + " " + convertUSD2USDC(currency)}/ {dayText}
              </span>
            </div>
            <div className={styles.chainTypeArea}>
              <p className={styles.label}>Select Network</p>
              <Select
                className={styles.selectCoin}
                value={currentChain}
                style={{ width: 100 }}
                onChange={(e, option) => this.handleChangeChain(e, option)}>
                {chainList.map((item, index) => {
                  return (
                    <Option key={index} value={item.asset}>
                      {convertUSD2USDC(item.chainName)}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <div className={styles.addressArea}>
              <div className={styles.topArea}>
                <span>{withdrawType ? withdrawFiatAddress : withdrawBtcAddress}</span>
                {withdrawType ? (
                  <span onClick={this.handleAddBank}> {withdrawFiatBind} </span>
                ) : (
                  <></>
                )}
              </div>
              {withdrawType ? (
                <Select
                  className={styles.selectArea}
                  placeholder={withdrawFiatPlaceholder}
                  onChange={this.handleChangeFiatAddress}
                  bordered={false}
                  size="large">
                  {bankList.map((item, index) => {
                    return (
                      <Option value={index} key={item.id}>
                        {item.accountNo}
                      </Option>
                    );
                  })}
                </Select>
              ) : (
                <Input
                  bordered={false}
                  onChange={this.handleChangeAddress}
                  placeholder={withdrawBtcAddressPlaceholder}
                />
              )}
            </div>
            {currency == "XRP" && (
              <div className={styles.xrpTag}>
                <p>{tagText}</p>
                <Input bordered={false} onChange={this.handleChangeXrpTag} placeholder={tagText} />
              </div>
            )}
            <div className={styles.amountArea}>
              <div className={styles.topArea}>
                <span>{amount}</span>
                <span onClick={this.handleMaxAmount}>{max}</span>
              </div>
              <div className={styles.bottomArea}>
                <div className={styles.amountTop}>
                  <Input
                    bordered={false}
                    placeholder={balance + " " + availableText}
                    // type="number"
                    className={styles.amountInput}
                    value={withdrawAmount}
                    onChange={this.handleChangeAmount}
                    suffix={convertUSD2USDC(currency)}
                  />
                  {withdrawAmount != "" && this.checkAmount(withdrawAmount) != "success" && (
                    <div className={styles.limitTips}>
                      <p>{this.checkAmount(withdrawAmount)}</p>
                    </div>
                  )}
                </div>
                {withdrawAmount != "" && this.checkAmount(withdrawAmount) == "success" && (
                  <p className={styles.feeTips}>
                    {window.__intl__.formatMessage(
                      { id: "wallet.withdraw.fee" },
                      {
                        fee: this.calculateFee()
                      }
                    )}
                    &nbsp;{convertUSD2USDC(currency)}
                  </p>
                )}
              </div>
            </div>
            <Button
              disabled={
                addressInfo &&
                withdrawAmount &&
                currentChain &&
                this.checkAmount(withdrawAmount) === "success"
                  ? false
                  : true
              }
              className={styles.confirmBtn}
              onClick={this.handleSubmit}
              type="primary">
              {confirm}
            </Button>
            <div className={styles.noticeArea}>
              <div className={styles.title}>{noticeTitle}</div>
              <ul className={styles.listArea}>
                {noticeList.map((item, index) => {
                  if (withdrawType || index) {
                    return (
                      <li
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: item
                        }}
                      />
                    );
                  }
                })}
              </ul>
            </div>
          </div>

          <ModalLoadingContext.Provider value={{ confirmLoading }}>
            <Suspense fallback={<div />}>
              <ModalAddBank
                name={this.props?.kycInfo?.name}
                visible={this.state.showAddBankModal}
                handleCancel={this.hideModal}
                getBankList={this.getBankList}
              />
              <WithEmailOrGoogleVerify ref={this.verifyRef} handleConfirm={this.submitInfo} />
            </Suspense>
          </ModalLoadingContext.Provider>
        </Spin>

        <Suspense
          fallback={
            <div className={styles.marketsCard + " middleAlign"}>
              <Spin />
            </div>
          }>
          <History showViewAll={true} type={2} currency={currency} fiat={false} />
        </Suspense>
      </section>
    );
  }
}

export default Withdraw;
