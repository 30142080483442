import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
// The store has been created with these options:
// - reducer对象里的所有属性会经 combineReducers()合成
// - redux-thunk 和 redux-logger 被添加到中间件里
// - Redux DevTools 拓展插件在生产模式下会自动屏蔽
// - 中间件、reduxBatch还有 Chrome 开发者工具增强器会自动组合到一起

import reducers from "@/slices/index";

// redux-persist
import storage from "redux-persist/lib/storage"; //  defaults to localStorage for web
//import storageSession from "redux-persist/lib/storage/session"; // sessionStorage机制

import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root", // 必须有的
  storage: storage, // 缓存机制
  stateReconciler: autoMergeLevel2,
  // todo open order不用持久化
  blacklist: ["marketSpotAndTradeInfo.openOrder"] // reducer 里不持久化的数据,除此外均为持久化数据
  // whitelist: [] // reducer 里持久化的数据,除此外均为不持久化数据
};

const persistedReducer = persistReducer<any, any>(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk]
});

// export const store = configureStore({
//   reducer: {
//     counter: counterReducer, // just for test
//     language: languageReducer,
//     userInfo: userReducer,
//     symbolList: exchangeReducer
//   }
// });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
