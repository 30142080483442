import React from "react";

import styles from "./Clients.module.less";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Row, Col, Input, Tooltip, Table, message, Spin } from "antd";
import WithFiatAndSpotTrade from "@/components/withFiatAndSpotTrade/WithFiatAndSpotTrade";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  getCustomerList,
  getClientInfo,
  getCommissionSummary,
  operateCustomer,
  cancelOperateCustomer
} from "@/services/request";
import { BENCHMARK_CURRENCY_SYMBOL, Client_Authorization_Status } from "@/config/constants";
import { IResClientInfo, IResClientAssets } from "../../model/index";
import { numberToThousands, decimalPointNoMoreX, transformTime, transformDate } from "@/utils";
interface IProps {
  registerWS?: () => void;
}
interface IState {
  agentInfo: IResClientInfo;
  agentAssets: IResClientAssets;
  dataSource: any[];
  nextToken: string | null;
  loading: boolean;
}

class PartnerManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      agentInfo: {
        inviteCode: "",
        type: "",
        aumAmount: null
      },
      agentAssets: {
        totalAmount: null,
        settledAmount: null,
        unsettledAmount: null
      },
      dataSource: [],
      nextToken: null,
      loading: false
    };
  }
  componentDidMount() {
    this.initData();
  }

  initData = () => {
    //如果存在代理删除
    if (localStorage.getItem(`${location.origin}agentOperatingToken`)) {
      cancelOperateCustomer();
      localStorage.removeItem(`${location.origin}agentOperatingToken`);
      localStorage.removeItem("operateCustomerEmail");
      localStorage.setItem("user_auth", "partner");
    }
    this.fetchAgentInfo();
    this.fetchCustomerList();
    this.fetchCommissionSummary();
  };
  //代理商信息
  fetchAgentInfo = () => {
    const intl = window.__intl__.messages;
    let param = {
      customerId: localStorage.getItem("customerId")
    };
    getClientInfo(param).then((res) => {
      if (res?.code == "0") {
        this.setState({
          agentInfo: res?.data
        });
      } else {
        message.destroy();
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
    });
  };
  //Customer list
  fetchCustomerList = () => {
    this.setState({ loading: true });
    const intl = window.__intl__.messages;
    let param = {
      customerId: localStorage.getItem("customerId"),
      limit: 10,
      nextToken: this.state.nextToken
    };
    getCustomerList(param)
      .then((res) => {
        if (res?.code == "0") {
          this.setState({
            dataSource:
              this.state.dataSource.length > 0
                ? this.state.dataSource.concat(res?.data?.customerList || [])
                : res?.data?.customerList,
            nextToken: res.data?.nextToken
          });
        } else {
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  //代理商资产
  fetchCommissionSummary = () => {
    const intl = window.__intl__.messages;
    getCommissionSummary().then((res) => {
      if (res?.code == "0") {
        this.setState({
          agentAssets: res?.data
        });
      } else {
        message.destroy();
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
    });
  };
  onCopy() {
    const intl = window.__intl__.messages;
    message.destroy();
    message.success(intl["copied"]);
  }
  getColumns: () => any[] = () => {
    const intl = window.__intl__.messages;
    const columns = [
      {
        title: intl["column.name"],
        dataIndex: "userName",
        ellipsis: true
      },
      {
        title: intl["column.email.address"],
        dataIndex: "email"
      },
      {
        title: intl["column.type"],
        dataIndex: "type"
      },
      {
        title: intl["column.user.status"],
        //isKyced 0=未认证，1=审核中，2=审核通过，3=拒绝
        render: (value, record) => (
          <>
            <Tooltip title={record?.isKyced == "2" ? `Certified ${record.type}` : `Not certified`}>
              <span
                className={record?.isKyced == "2" ? styles.greenBg : styles.grayBg}
                style={{ marginRight: 16 }}>
                V
              </span>
            </Tooltip>
            <Tooltip
              title={record?.deposited === true ? `Customer deposited` : `Customer not deposited`}>
              <span className={record?.deposited === true ? styles.greenBg : styles.grayBg}>D</span>
            </Tooltip>
          </>
        )
      },
      {
        title: (
          <>
            <span>{intl["column.total.assets"]}</span>
            {this.state.dataSource?.length > 0 && this.state?.dataSource[0]?.updateTime && (
              <Tooltip
                title={`Update Time: ${transformTime(this.state.dataSource[0]?.updateTime)}`}>
                <InfoCircleOutlined style={{ paddingLeft: "5px", cursor: "pointer" }} />
              </Tooltip>
            )}
          </>
        ),
        className: this.state.agentInfo?.type != "Partner" && "notShow", //Partner时才显示总资产
        dataIndex: "totalAmount",
        render: (value) => (
          <span>
            {BENCHMARK_CURRENCY_SYMBOL} {numberToThousands(decimalPointNoMoreX(value, 2)) || "0.00"}
          </span>
        )
      },
      // {
      //   title: intl["clients.commission"],
      //   dataIndex: "commission", //字段待定 因为未计算出数据目前隐藏
      //   render: (value) => (
      //     <span>
      //       {BENCHMARK_CURRENCY_SYMBOL} {numberToThousands(decimalPointNoMoreX(value, 2)) || "0.00"}
      //     </span>
      //   )
      // },
      // {
      //   title: intl["column.registration"],
      //   dataIndex: "registration",
      //   render: (value) => <span>{transformTime(value)}</span>
      // }
      {
        title: intl["column.authorization"],
        dataIndex: "roleApplyStatus",
        render: (value, record) => (
          <span>
            {value == 4 && record?.expirationDate
              ? transformDate(record?.expirationDate)
              : Client_Authorization_Status[value]}
          </span>
        )
      },
      {
        title: intl["column.action"],
        dataIndex: "roleApplyStatus",
        render: (value, record) => (
          <span
            style={{
              cursor: "pointer",
              color: record?.isKyced == 2 ? "#F17E00" : "#4F4F4F"
            }}
            onClick={() => {
              this.handleApply(record);
            }}>
            {Client_Authorization_Status[value] != "Reviewing" &&
              (value == 4 || value == 8 ? intl["button.manage"] : intl["button.apply"])}
          </span>
        )
      }
    ];
    return columns;
  };

  handleApply = (record) => {
    const intl = window.__intl__.messages;
    if (record?.isKyced != 2) {
      return;
    }
    if (record?.roleApplyStatus == 4 || record?.roleApplyStatus == 8) {
      this.setState({ loading: true });
      const params = {
        customerId: record.id
      };
      window.ws = null;
      operateCustomer(params).then((res) => {
        if (res?.code == "0") {
          localStorage.setItem(`${location.origin}agentOperatingToken`, res.data?.jwtToken || "");
          localStorage.setItem("user_auth", "partnercustomer");
          localStorage.setItem("operateCustomerEmail", record.email);
          this.props.registerWS();

          window.__history__.push("/trade");
        } else {
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
        this.setState({ loading: false });
      });
    } else {
      window.__history__.push({ pathname: "/clients/application", state: { record } });
    }
  };

  render(): React.ReactNode {
    const intl = window.__intl__.messages;
    const columns = this.getColumns();
    const { agentInfo, dataSource, loading, nextToken, agentAssets } = this.state;
    const link = `${location.origin}/login/register?invitedCode=${agentInfo.inviteCode}`.replace(
      "partner",
      "portal"
    );
    return (
      <section className={styles.customerManagment + " " + "customer"}>
        <div className="customerTop">
          <Row className={"top " + styles.top}>
            <Col span={24}>
              <p className="title">{intl["clients.title"]}</p>
            </Col>
          </Row>
        </div>
        <Spin spinning={loading}>
          <Row gutter={[16, 0]} className="section">
            {/* <Col span={16}> //目前先隐藏资产管理费页面 */}
            <Col span={24}>
              <div className={styles.module}>
                <p className={styles.agencyInfo}>{intl["clients.agency.information"]}</p>
                <div className={styles.divider} />
                <div className={styles.agencyDetail}>
                  <div className="flex marginBottom12">
                    <span className={styles.label}>{intl["clients.agency.type"]}</span>
                    <span>{agentInfo?.type}</span>
                  </div>
                  {agentInfo?.type === "Partner" && (
                    <div className="flex marginBottom12">
                      <div>
                        <span>{intl["clients.aum"]}</span>
                        {agentInfo?.updateTime && (
                          <Tooltip title={`Update Time: ${transformTime(agentInfo?.updateTime)}`}>
                            <InfoCircleOutlined style={{ paddingLeft: "5px", cursor: "pointer" }} />
                          </Tooltip>
                        )}
                      </div>
                      <span className={styles.aum}>
                        {BENCHMARK_CURRENCY_SYMBOL}
                        {numberToThousands(decimalPointNoMoreX(agentInfo?.aumAmount, 2))}
                      </span>
                    </div>
                  )}
                  <div className="flex marginBottom12">
                    <span className={styles.label}>{intl["clients.referral.code"]}</span>
                    <span className={styles.inviteCode}>{agentInfo?.inviteCode ?? ""}</span>
                  </div>
                  <Row justify="space-between">
                    <Col>{intl["profile.referral"]}</Col>
                    <Col style={{ display: "flex", justifyContent: "cneter" }}>
                      <CopyToClipboard
                        text={link}
                        onCopy={this.onCopy}
                        style={{ position: "relative", width: "400px" }}>
                        <div className="flex">
                          <Input type="text" disabled value={link} />
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#F17E00",
                              cursor: "pointer",
                              right: "17px",
                              position: "absolute"
                            }}>
                            {intl["button.copy"]}
                          </span>
                        </div>
                      </CopyToClipboard>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            {/* <Col span={8}>
              <div className={styles.module}>
                <p className={styles.agencyInfo}>{intl["clients.commission"]}</p>
                <div className={styles.divider} />
                <div className={styles.agencyDetail}>
                  <p className="flex marginBottom12">
                    <span className={styles.label}>{intl["clients.total"]}</span>
                    <span className={styles.aum}>
                      $ {numberToThousands(decimalPointNoMoreX(agentAssets.totalAmount, 2))}
                    </span>
                  </p>
                  <p className="flex marginBottom12">
                    <span className={styles.label}>{intl["clients.unsettled"]}</span>
                    <span className={styles.aum}>
                      $ {numberToThousands(decimalPointNoMoreX(agentAssets.unsettledAmount, 2))}
                    </span>
                  </p>
                  <p className="flex marginBottom20">
                    <span className={styles.label}>{intl["clients.settled"]}</span>
                    <span className={styles.aum}>
                      $ {numberToThousands(decimalPointNoMoreX(agentAssets.settledAmount, 2))}
                    </span>
                  </p>
                </div>
                <div className={styles.divider} />
                <div className={styles.bottomArea}>
                  <Link to="/clients/billingHisory">{intl["clients.view.billing.history"]}</Link>
                </div>
              </div>
            </Col> */}
          </Row>
          <div className={styles.moduleList} style={{ marginTop: 24 }}>
            <div className={styles.clientList}>
              <p className={styles.title}>{intl["clients.customer.list"]}</p>
              <Table
                locale={intl["empty.list"]}
                bordered={false}
                rowKey={(record) => record?.id}
                pagination={false}
                dataSource={dataSource}
                columns={columns}
                rowClassName={styles.sales}
              />
              {nextToken && dataSource.length >= 10 && (
                <div className={styles.loadMore} onClick={() => this.fetchCustomerList()}>
                  {intl["button.loadMore"]}
                </div>
              )}
            </div>
          </div>
        </Spin>
      </section>
    );
  }
}

export default WithFiatAndSpotTrade(PartnerManagement);
