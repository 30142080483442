import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../store/store";
import { IMarketPushItemType, IOrderBook } from "@/model/index";

interface IinitialState {
  marketSymbolsList: {
    [key: string]: IMarketPushItemType;
  };
  offlineSymbolsList: {
    [key: string]: IMarketPushItemType;
  };
  symbolNames: string[];
  openOrder: {};
  orderBook: IOrderBook;
  depthData: IOrderBook;
}
const initialState: IinitialState = {
  marketSymbolsList: {},
  offlineSymbolsList: {},
  symbolNames: [],
  openOrder: {},
  orderBook: {
    ts: 0,
    symbol: "",
    asks: [],
    bids: []
  },
  depthData: {
    bids: [],
    asks: []
  }
};

// export const incrementAsync = createAsyncThunk("counter/fetchCount", async (amount: number) => {
//   const response = await fetchCount(amount);
//   // The value we return becomes the `fulfilled` action payload
//   return response.data;
// });

export const exchangeSlice = createSlice({
  name: "symbol",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateSymbolInfo: (state, action: PayloadAction<any>) => {
      if (action.payload?.isWsMessage) {
        const marketSymbolsList = JSON.parse(JSON.stringify(state.marketSymbolsList));
        const pushedSymbolInfo = action.payload?.symbolInfoList;
        const pushedSymbol = pushedSymbolInfo.symbol;
        marketSymbolsList[pushedSymbol].priceLast = pushedSymbolInfo.mid;
        marketSymbolsList[pushedSymbol].volume = pushedSymbolInfo.volume;
        marketSymbolsList[pushedSymbol].highPrice = pushedSymbolInfo.high;
        marketSymbolsList[pushedSymbol].lowPrice = pushedSymbolInfo.low;
        marketSymbolsList[pushedSymbol].ask = pushedSymbolInfo.ask;
        marketSymbolsList[pushedSymbol].bid = pushedSymbolInfo.bid;
        marketSymbolsList[pushedSymbol].riseAndFall = pushedSymbolInfo.percentage;
        if (JSON.stringify(marketSymbolsList) != JSON.stringify(state.marketSymbolsList))
          state.marketSymbolsList = marketSymbolsList;
      } else {
        state.marketSymbolsList = action.payload?.symbolInfoList;
      }
    },
    updateOfflineSymbolInfo: (state, action: PayloadAction<any>) => {
      state.offlineSymbolsList = action.payload?.offlineSymbolInfoList;
    },
    updateSymbolNames: (state, action: PayloadAction<any>) => {
      state.symbolNames = action.payload?.symbolNames;
    },
    updateOrderBook: (state, action: PayloadAction<any>) => {
      state.orderBook = action.payload?.orderBook;
    },
    updateDepth: (state, action: PayloadAction<any>) => {
      state.depthData = action.payload?.depthData;
    },
    updateOpenOrder: (state, action: PayloadAction<any>) => {
      state.openOrder = action.payload;
    }
  }
});

export const {
  updateSymbolInfo,
  updateOfflineSymbolInfo,
  updateOrderBook,
  updateDepth,
  updateOpenOrder,
  updateSymbolNames
} = exchangeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const marketSymbolsList = (state: RootState) =>
  state.marketSpotAndTradeInfo.marketSymbolsList;
export const offlineSymbolsList = (state: RootState) =>
  state.marketSpotAndTradeInfo.offlineSymbolsList;
export const symbolNames = (state: RootState) => state.marketSpotAndTradeInfo.symbolNames;
export const orderBook = (state: RootState) => state.marketSpotAndTradeInfo.orderBook;
export const depthData = (state: RootState) => state.marketSpotAndTradeInfo.depthData;
export const openOrder = (state: RootState) => state.openOrder;

// exports a reducer function for the counter logic.
export default exchangeSlice.reducer;
