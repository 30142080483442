import React, { useState, Suspense } from "react";
import { Intl, IAnnouncement, INews } from "@/model/index";
import { Row, Col, Spin } from "antd";
import styles from "./Intelligence.module.less";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import {
  updateIntelligence,
  currentDailyBriefing,
  currentAnnouncement
} from "../../slices/IntelligenceSlice";

const DailyBrifing = React.lazy(
  () => import(/* webpackChunkName: "DailyBrifing" */ "./components/DailyBrifing")
);
const AnnouncementList = React.lazy(
  () => import(/* webpackChunkName: "AnnouncementList" */ "./components/AnnouncementList")
);

interface IProps {}

const Intelligence: React.FC<IProps> = (props: IProps) => {
  const [defaultActiveKey, setDefaultActiveKey] = useState<string>("dailyBrifing");
  const intl: Intl = window.__intl__.messages;
  const dailyBrifing = intl["intelligence.dailyBrifing"];

  const currentDailyBriefingLists = useAppSelector(currentDailyBriefing);
  const currentAnnouncementLists = useAppSelector(currentAnnouncement);

  const dispatch = useAppDispatch();

  const updateData: (type: string, data: INews | IAnnouncement) => void = (type, data) => {
    dispatch(updateIntelligence({ [type]: data })); // todo严格参数限制
  };
  return (
    <section className={styles.intelligence}>
      {/* <Tabs
        className={styles.dailyBrifing}
        defaultActiveKey={defaultActiveKey}
        activeKey={defaultActiveKey}
        onChange={(e) => setDefaultActiveKey(e)}>
        <TabPane tab={dailyBrifing} key="dailyBrifing" />
        <TabPane tab={marketSignal} key="marketSignal" />
      </Tabs> */}

      {defaultActiveKey == "dailyBrifing" && (
        <Row className={styles.row}>
          <Col flex="auto">
            <p className={styles.dailyBrifingTitle}>{dailyBrifing}</p>
            <div className={styles.articleList}>
              <Suspense fallback={<div className="middleAlign" style={{ height: "731px" }} />}>
                <DailyBrifing
                  initData={currentDailyBriefingLists}
                  onDataFetchedAction={updateData}
                />
              </Suspense>
            </div>
          </Col>
          <Col flex="340px">
            {/* <div className={styles.noticeList}> */}

            <Suspense fallback={<div className={styles.noticeList + " middleAlign"} />}>
              <AnnouncementList
                initData={currentAnnouncementLists}
                onDataFetchedAction={updateData}
              />
              {/* </div> */}
            </Suspense>
          </Col>
        </Row>
      )}

      {/* {defaultActiveKey == "marketSignal" && <MarketSignal />} */}
    </section>
  );
};

export default Intelligence;
