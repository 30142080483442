import React, { Component, Suspense } from "react";
import { injectIntl } from "react-intl";
import styles from "@/views/login/Login.module.less";
import { IInjectIntlProps } from "@/model/index";
// import Footer from "@/components/footer/Footer";
import logo from "@/assets/images/aspen_logo_white.webp";

const Footer = React.lazy(
  () => import(/* webpackChunkName: "Footer" */ "@/components/footer/Footer")
);

interface IProps extends IInjectIntlProps {}
interface IState {
  passwordRegExp: RegExp;
}

export default (WrappedComponent) => {
  class LoginLayoutCMS extends Component<IProps, IState> {
    constructor(props: IProps) {
      super(props);
      this.state = {
        // 密码格式，数字+小写字母+大写字母，6位以上
        passwordRegExp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}$/
      };
    }

    /**
     * 注册登陆重置密码时，input focus和blur公用方法
     * @param event
     */
    handleBlur: (event: { target: { value: any }; currentTarget: any }) => void = (event: {
      target: { value: any };
      currentTarget: any;
    }) => {
      if (!event.target.value) {
        event.currentTarget.parentNode.firstElementChild.style.zIndex = 0;
        event.currentTarget.parentNode.firstElementChild.style.top = "0";
        event.currentTarget.parentNode.firstElementChild.style.color = "#ffffffb3";
      }
    };
    handleFocus: (event: { target: { value: any }; currentTarget: any }) => void = (event: {
      target: { value: any };
      currentTarget: any;
    }) => {
      event.currentTarget.parentNode.firstElementChild.style.top = "-15px";
      event.currentTarget.parentNode.firstElementChild.style.zIndex = 1;
      event.currentTarget.parentNode.firstElementChild.style.color = "#FFFFFF59";
    };

    /**
     * @description: 校验密码格式是否正确
     * @params {
     *  password：密码，
     *  message：格式或者长度错误提示，
     * }
     * @return {Promise}
     */
    passwordValidator: (password: string, message: string) => Promise<any> = (
      password: string,
      message: string
    ) => {
      if (!password || !this.state.passwordRegExp.test(password)) {
        return Promise.reject(new Error(message));
      }
      return Promise.resolve();
    };

    /**
     * @description: 校验确认密码格式以及是否和密码一致，重置密码和设置密码使用
     * @params {
     *  confirmPassword：确认密码，
     *  password：密码，
     *  message：格式或者长度错误提示，
     *  notMatch：两次密码不一致时的提示
     * }
     * @return {Promise}
     */
    passwordConfirmValidator: (
      confirmPassword: string,
      password: string,
      message: string,
      notMatch: string
    ) => Promise<any> = (
      confirmPassword: string,
      password: string,
      message: string,
      notMatch: string
    ) => {
      if (!confirmPassword) {
        return Promise.reject(new Error(message));
      }
      if (password !== confirmPassword) {
        return Promise.reject(new Error(notMatch));
      }
      if (
        !confirmPassword ||
        password.length !== confirmPassword.length ||
        !this.state.passwordRegExp.test(confirmPassword)
      ) {
        return Promise.reject(new Error(message));
      }
      return Promise.resolve();
    };

    render() {
      const intl = window.__intl__.messages;
      return (
        <section className={styles.login}>
          <div className={styles.main}>
            <div className={styles.mainContainer}>
              <section className={styles.leftBriefBlack}>
                <img className={styles.logo} src={logo} alt="" style={{}} />
                <p className={styles.title}>{intl["login.title.CMS"]}</p>
              </section>
              <WrappedComponent
                {...this.props}
                handleFocus={this.handleFocus}
                handleBlur={this.handleBlur}
              />
            </div>
          </div>

          <Suspense fallback={<div />}>
            <Footer />
          </Suspense>
        </section>
      );
    }
  }
  return LoginLayoutCMS;
};
