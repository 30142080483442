import React, { useEffect, useState, Suspense } from "react";
import { Table, Row, Col, Spin, message } from "antd";
import styles from "./Wallet.module.less";
import { transformTime, decimalPointNoMoreX, convertUSD2USDC, numberToThousands } from "@/utils";
import { getAMFSummary, getAMFList } from "@/services/request";
import { Intl, IResClientAssets } from "@/model/index";

interface IProps {}

const WithEmptyContent = React.lazy(
  () => import(/* webpackChunkName: "WithEmptyContent" */ "@/components/emptyContent/EmptyContent")
);
export function AUMFee(props: IProps) {
  const intl: Intl = window.__intl__.messages;
  const loadMore = intl["button.loadMore"];
  const currency_text = intl["column.currency"];
  const feeTitle_text = intl["wallet.AUM.fee.title"];
  const feeTotal_text = intl["wallet.AUM.fee.total"];
  const feeSettled_text = intl["wallet.AUM.fee.settled"];
  const feeUnsettled_text = intl["wallet.AUM.fee.unsettled"];
  const status_text = intl["column.status"];
  const month_text = intl["column.month"];

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [assetInfo, setAssetInfo] = useState({
    totalAmount: 0,
    settledAmount: 0,
    unsettledAmount: 0
  });

  useEffect(() => {
    initData();
  }, []);

  // 初始化数据,
  const initData: () => void = async () => {
    fetchAMFSummary();
    fetchAMFList();
  };

  //资产管理费总额
  const fetchAMFSummary = () => {
    const intl = window.__intl__.messages;
    getAMFSummary().then((res) => {
      if (res?.code == "0") {
        setAssetInfo(res?.data);
      } else {
        message.destroy();
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
    });
  };

  //资产管理费历史
  const fetchAMFList = (type?: string) => {
    setLoading(true);
    const intl = window.__intl__.messages;
    const param = {
      limit: 10,
      nextToken: nextToken
    };
    getAMFList(param)
      .then((res) => {
        if (res?.code == "0") {
          if (type === "loadMore") {
            setDataSource(dataSource.concat(res.data?.rows || []));
          } else {
            setDataSource(res.data?.rows || []);
          }
          setNextToken(res.data?.nextToken);
        } else {
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const columns = [
    {
      title: month_text,
      dataIndex: "amfDate",
      render: (value) => <span>{value?.substring(0, 4) + "-" + value?.substring(4, 6)}</span> //目前前端截取
    },
    {
      title: currency_text,
      dataIndex: "currency",
      render: (value) => <span>{convertUSD2USDC(value)}</span>
    },
    {
      title: feeTitle_text,
      dataIndex: "amount",
      render: (value) => <span>{numberToThousands(decimalPointNoMoreX(value, 2))}</span>
    },
    {
      title: status_text,
      dataIndex: "status",
      render: (value) => (
        <span>
          {value != "2" ? (value == "0" ? intl["unsettled"] : intl["settled"]) : intl["failed"]}
        </span>
      )
    }
  ];
  return (
    <section className={styles.wallet + " " + "customer"}>
      <div className="customerTop">
        <Row className="top">
          <Col span={24}>
            <p className="title">{feeTitle_text}</p>
          </Col>
        </Row>
      </div>
      <Spin spinning={loading}>
        <div className={styles.AUMFee}>
          <Row className={styles.assetContent}>
            <Col span={8} className={styles.assetItem}>
              <p className={styles.assetValue}>
                $ {numberToThousands(decimalPointNoMoreX(assetInfo?.totalAmount, 2))}
              </p>
              <p className={styles.assetText}>{feeTotal_text}</p>
            </Col>
            <Col span={8} className={styles.assetItem + " " + styles.assetItemMiddle}>
              <p className={styles.assetValue}>
                $ {numberToThousands(decimalPointNoMoreX(assetInfo?.settledAmount, 2))}
              </p>
              <p className={styles.assetText}>{feeSettled_text}</p>
            </Col>
            <Col span={8} className={styles.assetItem}>
              <p className={styles.assetValue}>
                $ {numberToThousands(decimalPointNoMoreX(assetInfo?.unsettledAmount, 2))}
              </p>
              <p className={styles.assetText}>{feeUnsettled_text}</p>
            </Col>
          </Row>
          <div className={styles.tableArea}>
            <Suspense fallback={<div className={styles.marketsCard + " middleAlign"} />}>
              <WithEmptyContent showEmpty={true}>
                <Table
                  className={styles.table}
                  bordered={false}
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  rowClassName={styles.sales}
                />
              </WithEmptyContent>
            </Suspense>
            {nextToken && dataSource.length >= 10 && (
              <div className={styles.loadMore} onClick={() => fetchAMFList("loadMore")}>
                {loadMore}
              </div>
            )}
          </div>
        </div>
      </Spin>
    </section>
  );
}

export default AUMFee;
