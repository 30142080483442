import React, { Suspense } from "react";
import { Intl, IkycInfo, IVerifyParam } from "@/model/index";
import { Breadcrumb, Input, Button, message, Spin } from "antd";
import styles from "./Wallet.module.less";
import { Link } from "react-router-dom";
import { withdrawCircle, getCircleLimit, getAssetCurrencyConfig } from "@/services/request";
import { numberToThousands, decimalPointNoMoreX, convertUSD2USDC } from "@/utils";
import { InfoCircleFilled } from "@ant-design/icons";
import { ModalLoadingContext } from "@/store/context";

const History = React.lazy(() => import(/* webpackChunkName: "History" */ "./components/History"));

const WithEmailOrGoogleVerify = React.lazy(
  () =>
    import(
      /* webpackChunkName: "WithEmailOrGoogleVerify" */ "@/components/withEmailOrGoogleVerify/WithEmailOrGoogleVerify"
    )
);
interface IProps {
  intl: Intl; //
  kycInfo: IkycInfo;
}
interface IState {
  loading: boolean;
  confirmLoading: boolean;
  addressInfo: string;
  bankName: string;
  withdrawAmount: string | number;
  leastPaceNum: number; // 每次提币的最小数量
  maxPaceNum: number; // 每次提币的最大数量
  balance: number; //当前币种余额
  feeCurrecy: string; //费用固定时的金额
  limitInfo: {
    oneMonthAmount: number | null;
    oneWeekAmount: number | null;
    oneMonthBalance: number | null;
    oneWeekBalance: number | null;
  };
}

class WithdrawUSDC extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      confirmLoading: false,
      addressInfo: "",
      bankName: "",
      withdrawAmount: "",
      leastPaceNum: 0,
      maxPaceNum: 0,
      balance: 0,
      feeCurrecy: "",
      limitInfo: {
        oneMonthAmount: null,
        oneWeekAmount: null,
        oneMonthBalance: null,
        oneWeekBalance: null
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.initData(
      window?.__history__?.location?.state?.currency,
      window?.__history__?.location?.state?.bankInfo
    );
  }

  //初始化数据
  initData = (currency, bankInfo) => {
    this.setState({
      loading: true,
      addressInfo: bankInfo.accountNumber,
      bankName: bankInfo.bankName
    });
    this.getWithdrawUsdLimit(currency);
  };

  //获取当前币种限额
  getWithdrawUsdLimit = (currency) => {
    const param = {
      currency: currency
    };
    getAssetCurrencyConfig(param)
      .then((res) => {
        if (res?.code == "0") {
          const chainList = res.data?.assets?.filter((item) => item.chainType === "FIAT") || [];
          const asset = chainList[0];
          this.setState({
            leastPaceNum: asset?.quotas && asset?.quotas[0]?.minAmount, //单次最小额
            maxPaceNum: asset?.quotas && asset?.quotas[0]?.maxAmount, //单次最da额
            feeCurrecy: asset?.withdrawFeeAmount, //固定fee $25
            balance: decimalPointNoMoreX(res?.data?.available, 2)
          });
        }
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
    getCircleLimit().then((res) => {
      if (res?.code == 0) {
        this.setState({
          limitInfo: res?.data
        });
      }
    });
  };

  getText(): { [propName: string]: any } {
    const intl: Intl = window.__intl__.messages;
    const location: string = intl["kyc.certify.location"];
    const wallet: string = intl["wallet"];
    const noticeTips: string = intl["wallet.withdraw.usdc.notice.tips"];
    const limit: string = intl["wallet.withdraw.limit"];
    const amount: string = intl["wallet.withdraw.amount"];
    const max: string = intl["wallet.withdraw.max"];
    const amountPlaceholder: string = intl["wallet.withdraw.amount.placeholder"];
    const confirm: string = intl["button.confirm"];
    const noticeTitle: string = intl["wallet.withdraw.notice"];
    const noticeList: string = intl["wallet.withdraw.usdc.notice.content"];

    const withdrawFiatAddress: string = intl["wallet.withdraw.fiat.address"];
    const withdrawSuccess: string = intl["wallet.withdraw.success.tips"];
    const withdrawFailed: string = intl["wallet.withdraw.failed.tips"];
    const withdrawFee: string = intl["wallet.circle.withdraw.fee"];
    const availableText: string = intl["available"];
    const weekText: string = intl["overview.myAsset.week"];
    const monthText: string = intl["overview.myAsset.month"];

    return {
      location,
      wallet,
      noticeTips,
      limit,
      amount,
      max,
      amountPlaceholder,
      confirm,
      noticeTitle,
      noticeList,
      withdrawFiatAddress,
      withdrawSuccess,
      withdrawFailed,
      withdrawFee,
      availableText,
      weekText,
      monthText
    };
  }

  verifyRef = React.createRef<any>();

  handleSubmit() {
    this.verifyRef.current?.handleCheckVerify();
  }

  //修改金额
  handleChangeAmount = (e) => {
    let params = e.target.value.replace(/[^\d.]/g, "").replace(/\.{2,}/g, "."); //取数字
    const value = params.replace(/^(-)*(\d+)\.(\d{1,2}).*$/, "$1$2.$3"); //取两位
    this.setState({ withdrawAmount: value });
  };
  handleMaxAmount = () => {
    this.setState({ withdrawAmount: this.state.balance });
  };

  //提交信息
  submitInfo = (param: IVerifyParam) => {
    this.setState({
      confirmLoading: true
    });
    this.withdrawFiatFetch(param);
  };

  withdrawFiatFetch = (verifyParam: IVerifyParam) => {
    const { withdrawAmount, addressInfo } = this.state;
    const currency = window?.__history__?.location?.state?.currency;
    const { withdrawSuccess, withdrawFailed } = this.getText();
    const { verifyCode, verifyType, businessType } = verifyParam;

    const param = {
      asset: currency,
      amount: withdrawAmount,
      verifyCode: verifyCode, // 邮箱验证码或google验证器验证码 ,
      verifyType: verifyType, // 验证类型1:邮箱 2：google验证器
      businessType: businessType
    };
    withdrawCircle(param)
      .then((res) => {
        if (res?.code == "0") {
          this.verifyRef?.current?.hideVerifyModal();
          message.destroy();
          message.success(withdrawSuccess, 2).then(() => {
            window.__history__.push(`/wallet`);
          });
        } else {
          const intl: Intl = window.__intl__.messages;
          let msg = intl?.[res?.msg];
          if (!msg) {
            msg = intl?.[res?.code] ?? withdrawFailed;
          }
          message.destroy();
          message.error(msg);
        }
      })
      .finally(() => {
        this.setState({ confirmLoading: false });
      });
  };

  //判断输入金额是否符合规范
  checkAmount = (param) => {
    const intl: Intl = window.__intl__.messages;
    const currency = window?.__history__?.location?.state?.currency;
    const { leastPaceNum, maxPaceNum, limitInfo, balance } = this.state;
    const value = Number(param);
    if (value < leastPaceNum) {
      return `${intl["wallet.withdraw.amount.error.tips.first"]} ${decimalPointNoMoreX(
        leastPaceNum,
        2
      )} ${convertUSD2USDC(currency)}`;
    }
    if (value > balance) {
      return `${intl["wallet.withdraw.amount.error.tips.third"]} ${decimalPointNoMoreX(
        balance,
        2
      )} ${convertUSD2USDC(currency)}`;
    }
    if (value > maxPaceNum) {
      return `${intl["wallet.withdraw.amount.error.tips.fourth"]} ${decimalPointNoMoreX(
        maxPaceNum,
        2
      )} ${convertUSD2USDC(currency)}`;
    }
    if (value > limitInfo.oneWeekBalance) {
      return `${intl["wallet.withdraw.amount.error.tips.fifth"]} ${decimalPointNoMoreX(
        limitInfo.oneWeekBalance,
        2
      )} ${convertUSD2USDC(currency)}`;
    }
    if (value > limitInfo.oneMonthBalance) {
      return `${intl["wallet.withdraw.amount.error.tips.sixth"]} ${decimalPointNoMoreX(
        limitInfo.oneMonthBalance,
        2
      )} ${convertUSD2USDC(currency)}`;
    }
    return "success";
  };

  render(): React.ReactNode {
    const {
      location,
      wallet,
      noticeTips,
      limit,
      amount,
      max,
      confirm,
      noticeTitle,
      noticeList,
      withdrawFiatAddress,
      withdrawFee,
      availableText,
      weekText,
      monthText
    } = this.getText();
    const {
      loading,
      confirmLoading,
      balance,
      withdrawAmount,
      addressInfo,
      bankName,
      feeCurrecy,
      limitInfo
    } = this.state;
    const currency = window?.__history__?.location?.state?.currency;
    return (
      <section className={styles.withdraw}>
        <Spin spinning={loading}>
          <Breadcrumb>
            <Breadcrumb.Item>{location}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/wallet">{wallet}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {window.__intl__.formatMessage(
                { id: "wallet.withdraw.title" },
                {
                  currentCoin: convertUSD2USDC(window?.__history__?.location?.state?.currency)
                }
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.withdrawContent}>
            <p className={styles.title}>
              {window.__intl__.formatMessage(
                { id: "wallet.withdraw.title" },
                {
                  currentCoin: convertUSD2USDC(window?.__history__?.location?.state?.currency)
                }
              )}
            </p>
            <p className={styles.noticeTips}>
              <InfoCircleFilled
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                  border: "1px solid #225085",
                  color: "#225085",
                  marginRight: 10
                }}
              />
              {noticeTips}
            </p>
            <div className={styles.limitArea}>
              <span>{limit}</span>
              <span>
                {decimalPointNoMoreX(limitInfo?.oneWeekAmount, 2)} {convertUSD2USDC(currency)}/
                {weekText};{decimalPointNoMoreX(limitInfo?.oneMonthAmount, 2)}{" "}
                {convertUSD2USDC(currency)}/{monthText}
              </span>
            </div>
            <div className={styles.addressArea}>
              <div className={styles.topArea}>
                <span>{withdrawFiatAddress}</span>
              </div>
              <div className={styles.addressUsdc}>
                {addressInfo} ({bankName})
              </div>
            </div>
            <div className={styles.amountArea} style={{ marginBottom: 20 }}>
              <div className={styles.topArea}>
                <span>{amount}</span>
                <span onClick={this.handleMaxAmount}>{max}</span>
              </div>
              <div className={styles.bottomArea}>
                <div className={styles.amountTop}>
                  <Input
                    bordered={false}
                    placeholder={numberToThousands(balance) + " " + availableText}
                    // type="number"
                    className={styles.amountInput}
                    value={withdrawAmount}
                    onChange={this.handleChangeAmount}
                    suffix={convertUSD2USDC(currency)}
                  />
                  {withdrawAmount != "" && this.checkAmount(withdrawAmount) != "success" && (
                    <div className={styles.limitTips}>
                      <p>{this.checkAmount(withdrawAmount)}</p>
                    </div>
                  )}
                </div>
                <p className={styles.feeTips}>
                  {withdrawFee} {feeCurrecy} {convertUSD2USDC(currency)}
                </p>
              </div>
            </div>
            <div className={styles.noticeArea} style={{ marginTop: 80 }}>
              <div className={styles.title}>{noticeTitle}</div>
              <ul className={styles.listArea}>
                {noticeList.map((item, index) => {
                  return (
                    <li
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: item
                      }}
                    />
                  );
                })}
              </ul>
            </div>
            <Button
              disabled={
                withdrawAmount && this.checkAmount(withdrawAmount) === "success" ? false : true
              }
              className={styles.confirmBtn}
              onClick={this.handleSubmit}
              type="primary">
              {confirm}
            </Button>
          </div>

          <ModalLoadingContext.Provider value={{ confirmLoading }}>
            <Suspense fallback={<div />}>
              <WithEmailOrGoogleVerify ref={this.verifyRef} handleConfirm={this.submitInfo} />
            </Suspense>
          </ModalLoadingContext.Provider>
        </Spin>

        <Suspense
          fallback={
            <div className={styles.marketsCard + " middleAlign"}>
              <Spin />
            </div>
          }>
          <History showViewAll={true} type={2} currency={currency} fiat={true} />
        </Suspense>
      </section>
    );
  }
}

export default WithdrawUSDC;
