import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../store/store";

interface IUser {
  kycInfo: object;
}

export interface userState {
  kycInfo: object;
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const currentUserInfo = (state: RootState) => state.user.kycInfo;

const initialState: userState = {
  kycInfo: {}
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // todo，严格参数限制
    updateUser: (state, action: PayloadAction<IUser>) => {
      state.kycInfo = action.payload.kycInfo;
    }
  }
});

export const { updateUser } = userSlice.actions;
// exports a reducer function for the counter logic.
export default userSlice.reducer;
