import React, { useEffect, useState, Suspense } from "react";
import { partial } from "lodash";
import { Table, Row, Col, Spin, message } from "antd";
import { IInjectIntlProps, Intl } from "@/model/index";
import { transformTime } from "@/utils";
import { getMyInvitationList } from "@/services/request";
import styles from "@/views/profile/Profile.module.less";

interface IProps extends IInjectIntlProps {}

const WithEmptyContent = React.lazy(() => import("@/components/emptyContent/EmptyContent"));

const MyInvitation = (props: IProps) => {
  const intl: Intl = window.__intl__.messages;
  const title = intl["profile.my.invitation.title"];
  const loadMore = intl["button.loadMore"];
  const email_text = intl["column.email"];
  const name_text = intl["column.name"];
  const status_text = intl["column.status"];
  const registration_text = intl["column.registration"];

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [offset, setOffset] = useState(0);

  const columns = [
    {
      title: email_text,
      dataIndex: "email"
    },
    {
      title: name_text,
      dataIndex: "name",
      ellipsis: true,
      render: (value) => value || "-"
    },
    {
      title: registration_text,
      dataIndex: "registerTime",
      render: (value: number) => <span>{transformTime(value)}</span>
    },
    {
      title: status_text,
      dataIndex: "status",
      render: (value) => <span>{value == 2 ? intl["verified"] : intl["registered"]}</span> // 0=未认证，1=审核中，2=审核通过，3=拒绝
    }
  ];

  useEffect(() => {
    fetchData(offset);
  }, []);

  const fetchData = (offset: number) => {
    setLoading(true);
    const params = {
      offset,
      limit: 100
    };
    getMyInvitationList(params)
      .then((res) => {
        if (res?.code == "0") {
          setDataSource(res?.data?.rows || []);
          // const offset = res?.data?.offset || 0;
          // setDataSource(showLoadMore ? dataSource.concat(rows) : rows);
          // setOffset(offset + 10);
          // setShowLoadMore(rows.length === 10);
        } else {
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section className={styles.myReferrals + " " + "customer"}>
      <div className="customerTop">
        <Row className="top">
          <Col span={24}>
            <p className="title">{title}</p>
          </Col>
        </Row>
      </div>
      <div className={styles.tableWrap}>
        <div className="section" style={{ width: "100%", marginTop: 0 }}>
          <div className={styles.myInvitation} style={{ marginTop: 0 }}>
            <div className={styles.myInvitationArea}>
              <Suspense
                fallback={
                  <div className={styles.marketsCard + " middleAlign"}>
                    <Spin />
                  </div>
                }>
                <WithEmptyContent showEmpty={true}>
                  <Spin spinning={loading}>
                    <Table
                      className={styles.table}
                      rowKey={(record) => record?.id}
                      bordered={false}
                      dataSource={dataSource}
                      columns={columns}
                      pagination={false}
                      rowClassName={styles.sales}
                    />
                  </Spin>
                </WithEmptyContent>
              </Suspense>
              {/* 后端API目前不支持分页 */}
              {/* {showLoadMore && (
                <div className={styles.loadMore} onClick={partial(fetchData, offset + 10)}>
                  {loadMore}
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyInvitation;
