import React, { Suspense } from "react";
import { Intl, IResOrders } from "@/model/index";
import { yieldHistory, ordersOpen, subscribeRedeemList } from "@/services/request";
import { Table, Row, Col, Tabs, Spin, message, Radio } from "antd";
import styles from "./History.module.less";
import { Operator_Type } from "@/config/constants";
import { fetchOrderDetail } from "@/services/request";
import WithFiatAndSpotTrade from "@/components/withFiatAndSpotTrade/WithFiatAndSpotTrade";
import { transformTime, decimalPointNoMoreX, convertUSD2USDC, numberToThousands } from "@/utils";
import moment from "moment";

const WithEmptyContent = React.lazy(
  () => import(/* webpackChunkName: "WithEmptyContent" */ "@/components/emptyContent/EmptyContent")
);

const ModalOrderDetail = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ModalOrderDetail" */ "@/components/modalOrderDetail/ModalOrderDetail"
    )
);
const FixedYieldHistory = React.lazy(
  () => import(/* webpackChunkName: "SavingsTable" */ "./components/FixedYieldHistory")
);

const { TabPane } = Tabs;

const defaultProps = {
  showViewAll: true,
  dataSource: [],
  marketSymbolsList: [],
  offlineSymbolsList: []
};

type IProps = Partial<typeof defaultProps>;
interface IState {
  dataSource: any[];
  column: any[];
  currentTab: string;
  loading: boolean;
  nextToken: string | null;
  offset: number;
  totalCount: number; //策略分页总数
  orderDetails: IResOrders[];
  showDetailModal: boolean;
  yieldType: string;
}

class History extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      column: [],
      dataSource: [],
      currentTab: window?.__history__?.location?.state?.id || "0",
      loading: false,
      nextToken: null,
      offset: 0,
      totalCount: 0,
      orderDetails: [],
      showDetailModal: false,
      yieldType: "0" //0:活期 1:定期
    };
    this.handleClickOnView = this.handleClickOnView.bind(this);
  }

  componentDidMount(): void {
    this.setColumns();
    this.getCurrentData();
  }

  //建立colums
  setColumns = () => {
    const intl: Intl = window.__intl__.messages;
    const date: string = intl["column.date"];
    const pair: string = intl["column.pair"];
    const side: string = intl["column.side"];
    const price: string = intl["column.price"];
    const amount: string = intl["column.amount"];
    const fee: string = intl["column.fee"];
    const total: string = intl["column.total"];
    const denomination: string = intl["column.denomination"];
    const type: string = intl["column.type"];
    const status: string = intl["column.status"];
    const projectName: string = intl["column.project.name"];
    const purchase: string = intl["column.purchase"];
    const subscription: string = intl["savings.history.subscription"];
    const redemption: string = intl["savings.history.redemption"];
    const success: string = intl["savings.history.success"];
    const failed: string = intl["savings.history.failed"];
    const pending: string = intl["savings.history.pending"];
    const column_order_total = intl["column.order.total"];
    const column_filled_total = intl["column.filled.total"];
    const averagePrice = intl["column.average.price"];
    const column_price = intl["column.price"];
    const column_filled_amount = intl["column.filled.amount"];
    const column_order_amount = intl["column.order.amount"];
    const column_order_price = intl["column.order.price"];
    const column_status = intl["column.status"];
    const column_operator = intl["column.operator"];
    const column_action = intl["column.action"];
    const column_type = intl["column.type"];

    const { marketSymbolsList: symbolList, offlineSymbolsList } = this.props; //useAppSelector(marketSymbolsList);

    // 查找symbol 若已下架，则在下架的列表中找
    const getSymbolInfo = (symbol) => {
      return symbolList?.[symbol] ?? offlineSymbolsList?.[symbol];
    };

    const spotColumns = [
      {
        title: date,
        //dataIndex: "filledTime", //此处取成交时间，成交了才记录到表，所以成交时间filledTime小于创建时间created
        dataIndex: "created",
        render: (value: number) => <span>{transformTime(value)}</span>
      },
      {
        title: pair,
        dataIndex: "pair",
        render: (text, row) => {
          return <span>{convertUSD2USDC(`${row.baseCurrency}/${row.quoteCurrency}`)}</span>;
        }
      },
      {
        title: column_type,
        dataIndex: "orderType",
        width: 70,
        render: (_, record: IResOrders) => <span className="capitalize">{record?.orderType}</span>
      },
      {
        title: side,
        dataIndex: "side",
        render: (_, record: IResOrders) => <span className="capitalize">{record?.side}</span>
      },
      {
        title: (
          <div>
            {averagePrice} <br /> {column_order_price}
          </div>
        ),
        align: "right",
        dataIndex: "filledPrice",
        render: (_, record) => (
          <>
            {record?.orderType == "limit" && (
              <div style={{ minWidth: "85px" }}>
                {numberToThousands(
                  decimalPointNoMoreX(
                    record?.averageTradePrice ?? "--",
                    getSymbolInfo(record?.symbol)?.symbolPrecision
                  )
                )}
                <br />
                {numberToThousands(
                  decimalPointNoMoreX(record?.price, getSymbolInfo(record?.symbol)?.symbolPrecision)
                )}
              </div>
            )}
            {record?.orderType == "market" && (
              <>
                {numberToThousands(
                  decimalPointNoMoreX(
                    record?.averageTradePrice ?? "--",
                    getSymbolInfo(record?.symbol)?.symbolPrecision
                  )
                )}
                <br />
                --
              </>
            )}
          </>
        )
      },
      {
        title: (
          <>
            {column_filled_amount}
            <br /> {column_order_amount}
          </>
        ),
        align: "right",
        dataIndex: "filledQty",
        render: (filledQty, record) => (
          <>
            {numberToThousands(
              decimalPointNoMoreX(
                record?.filledQty,
                getSymbolInfo(record?.symbol)?.tradeVolumePrecision
              )
            )}
            <br />
            {numberToThousands(
              decimalPointNoMoreX(
                record?.baseQty,
                getSymbolInfo(record?.symbol)?.tradeVolumePrecision
              )
            )}
          </>
        )
      },
      {
        title: fee,
        dataIndex: "fee",
        align: "right",
        render: (_, record) => {
          return (
            <span>
              {/* fee展示8位 */}
              {numberToThousands(
                decimalPointNoMoreX(
                  record?.fee ?? "--",
                  getSymbolInfo(record?.symbol)?.volumePrecision
                )
              )}
              <br />
              {convertUSD2USDC(record?.feeCurrency)}
            </span>
          );
        }
      },
      {
        title: column_status,
        width: 100,
        dataIndex: "orderStatus",
        render: (orderStatus) => <span className="capitalize">{orderStatus}</span>
      },
      {
        title: column_operator,
        dataIndex: "operatorType", //操作人类别  0本人操作，1子账号，2代理商
        render: (value) => <span>{Operator_Type[value] || "--"}</span>
      },
      {
        title: (
          <div>
            {column_filled_total} <br /> {column_order_total}
          </div>
        ),
        align: "right",
        dataIndex: "total",
        render: (text, record) => {
          return (
            <>
              {numberToThousands(
                decimalPointNoMoreX(
                  record?.filledVolume,
                  getSymbolInfo(`${record?.baseCurrency}_${record?.quoteCurrency}`)
                    ?.tradeVolumePrecision
                )
              )}
              {/* <span style={{ paddingLeft: 4 }}>{convertUSD2USDC(record?.quoteCurrency)}</span> */}
              <br />
              <span>
                {record?.orderType == "limit" && (
                  <>
                    {numberToThousands(
                      decimalPointNoMoreX(
                        record?.price * record?.baseQty,
                        getSymbolInfo(`${record?.baseCurrency}_${record?.quoteCurrency}`)
                          ?.tradeVolumePrecision
                      )
                    )}
                    {/* <span style={{ paddingLeft: 4 }}>{convertUSD2USDC(record?.quoteCurrency)}</span> */}
                  </>
                )}
                {record?.orderType == "market" && "--"}
              </span>
            </>
          );
        }
      },
      {
        title: column_action,
        width: 75,
        align: "center",
        dataIndex: "orderId",
        render: (orderId) => (
          <div
            className={styles.action}
            onClick={() => {
              this.handleClickOnView(orderId);
            }}>
            View
          </div>
        )
      }
    ];
    const yieldColumns = [
      {
        title: date,
        dataIndex: "created",
        render: (value: number) => <span>{transformTime(value)}</span>
      },
      // {
      //   title: projectName,
      //   dataIndex: "productId"
      // },
      {
        title: denomination,
        dataIndex: "currency",
        render: (value: string) => convertUSD2USDC(value)
      },
      {
        title: type,
        dataIndex: "operateType",
        render: (value: number) => <span>{value === 1 ? subscription : redemption}</span>
      },
      {
        title: amount,
        dataIndex: "amount",
        render: (value: number) => decimalPointNoMoreX(value)
      },
      {
        title: column_operator,
        dataIndex: "operatorType", //操作人类别  0本人操作，1子账号，2代理商
        render: (value) => <span>{Operator_Type[value] || "--"}</span>
      },
      {
        title: status,
        dataIndex: "status",
        render: (value: number) => (
          <span>{value === 2 ? pending : value === 3 ? success : failed}</span>
        )
      }
    ];
    const strategyColumns = [
      {
        title: date,
        dataIndex: "srDate",
        render: (srDate) => (srDate ? moment(srDate).format("YYYY-MM-DD") : "-")
      },
      {
        title: projectName,
        dataIndex: "strategyName"
      },
      {
        title: denomination,
        dataIndex: "currency"
      },
      {
        title: type,
        dataIndex: "type",
        render: (type) => (type.toLowerCase() === "subscribe" ? subscription : redemption)
      },
      {
        title: amount,
        dataIndex: "amount"
      }
    ];

    const accountSpotColumns = [
      {
        title: date,
        dataIndex: "date"
      },
      {
        title: type,
        dataIndex: "operateType",
        render: (value: number) => <span>{value === 1 ? subscription : redemption}</span>
      },
      {
        title: total,
        dataIndex: "total"
      }
    ];

    this.setState({
      column: [spotColumns, yieldColumns, strategyColumns, accountSpotColumns]
    });
  };

  handleClickOnView(orderId: string): void {
    // 获取订单详情
    const param = {
      orderId: orderId
    };
    fetchOrderDetail(param)
      .then((res) => {
        const intl: Intl = window.__intl__.messages;
        if (res.code == 0) {
          this.setState({
            orderDetails: res?.data ?? []
          });
        } else {
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch((e) => {})
      .finally(() => {
        this.setState({
          showDetailModal: true
        });
      });
  }

  hideModal(): void {
    this.setState({
      orderDetails: [],
      showDetailModal: false
    });
  }

  //获取表格数据
  getCurrentData = () => {
    const { currentTab, offset } = this.state;
    switch (currentTab) {
      case "0":
        this.getSpotHistory();
        break;
      case "1":
        this.getYieldHistory();
        break;
      case "2":
        this.getStrategyHistory();
        break;
      default:
        break;
    }
  };

  //点击切换tab
  handleChangeTabs: (T: string) => void = (value: string) => {
    this.setState(
      {
        currentTab: value,
        nextToken: null,
        offset: 0,
        totalCount: 0,
        dataSource: [],
        yieldType: "0"
      },
      () => {
        this.getCurrentData();
      }
    );
  };

  //dispatch
  getSpotHistory = () => {
    const { nextToken, dataSource } = this.state;
    const param = {
      limit: 10,
      orderQueryStatus: "history",
      fromOrderId: nextToken
    };
    this.setState({ loading: true });
    ordersOpen(param)
      .then((res) => {
        if (res?.code == "0") {
          const nextToken =
            res?.data?.length < 10 ? null : res.data[res?.data?.length - 1]?.orderId.toString();
          this.setState({
            nextToken: nextToken, //再请求一遍才能判断下一页有没有数据
            dataSource: dataSource.concat(res?.data)
          });
        }
      })
      .catch((e) => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  getYieldHistory = () => {
    const { nextToken, dataSource } = this.state;
    const param = {
      limit: 10,
      nextToken: this.state.nextToken
    };
    this.setState({ loading: true });
    yieldHistory(param)
      .then((res) => {
        if (res?.code == "0") {
          this.setState({
            dataSource: (nextToken ? dataSource.concat(res?.data?.rows) : res?.data?.rows) ?? [],
            nextToken: res?.data?.nextToken
          });
        }
      })
      .catch((e) => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  getStrategyHistory = () => {
    const { dataSource, offset } = this.state;
    const params = {
      limit: 10,
      offset
    };
    this.setState({ loading: true });
    subscribeRedeemList(params)
      .then((res) => {
        if (res?.code == 0) {
          const { rows, totalCount } = res?.data;
          this.setState({
            dataSource: rows ? dataSource.concat(rows) : rows,
            offset: offset + 10,
            totalCount: totalCount
          });
        }
      })
      .catch((e) => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  //切换理财类型
  handleChangeYield = (e) => {
    this.setState({ yieldType: e.target.value });
  };

  render(): React.ReactNode {
    const {
      dataSource,
      column,
      currentTab,
      loading,
      nextToken,
      showDetailModal,
      orderDetails,
      yieldType,
      offset,
      totalCount
    } = this.state;
    const { marketSymbolsList: symbolList } = this.props; //useAppSelector(marketSymbolsList);
    const intl: Intl = window.__intl__.messages;

    return (
      <section className={styles.history + " " + "customer"}>
        <div className="customerTop">
          <Row className="top">
            <Col span={24}>
              <p className="title">Trading History</p>
            </Col>
          </Row>
        </div>
        <div className={styles.tableWrap}>
          <div className="section" style={{ width: "100%", marginTop: 0 }}>
            <div className={styles.history} style={{ marginTop: 0 }}>
              <Spin spinning={loading}>
                <Tabs
                  className={styles.tab}
                  activeKey={currentTab}
                  onChange={this.handleChangeTabs}
                  style={{ color: "#fff" }}>
                  <TabPane tab={intl["menu.trade"]} key="0" />
                  <TabPane tab={intl["menu.yield"]} key="1" />
                  <TabPane tab={intl["menu.strategies"]} key="2" />
                </Tabs>
                <div className={styles.historyArea}>
                  {currentTab === "1" && (
                    <div className={styles.yieldTableTitle}>
                      <Radio.Group
                        onChange={this.handleChangeYield}
                        value={yieldType}
                        style={{ marginBottom: 24 }}>
                        <Radio.Button value="0">Flexible</Radio.Button>
                        <Radio.Button value="1">Fixed</Radio.Button>
                      </Radio.Group>
                    </div>
                  )}
                  {yieldType == "1" ? (
                    <Suspense fallback={<div className={styles.marketsCard + " middleAlign"} />}>
                      <FixedYieldHistory />
                    </Suspense>
                  ) : (
                    <div>
                      <Suspense fallback={<div className={styles.marketsCard + " middleAlign"} />}>
                        <WithEmptyContent showEmpty={true}>
                          <Table
                            className={styles.table}
                            rowKey={(record) => record?.id || record?.tradeId}
                            bordered={false}
                            dataSource={dataSource}
                            columns={column[currentTab]}
                            pagination={false}
                            rowClassName={styles.sales}
                          />
                        </WithEmptyContent>
                      </Suspense>
                      {currentTab == "2"
                        ? offset < totalCount && (
                            <div className={styles.loadMore} onClick={this.getCurrentData}>
                              {intl["button.loadMore"]}
                            </div>
                          )
                        : nextToken &&
                          dataSource.length >= 10 && (
                            <div className={styles.loadMore} onClick={this.getCurrentData}>
                              {intl["button.loadMore"]}
                            </div>
                          )}
                    </div>
                  )}
                </div>
              </Spin>
            </div>
          </div>
        </div>

        <Suspense fallback={<div />}>
          <ModalOrderDetail
            visible={showDetailModal}
            tableData={orderDetails}
            handleCancel={() => this.hideModal()}
            onOk={() => this.hideModal()}
            symbolList={symbolList}
          />
        </Suspense>
      </section>
    );
  }
}
export default WithFiatAndSpotTrade(History);
