import React, { useEffect, useState } from "react";
import { Button } from "antd";
import styles from "./Intelligence.module.less";

import { INews } from "@/model/index";
import {
  announcementsRead,
  fecthIntelligenceNewsDetail,
  fecthIntelligenceAnnouncementsDetail
} from "@/services/request";
import { transformTime, getQueryValue } from "@/utils";

interface IProps {}

const IntelligenceDetail: React.FC<IProps> = (props: IProps) => {
  const [detailContent, setDetailContent] = useState<INews>(
    window?.__history__?.location?.state || ""
  );

  useEffect(() => {
    if (detailContent) {
      getNoticeReaded(detailContent);
    } else {
      getDetailInfo();
    }
  }, []);
  //非列表进入时触发
  const getDetailInfo = () => {
    if (getQueryValue("type") == "news") {
      fecthIntelligenceNewsDetail({ id: getQueryValue("id") }).then((res) => {
        setDetailContent(res.data);
      });
    } else if (getQueryValue("type") == "announcements") {
      fecthIntelligenceAnnouncementsDetail({ id: getQueryValue("id") }).then((res) => {
        setDetailContent(res.data);
      });
    }
  };

  //消息已读请求上报
  const getNoticeReaded = (detailContent) => {
    if (getQueryValue("type") == "announcements" && !detailContent?.flag) {
      const param = {
        ids: detailContent.id
      };
      announcementsRead(param);
    }
  };

  return (
    <div className={styles.detail}>
      {/* <img className={styles.image} src={detailContent.imgUrl} alt={detailContent.imgUrl} /> */}
      <p className={styles.title}>{detailContent?.title}</p>
      <div className={styles.articleOthers}>
        {detailContent.category && (
          <Button className={styles.type}>{detailContent.category}</Button>
        )}
        {detailContent.category && <span className={styles.divider} />}
        <span className={styles.auth}> {detailContent.author} </span>
        <span className={styles.divider} />
        <span className={styles.date}>
          {" "}
          {transformTime(
            Number(detailContent.newsTime ? detailContent.newsTime : detailContent.noticeTime)
          )}{" "}
        </span>
      </div>
      <div
        className={styles.articleContents}
        dangerouslySetInnerHTML={{ __html: detailContent.content }}
      />
    </div>
  );
};

//export default (IntelligenceDetail);

export default React.memo(IntelligenceDetail);
