import React, { Suspense } from "react";

import styles from "@/views/login/Login.module.less";
import LoginLayout from "./components/LoginLayout";
import ResetPasswordStep2 from "./components/ResetPasswordStep2";
import ResetPasswordAll from "./components/ResetPasswordAll";
import { Spin } from "antd";

const ResetPasswordStep1 = React.lazy(
  () => import(/* webpackChunkName: "ResetPasswordStep1" */ "./components/ResetPasswordStep1")
);
interface IProps {
  handleBlur: (event: { target: { value: any }; currentTarget: any }) => void;
  handleFocus: (event: { target: { value: any }; currentTarget: any }) => void;
}

interface IState {
  userName: string;
  resetToken: string;
  showStep2: boolean;
}

class ResetPassword extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      userName: "",
      resetToken: "",
      showStep2: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(userName: string, resetToken: string): void {
    this.setState({
      userName: userName,
      resetToken: resetToken,
      showStep2: true
    });
  }

  render(): React.ReactNode {
    return (
      <Suspense
        fallback={
          <div className={styles.form + " middleAlign"}>
            <Spin />
          </div>
        }>
        {/* {!this.state.showStep2 && (
          <div className={styles.form}>
            <ResetPasswordStep1
              handleClick={this.handleClick}
              handleFocus={this.props.handleFocus}
              handleBlur={this.props.handleBlur}
            />
          </div>
        )}
        {this.state.showStep2 && (
          <div className={styles.form}>
            <ResetPasswordStep2
              userName={this.state.userName}
              resetToken={this.state.resetToken}
              handleFocus={this.props.handleFocus}
              handleBlur={this.props.handleBlur}
            />
          </div>
        )} */}
        <div className={styles.form}>
          <ResetPasswordAll
            handleFocus={this.props.handleFocus}
            handleBlur={this.props.handleBlur}
          />
        </div>
      </Suspense>
    );
  }
}
export default LoginLayout(ResetPassword);
