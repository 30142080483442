import React from "react";
import styles from "@/views/login/Login.module.less";

const PrivacyPolicy = () => {
  return (
    <div className={styles.privatePolicy}>
      <div className={styles.title}>Aspen Digital Privacy Policy</div>
      <div className={styles.content}>
        <div>
          At Aspen Digital, your privacy is very important to us. This Policy applies to the online
          products and services offered by Aspen Digital (collectively, “Aspen Digital Services”)
          and shall form part of the User Service Agreement. If you have any questions about this
          Policy, please feel free to contact us via our website (www.aspendigital.co) or
          at support@aspendigital.co.
        </div>
        <div className={styles.conTitle1}>Type of Information</div>
        We offer a service that requires you to register for an account and provide some personal
        and business information to us. In order for us to provide the Aspen Digital Services, we
        may collect the following types of information:- Personal and Business information – When
        you sign up for an Aspen Digital account, we ask you for personal and business information
        and we may ask for supporting documents and additional information when conducting identity
        verification. We may also collect personal and business information about you or persons
        affiliated with your company from third party entities (e.g. identity verification agencies
        and credit referencing agencies) when we seek to verify your identity as part of our
        regulatory requirements. The personal and business information may be shared with Aspen
        Digital’s designated third party service provider for the sole purpose of conducting
        customer due diligence, anti-money laundering/counter-terrorist financing (“AML/CTF”)
        screening, suspicious activities/transaction monitoring and any other similar purposes as
        required by the applicable laws, provided that any such party shall handle have a vigorous
        privacy policy in place and is compliant with all applicable laws. Transaction Information -
        We may collect transaction information as you use Aspen Digital Services, including but not
        limited to deposit records, account balances, trade history, order activity, and other
        records relating to transactions you conduct using the Aspen Digital Services. We collect
        such transaction information to monitor suspicious trading activity for user fraud
        protection, legal case resolution, and any other purposes disclosed in this Privacy Policy.
        User communications – Email or other communication sent to Aspen Digital, may be retained in
        order to process your inquiries, to respond to your requests, and to improve our services.
        <div className={styles.conTitle1}>Use of Information</div>
        In addition to the purposes of the collection of the information as aforementioned, Aspen
        Digital may use the information collected to:- Provide you with the Aspen Digital Services
        and carry out our obligation to you; Communicate and interact with you regarding the Aspen
        Digital Services; Comply with the applicable regulatory and legal obligation, including but
        not limited to verifying your identity as part of initial and on-going Know-Your-Client due
        diligence process and fulfil our obligations under the applicable AML/CTF laws and
        regulations; Perform credit check, verification procedures, data verification, due
        diligence and risk management; Protect our platform, users, and other sensitive information
        from malicious activities, including but not limited to using IP addresses and cookies to
        protect against automated abuse such as spam, phishing, and DDoS attack; Analyse trading
        activities with a view to detect suspicious behaviours and prevent potential fraud and loss
        of funds; Assist other financial institutions to conduct credit checks and collect debts as
        may be permissible or required under the applicable laws; Ensure that users maintain a
        reliable credit and provide up-to-date information for our record; Design financial
        services or related products for client’s use (including the provision of financial advice
        to clients in appropriate cases); Meet the information disclosure requests or requirements
        put forward by laws or lawfully required by law enforcement agencies; Any other purposes
        allowed or required by laws. In the event that Aspen Digital goes through a business merger,
        acquisition by another company, or sale of all or a portion of its assets, personal and
        business information may be among the assets transferred. We will provide notification on
        Aspen Digital’s website for a minimum of 30 days if any such change in ownership takes
        place.
        <div className={styles.conTitle1}>Handling of Information</div>
        We take appropriate security measures to protect against unauthorized access to or
        unauthorized alteration, disclosure, or destruction of data. These security measures include
        internal reviews of our data collection, storage and processing practices, and security
        measures, as well as physical security measures to guard against unauthorized access to
        systems where we store personal data. We restrict access to personal information by Aspen
        Digital employees and third party service providers who need to know that information in
        order to operate, develop, or improve our services and ensure compliance with the applicable
        laws. These individuals and/or entities are bound by confidentiality obligations and may be
        subject to discipline, including termination and criminal prosecution, if they fail to meet
        these obligations. While we shall endeavour to keep your personal information safe by using
        secured storage systems with industry standard encryption and implement security measures to
        ensure that your information is not lost, abused, or altered, it is impossible for us to
        guarantee 100% security of the information. By using the site, you agree to hold harmless
        and indemnify Aspen Digital from accidental loss or dissemination of information. We may
        offer links to other websites as part of our services. Personal information that you provide
        directly to such sites may constitute privacy issues outside the scope of the Aspen Digital
        Privacy Policies. Aspen Digital does not exercise control over linked sites and does not
        collect information as a result of your contact with those sites and shall not in any way be
        responsible for the collection, use, handling or storage of such data. Nothing in this
        Policy shall prejudice the data protection rights entitled by you in accordance with the
        applicable laws.
        <div className={styles.conTitle1}>Links To Third Party Websites</div>
        Our websites may contain links to other sites and pages which are operated by third parties.
        We have no control over the content of such websites or pages or the way in which the
        operators of those websites deal with your personal data. You should review the privacy
        policy for those third party websites to understand the ways in which your personal data may
        be used by those third parties.
        <div className={styles.conTitle1}>Access and Correction of Personal Data</div>
        Subject to the exceptions provided in laws and regulation, you have the right to access,
        review and request a physical or electronic copy of information held about you and also to
        also request deletion, correction or revision of your personal data. You may exercise such
        right by contacting us at support@aspendigital.co. Any such request should clearly state the
        details of the personal data in respect of which the request is being made. We may require
        further proof from you to verify the authenticity and validity of your request before the
        same can be processed. Please note that, in certain circumstances, we will not be able to
        delete your personal data without also deleting your user account. We may be required to
        retain some of your personal data after you have requested deletion, to satisfy our legal or
        contractual obligations.
        <div className={styles.conTitle1}>Direct Marketing</div>
        Aspen Digital may use your personal or business data (including name, contact information,
        and information about the products and services that you have purchased or subscribed to) to
        deliver, through various communication channels by using email address, correspondence
        address, mobile phone number, telephone number, service number and service account number,
        etc., various promotion materials including Aspen Digital’s latest offers, gifts, discounts,
        benefits, information relating to the Aspen Digital Services. For the avoidance of doubt,
        this consent can be withdrawn at any time as per this Privacy Policy without reference to
        the duration of the services, and will survive the termination or expiration or termination
        of customer’s engagement of Aspen Digital Services. You may at any time send request to
        support@aspendigital.co together with your contact details to stop receiving the promotion
        materials aforesaid or to resume receiving the same if you have unsubscribed from receiving
        such materials before. Compliance with this Policy Aspen Digital will regularly monitor and
        review its compliance with this Policy. Please feel free to direct any questions or concerns
        regarding this Policy or Aspen Digital’s use of information by contacting us by email or via
        our website. Upon receipt of such complaints, it is Aspen Digital’s policy to contact the
        complaining user regarding his or her concerns. We will cooperate with the appropriate
        regulatory authorities, including data protection authorities, to resolve any complaints
        regarding the transfer of data that cannot be resolved between Aspen Digital and an
        individual.
        <div className={styles.conTitle1}>Changes to this Policy</div>
        Please note that Aspen Digital reserves the right to change this Privacy Policy from time to
        time without notifying you or seeking your specific consent or approval. Any changes will
        become effective upon being made available on Aspen Digital’s website. By continuing to use
        Aspen Digital Services after the changes come into effect, you agree to be bound by the
        policy as revised from time to time.
      </div>
    </div>
  );
};

export default PrivacyPolicy;
