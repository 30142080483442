import React, { useState, useEffect, Suspense } from "react";
import { Intl, IkycInfo } from "@/model/index";
import { Spin, Breadcrumb, Select, Input, Button, Modal, message } from "antd";
import { Link } from "react-router-dom";
import styles from "./Wallet.module.less";
import { getBankList, addBankAccount, refillBankAccount } from "@/services/request";
import KYCInfoLayout from "@/components/withKycInfo/WithKYCInfo";
import Bugsnag from "@bugsnag/browser";
import { formatBugsnagMessage } from "@/utils";

const { Option } = Select;

interface IProps {
  kycInfo: IkycInfo;
}

const UploadFile = React.lazy(
  () => import(/* webpackChunkName: "UploadFile" */ "@/components/uploadFile/UploadFile")
);

export function DepositUSDC(props: IProps) {
  const intl: Intl = window.__intl__.messages;
  const title_text: string = intl["wallet.circle.add.title"];
  const btnOk_text: string = intl["button.ok"];
  const btnSubmit_text: string = intl["button.submit"];
  const notice_text: string = intl["wallet.circle.notice"];
  const location_text: string = intl["kyc.certify.location"];
  const wallet_text: string = intl["wallet"];
  const bankInformation_text: string = intl["wallet.circle.bank.information"];
  const bankName_text: string = intl["wallet.circle.bank.name"];
  const swiftCode_text: string = intl["wallet.circle.swift"];
  const country_text: string = intl["wallet.circle.country"];
  const city_text: string = intl["wallet.circle.city"];
  const bankAddress_text: string = intl["wallet.circle.bank.address"];
  const bankAccount_text: string = intl["wallet.circle.bank.account"];
  const accountInformation_text: string = intl["wallet.circle.account.information"];
  const fullName_text: string = intl["wallet.circle.name"];
  const postalCode_text: string = intl["wallet.circle.postal"];
  const address_text: string = intl["wallet.circle.address"];
  const bankStatements_text: string = intl["wallet.circle.bank.statements"];
  const uploadSgin_text: string = intl["wallet.circle.upload.sgin"];
  const uploadTips_text = intl["wallet.circle.upload.tips"];
  const noticeContent_text = intl["wallet.circle.add.notice.content"];
  const modalTitle_text: string = intl["wallet.circle.add.modal.title"];
  const modalContent_text: string = intl["wallet.circle.add.modal.content"];

  const [loading, setLoading] = useState<boolean>(false);
  const [refill, setRefill] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(true);
  const [bankList, setBankList] = useState([]);
  const [currentBank, setCurrentBank] = useState<any>({});
  const [bankAccount, setBankAccount] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [userRegion, setUserRegion] = useState<string>(""); //目前接口没有字段
  const [userCity, setUserCity] = useState<string>("");
  const [userPostalCode, setUserPostalCode] = useState<string>("");
  const [userAddress, setUserAddress] = useState<string>("");
  // const [userAddressSec, setUserAddressSec] = useState<string>(""); //备用用户地址 ，可不填
  const [uploadInfo, setUploadInfo] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);

  // 回显银行信息
  const refillInfo =
    window?.__history__?.location?.state?.info ||
    JSON.parse(localStorage.getItem("refillBankInfo"));

  useEffect(() => {
    getInitData();
  }, []);

  // 初始化数据,
  const getInitData: () => void = async () => {
    setLoading(true);
    await getBankList().then((res) => {
      if (res?.code == 0) {
        setBankList(res?.data);
        if (refillInfo) {
          const index = res?.data.findIndex((e) => e.name == refillInfo.bankName);
          setCurrentBank(index !== -1 ? res?.data[index] : null); // 回显银行信息
        }
      }
      setLoading(false);
    });
    setUserName(props.kycInfo?.name);
    setUserRegion(props.kycInfo?.region);
    if (refillInfo) updateRefillInfo();
  };

  // 设置回显银行信息
  const updateRefillInfo = () => {
    setRefill(true);
    setBankAccount(refillInfo.accountNumber); // 回显银行账户
    setUploadInfo(refillInfo.proof);
    setUserCity(refillInfo.customerCity);
    setUserPostalCode(refillInfo.customerPostal);
    setUserAddress(refillInfo.customerStreet);
  };

  const handleChangeBank = (e) => {
    setCurrentBank(bankList[e]);
  };
  const handleChangeBnakAccount = (e) => {
    setBankAccount(e.target.value);
  };
  const handleChangeUserCity = (e) => {
    setUserCity(e.target.value);
  };
  const handleChangeUserPostalCode = (e) => {
    setUserPostalCode(e.target.value);
  };
  const handleChangeUserAddress = (e) => {
    setUserAddress(e.target.value);
  };

  // const handleChangeUserAddressSec = (e) => {
  //   setUserAddressSec(e.target.value);
  // };

  //获取上传凭证url
  const getTransfer = (value: string) => {
    let info: string = "";
    if (value) {
      info = value;
    }
    setUploadInfo(info);
    // 保存上传信息
    handleSaveUserInfo(null, info);
  };

  // 判断
  useEffect(() => {
    checkSubmitInfo();
  }, [currentBank, bankAccount, userCity, userPostalCode, userAddress, uploadInfo]);

  // 按钮是否可提交
  const checkSubmitInfo = () => {
    const test =
      currentBank?.name &&
      currentBank?.swiftCode &&
      currentBank?.country &&
      currentBank?.city &&
      currentBank?.address &&
      bankAccount &&
      userCity &&
      userPostalCode &&
      userAddress &&
      uploadInfo &&
      true;
    if (test) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleClickSubmit = () => {
    setLoading(true);
    //用户如果输入0000、00000、000000，后端转存为999077 邮编
    let code = userPostalCode;
    if (code == "0000" || code == "00000" || code == "000000") {
      code = "999077";
    }
    const params = {
      type: 1,
      usage: 1,
      bankName: currentBank?.name,
      swiftCode: currentBank?.swiftCode,
      country: currentBank?.country,
      city: currentBank?.city,
      street: currentBank?.address,
      accountNo: bankAccount,
      userName: userName,
      countryCode: "HK",
      customerCity: userCity,
      customerPostal: code,
      customerStreet: userAddress,
      proof: uploadInfo
    };
    if (refill) {
      refillBankAccount(params).then((res) => {
        if (res?.code == 0) {
          message.success(intl["system.successful"]);
          window.__history__.push("/wallet");
        } else {
          setLoading(false);
          Bugsnag.notify(formatBugsnagMessage(JSON.stringify(res, null, "\t"), "Action-Add-Bank"));
        }
      });
    } else {
      addBankAccount(params).then((res) => {
        if (res?.code == 0) {
          message.success(intl["system.successful"]);
          window.__history__.push("/wallet");
        } else {
          setLoading(false);
          Bugsnag.notify(formatBugsnagMessage(JSON.stringify(res, null, "\t"), "Action-Add-Bank"));
        }
      });
    }
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  // 当数据失去焦点时，保存用户填写的信息
  const handleSaveUserInfo = (e, upload?: string) => {
    const refillInfo = {
      accountNumber: bankAccount,
      proof: upload !== undefined ? upload : uploadInfo,
      customerCity: userCity,
      customerPostal: userPostalCode,
      customerStreet: userAddress,
      bankName: currentBank?.name
    };
    localStorage.setItem("refillBankInfo", JSON.stringify(refillInfo));
  };

  return (
    <section className={styles.depositCircle}>
      <Spin spinning={loading}>
        <Breadcrumb>
          <Breadcrumb.Item>{location_text}:</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/wallet">{wallet_text}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{title_text}</Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.circleContent}>
          <p className={styles.title}>{title_text}</p>
          <div className={styles.addContent}>
            <p className={styles.title}>{bankInformation_text}</p>
            <div className={styles.infoCotnet}>
              <div className={styles.itemsContent}>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{bankName_text}</p>
                  {bankList?.length > 0 && (
                    <Select
                      style={{ width: "100%" }}
                      onChange={handleChangeBank}
                      onBlur={handleSaveUserInfo}
                      defaultValue={
                        refillInfo?.bankName
                          ? bankList?.findIndex((bank) => bank.name === refillInfo.bankName)
                          : null
                      }
                      placeholder={intl["placeholder.select"]}
                      size="large">
                      {bankList.map((item, index) => (
                        <Option key={index} value={index}>
                          {`${item.name} ${item.bankCode ? `(${item.bankCode})` : ""}`}
                        </Option>
                      ))}
                    </Select>
                  )}
                </div>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{swiftCode_text}</p>
                  <p className={styles.itemArea}>{currentBank?.swiftCode}</p>
                </div>
              </div>
              <div className={styles.itemsContent}>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{country_text}</p>
                  <p className={styles.itemArea}>{currentBank?.country}</p>
                </div>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{city_text}</p>
                  <p className={styles.itemArea}>{currentBank?.city}</p>
                </div>
              </div>
              <div className={styles.itemsContent}>
                <div className={styles.itemContent} style={{ width: "100%" }}>
                  <p className={styles.itemTitle}>{bankAddress_text}</p>
                  <p className={styles.itemArea}>{currentBank?.address}</p>
                </div>
              </div>
              <div className={styles.itemsContent}>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{bankAccount_text}</p>
                  <Input
                    placeholder={intl["placeholder.enter"]}
                    value={bankAccount}
                    maxLength={100}
                    onChange={handleChangeBnakAccount}
                    onBlur={handleSaveUserInfo}
                  />
                </div>
              </div>
            </div>
            <p className={styles.title}>{accountInformation_text}</p>
            <div className={styles.infoCotnet}>
              <div className={styles.itemsContent}>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{fullName_text}</p>
                  <p className={styles.itemArea}>{userName}</p>
                </div>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{country_text}</p>
                  <p className={styles.itemArea}>{userRegion}</p>
                </div>
              </div>
              <div className={styles.itemsContent}>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{city_text}</p>
                  <Input
                    placeholder={intl["placeholder.enter"]}
                    value={userCity}
                    maxLength={30}
                    onChange={handleChangeUserCity}
                    onBlur={handleSaveUserInfo}
                  />
                </div>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{postalCode_text}</p>
                  <Input
                    placeholder={intl["placeholder.hk.postal.code"]}
                    maxLength={16}
                    value={userPostalCode}
                    onChange={handleChangeUserPostalCode}
                    onBlur={handleSaveUserInfo}
                  />
                </div>
              </div>
              <div className={styles.itemsContent}>
                <div className={styles.itemContent} style={{ width: "100%" }}>
                  <p className={styles.itemTitle}>{address_text}</p>
                  <Input
                    style={{ marginBottom: 10 }}
                    maxLength={140}
                    value={userAddress}
                    placeholder={intl["placeholder.billing.address"]}
                    onChange={handleChangeUserAddress}
                    onBlur={handleSaveUserInfo}
                  />
                  {/* <Input
                    placeholder={intl["placeholder.enter"]}
                    maxLength={35}
                    onChange={handleChangeUserAddressSec}
                  /> */}
                </div>
              </div>
            </div>
            <p className={styles.title}>{bankStatements_text}</p>
            <div className={styles.uploadContent}>
              <div className={styles.uploadContainer}>
                <Suspense
                  fallback={
                    <div className={styles.uploadContainer + " middleAlign"}>
                      <Spin />
                    </div>
                  }>
                  {refillInfo?.proof ? (
                    <UploadFile
                      echo={refillInfo.proof}
                      type="bankstatements"
                      text={uploadSgin_text}
                      getUploadInfo={getTransfer}
                    />
                  ) : (
                    <UploadFile
                      type="bankstatements"
                      text={uploadSgin_text}
                      getUploadInfo={getTransfer}
                    />
                  )}
                </Suspense>
              </div>
              <div className={styles.rightArea}>
                <p style={{ marginBottom: 15, color: "#fff" }}>{uploadSgin_text}</p>
                {uploadTips_text.map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
            </div>
            <p className={styles.title}>{notice_text}</p>
            {noticeContent_text.map((item, index) => {
              return (
                <p style={{ color: "rgba(255,255,255,0.7)" }} key={index}>
                  {item}
                </p>
              );
            })}
            <Button
              type="primary"
              size="large"
              disabled={disabled}
              onClick={handleClickSubmit}
              style={{ width: 128, marginTop: 40 }}>
              {btnSubmit_text}
            </Button>
          </div>
        </div>
        <Modal
          visible={showModal}
          title={modalTitle_text}
          closable={false}
          width={480}
          wrapClassName={styles.modal}
          onCancel={handleShowModal}
          footer={[
            <Button key="ok" type="primary" onClick={handleShowModal}>
              {btnOk_text}
            </Button>
          ]}>
          <p className={styles.content}>{modalContent_text}</p>
        </Modal>
      </Spin>
    </section>
  );
}

export default KYCInfoLayout(DepositUSDC);
