import React from "react";

import styles from "./Clients.module.less";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Row, Col, Input, Button, Tooltip, Table, message, Spin } from "antd";
import WithFiatAndSpotTrade from "@/components/withFiatAndSpotTrade/WithFiatAndSpotTrade";
import { getManageCustomerInfo, managerOperate, cancelManagerOperate } from "@/services/request";
interface IProps {
  registerWS?: () => void;
}
interface IState {
  dataSource: any[];
  loading: boolean;
  id: string;
}

class CustomerManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dataSource: [],
      loading: false,
      id: ""
    };
  }
  componentDidMount() {
    this.initData();
  }

  initData = () => {
    //如果存在代理删除
    if (localStorage.getItem(`${location.origin}agentOperatingToken`)) {
      cancelManagerOperate();
      localStorage.removeItem(`${location.origin}agentOperatingToken`);
      localStorage.removeItem("operateCustomerEmail");
      localStorage.setItem("user_auth", "manager");
    }
  };
  //客户信息
  fetchAgentInfo = () => {
    this.setState({ loading: true });
    const intl = window.__intl__.messages;
    let param = {
      customerId: this.state.id
    };
    getManageCustomerInfo(param)
      .then((res) => {
        if (res?.code == "0") {
          this.setState({
            dataSource: [res.data]
          });
        } else {
          this.setState({
            dataSource: []
          });
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  getColumns: () => any[] = () => {
    const intl = window.__intl__.messages;
    const columns = [
      {
        title: intl["column.name"],
        ellipsis: true,
        dataIndex: "userName"
      },
      {
        title: intl["column.email.address"],
        dataIndex: "email"
      },
      {
        title: intl["column.action"],
        dataIndex: "roleApplyStatus",
        render: (value, record) => (
          <span
            style={{
              cursor: "pointer",
              color: "#F17E00"
            }}
            onClick={() => {
              this.handleManage(record);
            }}>
            {intl["button.manage"]}
          </span>
        )
      }
    ];
    return columns;
  };

  handleManage = (record) => {
    const intl = window.__intl__.messages;
    const params = {
      customerId: record.id
    };
    window.ws = null;
    this.setState({ loading: true });
    managerOperate(params).then((res) => {
      if (res?.code == "0") {
        localStorage.setItem(`${location.origin}agentOperatingToken`, res.data?.jwtToken || "");
        localStorage.setItem("user_auth", "managercustomer");
        localStorage.setItem("operateCustomerEmail", record.email);
        this.props.registerWS();
        window.__history__.push("/trade");
      } else {
        this.setState({ loading: false });
        message.destroy();
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
      this.setState({ loading: false });
    });
  };
  handleChangeId = (e) => {
    this.setState({
      id: e.target.value
    });
  };

  render(): React.ReactNode {
    const intl = window.__intl__.messages;
    const columns = this.getColumns();
    const { dataSource, loading, id } = this.state;
    return (
      <section className={styles.customerManagment + " " + "customer"}>
        <div className="customerTop">
          <Row className={"top " + styles.top}>
            <Col span={24}>
              <p className="title">{intl["clients.title"]}</p>
            </Col>
          </Row>
        </div>
        <Spin spinning={loading}>
          <div className="section">
            <div className={styles.module} style={{ height: 175 }}>
              <div className={styles.title}>
                <span>{intl["button.search"]}</span>
                <Tooltip title={intl["clients.manager.search.tips"]}>
                  <InfoCircleOutlined
                    style={{ paddingLeft: "5px", cursor: "pointer", fontSize: 14 }}
                  />
                </Tooltip>
              </div>
              <div className={styles.divider} />
              <div className={styles.search}>
                <span>{intl["clients.customer.id"]}</span>
                <Input
                  className={styles.input}
                  maxLength={140}
                  size="middle"
                  onChange={this.handleChangeId}
                />
                <Button
                  type="primary"
                  disabled={id == ""}
                  className={styles.btn}
                  onClick={this.fetchAgentInfo}>
                  {intl["button.search"]}
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.moduleList} style={{ marginTop: 24, height: 300 }}>
            <div className={styles.clientList}>
              <p className={styles.title}>{intl["clients.customer.list"]}</p>
              <Table
                locale={{ emptyText: intl["clients.customer.tips"] }}
                bordered={false}
                rowKey={(record) => record?.id}
                pagination={false}
                dataSource={dataSource}
                columns={columns}
                rowClassName={styles.sales}
              />
            </div>
          </div>
        </Spin>
      </section>
    );
  }
}

export default WithFiatAndSpotTrade(CustomerManagement);
