import React, { Suspense } from "react";
import { Intl, IInstitutionsForm, IIndividualsForm } from "@/model/index";
import styles from "@/views/profile/Profile.module.less";
import { Alert, Breadcrumb, Steps, message, Spin } from "antd";
import { Link } from "react-router-dom";
import { userCountriesData, phonePrefixs } from "@/assets/offlineData/usersCountries";
import { getQueryValue } from "@/utils/index";
import moment from "moment";

export const KYCContext = React.createContext({ initInfo: {}, auditStatus: "" });

const Institutions = React.lazy(
  () => import(/* webpackChunkName: "Institutions" */ "./components/Institutions")
);

const Individuals = React.lazy(
  () => import(/* webpackChunkName: "Individuals" */ "./components/Individuals")
);

const ConfirmInfomation = React.lazy(
  () => import(/* webpackChunkName: "ConfirmInfomation" */ "./components/ConfirmInfomation")
);

const DetailKYCInfo = React.lazy(
  () => import(/* webpackChunkName: "DetailKYCInfo" */ "./components/DetailKYCInfo")
);

const { Step } = Steps;

interface IProps {}
interface IState {
  invitationCode: string;
  imageUrl: string;
  loading: boolean;
  currentStep: number;
  institutionsForm: IInstitutionsForm;
  individualsForm: IIndividualsForm;
  certifyType: string;
  institutionsConfirm: string[];
  individualsConfirm: string[];
  initInfo: any;
  id: string;
  auditStatus: string;
  phonePrefix: string;
  telephoneCode: string;
  institutionsDataShow: IInstitutionsForm | IIndividualsForm;
  showCmp: boolean;
}

class KycCertify extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const {
      // 公共字段
      name,
      contactAddress,
      totalAssets,
      email,
      phone,
      liquidAssets,
      region,
      // 机构kyc 私有字段
      businessNature,
      brNumber,
      companyType,
      sourceOfFund,
      fund,
      representativeTitle,
      representativeName,
      certifyType,
      // 个人kyc 私有字段
      fullName,
      age,
      gender,
      birth,
      nationality,
      cardId,
      contact,
      address,
      annualIncome,
      passportId,
      sourceIncome
    } = this.getText();
    const auditStatus = getQueryValue("auditStatus");
    this.state = {
      certifyType: certifyType,
      phonePrefix: "",
      telephoneCode: "",
      invitationCode: "",
      imageUrl: "",
      loading: false,
      auditStatus: auditStatus,
      currentStep: auditStatus && auditStatus != "rejected" ? 2 : 0,
      individualsForm: {
        name: {
          label: fullName,
          value: ""
        },
        age: {
          label: age,
          value: ""
        },
        gender: {
          label: gender,
          value: ""
        },
        birthday: {
          label: birth,
          value: moment(new Date())
        },
        annualIncome: {
          label: annualIncome,
          value: ""
        },
        sourceOfIncome: {
          label: sourceIncome,
          value: ""
        },
        sourceOfFund: {
          label: sourceOfFund,
          value: ""
        },
        region: {
          label: nationality,
          value: ""
        },
        passportId: {
          label: passportId,
          value: ""
        },
        contactAddress: {
          label: address,
          value: ""
        },
        liquidAssets: {
          label: liquidAssets,
          value: ""
        },
        totalAssets: {
          label: totalAssets,
          value: ""
        },
        phone: {
          label: phone,
          value: ""
        },
        email: {
          label: "Email",
          value: localStorage.getItem("user")
        }
      },
      id: "",
      initInfo: window?.__history__?.location?.state?.info ?? {},
      individualsConfirm: [
        "name",
        "age",
        "gender",
        "birthday",
        "region",
        "passportId",
        "contactAddress",
        "phone",
        "email",
        "annualIncome",
        "sourceOfIncome",
        "sourceOfFund",
        "liquidAssets",
        "totalAssets"
      ],
      institutionsConfirm: [
        "name",
        "businessNature",
        "brNumber",
        "region",
        "contactAddress",
        "companyType",
        "representativeName",
        "representativeTitle",
        "phone",
        "email",
        "sourceOfFund",
        "liquidAssets",
        "totalAssets"
      ],
      institutionsForm: {
        name: {
          label: name,
          value: "1"
        },
        businessNature: {
          label: businessNature,
          value: "1"
        },
        brNumber: {
          label: brNumber,
          value: "1"
        },
        region: {
          label: region,
          value: "1"
        },
        contactAddress: {
          label: contactAddress,
          value: ""
        },
        companyType: {
          label: companyType,
          value: ""
        },
        sourceOfFund: {
          label: sourceOfFund,
          value: ""
        },
        liquidAssets: {
          label: liquidAssets,
          value: ""
        },
        totalAssets: {
          label: totalAssets,
          value: ""
        },
        representativeName: {
          label: representativeName,
          value: ""
        },
        representativeTitle: {
          label: representativeTitle,
          value: ""
        },
        phone: {
          label: phone,
          value: ""
        },
        email: {
          label: email,
          value: ""
        }
      },
      institutionsDataShow: {
        name: {
          label: name,
          value: "1"
        },
        businessNature: {
          label: businessNature,
          value: "1"
        },
        brNumber: {
          label: brNumber,
          value: "1"
        },
        region: {
          label: region,
          value: "1"
        },
        contactAddress: {
          label: contactAddress,
          value: ""
        },
        companyType: {
          label: companyType,
          value: ""
        },
        sourceOfFund: {
          label: sourceOfFund,
          value: ""
        },
        liquidAssets: {
          label: liquidAssets,
          value: ""
        },
        totalAssets: {
          label: totalAssets,
          value: ""
        },
        representativeName: {
          label: representativeName,
          value: ""
        },
        representativeTitle: {
          label: representativeTitle,
          value: ""
        },
        phone: {
          label: phone,
          value: ""
        },
        email: {
          label: email,
          value: localStorage.getItem("user")
        }
      },
      showCmp: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.clickOnNextStep = this.clickOnNextStep.bind(this);
    this.getPhonePrefix = this.getPhonePrefix.bind(this);
    this.clickOnBack = this.clickOnBack.bind(this);
    this.updateFormData = this.updateFormData.bind(this);
  }

  render(): React.ReactNode {
    const {
      location,
      profile,
      information,
      certifyTitle,
      step1,
      step2,
      step3,
      tips,
      confirmTips,
      entityTypes,
      fund,
      fundTypes,
      tipsBusinessNature,
      taxIdentificationNumberTips,
      authorizedRepresentative,
      btnStep1,
      contact,
      basicInfo,
      individualFundTypes
    } = this.getText();

    const institutionsTexts = {
      entityTypes,
      fund,
      fundTypes,
      tipsBusinessNature,
      taxIdentificationNumberTips,
      authorizedRepresentative,
      btnStep1
    };

    const individualsTexts = {
      basicInfo,
      fund,
      contact,
      btnStep1,
      fundTypes: individualFundTypes
    };

    const {
      institutionsForm,
      individualsForm,
      certifyType,
      currentStep,
      institutionsConfirm,
      individualsConfirm,
      phonePrefix,
      telephoneCode,
      institutionsDataShow,
      showCmp
    } = this.state;
    return (
      <section className={styles.kycCertify}>
        <Breadcrumb>
          <Breadcrumb.Item>{location}:</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/profile">{profile}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{information}</Breadcrumb.Item>
        </Breadcrumb>
        <p className={styles.certifyTitle}>{certifyTitle}</p>
        <div className={styles.certifyContent}>
          <Steps current={currentStep}>
            <Step title={step1} description="" />
            <Step title={step2} description="" />
            <Step title={step3} description="" />
          </Steps>
          {currentStep == 0 && (
            <>
              {/* <Alert message={tips} type="info" showIcon closable /> */}
              {certifyType == "institutions" && showCmp && (
                <KYCContext.Provider
                  value={{
                    initInfo: this.state.initInfo,
                    auditStatus: this.state.auditStatus
                  }}>
                  <Suspense
                    fallback={
                      <div className={styles.individal + " middleAlign"}>
                        <Spin />
                      </div>
                    }>
                    <Institutions
                      formConfig={institutionsForm}
                      texts={institutionsTexts}
                      clickOnNextStep={this.clickOnNextStep}
                      userCountriesData={userCountriesData}
                      phonePrefixs={phonePrefixs}
                      telephoneCode={telephoneCode}
                      phonePrefix={phonePrefix}
                    />
                  </Suspense>
                </KYCContext.Provider>
              )}
              {certifyType == "individuals" && showCmp && (
                <KYCContext.Provider
                  value={{
                    initInfo: this.state.initInfo,
                    auditStatus: this.state.auditStatus
                  }}>
                  <Suspense
                    fallback={
                      <div className={styles.individal + " middleAlign"}>
                        <Spin />
                      </div>
                    }>
                    <Individuals
                      formConfig={individualsForm}
                      texts={individualsTexts}
                      clickOnNextStep={this.clickOnNextStep}
                      userCountriesData={userCountriesData}
                      phonePrefixs={phonePrefixs}
                      telephoneCode={telephoneCode}
                      phonePrefix={phonePrefix}
                    />
                  </Suspense>
                </KYCContext.Provider>
              )}
            </>
          )}

          {currentStep == 1 && (
            <>
              <Alert message={confirmTips} type="info" showIcon closable />

              <Suspense fallback={<div className={"middleAlign"} />}>
                <ConfirmInfomation
                  formConfig={certifyType == "institutions" ? institutionsForm : individualsForm}
                  clickOnNextStep={this.clickOnNextStep}
                  id={this.state.id}
                  certifyType={certifyType}
                  clickOnBack={this.clickOnBack}
                  formArray={
                    certifyType == "institutions" ? institutionsConfirm : individualsConfirm
                  }
                  institutionsDataShow={certifyType == "institutions" && institutionsDataShow}
                />
              </Suspense>
            </>
          )}

          {currentStep == 2 && (
            <Suspense fallback={<div className={"middleAlign"} />}>
              <KYCContext.Provider
                value={{
                  initInfo: this.state.initInfo,
                  auditStatus: this.state.auditStatus
                }}>
                <DetailKYCInfo
                  formConfig={certifyType == "institutions" ? institutionsForm : individualsForm}
                  formArray={
                    certifyType == "institutions" ? institutionsConfirm : individualsConfirm
                  }
                  institutionsDataShow={certifyType == "institutions" && institutionsDataShow}
                />
              </KYCContext.Provider>
            </Suspense>
          )}
        </div>
      </section>
    );
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>, key: string): void {
    let data = {};
    data[key] = e.target.value;
    this.setState(data);
  }

  getText(): { [propName: string]: any } {
    const intl: Intl = window.__intl__.messages;
    const location: string = intl["kyc.certify.location"];
    const profile: string = intl["profile"];
    const information: string = intl["kyc.information"];
    const certifyType = getQueryValue("type") || "institutions";
    const kycType = {
      institutions: intl["kyc.institutions"],
      individuals: intl["kyc.individuals"]
    };
    const certifyTitle = `${kycType[certifyType]} ${intl["kyc.certify"]}`;
    const step1 = intl["kyc.step1"];
    const step2 = intl["kyc.step2"];
    const step3 = intl["kyc.step3"];
    const tips = intl["kyc.certify.tips"];
    const confirmTips = intl["kyc.certify.comfirm.tips"];
    const name = intl["kyc.certify.institutions.name"];
    const businessNature = intl["kyc.certify.institutions.business.nature"];
    const tipsBusinessNature = intl["kyc.certify.institutions.business.nature.tips"];
    const brNumber = intl["kyc.certify.institutions.taxIdentificationNumber"];
    const region = intl["kyc.certify.institutions.CountryIncorporation"];
    const contactAddress = intl["kyc.certify.institutions.address"];
    const companyType = intl["kyc.certify.institutions.entity.type"];
    const fund = intl["kyc.certify.institutions.fund"];
    const fundTypes = intl["kyc.certify.institutions.fundTypes"];
    const sourceOfFund = fundTypes[0].label;
    const liquidAssets = fundTypes[1].label;
    const totalAssets = fundTypes[2].label;
    const entityTypes = intl["kyc.certify.institutions.entityTypes"];
    const authorizedRepresentative = intl["kyc.certify.institutions.authorizedRepresentative"];
    const representativeName = intl["kyc.certify.institutions.authorizedName"];
    const representativeTitle = intl["kyc.certify.institutions.authorizedTitle"];
    const phone = intl["kyc.certify.institutions.authorizedPhone"];
    const email = intl["kyc.certify.institutions.authorizedEmail"];
    const taxIdentificationNumberTips =
      intl["kyc.certify.institutions.taxIdentificationNumberTips"];
    const liquidAssetsTips = intl["kyc.certify.institutions.liquidAssetsTips"];
    const totalAssetsTips = intl["kyc.certify.institutions.totalAssetsTips"];
    const btnStep1 = intl["button.next.step"];

    const basicInfo = intl["kyc.basic.info"];
    const fullName = intl["kyc.individual.fullname"];
    const age = intl["kyc.individual.age"];
    const gender = intl["kyc.individual.gender"];
    const birth = intl["kyc.individual.birth"];
    const nationality = intl["kyc.individual.nationality"];
    const cardId = intl["kyc.individual.cardId"];
    const contact = intl["kyc.individual.contact"];
    const address = intl["kyc.individual.address"];
    const individualFundTypes = intl["kyc.individual.funds"];
    const annualIncome = individualFundTypes[0].label;
    const sourceIncome = individualFundTypes[1].label;

    const passportId = intl["kyc.individual.passportId"];

    return {
      basicInfo,
      fullName,
      annualIncome,
      age,
      gender,
      birth,
      nationality,
      cardId,
      contact,
      address,
      individualFundTypes,

      certifyType,
      liquidAssetsTips,
      totalAssetsTips,
      location,
      profile,
      information,
      certifyTitle,
      step1,
      step2,
      step3,
      tips,
      confirmTips,
      name,
      businessNature,
      brNumber,
      region,
      contactAddress,
      companyType,
      entityTypes,
      fund,
      fundTypes,
      sourceIncome,
      sourceOfFund,
      liquidAssets,
      totalAssets,
      email,
      phone,
      representativeTitle,
      representativeName,
      authorizedRepresentative,
      taxIdentificationNumberTips,
      tipsBusinessNature,
      btnStep1,
      passportId
    };
  }

  clickOnBack(step: number) {
    this.setState({
      currentStep: step
    });
  }

  clickOnNextStep(
    currentStep: number,
    formData?: IInstitutionsForm | IIndividualsForm,
    institutionsDataShow?: IInstitutionsForm
  ) {
    const initInfo = JSON.parse(JSON.stringify(this.state.initInfo));
    if (formData) {
      let newData = {};
      newData[`${this.state.certifyType}Form`] = formData;
      this.setState(newData);
      Object.keys(formData).map((key) => {
        initInfo[key] = formData[key].value;
      });
      this.getPhonePrefix(initInfo);
    }
    if (institutionsDataShow) {
      this.setState({
        currentStep: currentStep,
        initInfo: initInfo,
        institutionsDataShow: institutionsDataShow
      });
      return;
    }
    this.setState({
      currentStep: currentStep,
      initInfo: initInfo
    });
    //提交跳转个人中心
    if (currentStep === 2) {
      message.destroy();
      // todo， 移至国际化
      message.success("提交成功", 1, () => window.__history__.push("/profile"));
    }
  }

  getPhonePrefix(initInfo) {
    if (initInfo?.region) {
      // const prefixItem = userCountriesData.find(
      //   (item: ICountriesData) => item.commonName == initInfo.region
      // );
      const prefix = initInfo.phone.split(" ")[0];
      const telephoneCode = initInfo.phone.split(" ")[1];
      this.setState({
        phonePrefix: prefix,
        telephoneCode: telephoneCode && telephoneCode,
        showCmp: true
      });
    } else {
      this.setState({
        showCmp: true
      });
    }
  }
  updateFormData: () => void = () => {
    const formData = this.state[`${this.state.certifyType}Form`];
    if (window?.__history__?.location?.state?.info) {
      const initInfo = JSON.parse(JSON.stringify(this.state.initInfo));
      this.getPhonePrefix(initInfo);
      Object.keys(formData).map((key) => {
        if (initInfo[key] !== undefined) {
          if (key != "birthday") {
            formData[key].value = initInfo[key];
          } else {
            formData[key].value = moment(initInfo[key]);
            initInfo.birthday = moment(initInfo[key]);
          }
        }
      });
      if (this.state.certifyType == "institutions") {
        const mapList = JSON.parse(JSON.stringify(formData));
        // mapList.representativeName.value =
        //   mapList?.representativeName?.value + " " + mapList?.representativeTitle?.value;
        this.setState({
          institutionsForm: formData, // 机构
          institutionsDataShow: mapList
        });
      } else {
        this.setState({
          individualsForm: formData // 个人
        });
      }

      this.setState({
        initInfo: initInfo,
        id: window?.__history__?.location?.state?.info?.id
      });
    }
  };
  componentDidMount() {
    this.updateFormData();
  }
}

export default KycCertify;
