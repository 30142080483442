import { createSlice, PayloadAction, createReducer } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "@/store/store";

interface IIntelligence {
  dailyBriefing?: object;
  announcement?: object;
}

export interface IntelligenceState {
  dailyBriefing: object;
  announcement: object;
}

const initialState: IntelligenceState = {
  dailyBriefing: [],
  announcement: []
};

export const intelligenceSlice = createSlice({
  name: "intelligence",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // todo，严格参数限制
    updateIntelligence: (state, action: PayloadAction<IIntelligence>) => {
      const keys = Object.keys(action.payload);
      for (let key of keys) {
        if (state[key]) {
          state[key] = action.payload[key];
        } else {
          const _new = state[key];
          state[key] = _new;
        }
      }
    }
  }
});

export const { updateIntelligence } = intelligenceSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const currentDailyBriefing = (state: RootState) => state.intelligence.dailyBriefing;
export const currentAnnouncement = (state: RootState) => state.intelligence.announcement;

// exports a reducer function for the counter logic.
export default intelligenceSlice.reducer;
