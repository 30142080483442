import React, { Suspense } from "react";

import { Intl } from "@/model/index";
import { Col, Spin } from "antd";
import styles from "./Strategy.module.less";
import { getStrategiesList, getMock } from "@/services/request";
import { strategyListOffLine } from "@/assets/offlineData/strategyList";
const StrategyItem = React.lazy(
  () => import(/* webpackChunkName: "StrategyItem" */ "@/components/strategy/StrategyItem")
);

interface IProps {}

interface IState {
  strategyList: any[];
  loading: boolean;
}
class Strategy extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      strategyList: [],
      loading: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    getMock()
      .then((response) => {
        let strategyList = response?.strategyList || strategyListOffLine;
        getStrategiesList()
          .then((res) => {
            if (res?.code == 0) {
              res.data?.map((item) => {
                const itemIndex = strategyList?.findIndex((e) => e.code == item.code);
                if (itemIndex >= 0) {
                  strategyList[itemIndex].id = item.id;
                }
              });
              this.setState({ strategyList: [...strategyList] });
            }
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }
  render(): React.ReactNode {
    const { strategyList, loading } = this.state;
    const intl: Intl = window.__intl__.messages;
    const title: string = intl["strategies.title"];
    return (
      <div className={styles.savings + " " + "customer"}>
        <div className="customerTop">
          <div className="top">
            <Col span={12}>
              <div>
                <p className="title">{title}</p>
              </div>
            </Col>
          </div>
        </div>
        <Spin spinning={loading}>
          <Suspense
            fallback={
              <div className={styles.strategy + " middleAlign"}>
                <Spin />
              </div>
            }>
            <div className="section">
              <div className={styles.strategy} style={{ marginTop: "34px" }}>
                <div className={styles.strategyList}>
                  {strategyList.map((item, index) => (
                    <div className={styles.strategyListItem} key={index}>
                      <StrategyItem key={item.id} data={item} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Suspense>
        </Spin>
      </div>
    );
  }
}
export default Strategy;
