import React, { Suspense } from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { Intl } from "@/model/index";

const History = React.lazy(() => import(/* webpackChunkName: "History" */ "./components/History"));

interface IProps {}
interface IState {}

class WalletHistory extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render(): React.ReactNode {
    const intl: Intl = window.__intl__.messages;
    const location: string = intl["kyc.certify.location"];
    const wallet: string = intl["wallet"];
    const history = intl["wallet.history"];
    const { type, currency, fiat } = window?.__history__?.location?.state;
    return (
      <>
        <Breadcrumb style={{ marginBottom: "24px" }}>
          <Breadcrumb.Item>{location}:</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/wallet">{wallet}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{history}</Breadcrumb.Item>
        </Breadcrumb>

        <Suspense fallback={<div className="middleAlign" />}>
          <History showViewAll={false} type={type} currency={currency} fiat={fiat} />
        </Suspense>
      </>
    );
  }
}
export default WalletHistory;
