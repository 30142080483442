import React, { Component, Suspense } from "react";
import { IkycInfo } from "@/model/index";
import { fecthKycInfo } from "@/services/request";
import { updateUser } from "@/slices/UserSlice";
import { connect } from "react-redux";
import { Spin } from "antd";

const ModalKycGuide = React.lazy(
  () => import(/* webpackChunkName: "ModalKycGuide" */ "@/components/modalKycGuide/ModalKycGuide")
);

interface IProps {
  kycInfo: IkycInfo;
  updateUser: ({ kycInfo: object }) => void;
  [key: string]: any; // todo @elaine
}

type IState = {
  auditStatus: number | string;
  loading: boolean;
  showModal: boolean;
  noClose: boolean;
  componentDidMount: boolean;
};

const mapStateToProps = (state) => {
  const { kycInfo } = state.user;
  return {
    kycInfo: kycInfo
  };
};
const mapDispatchToProps = () => {
  return {
    updateUser: (payload) => updateUser(payload)
  };
};

export default (WrappedComponent) => {
  class WithKYCInfo extends Component<IProps, IState> {
    constructor(props: IProps) {
      super(props);
      this.state = {
        componentDidMount: false,
        loading: true,
        showModal: false,
        auditStatus: localStorage.getItem("kycCertifiedStatus"),
        noClose: false
      };
      this.getKYCInfo = this.getKYCInfo.bind(this);
      this.hideModal = this.hideModal.bind(this);
      this.showGuideModal = this.showGuideModal.bind(this);
    }

    showGuideModal = (isClose?: boolean) => {
      this.setState({
        showModal: true,
        noClose: isClose ? true : false
      });
    };

    hideModal(): void {
      this.setState({
        showModal: false
      });
    }

    getKYCInfo: () => Promise<IkycInfo> = async () => {
      return fecthKycInfo()
        .then((res) => {
          if (res?.code == "0") {
            const kycInfo = res?.data != null ? res?.data : { auditStatus: "" };
            this.props.updateUser({ kycInfo: kycInfo }); // todo严格参数限制
            this.setState({
              auditStatus: kycInfo?.auditStatus ?? "",
              loading: false
            });
            localStorage.setItem("kycCertifiedStatus", kycInfo?.auditStatus);
            return kycInfo;
          } else {
            // message.error("获取失败KYC信息失败");
          }
        })
        .finally(() => {
          if (!this.state.componentDidMount) return;
          this.setState({
            loading: false
          });
        });
    };

    componentDidMount: () => void = async () => {
      this.setState({
        componentDidMount: true
      });
      // 除登陆页之外，其他需要获取kyc的地方在页面加载完成查看/更新kyc状态
      if (window.location.pathname != "/login") {
        if (this.props?.kycInfo?.auditStatus != 1) {
          this.getKYCInfo();
        } else {
          this.setState({
            auditStatus: 1
          });
          localStorage.setItem("kycCertifiedStatus", "1");
        }
      }
    };

    componentWillUnmount() {
      this.setState({
        componentDidMount: false
      });
      this.setState = (state, callback) => {
        return;
      };
    }

    render() {
      return (
        <>
          <WrappedComponent
            {...this.props}
            loading={this.state.loading}
            auditStatus={this.state.auditStatus}
            getKYCInfo={this.getKYCInfo}
            showGuideModal={this.showGuideModal}
          />
          <Suspense
            fallback={
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  paddingTop: "30%",
                  textAlign: "center"
                }}>
                <Spin />
              </div>
            }>
            <ModalKycGuide
              noClose={this.state.noClose}
              visible={this.state.showModal}
              handleCancel={this.hideModal}
            />
          </Suspense>
        </>
      );
    }
  }
  return connect(mapStateToProps, mapDispatchToProps())(WithKYCInfo);
};
