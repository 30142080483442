import React, { Suspense } from "react";
import { IkycInfo, Intl } from "@/model/index";
import styles from "./Profile.module.less";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { Row } from "antd";
import { fecthInviterInfo, applyCustomerRole, getWalletInfo } from "@/services/request";
import { Client_Authorization_Status } from "@/config/constants";
import KYCInfoLayout from "@/components/withKycInfo/WithKYCInfo";
import { getQueryValue } from "@/utils";
import ServiceAccount from "./components/ServiceAccount";

const KYCInfomations = React.lazy(
  () => import(/* webpackChunkName: "KYCInfomations" */ "./components/KYCInfomations")
);

const BasicInfo = React.lazy(
  () => import(/* webpackChunkName: "BasicInfo" */ "./components/BasicInfo")
);
const Referral = React.lazy(
  () => import(/* webpackChunkName: "Referral" */ "./components/Referral")
);
const SubAccount = React.lazy(
  () => import(/* webpackChunkName: "SubAccount" */ "./components/SubAccount")
);
const PartnerAccount = React.lazy(
  () => import(/* webpackChunkName: "SubAccount" */ "./components/PartnerAccount")
);
interface IProps {
  kycInfo: IkycInfo;
}
interface IState {
  userName: string;
  resetToken: string;
  showResetPasswordStep2: boolean;
  inviteInfo: {
    code: string;
    type: 0 | 1;
  };
  partnerInfo: any;
  walletInfo: any;
}

class Profile extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      inviteInfo: {
        code: "",
        type: 0
      },
      userName: "",
      resetToken: "",
      showResetPasswordStep2: false,
      partnerInfo: {},
      walletInfo: {}
    };
    this.handleClick = this.handleClick.bind(this);
    this.fetchCurrentInviterInfo = this.fetchCurrentInviterInfo.bind(this);
  }

  fetchCurrentInviterInfo(): void {
    fecthInviterInfo()
      .then((res) => {
        if (res?.code == "0") {
          this.setState({
            inviteInfo: res?.data ?? {}
          });
        } else {
          // ignore
        }
      })
      .finally(() => {});
  }
  getApplyPartnerRole = () => {
    applyCustomerRole().then((res) => {
      if (res?.code == 0) {
        this.setState({ partnerInfo: res?.data });
      }
    });
  };
  fetchWalletInfo = () => {
    getWalletInfo().then((res) => {
      if (res?.code == 0) {
        this.setState({ walletInfo: res?.data });
      }
    });
  };

  render(): React.ReactNode {
    const { partnerInfo, inviteInfo, walletInfo } = this.state;
    //TODO: 子组件懒加载未完成时不会掉转锚点，组件加载完成时调用，有更好方案时替换
    window.onload = () => {
      getQueryValue("anchorPoint") && this.scrollToAnchor(getQueryValue("anchorPoint"));
    };
    const intl: Intl = window.__intl__.messages;
    return (
      <section className={styles.profile + " " + "customer"}>
        <section className="customerTop">
          <div className={"top " + styles.profileTop}>
            <p className="title">{intl["profile"]}</p>
          </div>
        </section>
        <Row gutter={16} className="section" style={{ flexDirection: "column" }}>
          <Suspense fallback={<div className={styles.marketsCard + " middleAlign"} />}>
            <BasicInfo inviteInfo={inviteInfo} />
          </Suspense>
          <Suspense fallback={<div className={styles.marketsCard + " middleAlign"} />}>
            <Referral inviteInfo={inviteInfo} />
          </Suspense>
          <Suspense fallback={<div className={styles.marketsCard + " middleAlign"} />}>
            <div className={styles.automaticEntrance}>
              <span className={styles.title}>{intl["savings.automatic.my.title"]}</span>
              <Button
                type="primary"
                className={styles.automaticEntranceBtn}
                onClick={() => {
                  // window.__history__.push("/yield/myInvestmentPlan");
                  window.__history__.push({
                    pathname: "/yield",
                    query: { tabType: "2" }
                  });
                }}>
                {intl["go"]} &gt;
              </Button>
            </div>
          </Suspense>
          <div id="kyc">
            <Suspense fallback={<div className={styles.marketsCard + " middleAlign"} />}>
              <KYCInfomations />
            </Suspense>
          </div>

          {this.props?.kycInfo?.auditStatus == 1 && walletInfo.totalAmount > 0 && (
            <Suspense fallback={<div className={styles.marketsCard + " middleAlign"} />}>
              <SubAccount kycInfo={this.props?.kycInfo} />
            </Suspense>
          )}

          {this.props?.kycInfo?.auditStatus == 1 && (
            <Suspense fallback={<div className={styles.marketsCard + " middleAlign"} />}>
              <ServiceAccount />
            </Suspense>
          )}

          <div id="authorize">
            {partnerInfo &&
              (Client_Authorization_Status[partnerInfo.status] == "Success" ||
                Client_Authorization_Status[partnerInfo.status] == "Reviewing") && (
                <Suspense fallback={<div className={styles.marketsCard + " middleAlign"} />}>
                  <PartnerAccount partnerInfo={partnerInfo} reload={this.getApplyPartnerRole} />
                </Suspense>
              )}
          </div>
          {/* v1.0 只做部分功能 ，目前不显示 */}
          {/* <div className={styles.module} style={{ paddingRight: "24px" }}>
            <div className="flex" style={{ justifyContent: "space-between" }}>
              <span className={styles.title}>Client Management</span>
              <Link className={styles.button} to="/profile/customer">
                Go &gt;
              </Link>
            </div>
          </div> */}
        </Row>
      </section>
    );
  }

  handleClick(userName: string, resetToken: string): void {
    this.setState({
      userName: userName,
      resetToken: resetToken,
      showResetPasswordStep2: true
    });
  }

  onChange = (link: string) => {
    console.log("Anchor:OnChange", link);
  };
  //函数定义
  scrollToAnchor = (anchorName) => {
    if (anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        setTimeout(() => {
          anchorElement.scrollIntoView({ block: "end", behavior: "smooth" });
        }, 200);
      }
    }
  };

  componentDidMount() {
    this.fetchCurrentInviterInfo();
    this.getApplyPartnerRole();
    this.fetchWalletInfo();
    getQueryValue("anchorPoint") && this.scrollToAnchor(getQueryValue("anchorPoint"));
  }
  componentWillUnmount() {
    window.onload = null;
  }
}

export default KYCInfoLayout(Profile);
