import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../store/store";

export interface LanguageState {
  locale: string;
}
const initLocale: string = "en";
const initialState: LanguageState = {
  locale: initLocale
};

export const intlSlice = createSlice({
  name: "language",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    switchLanguage: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    }
  }
});

export const { switchLanguage } = intlSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectedIntl = (state: RootState) => state.language.locale;

// exports a reducer function for the counter logic.
export default intlSlice.reducer;
