// Supporting Internet Explorer
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";
import WithLogging from "@/components/withLogging/WithLogging";
import { store } from "./store/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import IntlWrapper from "@/components/intl/IntlWrapper";

import "./index.less";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

let persistor = persistStore(store);
ReactDOM.render(
  <React.StrictMode>
    <WithLogging>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <IntlWrapper />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </WithLogging>
  </React.StrictMode>,
  document.getElementById("root")
);

// function sendToAnalytics({ id, name, value }) {
//   ga('send', 'event', {
//     eventCategory: 'Web Vitals',
//     eventAction: name,
//     eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
//     eventLabel: id, // id unique to current page load
//     nonInteraction: true, // avoids affecting bounce rate
//     transport: 'beacon',
//   });
// }
// reportWebVitals(sendToAnalytics);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(sendToAnalytics);
// 使用谷歌扩展工具： web-vitals-extension

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://github.com/facebook/create-react-app/blob/main/packages/cra-template/template/README.md
serviceWorker.register();
