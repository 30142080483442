import React, { useEffect, useState } from "react";
import { IDepthItem } from "@/model/index";
import deptchChart from "depth-chart-hb";

interface IProps {
  minHeight: string;
  fetchOrderbook?: (coinCode: string) => void;
  coinCode: string;
  precision: number;
  tradeVolumePrecision: number;
  depthData: {
    bids: Array<IDepthItem>;
    asks: Array<IDepthItem>;
  };
}

export function DepthChart(props: IProps) {
  const option = {
    priceFix: props.precision,
    amountFix: props.tradeVolumePrecision,
    theme: "hb-night",
    color: "#61688A",
    tipColor: "#CAD7E0",
    bgColor: "rgba(0, 0, 0, 0.8)",
    bidsLineColor: "rgba(65, 179, 125, 1)",
    asksLineColor: "rgba(215, 78, 90, 1)",
    axisColor: "rgba(97, 104, 138, .3)"
    // langMap:{ "委托价": "委托价", "累计": "累计" },
    //   "langMap": {
    //     "zh-cn": { "委托价": "委托价", "累计": "累计" },
    //     "zh-hk": { "委托价": "委託價", "累计": "累計" },
    //     "en-us": { "委托价": "Price", "累计": "Sum" },
    //     "de-de": { "委托价": "Preis", "累计": "Total" },
    //     "ru-ru": { "委托价": "Цена", "累计": "Объём" },
    //     "ko-kr": { "委托价": "위탁단가", "累计": "누적량" },
    //     "fr-fr": { "委托价": "Prix", "累计": "Somme" },
    //     "es-es": { "委托价": "Precio", "累计": "Suma" },
    //     "th-th": { "委托价": "ราคาสั่งซื้อ", "累计": "ทยอยสะสม" },
    //     "vi-vi": { "委托价": "Giá lệnh", "累计": "Lũy kế" },
    //     "tr-tr": { "委托价": "Fiyat", "累计": "Sum" },
    //     "pt-pt": { "委托价": "O Preço", "累计": "Total" },
    //     "pt-br": { "委托价": "O Preço", "累计": "Total" }
    // }
  };
  const [currentDepthChart, updateCurrentDepthChart] = useState<any>();
  useEffect(() => {
    props.fetchOrderbook(props.coinCode);
    renderChart();
  }, []);

  useEffect(() => {
    if (currentDepthChart) {
      currentDepthChart.putData(props.depthData);
    } else {
      renderChart();
    }
  }, [props.depthData]);

  useEffect(() => {
    currentDepthChart?.reload(option);
  }, [props.coinCode]);

  const renderChart: () => void = () => {
    console.info(props.precision + "-----" + props.tradeVolumePrecision);

    let chart = deptchChart(document.querySelector("#depth-chart"), option);
    updateCurrentDepthChart(chart);
  };
  return <section id="depth-chart" style={{ minHeight: props.minHeight, height: "100%" }} />;
}

export default DepthChart;
