import React, { Component } from "react";
import { ICurrency } from "@/model/index";
import { updateCoinList } from "@/slices/MarketCurrenciesSlice";
import { fetchCoinList } from "@/services/request";
import { connect } from "react-redux";

interface IProps {
  [key: string]: any; // todo, @elaine
  coinList: ICurrency[];
}
const mapStateToProps = (state) => {
  const { coinList } = state.currencies;
  return {
    coinList: coinList // 所有币种、名称、icon等
  };
};
const mapDispatchToProps = () => {
  return {
    updateCoinList: (payload) => updateCoinList(payload)
  };
};

export default (WrappedComponent) => {
  class WithCoinList extends Component<IProps, {}> {
    constructor(props) {
      super(props);
      this.fetchAllCoinList = this.fetchAllCoinList.bind(this);
    }

    fetchAllCoinList: () => void = async () => {
      await fetchCoinList()
        .then((res) => {
          if (res?.code == 0) {
            this.props.updateCoinList({ coinList: res?.data ?? [] });
          } else {
            // ignore
          }
        })
        .finally(() => {});
    };

    render() {
      return (
        <>
          <WrappedComponent
            {...this.props}
            fetchAllCoinList={this.fetchAllCoinList}
            coinList={this.props.coinList}
          />
        </>
      );
    }
  }
  return connect(mapStateToProps, mapDispatchToProps())(WithCoinList);
};
