import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Checkbox } from "antd";
import styles from "@/views/login/Login.module.less";
import { register, googleV3Record, getConfigInfo } from "@/services/request";
import { Intl } from "@/model/index";
import {
  passwordConfirmValidator,
  passwordValidator,
  grecaptchaToken,
  isEmail
} from "@/utils/index";
import EmailCodeLayout from "@/components/withVerifyEmailCode/WithEmailCodeLayout";
import {
  PASSWORD_MAX_LENGTH,
  VerifyCode_MAX_LENGTH,
  MD5_FIXED_STRING,
  RECAPTCHA_V2_KEY_INVISIBLE
} from "@/config/constants";
import md5 from "md5";
import ReCAPTCHA from "react-google-recaptcha";
import Bugsnag from "@bugsnag/js";
import { formatBugsnagMessage } from "@/utils";

interface IProps {
  countdown: number;
  loading: boolean;
  inviteCode: string;
  disadbledSkip: boolean;
  getEmailCode: (businessType: number, email?: string) => void;
  sendMailBtn: string;
  handleBlur: (event: { target: { value: any }; currentTarget: any }) => void;
  handleFocus: (event: { target: { value: any }; currentTarget: any }) => void;
}

const RegisterStep2: React.FC<IProps> = (props: IProps) => {
  const [form] = Form.useForm();
  const [fullName, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [verifyCode, setVerificationCode] = useState<string>("");
  const [policyChecked, setPolicyChecked] = useState(false);
  const [showRecaptcha, setShowRecaptcha] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  // const recaptchaRef = React.createRef<any>();

  useEffect(() => {
    getConfigInfo().then((res) => {
      if (res?.code == 0) {
        setShowRecaptcha(res.data?.isRecaptcha);
      }
    });
  }, []);

  const handleChangePolicy = (e) => {
    setPolicyChecked(e.target.checked);
  };
  const handleClickAgreement = () => {
    window.open("/agreement");
  };

  const handleClickPolicy = () => {
    window.open("/policy");
  };

  const handleSubmit: () => void = async () => {
    // if (showRecaptcha) {
    //   try {
    //     recaptchaRef?.current?.execute();
    //   } catch (e) {
    //     message.destroy();
    //     message.error(intl["login.network.error"]);
    //     Bugsnag.notify(formatBugsnagMessage("recaptcha loaded error", "Action-register"));
    //   }
    // } else {
    //   fetchSubmit();
    // }
    fetchSubmit();
  };
  const fetchSubmit = async (recaptchaValue2?: string) => {
    // 当用户注册时，先保存用户邮箱，上报信息时使用
    localStorage.setItem("user", email);
    // const clientTokenV3 = await grecaptchaToken();
    setLoading(true);
    const params = {
      firstName: fullName,
      accountName: email,
      clientToken: showRecaptcha ? recaptchaValue2 : null,
      password: md5(confirmPassword + MD5_FIXED_STRING),
      verifyCode,
      inviteCode: props.inviteCode
    };
    // googleV3Record({ clientToken: clientTokenV3 });
    register(params)
      .then((res) => {
        if (res?.code == "0") {
          const state = { email, password };
          window.__history__.push("/login", state);
          return;
        }
        message.destroy();
        message.error(intl?.[res?.msg] ?? res?.msg);
      })
      .finally(() => {
        // window.grecaptcha.reset();
        setLoading(false);
      });
  };

  const isSubmitDisable = !!policyChecked;
  const intl: Intl = window.__intl__.messages;
  const messageEmail: string = intl["register.email.incorrect"];
  const messagePassword: string = intl["error.password.format"];
  return (
    <section className={styles.container}>
      <div className={styles.titleArea}>
        <p className={styles.registerStep2Title}> {intl["register"]} </p>
        {!props.disadbledSkip && (
          <p className={styles.goBack} onClick={() => window.history.back()}>
            {intl["register.back"]}
          </p>
        )}
      </div>
      <Form
        name="basic"
        autoComplete="off"
        layout="vertical"
        className={styles.registerStep2}
        form={form}
        onFinish={handleSubmit}>
        <input type="password" className="hiddenInput" />
        {/* 防止自动填充邮箱验证码 */}
        <input type="text" className="hiddenInput" />
        {/* 防止自动填充密码 */}
        <input type="text" className="hiddenInput" />
        <Form.Item
          style={{ marginBottom: 25 }}
          name="fullName"
          rules={[{ required: true, message: "Name required" }]}>
          <div>
            <label className={styles.label}> {intl["register.name"]} </label>
            <Input
              className={fullName ? styles.inputHover : ""}
              autoComplete="new-password"
              autoFocus={true}
              maxLength={100}
              onChange={(e) => setFullName(e.target.value)}
              onFocus={props.handleFocus}
              onBlur={props.handleBlur}
            />
          </div>
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 25 }}
          name="email"
          className={styles.email}
          rules={[
            {
              type: "email",
              message: messageEmail
            },
            {
              required: true,
              message: messageEmail
            }
          ]}>
          <div>
            <label className={styles.label}> {intl["register.email"]} </label>
            <Input
              className={email ? styles.inputHover : ""}
              autoComplete="new-password"
              onChange={(e) => setEmail(e.target.value)}
              onFocus={props.handleFocus}
              onBlur={props.handleBlur}
            />
          </div>
        </Form.Item>
        <div className={styles.eamilGroup} style={{ marginBottom: -22 }}>
          <Form.Item
            name="verifyCode"
            style={{ marginBottom: 24 }}
            rules={[{ required: true, message: intl["customer.email.code.incorrect"] }]}>
            <div>
              <label className={styles.label}> {intl["register.verify"]} </label>
              <Input
                className={verifyCode ? styles.inputHover : ""}
                type="text"
                autoComplete="new-password"
                onChange={(e) => setVerificationCode(e.target.value)}
                onFocus={props.handleFocus}
                onBlur={props.handleBlur}
                maxLength={VerifyCode_MAX_LENGTH}
              />
            </div>
          </Form.Item>
          <Form.Item style={{ marginBottom: 24 }}>
            <Button
              disabled={email == "" || !isEmail(email) || props.countdown != 60}
              loading={props.loading}
              className={styles.sendEmail}
              onClick={() => props.getEmailCode(0, email)}>
              {props.sendMailBtn}
            </Button>
          </Form.Item>
        </div>
        {/* 防止自动填充邮箱验证码 */}
        <input className="hiddenInput" />
        <input type="password" className="hiddenInput" />
        <Form.Item
          style={{ marginBottom: 24 }}
          name="password"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                return passwordValidator(value, messagePassword);
              }
            })
          ]}>
          <div>
            <label className={styles.label}> {intl["register.password"]} </label>
            <Input
              className={password ? styles.inputHover : ""}
              autoComplete="off"
              type="password"
              maxLength={PASSWORD_MAX_LENGTH}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={props.handleFocus}
              onBlur={props.handleBlur}
            />
          </div>
        </Form.Item>
        <Form.Item
          name="passwordconfirm"
          dependencies={["password"]}
          style={{ marginBottom: 24 }}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                return passwordConfirmValidator(
                  value,
                  getFieldValue("password"),
                  messagePassword,
                  intl["password.not.match"]
                );
              }
            })
          ]}>
          <div>
            <label className={styles.label}> {intl["register.passwordConfirm"]} </label>
            <Input
              className={confirmPassword ? styles.inputHover : ""}
              type="password"
              autoComplete="off"
              maxLength={PASSWORD_MAX_LENGTH}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onFocus={props.handleFocus}
              onBlur={props.handleBlur}
            />
          </div>
        </Form.Item>
        <Button
          className={styles.submit}
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={!isSubmitDisable}>
          {intl["button.submit"]}
        </Button>

        {/* <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          hl="en"
          theme="dark"
          sitekey={RECAPTCHA_V2_KEY_INVISIBLE}
          onChange={(value) => {
            fetchSubmit(value);
          }}
        /> */}
        <div className={styles.policy}>
          <Checkbox
            style={{ marginRight: 10 }}
            checked={policyChecked}
            onChange={handleChangePolicy}
          />
          <div>
            {intl["register.t&c.01"]}
            <span style={{ color: "#F17E00", cursor: "pointer" }} onClick={handleClickAgreement}>
              &nbsp;{intl["register.t&c.02"]}&nbsp;
            </span>
            {intl["register.t&c.04"]}
            <span style={{ color: "#F17E00", cursor: "pointer" }} onClick={handleClickPolicy}>
              &nbsp;{intl["register.t&c.03"]}
            </span>
          </div>
        </div>
      </Form>
    </section>
  );
};
export default EmailCodeLayout(RegisterStep2);
