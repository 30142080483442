import React, { Suspense } from "react";

import { Intl } from "@/model/index";
import styles from "./Savings.module.less";
import { yieldProducts, yieldBalance } from "@/services/request";
import { Row, Col, Select, Spin, Button, Tabs } from "antd";
import { IFlexibleProducts, IBalance } from "@/model/index";

const SavingsTable = React.lazy(
  () => import(/* webpackChunkName: "SavingsTable" */ "@/views/savings/components/SavingsTableList")
);
const FiexedYield = React.lazy(
  () => import(/* webpackChunkName: "SavingsTable" */ "@/views/savings/components/FiexedYield")
);
// const Balance = React.lazy(() => import(/* webpackChunkName: "Balance" */ "./components/Balance"));
const InvestmentPlan = React.lazy(
  () => import(/* webpackChunkName: "SavingsTable" */ "@/views/savings/InvestmentPlan")
);
const { Option } = Select;
const { TabPane } = Tabs;

interface IProps {}
interface IState {
  yieldProducts: IFlexibleProducts[];
  nextToken: string;
  totalCount: number;
  myBalance: IBalance;
  category: string;
  lockType: string;
  stableCoin: boolean;
  loading: boolean;
  balanceLoading: boolean;
  currentTab: string;
}

class Savings extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      balanceLoading: true,
      stableCoin: false,
      nextToken: "",
      totalCount: 0,
      yieldProducts: [],
      category: "",
      lockType: "",
      myBalance: {
        cumulativeProfit: 0, // 到现在一共在Savings赚了多少钱
        totalSavings: 0, //在Savings里面的总投入 和 所赚利息（复利利息会记录再投入金额中，单利利息会进入现货不统计）: 目前没有复利
        assetRatio: 0, // TotalSavings 在总资产的占比  -- account (理财/(理财账户+普通账户))
        apyOfAll: 0, // 所有投入savings的资产的加权APY平均值=∑(投入币种的美元额度 × APY) ÷ TotalSavings
        yDayEarned: 0 // 昨日收益，昨日的收益额度  =∑(投入币种的美元额度 × 昨日APY ÷ 365 )
      },
      currentTab: window?.__history__?.location?.query?.tabType ?? "0"
    };
    this.getFlexibleProducts = this.getFlexibleProducts.bind(this);
    this.getYieldBalance = this.getYieldBalance.bind(this);
  }

  //点击切换tab
  handleChangeTabs: (T: string) => void = (value: string) => {
    window?.__history__?.replace({ state: null, query: null });
    this.setState({ currentTab: value });
  };

  render(): React.ReactNode {
    const intl: Intl = window.__intl__.messages;
    // const subtitle: string = intl["overview.savings.subtitle"];
    // const flexible: string = intl["overview.savings.flexible"];
    // const locked: string = intl["overview.savings.locked"];
    // const { Search } = Input;
    const { currentTab } = this.state;
    return (
      <section className={styles.savings + " " + "customer"}>
        <div className="customerTop">
          <Row className="top">
            <Col span={24}>
              <p className="title">{intl["overview.savings.title"]}</p>
              {/* <p className="subtitle">{intl["overview.savings.subtitle"]}</p> */}
            </Col>
            {/* <Col span={12} className={styles.searchContainer}>
                <Search className={styles.search} placeholder="search" onSearch={this.onSearch} />
              </Col> */}
          </Row>
        </div>

        <Row className="section">
          <Col span={24}>
            <Spin spinning={this.state.loading}>
              <Tabs
                className={styles.tab}
                activeKey={currentTab}
                onChange={this.handleChangeTabs}
                style={{ color: "#fff" }}>
                <TabPane tab={"Flexible"} key="0" />
                <TabPane tab={"Fixed"} key="1" />
                <TabPane tab={"Auto-Investment"} key="2" />
              </Tabs>
              <div className={styles.list}>
                {currentTab === "0" && (
                  <div className={styles.flexibleContent}>
                    <div className={styles.searchContainer}>
                      {/* 币种较少，暂不支持搜索等功能， 后续可能加上，先注释调 */}
                      {/* <Search className={styles.search} placeholder="search" onSearch={this.onSearch} /> */}
                      {/* <Input style={{ width: "300px", marginRight: "24px" }} placeholder="Search" /> */}

                      {/* prefix={<AudioOutlined className="site-form-item-icon" />} */}
                      {/* <Checkbox onChange={this.onChangeStableCoin}>StableCoin</Checkbox> */}
                      {/* <Select
                  // labelInValue
                  defaultValue=""
                  style={{ width: 180, marginRight: "24px" }}
                  onChange={(value) => this.handleChange(value, "category")}>
                  <Option value="">All Category</Option>
                  <Option value="Saving">Saving</Option>
                  <Option value="Staking">Staking</Option>
                  <Option value="Defi">Defi Yield Farming</Option>
                </Select>
                <Select
                  defaultValue=""
                  style={{ width: 120 }}
                  onChange={(value) => this.handleChange(value, "lockType")}>
                  <Option value="">All Lock</Option>
                  <Option value="Flexible">Flexible</Option>
                  <Option value="100">100</Option>
                  <Option value="90">90</Option>
                  <Option value="70">70</Option>
                </Select> */}
                    </div>
                    <Suspense
                      fallback={
                        <div className={styles.savingsList + " middleAlign"}>{/* <Spin /> */}</div>
                      }>
                      <SavingsTable
                        dataSource={this.state.yieldProducts}
                        getFlexibleProducts={this.getFlexibleProducts}
                        getYieldBalance={this.getYieldBalance}
                      />
                    </Suspense>
                  </div>
                )}
                {currentTab === "1" && (
                  <Suspense
                    fallback={
                      <div className={styles.savingsList + " middleAlign"}>{/* <Spin /> */}</div>
                    }>
                    <FiexedYield />
                  </Suspense>
                )}
                {currentTab === "2" && (
                  <Suspense
                    fallback={
                      <div className={styles.savingsList + " middleAlign"}>{/* <Spin /> */}</div>
                    }>
                    <InvestmentPlan />
                  </Suspense>
                )}
              </div>
            </Spin>
          </Col>

          {/* <Col span={7}>
            <div className={styles.automaticEntrance}>
              <p>Automatic</p>
              <p>Investment Plan</p>
              <Button
                type="primary"
                className={styles.automaticEntranceBtn}
                onClick={() => {
                  window.__history__.push("/yield/investmentPlan");
                }}>
                Start Now
              </Button>
            </div>
            <Spin spinning={this.state.balanceLoading}>
              <Suspense
                fallback={
                  <div className={styles.savingsList + " middleAlign"}>
                    <Spin />
                  </div>
                }>
                <Balance myAssetsBalance={this.state.myBalance} />
              </Suspense>
            </Spin>
          </Col> */}
        </Row>
      </section>
    );
  }

  componentDidMount(): void {
    this.getFlexibleProducts();
    this.getYieldBalance();
  }

  // onChangeStableCoin(e: CheckboxChangeEvent): void {
  //   this.setState({
  //     stableCoin: e.target.checked
  //   });
  // }

  // handleChange(value: string, key: "lockType" | "category") {
  //   const data = {};
  //   data[key] = value;
  //   this.setState(data);
  // }

  // handleClick: (type: string, currency: string) => void = (type: string, currency: string) => {
  //   let data = {};
  //   data[type] = true;
  //   this.setState(data);
  // };

  // onSearch: () => void = () => {};

  // 理财产品列表
  getFlexibleProducts: () => void = () => {
    const param = {
      limit: 100 // 理财产品暂时较少，先设置较大limit一次返回，不分页、搜索等
    };
    yieldProducts(param)
      .then((res) => {
        if (res?.code == "0") {
          this.setState({
            yieldProducts: res?.data?.financingProducts ?? []
          });
        }
      })
      .catch((e) => {})
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  // 理财余额
  getYieldBalance: () => void = () => {
    yieldBalance()
      .then((res) => {
        this.setState({
          myBalance: res?.data
        });
      })
      .catch((e) => {})
      .finally(() => {
        this.setState({
          balanceLoading: false
        });
      });
  };
}
export default Savings;
