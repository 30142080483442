import React from "react";
import { Row, Col } from "antd";
// https://github.com/nygardk/react-share/blob/master/demo/Demo.tsx
// import {
//   FacebookShareButton,
//   FacebookIcon,
//   TwitterShareButton,
//   TwitterIcon,
//   TelegramShareButton,
//   TelegramIcon
// } from "react-share";
const twitter = require("@/assets/images/twitter_icon.svg");
const linkedin = require("@/assets/images/linkedin_icon.svg");
const email = require("@/assets/images/email_icon.svg");

import styles from "./Footer.module.less";

function Footer() {
  const year = new Date().getFullYear();

  return (
    <div className={styles.footerDark}>
      <Row className={styles.content}>
        {/* <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/dialog/share?app_id=1032705147200897&display=popup&href=https://www.luotuxiu.cn/&redirect_uri=https://www.luotuxiu.cn/"
        >
          111
        </a> */}
        <Col flex="auto">
          {" "}
          <span>ASPEN DIGITAL</span>{" "}
          <span style={{ paddingRight: "9px", paddingLeft: "9px" }}>|</span>© {year} ASPEN DIGITAL
        </Col>
        <Col flex="150px">
          <Row justify="space-around">
            <a
              href="https://www.linkedin.com/company/aspendigitalco"
              target="_blank"
              className={styles.shareIcon}>
              <img src={linkedin} />
            </a>
            <a
              href="https://twitter.com/aspendigitalamp"
              target="_blank"
              className={styles.shareIcon}>
              <img src={twitter} />
            </a>
            <a
              href="mailto:support@aspendigital.co?subject=general enquiry"
              target="_blank"
              className={styles.shareIcon}>
              <img src={email} />
            </a>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
