import React, { Suspense } from "react";

import styles from "@/views/login/Login.module.less";
import LoginLayout from "./components/LoginLayout";
import RegisterStep2 from "./components/RegisterStep2";
import { Spin } from "antd";
import { getQueryValue } from "@/utils/index";

const RegisterStep1 = React.lazy(
  () => import(/* webpackChunkName: "RegisterStep1" */ "./components/RegisterStep1")
);

interface IProps {
  handleBlur: (event: { target: { value: any }; currentTarget: any }) => void;
  handleFocus: (event: { target: { value: any }; currentTarget: any }) => void;
}
interface IState {
  invitationCode: string;
  showRegisterStep2: boolean;
  disadbledSkip: boolean;
}
class Register extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      invitationCode: "",
      disadbledSkip: getQueryValue("invitedCode") ? true : false,
      showRegisterStep2: false
    };
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    localStorage.clear();
  }

  handleClick(invitationCode: string): void {
    this.setState({
      invitationCode: invitationCode,
      showRegisterStep2: true
    });
  }

  render(): React.ReactNode {
    const { showRegisterStep2, invitationCode, disadbledSkip } = this.state;
    return (
      <div className={styles.form}>
        <Suspense
          fallback={
            <div className={styles.form + " middleAlign"}>
              <Spin />
            </div>
          }>
          {showRegisterStep2 ? (
            <RegisterStep2
              disadbledSkip={disadbledSkip}
              handleFocus={this.props.handleFocus}
              handleBlur={this.props.handleBlur}
              inviteCode={invitationCode}
            />
          ) : (
            <RegisterStep1
              disadbledSkip={disadbledSkip}
              handleClick={this.handleClick}
              handleFocus={this.props.handleFocus}
              handleBlur={this.props.handleBlur}
            />
          )}
        </Suspense>
      </div>
    );
  }
}

export default LoginLayout(Register);
