import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { ICurrency } from "@/model/index";

export interface IState {
  coinList: {
    [key: string]: ICurrency;
  };
}

export const currencies = (state: RootState) => state.currencies.coinList;

const initialState: IState = {
  coinList: {}
};
export const currenciesSlice = createSlice({
  name: "currencies",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateCoinList: (state, action: PayloadAction<any>) => {
      state.coinList = action.payload.coinList;
    }
  }
});

export const { updateCoinList } = currenciesSlice.actions;

export default currenciesSlice.reducer;
