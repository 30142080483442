import languageReducer from "@/slices/IntlSlice";

// 在这里TradeSlice里存放实时交易币币种信息，供需要的页面去取值做计算，
import exchangeReducer from "@/slices/TradeSlice";
import userReducer from "@/slices/UserSlice";
import intellgenceReducer from "@/slices/IntelligenceSlice";
import marketCurrenciesReducer from "@/slices/MarketCurrenciesSlice";
import openOrderReducer from "@/slices/MarketCurrenciesSlice";
import { combineReducers } from "redux";

const reducers = combineReducers({
  language: languageReducer,
  marketSpotAndTradeInfo: exchangeReducer,
  user: userReducer,
  intelligence: intellgenceReducer,
  currencies: marketCurrenciesReducer,
  openOrder: openOrderReducer
});
export default reducers;
