import React, { useState, useEffect, Suspense } from "react";

import { Intl, IkycInfo } from "@/model/index";
import { Row, Col, Button, Spin, message } from "antd";
import styles from "./Wallet.module.less";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { getWalletInfo, getBankAccount } from "@/services/request";
import KYCInfoLayout from "@/components/withKycInfo/WithKYCInfo";
import { USER_ROLE_POWER } from "@/config/constants";

const Dashboard = React.lazy(
  () => import(/* webpackChunkName: "Dashboard" */ "./components/Dashboard")
);
const WalletCategoryDetails = React.lazy(
  () => import(/* webpackChunkName: "WalletCategoryDetails" */ "./components/WalletCategoryDetails")
);

const ModalBankAccountStatus = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Dashboard" */ "@/components/modalBankAccountStatus/ModalBankAccountStatus"
    )
);

interface IProps {
  showGuideModal: () => void;
  kycInfo: IkycInfo;
}

export function Wallet(props: IProps) {
  const intl: Intl = window.__intl__.messages;
  const title: string = intl["wallet"];
  const hide = intl["wallet.hideAsset"];
  const deposit = intl["button.deposit.crypto.currency"];
  const depositFiat = intl["button.deposit.fiat.currency"];

  const type = window?.__history__?.location?.state?.type ?? "";
  const [isShowAmount, setAmountInfo] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [walletInfo, setWalletInfo] = useState({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [circleInfo, setCircleInfo] = useState({}); //circle

  useEffect(() => {
    getInitData();
  }, []);

  // 初始化数据,
  const getInitData: () => void = async () => {
    setLoading(true);
    getWalletInfo()
      .then((res) => {
        if (res?.code == "0") {
          setWalletInfo(res?.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getCircleInfo = (params) => {
    setCircleInfo(params);
  };
  const showCircleModal = () => {
    setShowModal(!showModal);
  };
  const showKycModal = () => {
    props.showGuideModal();
  };
  const goToDepositFiat = () => {
    if (props?.kycInfo?.auditStatus == 1) {
      getBankAccount().then((res) => {
        if (res?.code == 0) {
          if (res?.data?.status == "active") {
            window.__history__.push({
              pathname: "/wallet/deposit/usdc",
              state: { dataInfo: res?.data }
            });
          } else {
            showCircleModal();
            getCircleInfo(res?.data);
          }
        } else if (res?.code == 6001) {
          //未绑定
          window.__history__.push("/wallet/addAccount");
        } else {
          const intl: Intl = window.__intl__.messages;
          let msg = intl?.[res?.msg];
          if (!msg) {
            msg = intl?.[res?.code] ?? intl?.["global.exception"];
          }
          message.destroy();
          message.error(msg);
        }
      });
    } else {
      showKycModal();
    }
  };

  return (
    <section className={styles.wallet + " " + "customer"}>
      <div>
        <div className="customerTop">
          <Row className="top">
            <Col span={24}>
              <div className={styles.topTitleArea}>
                <div className={styles.leftTitleArea}>
                  <span className="title">{title}</span>
                  <div
                    style={{ display: "inline-block" }}
                    onClick={() => {
                      setAmountInfo(!isShowAmount);
                    }}>
                    {isShowAmount ? (
                      <EyeOutlined className={styles.hideIcon} />
                    ) : (
                      <EyeInvisibleOutlined className={styles.hideIcon} />
                    )}
                  </div>
                </div>
                {USER_ROLE_POWER[localStorage.getItem("user_auth")]?.depositAndWithdraw && (
                  <div className={styles.rightTitleArea}>
                    <Button
                      type="primary"
                      className={styles.allBtn}
                      onClick={() => window.__history__.push("/wallet/deposit")}>
                      {deposit}
                    </Button>
                    <Button type="primary" className={styles.allBtn} onClick={goToDepositFiat}>
                      {depositFiat}
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <div className="section">
          <div className={styles.wallet}>
            <Suspense fallback={<div className={styles.marketsCard + " middleAlign"} />}>
              <Spin spinning={loading}>
                <Dashboard showAmout={isShowAmount} walletInfo={walletInfo} />
                <WalletCategoryDetails
                  showAmout={isShowAmount}
                  walletInfo={walletInfo}
                  type={type}
                  showCircleModal={showCircleModal}
                  getCircleInfo={getCircleInfo}
                />
                <ModalBankAccountStatus
                  showModal={showModal}
                  circleInfo={circleInfo}
                  showCircleModal={showCircleModal}
                />
              </Spin>
            </Suspense>
          </div>
        </div>
      </div>
    </section>
  );
}

export default KYCInfoLayout(Wallet);
