import React, { Component } from "react";
import { Intl } from "@/model/index";
import { sendEmailCode, sendAuthEmailCode, verifyEmailCode } from "@/services/request";
import { message } from "antd";

interface IProps {
  [key: string]: any; // todo， @elaine
}

type IState = {
  countdown: number;
  consecutiveErrors: boolean;
  loading: boolean;
  verifyStatus: boolean | string;
  interval: any;
  businessType: number;
};

export default (WrappedComponent) => {
  class WithEmailCodeLayout extends Component<IProps, IState> {
    constructor(props: IProps) {
      super(props);
      this.state = {
        loading: false,
        countdown: 60, // 倒计时
        consecutiveErrors: false, // 是否连续错误超过5次
        verifyStatus: "", // 验证码验证结果
        interval: "",
        businessType: 0
      };
      this.getEmailCode = this.getEmailCode.bind(this);
      this.verifyEmailInputCode = this.verifyEmailInputCode.bind(this);
    }

    getEmailCode: (businessType: number, email?: string) => void = async (businessType, email) => {
      // 当用户点击发送验证码时，先保存用户邮箱，上报信息时使用
      if (email && !localStorage.getItem("user")) {
        localStorage.setItem("user", email);
      }

      this.setState({ loading: true, businessType: businessType });
      const intl: Intl = window.__intl__.messages;
      //登陆后可不传邮箱
      const params = {
        businessType: businessType,
        email: localStorage.getItem("isAuthenticated")
          ? null
          : email ?? (localStorage.getItem("operateCustomerEmail") || localStorage.getItem("user"))
      };

      //登陆后调用需要身份验证的验证码
      const fetchMethod = localStorage.getItem("isAuthenticated")
        ? sendAuthEmailCode
        : sendEmailCode;
      fetchMethod(params)
        .then((res) => {
          if (res?.code == "0") {
            // 发送验证码60s之后才可以再次发送
            this.setState({
              interval: setInterval(() => {
                if (this.state.countdown <= 0) {
                  clearInterval(this.state.interval);
                  this.setState({
                    countdown: 60
                  });
                } else {
                  this.setState({
                    countdown: this.state.countdown - 1
                  });
                }
              }, 1000)
            });
          } else {
            message.destroy();
            message.error(intl?.[res?.msg] ?? res?.msg);
          }
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    };
    // todo 参数为object传递
    verifyEmailInputCode: (
      verifyCode: string,
      email?: string
    ) => Promise<{ consecutiveErrors?: boolean; verifyStatus?: string | number }> = async (
      verifyCode: string,
      email?: string
    ) => {
      const intl: Intl = window.__intl__.messages;
      const { businessType } = this.state;
      const params = {
        businessType: businessType,
        email: email ?? localStorage.getItem("user"),
        verifyCode: verifyCode
      };
      return await verifyEmailCode(params).then((res) => {
        if (res?.code == "0") {
          clearInterval(this.state.interval);
          this.setState({
            verifyStatus: res?.code,
            countdown: 60
          });
          // 验证成功将结果返回给组件，做下一步处理
          return { verifyStatus: res?.code, ...res };
        } else {
          if (res?.msg == "email_code_verify_count_often") {
            // 错误后将consecutiveErrors 置为true, 返回给组件使用  > , 比如输入的验证码从input中清空
            this.setState({
              consecutiveErrors: true
            });
            const msg = intl["email_code_verify_count_often"];
            message.destroy();
            message.error(msg);
            return { consecutiveErrors: this.state.consecutiveErrors };
          }
          if (res?.msg == "email_code_not_send") {
            //验证码为发送 点击提交下一步
            // 错误后将consecutiveErrors 置为true, 返回给组件使用  > , 比如输入的验证码从input中清空
            this.setState({
              consecutiveErrors: true
            });
            const msg = intl["email_code_not_send"];
            message.destroy();
            message.error(msg);
            return { consecutiveErrors: this.state.consecutiveErrors };
          }
          if (res?.msg == "customer.email.code.incorrect") {
            // 输入错误，请重试  - 尽可尝试4次
            // const errorEmailCode: string = `${intl["profile.googlecode.bind.email.code.error"]}(${this.state.retry})`;
            const errorEmailCode: string = intl["profile.googlecode.bind.email.code.error"];
            message.destroy();
            message.error(errorEmailCode);
          } else {
            message.destroy();
            message.error(intl?.[res?.msg] ?? res?.msg);
          }
        }
      });
    };

    render() {
      const intl: Intl = window.__intl__.messages;
      const sendMailBtn =
        this.state.countdown == 60
          ? intl["register.sendverify"]
          : `${intl["register.resend"]}(${this.state.countdown})`;
      return (
        <>
          <WrappedComponent
            {...this.props}
            getEmailCode={this.getEmailCode}
            countdown={this.state.countdown}
            sendMailBtn={sendMailBtn}
            verifyEmailInputCode={this.verifyEmailInputCode}
            loading={this.state.loading}
          />
        </>
      );
    }
  }
  return WithEmailCodeLayout;
};
