import React, { Suspense } from "react";
import { Intl, IkycInfo } from "@/model/index";
import { Breadcrumb, Input, Steps, Button, message, Select, Spin, Checkbox, Modal } from "antd";
import styles from "./Wallet.module.less";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getBanks, getOtcDepositParam, applyOtcDeposit } from "@/services/request";
import KYCInfoLayout from "@/components/withKycInfo/WithKYCInfo";

const { Step } = Steps;
const { Option } = Select;

const UploadFile = React.lazy(
  () => import(/* webpackChunkName: "UploadFile" */ "@/components/uploadFile/UploadFile")
);

const ModalAddBank = React.lazy(
  () => import(/* webpackChunkName: "ModalAddBank" */ "@/views/profile/components/ModalAddBank")
);
const History = React.lazy(() => import(/* webpackChunkName: "History" */ "./components/History"));

interface IProps {
  kycInfo: IkycInfo;
  showGuideModal: (isClose) => void;
}

interface IState {
  currentStep: number;
  submitCheckState: boolean;
  bankList: any[];
  currentBankInfo: {
    bankId: string;
    bankName: string;
    accountName: string;
    bankAccountNo: string;
  };
  showAddBankModal: boolean;
  amount: number | string;
  otcOrder: string;
  remarkCode: string;
  transferInfo: string;
  loading: boolean;
  showConfirm: boolean;
  showKycModal: boolean;
}

const mapStateToProps = (state) => {
  const { kycInfo } = state.user;
  return {
    kycInfo: kycInfo
  };
};

class DepositFiat extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      submitCheckState: false,
      bankList: [],
      currentBankInfo: {
        bankId: "",
        bankName: "",
        accountName: "",
        bankAccountNo: ""
      },
      showAddBankModal: false,
      amount: "",
      otcOrder: "",
      remarkCode: "",
      transferInfo: "",
      loading: false,
      showConfirm: false,
      showKycModal: props?.kycInfo?.auditStatus == 1 ? false : true
    };
  }

  componentDidMount() {
    if (this.state.showKycModal) {
      this.props.showGuideModal(true);
    } else {
      this.setState({
        loading: true
      });
      this.getBankList();
      this.getDepositQuery();
    }
  }

  // 获取银行卡列表
  getBankList: () => void = async () => {
    const param = {
      page: 1,
      limit: 100 //默认全部显示
    };
    getBanks(param)
      .then((res) => {
        if (res?.code == "0") {
          const _list = res?.data || [];
          this.setState({
            bankList: _list
          });
        } else {
          message.destroy();
          message.error("Failed to get the card list");
        }
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  //获取充值参数
  getDepositQuery = () => {
    getOtcDepositParam()
      .then((res) => {
        if (res?.code == "0") {
          this.setState({
            otcOrder: res.data?.otcOrder,
            remarkCode: res.data?.remarkCode
          });
        }
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  //获取上传凭证url
  getTransfer = (value) => {
    this.setState({
      transferInfo: value || "" //删除时Value为undefined
    });
  };

  getText(): { [propName: string]: any } {
    const intl: Intl = window.__intl__.messages;
    const location: string = intl["kyc.certify.location"];
    const wallet: string = intl["wallet"];
    const information: string = intl["wallet.deposit.fiat"];
    const step1 = intl["wallet.deposit.fiat.step1"];
    const step2 = intl["wallet.deposit.fiat.step2"];
    const channels = intl["wallet.deposit.channels"];
    const fbs = intl["wallet.deposit.channel.fbs"];
    const bank = intl["wallet.deposit.channel.bank"];
    const bankDetail = intl["wallet.deposit.channel.bank.details"];
    const account = intl["wallet.deposit.channel.bank.details.account"];
    const fpsNo = intl["wallet.deposit.channel.bank.details.fps.no"];
    const bankAccount = intl["bank.account"];
    const bankName = intl["column.bank.name"];
    const identifier = intl["wallet.deposit.channel.bank.details.identifier"];
    const step1Tips = intl["wallet.deposit.fiat.step1.tips"];
    const step1TipsTitle = intl["wallet.deposit.fiat.step1.tipsTitle"];
    const step1Notice = intl["wallet.deposit.fiat.step1.notice"];
    const stepNoticeTitle = intl["wallet.deposit.fiat.step1.noticeTitle"];
    const step1Agree = intl["wallet.deposit.fiat.step1.agree"];
    const step1Agreement = intl["wallet.deposit.fiat.step1.agreement"];
    const step1Proceed = intl["wallet.deposit.fiat.step1.proceed"];
    const step1Message = intl["wallet.deposit.fiat.step1.message"];
    const step2Title = intl["wallet.deposit.fiat.step2.title"];
    const step2PaymentAccount = intl["wallet.deposit.fiat.step2.paymentAccount"];
    const step2PaymentPlaceholder = intl["wallet.deposit.fiat.step2.paymentPlaceholder"];
    const step2NoBind = intl["wallet.deposit.fiat.step2.noBind"];
    const step2Amount = intl["wallet.deposit.fiat.step2.amount"];
    const step2Type = intl["wallet.deposit.fiat.step2.type"];
    const step2Informations = intl["wallet.deposit.fiat.step2.informations"];
    const step2Upload = intl["wallet.deposit.fiat.step2.upload"];
    const step2UploadNotice = intl["wallet.deposit.fiat.step2.upload.notice"];
    const step2UploadNoticeList = intl["wallet.deposit.fiat.step2.upload.noticeList"];
    const step2NoticeList = intl["wallet.deposit.fiat.step2.noticeList"];
    const step2Agree = intl["wallet.deposit.fiat.step2.agree"];
    const step2Submit = intl["wallet.deposit.fiat.step2.submit"];
    const step2Message = intl["wallet.deposit.fiat.step2.message"];
    const successTips = intl["wallet.deposit.fiat.success.tips"];
    const errorTips1 = intl["wallet.deposit.fiat.error.tips1"];
    const errorTips2 = intl["wallet.deposit.fiat.error.tips2"];
    const warningTips = intl["wallet.deposit.fiat.warning.tips"];
    const imgText = intl["wallet.deposit.fiat.imgText"];
    const uploadText = intl["wallet.deposit.fiat.upload"];
    const modalTitle = intl["wallet.deposit.fiat.modal.title"];
    const modalContentOne = intl["wallet.deposit.fiat.modal.content.one"];
    const modalContentTwo = intl["wallet.deposit.fiat.modal.content.two"];
    const okText: string = `${intl["button.confirm"]}`;
    const cancelText: string = `${intl["button.cancel"]}`;

    return {
      step1,
      step2,
      wallet,
      location,
      channels,
      fbs,
      bank,
      bankDetail,
      account,
      fpsNo,
      bankAccount,
      bankName,
      identifier,
      information,
      step1Tips,
      step1TipsTitle,
      step1Notice,
      stepNoticeTitle,
      step1Agree,
      step1Agreement,
      step1Proceed,
      step1Message,
      step2Title,
      step2PaymentAccount,
      step2PaymentPlaceholder,
      step2NoBind,
      step2Amount,
      step2Type,
      step2Informations,
      step2Upload,
      step2UploadNotice,
      step2UploadNoticeList,
      step2NoticeList,
      step2Agree,
      step2Submit,
      step2Message,
      successTips,
      errorTips1,
      errorTips2,
      warningTips,
      imgText,
      uploadText,
      modalTitle,
      modalContentOne,
      modalContentTwo,
      okText,
      cancelText
    };
  }
  handleProceed = () => {
    this.setState({
      currentStep: 1
    });
  };

  //选择/绑定银行卡
  handleSelectCard = (value) => {
    const { bankList } = this.state;
    const bankInfo = {
      bankId: bankList[value].id,
      bankName: bankList[value].bankName,
      accountName: bankList[value].nameAtBank,
      bankAccountNo: bankList[value].accountNo
    };
    this.setState({
      currentBankInfo: bankInfo
    });
  };
  handleAddBank = () => {
    this.setState({
      showAddBankModal: true
    });
  };
  hideModal = () => {
    this.setState({
      showAddBankModal: false,
      showConfirm: false
    });
  };

  //输入金额
  handleChangeAmount = (e) => {
    let value = e.target.value;
    if (value?.split(".")[1] && value?.split(".")[1].length > 8) {
      value = value.substring(0, value.indexOf(".") + 9);
    }
    this.setState({ amount: value });
  };
  //提交充值check
  handleCheckSubmit = (e) => {
    this.setState({ submitCheckState: e.target.checked });
  };

  confirmSubmit = (params) => {
    this.setState({ showConfirm: true });
  };

  handleSubmit = () => {
    const { currentBankInfo, amount, transferInfo } = this.state;
    const { warningTips } = this.getText();
    if (currentBankInfo.bankId && amount > 0 && transferInfo) {
      this.applyDepositFiat();
    } else {
      message.destroy();
      message.warning(warningTips);
    }
  };
  //充值
  applyDepositFiat = () => {
    const { currentBankInfo, amount, otcOrder, remarkCode, transferInfo } = this.state;
    const { successTips, errorTips1, errorTips2 } = this.getText();
    this.setState({
      loading: true,
      showConfirm: false
    });
    const prarms = {
      currency: "USD",
      otcOrderId: otcOrder,
      paymentWayId: currentBankInfo.bankId,
      referenceImage: transferInfo,
      referenceNo: "",
      remarkCode: remarkCode,
      requestAmount: amount,
      transferMethod: "bank"
    };
    applyOtcDeposit(prarms)
      .then((res) => {
        if (res?.code == "0") {
          message.destroy();
          message.success(successTips, 2).then(() => {
            window.__history__.push(`/wallet`);
          });
        } else {
          if (res?.code == "05212") {
            //充值过于频繁
            message.destroy();
            message.error(errorTips1);
            return;
          }
          message.destroy();
          message.error(errorTips2);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render(): React.ReactNode {
    const {
      identifier,
      location,
      wallet,
      information,
      step1,
      step2,
      channels,
      fbs,
      bank,
      bankDetail,
      account,
      fpsNo,
      bankAccount,
      bankName,
      step1Tips,
      step1TipsTitle,
      step1Notice,
      stepNoticeTitle,
      step1Agree,
      step1Agreement,
      step1Proceed,
      step2Title,
      step2PaymentAccount,
      step2PaymentPlaceholder,
      step2NoBind,
      step2Amount,
      step2Type,
      step2Informations,
      step2Upload,
      step2UploadNotice,
      step2UploadNoticeList,
      step2NoticeList,
      step2Agree,
      step2Submit,
      imgText,
      uploadText,
      modalTitle,
      modalContentOne,
      modalContentTwo,
      okText,
      cancelText
    } = this.getText();
    const { bankList, currentBankInfo, loading, submitCheckState, amount } = this.state;
    return (
      <section className={styles.depositFiat}>
        <Spin spinning={loading}>
          <Breadcrumb>
            <Breadcrumb.Item>{location}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/wallet">{wallet}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{information}</Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.depositContent}>
            <p className={styles.title}>{information}</p>
            <Steps current={this.state.currentStep} style={{ width: "400px" }}>
              <Step title={step1} description="" />
              <Step title={step2} description="" />
            </Steps>
            {this.state.currentStep === 0 && (
              <div className={styles.stepOneContent}>
                <p className={styles.label}>{channels}</p>
                <div className={styles.channels}>
                  {/* <Button className={styles.primary}>{fbs}</Button> */}
                  <Button className={styles.primary}>{bank}</Button>
                </div>
                <p className={styles.label}>{bankDetail}</p>
                {/* <div className={styles.bankDetails}>
                  <div className="flex">
                    <p>{account}</p>
                    <p>Aspen Management Limited</p>
                  </div>
                  <div className="flex">
                    <p>{fpsNo}</p>
                    <p>deposit@aspen.com</p>
                  </div>
                  <div className="flex">
                    <p>{bankName}</p>
                    <p>Aspen Management Limited</p>
                  </div>
                  <div className="flex">
                    <p>{identifier}</p>
                    <p>100398</p>
                  </div>
                </div> */}
                <ul className={styles.stepArea}>
                  {imgText.map((item, index) => {
                    return (
                      <li className={styles.stepAreaItem} key={index}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item
                          }}
                        />
                        {index == 2 && (
                          <Button
                            type="primary"
                            className={styles.btn}
                            onClick={this.handleProceed}>
                            {uploadText}
                          </Button>
                        )}
                      </li>
                    );
                  })}
                  <div className={styles.stepAreaOne} />
                  <div className={styles.stepAreaTwo} />
                  <div className={styles.stepAreaThree} />
                </ul>
                {/* <div className={styles.tipsContent}>
                  <p className={styles.tipsTitle}>{step1TipsTitle}</p>
                  {step1Tips.map((item, index) => {
                    return (
                      <p key={index} className={styles.items}>
                        {item}
                      </p>
                    );
                  })}
                </div> */}
                {/* <div className={styles.tipsContent}>
                  <p className={styles.tipsTitle}>{stepNoticeTitle}</p>
                  {step1Notice.map((item, index) => {
                    return (
                      <p key={index} className={styles.items}>
                        {item}
                      </p>
                    );
                  })}
                </div> */}
                {/* <Button type="primary" className={styles.btnContent} onClick={this.handleProceed}>
                  {step1Proceed}
                </Button> */}
              </div>
            )}
            {this.state.currentStep === 1 && (
              <div className={styles.stepTwoContent}>
                <p className={styles.label}>{step2Title}</p>
                <div className={styles.accountArea}>
                  <div className={styles.leftArea}>
                    <p className={styles.label}>{step2PaymentAccount}</p>
                    <Select
                      size="large"
                      className={styles.selectCard}
                      bordered={false}
                      placeholder={step2PaymentPlaceholder}
                      onChange={this.handleSelectCard}>
                      {bankList.map((item, index) => {
                        return (
                          <Option value={index} key={item.id}>
                            {item.accountNo}
                          </Option>
                        );
                      })}
                    </Select>
                    <p className={styles.bindCard} onClick={this.handleAddBank}>
                      {step2NoBind}
                    </p>
                    <p className={styles.label}>{step2Amount}</p>
                    <Input
                      size="small"
                      className={styles.inputArea}
                      type="number"
                      value={amount}
                      onChange={this.handleChangeAmount}
                      bordered={false}
                      placeholder={step2Amount}
                      suffix={step2Type}
                    />
                  </div>
                  <div className={styles.middleLine} />
                  <div className={styles.rightArea}>
                    <p className={styles.label}>{step2Informations}</p>
                    <div className={styles.bindCardInformationsArea}>
                      <div className="flex">
                        <p>{account}</p>
                        <p className={styles.bankData}>{currentBankInfo.accountName}</p>
                      </div>
                      <div className="flex">
                        <p>{bankAccount}</p>
                        <p className={styles.bankData}>{currentBankInfo.bankAccountNo}</p>
                      </div>
                      <div className="flex">
                        <p>{bankName}</p>
                        <p className={styles.bankData}>{currentBankInfo.bankName}</p>
                      </div>
                      {/* <div className="flex">
                      <p>{identifier}</p>
                      <p>100398</p>
                    </div> */}
                    </div>
                  </div>
                </div>
                <p className={styles.label}>{step2Informations}</p>
                <div className={styles.uploadContent}>
                  <div className={styles.uploadContainer}>
                    <Suspense
                      fallback={
                        <div className={styles.uploadContainer + " middleAlign"}>
                          <Spin />
                        </div>
                      }>
                      <UploadFile text={step2Upload} getUploadInfo={this.getTransfer} />
                    </Suspense>
                  </div>
                  <div className={styles.rightArea}>
                    <p style={{ marginBottom: 10, color: "#fff" }}>{step2UploadNotice}</p>
                    {step2UploadNoticeList.map((item, index) => {
                      return <p key={JSON.stringify(item)}>{item}</p>;
                    })}
                  </div>
                </div>
                <div className={styles.noticeContainer}>
                  <p className={styles.noticeTitle}>{stepNoticeTitle}</p>
                  {step2NoticeList.map((item, index) => {
                    return (
                      <p key={JSON.stringify(item)} className={styles.noticeItem}>
                        {item}
                      </p>
                    );
                  })}
                </div>
                <div>
                  <Checkbox onChange={this.handleCheckSubmit} style={{ marginRight: 10 }} />
                  {step2Agree}
                </div>
                <Button
                  type="primary"
                  disabled={!submitCheckState}
                  className={styles.btnContent}
                  onClick={this.confirmSubmit}>
                  {step2Submit}
                </Button>

                <Suspense fallback={<div className={styles.marketsCard + " middleAlign"} />}>
                  <ModalAddBank
                    name={this.props?.kycInfo?.name}
                    visible={this.state.showAddBankModal}
                    handleCancel={this.hideModal}
                    getBankList={this.getBankList}
                  />
                </Suspense>

                <Suspense fallback={<div className={styles.marketsCard + " middleAlign"} />}>
                  <Modal
                    title={modalTitle}
                    wrapClassName={styles.modal}
                    visible={this.state.showConfirm}
                    confirmLoading={loading}
                    onOk={this.handleSubmit}
                    onCancel={this.hideModal}
                    okText={okText}
                    cancelText={cancelText}>
                    <p>{modalContentOne}</p>
                    <br />
                    <p>{modalContentTwo}</p>
                  </Modal>
                </Suspense>
              </div>
            )}
          </div>
        </Spin>

        <Suspense
          fallback={
            <div className={styles.history + " middleAlign"}>
              <Spin />
            </div>
          }>
          <History showViewAll={true} type={1} currency="USD" fiat={true} />
        </Suspense>
      </section>
    );
  }
}

export default KYCInfoLayout(connect(mapStateToProps)(DepositFiat));
