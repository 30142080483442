import React, { useState, useEffect, Suspense } from "react";
import { Intl, ICurrency } from "@/model/index";
import { Spin, Breadcrumb, Row, Col, message, Checkbox, Input, Select, Button } from "antd";
import { Link } from "react-router-dom";
import { availableSpot, addAutomaticInvestmentPlan } from "@/services/request";
import WithCoinList from "@/components/withCoinList/WithCoinList";
import styles from "./Savings.module.less";
import { numberToThousands, decimalPointNoMoreX, convertUSD2USDC, resetRate } from "@/utils";
import {
  BENCHMARK_CURRENCY_ICON,
  Automatice_Investment_Cycle,
  USER_ROLE_POWER
} from "@/config/constants";

const { Option } = Select;

interface IProps {
  coinList: ICurrency[];
  intl: Intl; //
}
const ModalSafetyVerification = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ModalSafetyVerification" */ "@/components/modalSafetyVerification/ModalSafetyVerification"
    )
);

const ModalAutomaticInvestment = React.lazy(
  () => import(/* webpackChunkName: "ModalSubscrible" */ "./components/ModalAutomaticInvestment")
);

export function AddInvestmentPlan(props: IProps) {
  const intl: Intl = window.__intl__.messages;
  const location_text: string = intl["kyc.certify.location"];
  const breadcrumb_first_text: string = intl["savings.automatic.add.breadcrumb.first"];
  const breadcrumb_second_text: string = intl["savings.automatic.add.breadcrumb.second"];
  const title_text: string = intl["savings.automatic.add.title"];
  const choose_plan_text: string = intl["savings.automatic.add.choose.plan"];
  const title_amount_cycle_text: string = intl["savings.automatic.add.title.amount.cycle"];
  const available_text: string = intl["savings.automatic.add.available"];
  const amount_cycle_text: string = intl["savings.automatic.add.amount.cycle"];
  const cycle_text: string = intl["savings.automatic.add.cycle"];
  const back_text: string = intl["button.back"];
  const next_text: string = intl["button.next"];
  const success_text: string = intl["savings.automatic.add.success"];
  const fail_text: string = intl["savings.automatic.add.fail"];
  const amount_err_tips_first_text: string = intl["savings.automatic.add.error.tips.first"];
  const amount_err_tips_second_text: string = intl["savings.automatic.add.error.tips.second"];
  const amount_err_tips_third_text: string = intl["savings.automatic.add.error.tips.third"];

  const formatwebp = localStorage.getItem("formatwebp") || "";
  const supportWebp = formatwebp ? `?x-oss-process=image/${formatwebp}` : "";

  const info = window?.__history__?.location?.state.info;
  const { coinList } = props;
  const submitInfo = {
    cycle: "",
    amount: "",
    fee: "",
    revenueRate: "",
    payCoin: "USD",
    rateList: []
  };

  useEffect(() => {
    getInitData();
  }, []);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSafetyVerificationModal, setShowSafetyVerificationModal] = useState<boolean>(false);
  const [available, setAvailable] = useState<number>();
  const [checkedValue, setCheckedValue] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [cycle, setCycle] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [confirmInfo, setConfirmInfo] = useState(submitInfo);
  const [isTransferYield, setIsTransferYield] = useState<boolean>(false);

  // 初始化数据,
  const getInitData: () => void = async () => {
    setLoading(true);
    const param = {
      currency: info.payOptionList[0].currency
    };
    availableSpot(param)
      .then((res) => {
        if (res?.code == 0) {
          setAvailable(res.data?.balance);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    setConfirmInfo((prevInfo) => {
      prevInfo.fee = info.feeRate;
      prevInfo.revenueRate = info.revenueRate;
      prevInfo.payCoin = info.payOptionList[0]?.currency;
      return { ...prevInfo };
    });
  };
  const checkedPlan = (e, param) => {
    if (e.target.checked) {
      setCheckedValue(param.id);
    } else {
      setCheckedValue("");
    }
    setConfirmInfo((prevInfo) => {
      prevInfo.rateList = [...resetRate(param.currencyProportion)];
      return { ...prevInfo };
    });
  };

  //计算每种币种比例 数据结构可优化 @后端
  const getCoinRate = (rate) => {
    return resetRate(rate).map((item, index) => {
      return (
        <div key={index} className={styles.rateItem}>
          <div className={styles.rateLeft}>
            <img src="" alt="" />
            {item.denomintaion?.toLowerCase() != "usd" ? (
              <img
                className={styles.icon}
                src={coinList[item.denomintaion]?.picturePath + supportWebp}
              />
            ) : (
              <img className={styles.icon} src={BENCHMARK_CURRENCY_ICON} />
            )}
            <div>
              <p className={styles.fullName}>{convertUSD2USDC(item.denomintaion)}</p>
              <p className={styles.coinName}>
                {convertUSD2USDC(coinList[item.denomintaion]?.name)}
              </p>
            </div>
          </div>
          <p className={styles.rateNum}>{item.percent}</p>
        </div>
      );
    });
  };

  //输入金额
  const handleChangeAmount = (e) => {
    let params = e.target.value.replace(/[^\d.]/g, "").replace(/\.{2,}/g, "."); //取数字
    const value = params.replace(/^(-)*(\d+)\.(\d{1,2}).*$/, "$1$2.$3"); //取两位
    setAmount(value);
    setConfirmInfo((prevInfo) => {
      prevInfo.amount = value;
      return { ...prevInfo };
    });
  };
  //判断金额是否符合
  const checkAmount = (param) => {
    let value = Number(param);
    const max = info.payOptionList[0].maxAmount;
    const min = info.payOptionList[0].minAmount;
    const increment = info.payOptionList[0].incrementAmount;
    const currency = info.payOptionList[0].currency;
    if (value > available) {
      return amount_err_tips_first_text;
    }
    if (value < min) {
      return `${amount_err_tips_second_text} ${decimalPointNoMoreX(min, 2)} ${convertUSD2USDC(
        currency
      )}`;
    }
    if (value > max) {
      return `${amount_err_tips_third_text} ${decimalPointNoMoreX(max, 2)} ${convertUSD2USDC(
        currency
      )}`;
    }
    //购买金额必须是递增金额倍数
    const formatAmount = parseFloat((Number(value) * 100000000).toPrecision(12));
    const formatIncrementAmount = parseFloat((Number(increment) * 100000000).toPrecision(12));
    if (formatAmount % formatIncrementAmount != 0) {
      return props.intl.formatMessage(
        { id: "subscribe.amount.not.increment" },
        {
          incrementAmount: increment
        }
      );
    }
    return "success";
  };
  //修改定投频率
  const handleChangeCycle = (value: string) => {
    setCycle(value);
    setConfirmInfo((prevInfo) => {
      prevInfo.cycle = value;
      return { ...prevInfo };
    });
  };

  //点击下一步弹框
  const showOrCloseModal = () => {
    setModalVisible(!modalVisible);
  };

  //定投转活期理财
  const isTransfer = (value) => {
    setIsTransferYield(value);
  };

  //验证码
  const hideOK = (code: string) => {
    fetchSubscribe(code);
  };

  const handleSubscribe = () => {
    if (USER_ROLE_POWER[localStorage.getItem("user_auth")]?.actionForOthers) {
      setShowSafetyVerificationModal(true);
    } else {
      fetchSubscribe();
    }
  };

  //提交请求
  const fetchSubscribe = (emailCode?: string) => {
    setLoading(true);
    const param = {
      productId: info.id,
      amount: amount,
      period: Automatice_Investment_Cycle[cycle].code,
      proportionId: checkedValue,
      payOptionId: info.payOptionList[0].id,
      autoFinancing: isTransferYield,
      verifyCode: emailCode || null
    };
    addAutomaticInvestmentPlan(param)
      .then((res) => {
        if (res?.code == 0) {
          if (res?.data == true) {
            message.destroy();
            message.success(success_text, 2).then(() => {
              window?.__history__?.replace("/yield/myInvestmentPlan");
            });
            setShowSafetyVerificationModal(false);
            showOrCloseModal();
          } else {
            message.destroy();
            message.error(intl?.[res?.msg] ?? fail_text);
          }
        } else {
          message.destroy();
          message.error(intl?.[res?.msg] ?? fail_text);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section className={styles.addInvestmentPlan}>
      <Spin spinning={loading}>
        <Breadcrumb>
          <Breadcrumb.Item>{location_text}: </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={{ pathname: "/yield", query: { tabType: "2" } }}>
              {breadcrumb_first_text}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{breadcrumb_second_text}</Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.container}>
          <p className={styles.title}>{title_text}</p>
          <p className={styles.smallTitle}>{choose_plan_text}</p>
          <Row gutter={[60, 16]}>
            {info?.proportionList.length > 0 &&
              info?.proportionList.map((item, index) => {
                return (
                  <Col span={8} key={item.id}>
                    <Checkbox
                      style={{ marginBottom: 18, fontSize: 16, color: "#fff" }}
                      checked={item.id == checkedValue}
                      onChange={(e) => checkedPlan(e, item)}>
                      {item.name}
                    </Checkbox>
                    <div className={styles.coinRateContent}>
                      {getCoinRate(item.currencyProportion)}
                    </div>
                  </Col>
                );
              })}
          </Row>
          <p className={styles.smallTitle}>{title_amount_cycle_text}</p>
          <p className={styles.balanceTitle}>{available_text}</p>
          <p className={styles.balanceValue}>
            {numberToThousands(decimalPointNoMoreX(available))}{" "}
            {convertUSD2USDC(info?.payOptionList[0]?.currency)}
          </p>
          <div className={styles.cycleContainer}>
            <div className={styles.leftCycle}>
              <p>{amount_cycle_text}</p>
              <Input
                bordered={false}
                placeholder={intl["placeholder.enter"]}
                value={amount}
                onChange={handleChangeAmount}
              />
              {
                <div className={styles.limitTips}>
                  {amount != "" && checkAmount(amount) != "success" && <p>{checkAmount(amount)}</p>}
                </div>
              }
            </div>
            <div className={styles.rightCycle}>
              <p>{cycle_text}</p>
              <Select
                className={styles.selectArea}
                placeholder={intl["placeholder.select"]}
                onChange={handleChangeCycle}
                bordered={false}
                size="large">
                {info.periodList?.map((item, index) => {
                  return (
                    <Option value={item} key={index}>
                      {Automatice_Investment_Cycle[item].text}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <div className={styles.btns}>
            <Button
              className={styles.actionBtn}
              style={{ width: 128, height: 40, marginRight: 16 }}
              onClick={() => {
                // window?.__history__?.goBack();
                window.__history__.push({
                  pathname: "/yield",
                  query: { tabType: "2" }
                });
              }}>
              {back_text}
            </Button>
            <Button
              disabled={
                checkedValue && amount && cycle && checkAmount(amount) == "success" ? false : true
              }
              style={{ width: 128, height: 40 }}
              onClick={showOrCloseModal}
              type="primary">
              {next_text}
            </Button>
          </div>

          <Suspense
            fallback={
              <div className={styles.history + " middleAlign"}>
                <Spin />
              </div>
            }>
            <ModalAutomaticInvestment
              type="add"
              loading={loading}
              visible={modalVisible}
              handleOk={handleSubscribe}
              checked={isTransferYield}
              handleCancel={showOrCloseModal}
              handleChangeIsTransfer={isTransfer}
              info={confirmInfo}
              showAutoFinancing={info.canAutoFinancing === 1}
            />
          </Suspense>
          <Suspense
            fallback={
              <div className={styles.history + " middleAlign"}>
                <Spin />
              </div>
            }>
            <ModalSafetyVerification
              confirmLoading={loading}
              visible={showSafetyVerificationModal}
              handleCancel={() => {
                setShowSafetyVerificationModal(false);
              }}
              onOk={hideOK}
              businessType={-1}
              isCustomerValidate={true}
            />
          </Suspense>
        </div>
      </Spin>
    </section>
  );
}

export default WithCoinList(AddInvestmentPlan);
