import React from "react";
import styles from "@/views/login/Login.module.less";
import { map } from "lodash";

const LIST = [
  {
    content: [
      "This User Service Agreement is entered into between you (hereinafter referred to as “you” or “your”) and Aspen Digital (as defined below). By accessing, downloading, using or clicking on “I agree” to accept any Aspen Digital Services (as defined below) provided by Aspen Digital, you agree that you have read, understood and accepted all of the terms and conditions stipulated herein (“<strong>these Terms</strong>”), the Privacy Policy (the “<strong>Privacy Policy</strong>”) and the Know-Your-Customer / Anti-Money Laundering Policy (“<strong>KYC/AML Policy</strong>”) (collectively, the “<strong>Agreement</strong>”) as may be amended from time to time at the sole discretion of the Company (as defined below)."
    ]
  },
  {
    title: "1.Definitions",
    content: [
      {
        title: "1.1.In these Terms:-",
        content: [
          "1.1.1.<strong>“Account”</strong> refers to the foundational virtual accounts, including main accounts and subaccounts, which are opened by Aspen Digital for Users to record on Aspen Digital their usage of Aspen Digital Services, transactions, asset changes and basic information. Aspen Digital Accounts serve as the basis for Users to enjoy and exercise their rights on Aspen Digital.",
          "1.1.2.<strong>“Aspen Digital”</strong> refers to an ecosystem comprising Aspen Digital websites, mobile applications and other applications that are, or will be in the future, developed to offer Aspen Digital Services. ",
          "1.1.3.<strong>“Aspen Digital Services”</strong> refer to various services provided to you by Aspen Digital that are based on Internet and/or blockchain technologies and offered via Aspen Digital websites, mobile applications and other forms (including new ones enabled by future technological development).",
          "1.1.4.<strong>“AUM”</strong> means the total market value of the assets under the management of the Company on behalf of its Users.",
          "1.1.5.<strong>“Business Days”</strong> means a day (excluding Saturday and any day on which a tropical cyclone warning no. 8 or above is hoisted or remains hoisted between 9:00 a.m. and 5:00 p.m. and is not lowered at or before 5:00 p.m. or on which a “black” rainstorm warning is hoisted or remains in effect between 9:00 a.m. and 5:00 p.m. and is not discontinued at or before 5:00 p.m.) on which licensed banks in Hong Kong are generally open for business throughout their normal business hours.",
          "1.1.6.<strong>“Company”, “we”, “our” or “us”</strong> refers to Aspen Digital Limited, a company incorporated under the laws of the British Virgin Islands, and all other parties that run Aspen Digital, including but not limited to legal persons, unincorporated organizations and teams that provide Aspen Digital Services and are responsible for such services. Under the Agreement, the Company may change or expand as Aspen Digital’s business adjusts, in which case, the changed entity shall perform their obligations under these terms with you and provide services to you, and such change does not affect your rights and interests under these terms. ",
          "1.1.7.<strong>“Crypto-to-crypto Trading”</strong> refers to spot transactions in which one digital currency is exchanged for another digital currency.",
          "1.1.8.<strong>“Digital asset”, “digital currency” and “virtual currency”</strong> mean digital assets or blockchain tokens which are based on the cryptographic protocol of a computer network that may be (i) centralized or decentralized, (ii) closed or open-source, and (iii) used as a medium of exchange and/or stored value.",
          "1.1.9.<strong>“Hong Kong”</strong> means the Hong Kong Special Administrative Region of the People's Republic of China.",
          "1.1.10.<strong>“Management Fee”</strong> means the asset management fee that applied to your Account and collected by the Company from time to time.",
          "1.1.11.<strong>“Monthly Average AUM”</strong> means the monthly average AUM of the funds in your Account.",
          "1.1.12.<strong>“Platform”</strong> refers to the platform or environment, in whatever form, on which Aspen Digital Services are provided, including but not limited to websites, mobile applications and other forms (including new ones enabled by future technological development).",
          "1.1.13.<strong>“Users”</strong> refer to all individuals, institutions or organizations that access, download or use Aspen Digital or Aspen Digital Services and who meet the criteria and conditions stipulated by Aspen Digital including but not limited to be satisfactorily verified under the KYC/AML Policy at the sole discretion of the Company. If there exist other agreements for such entities as developers, distributors, market makers, and Digital Currencies exchanges, such agreements shall be followed."
        ]
      }
    ]
  },
  {
    title: "2.General Provisions",
    content: [
      "2.1.Aspen Digital reserves the right to change or modify these Terms and any other terms contained in the Agreement in its discretion at any time. Aspen Digital will effect such changes by updating the terms on its website. Any and all modifications or changes to these terms will become effective upon publication on the website or release to Users. The continued use of Aspen Digital Services by any User shall be deemed as acceptance of the modified agreement and rules. You are advised to frequently review these terms to ensure your understanding of the terms and conditions that apply to your access to and use of Aspen Digital Services."
    ]
  },
  {
    title: "3.Scope of Services",
    content: [
      `3.1.Subject to the terms and conditions of this Agreement, Aspen Digital offers Users an online platform for trading of digital assets. The Platform consists of an automated order matching system that matches open orders of Users in respect of digital assets. The digital assets traded do not constitute 'security tokens' or digital assets with characteristics of 'securities' under applicable securities laws.`,
      "3.2.Depending on the User’s place of residence, verification status, compliance with the Agreement and any other factors as may be ultimately determined by the Company at its sole discretion, the User, or certain Users, may not be able to use all the functions and features of Aspen Digital. It is the User’s responsibility to follow those rules and laws in his/her place of residence and/or place from which the User accesses Aspen Digital.",
      "3.3.The fact that any certain services, products, features or functions shall not constitute a representation or warranty that the same is, or will in the future become, available to any or all of the Users or otherwise provided by Aspen Digital. The availability of any services, products, features or functions will ultimately be based on what is provided to Users on Aspen Digital which may be changed at any time by the Company without prior notification to the Users. ",
      {
        title: "3.4.We further reserve the right and without liability to you to:",
        content: [
          "3.4.1.update, modify, suspend, disable, terminate or restrict access to any Aspen Digital Services or change any features, component or content thereof based on its business development plan;",
          "3.4.2.decline, suspend, cancel, reverse, void or partially execute any trading instruction; ",
          "3.4.3.reverse, cancel, clawback, change any terms or refuse to honour any reward, bonus or pay-out for any incentive programmes regardless of your entitlement; or",
          "3.4.4.take any of the above actions against only specific, but not all, Users."
        ]
      },
      "3.5.You understand and agree that we may engage any third-party service provider or subcontractor to perform any or all of the services provided hereunder. We shall not be liable for any delay, loss or damage of any kind incurred from any services provided by any such third party service provider or subcontractor. All claims in connection with the act of any third party service provider or subcontractor shall be brought solely and directly against such party and/or its agents. ",
      "3.6.Aspen Digital may from time to time announce marketing promotions subject to the terms and conditions that may be published in relation to such marketing promotions. Subject to the sole discretion and final decision, Users may be eligible to participate in such promotions and receive bonuses in digital assets which may be deposited into a User’s Account. Aspen Digital reserves the right to vary, extend, terminate and/or cancel such promotions or amend the terms and conditions entailed at any time at its sole discretion without prior notice. Subject to the applicable terms and conditions, Aspen Digital reserves the right to declare such Users ineligible to receive benefits or withdraw or deduct such bonuses or benefits already credited to a User’s Account. "
    ]
  },
  {
    title: "4.Account Registration ",
    content: [
      {
        title: "4.1.Registration",
        content: [
          "4.1.1.All Users must apply for an Account at before accessing Aspen Digital. When you register an Account, you must provide your real name, email address and password, and accept the Agreement. You agree to provide complete and accurate information when opening a Aspen Digital Account, and agree to timely update any information you provide to Aspen Digital to maintain the integrity and accuracy of the information. Each individual User (including any User that is an individual, business or legal entity) may maintain only one main account at any given time. ",
          "4.1.2.The Company may refuse at any time, in its sole discretion, to open a Aspen Digital Account for you or otherwise provide any services to any User. The Company also reserves the right to suspend or terminate any Aspen Digital Services or access to Aspen Digital to any Users. "
        ]
      },
      {
        title: "4.2.Eligibility",
        content: [
          "4.2.1.By registering to use an Account, you represent and warrant that (i) as an individual, you are at least 18 or are of legal age to form a binding contract under applicable laws; (ii) as an individual, legal person, or other organization, you have full legal capacity and sufficient authorizations to enter into these Terms; (iii) you have not been previously suspended or prohibited from using Aspen Digital Services; (iv) you do not currently have an Account; (v) you are not prohibited to use Aspen Digital Services under any laws applicable to you. If you act as an employee or agent of a legal entity, and enter into these Terms on their behalf, you represent and warrant that you have all the necessary rights and authorizations to bind such legal entity; (vi) your use of Aspen Digital Services will not violate any and all laws and regulations applicable to you, including but not limited to regulations on anti-money laundering, anti-corruption, and counter-terrorist financing; (vii) you have not been included in any trade embargoes or economic sanctions list (such as the United Nations Security Council Sanctions List), the list of specially designated nationals maintained by the Office of Foreign Assets Control of the U.S. Department of The Treasury, or the denied persons or entity list of the U.S. Department of Commerce. We reserve the right to choose markets and jurisdictions to conduct business, and may restrict or refuse, in its discretion, the provision of Aspen Digital Services in certain countries or regions.",
          "4.2.2.If you are registering to use the Aspen Digital Services on behalf of a legal entity, you represent and warrant that (i) such legal entity is duly organized and validly existing under the applicable laws of the jurisdiction of its organization; (ii) you are duly authorized by such legal entity to act on its behalf; and (iii) such organization (and any affiliate entity) must not have been previously suspended or removed from the Aspen Digital Services or any other service or product offered by the Company.",
          "4.2.3.Aspen Digital reserves the right to change, modify or impose additional restrictions at its discretion at any time without giving prior explicit notice to the Users."
        ]
      },
      {
        title: "4.3.User Identity Verification",
        content: [
          "4.3.1.You acknowledge that Aspen Digital may require you to provide personal information and other information for identity verification in order to provide the full extent of Aspen Digital Services in accordance with the KYC/AML Policy. The collection, use and sharing of any such information shall be governed by the KYC/AML Policy. "
        ]
      }
    ]
  },
  {
    title: "5.Deposit and Withdrawal of Digital Assets",
    content: [
      {
        title: "5.1.Deposit",
        content: [
          "5.1.1.Upon successful user identity verification, you may deposit digital assets into your Account with a specified address provided by us. You may deposit only specified digital assets that is supported by the Platform, which may be updated from time to time at Aspen Digital’s sole discretion.",
          "5.1.2.You agree and acknowledge that Aspen Digital has the right to set deposit limits and decline to approve deposits exceeding such limits or if the User fails to provide additional information and documentation as may be requested for deposit exceeding a certain amount in accordance with the KYC/AML Policy. ",
          "5.1.3.You represent and warrant that you are, and shall remain at all times, the ultimate and effective legal and beneficial owner of any digital assets held by you in your Account.",
          "5.1.4.You agree that any digital assets deposited may be pooled with the digital assets of other Users and may not be identifiable as separate and distinct documents of title or other electronic records. In case of an irreconcilable shortfall, you may not receive your full entitlement and may shore in the shortfall pro rata.",
          "5.1.5.In the event of any deposits into your Account by error, whether by human error or system error, Aspen Digital reserves the right to clawback, withdraw or deduct such erroneous deposits. You agree to co-operate with Aspen Digital in relation to such withdrawals or deductions."
        ]
      },
      {
        title: "5.2.Withdrawal",
        content: [
          "5.2.1.Subject to the terms of this Agreement, you may request withdrawal of your digital assets by making a withdrawal instruction through the Platform. Aspen Digital shall credit the digital assets available in your account to an address specified in your Account.",
          "5.2.2.You agree and acknowledge that Aspen Digital has the right to set withdrawal limits and decline to approve withdrawals in the event withdrawals are made to an address rated as ‘High Risk’ by our transaction monitoring team at their sole discretion.",
          "5.2.3.We may at our discretion, withhold your request to withdraw digital assets to you in the event that your open positions show notional losses or additional digital assets are required to meet any margin requirements on open positions or if you have any liability towards us or there is an unresolved dispute between you and the Company.",
          "5.2.4.Aspen Digital may, but is not obliged to, provide withdrawal services of fiat currencies. Such withdrawal may only be available in certain jurisdiction and is subject to the local laws, regulations and banking practices. Where Aspen Digital provides such withdrawal services, the designated receiving bank account must be in the exact same name as the User’s verified identity (joint name accounts will not be accepted), unless otherwise approved by Aspen Digital. You agree and acknowledge that the Company does not guarantee, warrant or represent that any balance of fiat currencies in your Account can be withdrawn to your bank account or elsewhere. ",
          "5.2.5.In the event that your Account is suspended or terminated for any reason in accordance with the terms of this Agreement, Aspen Digital shall by notice to give you a reasonable period of time to withdraw any assets in your Account. Your failure to withdraw all the funds within the specified period of time shall be deemed as your forfeiture of any remaining balance in your Account and we shall not be liable for any loss or damages incurred therefrom."
        ]
      }
    ]
  },
  {
    title: "6.Management Fee",
    content: [
      "6.1.In consideration of the asset management services provided by the Company, a Management Fee shall be deducted directly from the User’s Account on a monthly basis. The Company may, at its own discretion, waive or reduce the amount of such Management Fee payable by the User as it deems appropriate.",
      "6.2.The Management Fee shall be an annualized percentage of the User’s AUM as determined at the sole discretion of the Company and represented, whether implicitly or explicitly to the User. The Management Fee shall be accrued at the end of each calendar month based on the Monthly Average AUM of such User's Account and immediately become payable by the User.",
      "6.3.The Company reserves the rights to directly deduct any accrued Management Fee from the User’s Account, and, in the event that the User fails to pay the Management Fee within a reasonable time after such Management Fee becomes payable, impose an interest on the outstanding Management Fee and/or restrict certain functions on Aspen Digital, including but not limited to the User’s ability to withdraw any assets, until all outstanding Management Fees have been fully paid up.",
      "6.4.You acknowledge and agree that the bases, terms, conditions, rates, methods and models for generating and calculating the Management Fee and Monthly Average AUM shall be determined and may be changed from time to time by the Company at its sole discretion, subject to any specific restrictions provided under the applicable laws. The Company shall not be obliged to disclose any internal records relating to the calculation and accrual of the Management Fee and Monthly Average AUM to Users."
    ]
  },
  {
    title: "7.Usage Requirements and Restrictions",
    content: [
      {
        title:
          "7.1.You agree not to misuse the Platform or assist anyone else to do so, including but not limited to doing or attempting to do any of the following in connection with the Platform and Aspen Digital:",
        content: [
          "7.1.1.use the Platform for market manipulation, regardless of whether prohibited by law;",
          "7.1.2.use the Platform for unlawful or unauthorised purposes;",
          "7.1.3.re-sell or attempt to benefit for commercial purposes from any data, content or information available on the Platform;",
          "7.1.4.replicate or bypass the navigational structure or presentation of Aspen Digital in any way, in order to obtain or attempt to obtain any materials, documents or information in any manner not purposely provided through Aspen Digital;",
          "7.1.5.probe, scan, or test the vulnerability of any system or network of Aspen Digital;",
          "7.1.6.circumvent any security or authentication measures or service use limits or otherwise access, tamper with, or use non-public parts or functions of the Platform;",
          "7.1.7.interfere with or disrupt any user, host, or network, including but not limited to overloading, flooding, spamming, or mail-bombing any part of Aspen Digital;",
          "7.1.8.reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Platform or any related technology of Aspen Digital that is not open source;",
          "7.1.9.access, search, or create accounts for the Platform by any means other than our publicly supported interfaces (for example, 'scraping' or creating accounts in bulk);",
          "7.1.10.send unsolicited communications, promotions or advertisements, or spam;",
          "7.1.11.forge any headers, impersonate, or otherwise manipulate identification, to disguise your identity in any correspondence with Aspen Digital;",
          "7.1.12.send altered, deceptive, or false source-identifying information, including 'spoofing' or 'phishing';",
          "7.1.13.conduct surveys, contests, or pyramid schemes, or promote or advertise products or services without appropriate authorisation;",
          "7.1.14.abuse referrals or promotions;",
          "7.1.15.engage in malicious trading activities, market manipulation, wash trades or other forms of market manipulation;",
          "7.1.16.post, publish, upload, display, distribute, or share materials that are unlawful, inappropriate, profane, pornographic, obscene, indecent, libellous, defamatory, abusive, or knowingly false, and/or that infringe intellectual property rights of others;",
          "7.1.17.violate any terms of the Agreement;",
          "7.1.18.violate public interests, public morals, or the legitimate interests of others, including but not limited to the interreference with, disruption of, negatively affecting, other Users’ access to the Platform and usage of Aspen Digital Services;",
          "7.1.19.violate the laws or regulations that may be applicable to you; or",
          "7.1.20.violate the privacy or infringe the rights of others."
        ]
      },
      "7.2.We reserve the right to limit your use of the Platform and the Services, including the right to restrict, suspend or terminate your account and levy administrative penalties payable by you at our sole discretion if we believe, in our sole discretion, you are in breach of any terms of this Agreement or are otherwise misusing the Platform or any services we provide.",
      "7.3.The Account can only be used by the registrant of such Account. If you suspect or become aware of any unauthorized use of your username and password, you should notify Aspen Digital immediately. We assume no liability for any loss or damage arising from the use of Aspen Digital Account by you or any third party with or without your authorization."
    ]
  },
  {
    title: "8.License and Intellectual Property",
    content: [
      "8.1.Subject to the terms and condition of this Agreement, Aspen Digital grants you a revocable, limited, royalty-free, non-exclusive, non-transferable, and non-sublicensable license to access the Platform and use Aspen Digital Services for personal, but not commercial, purposes. Aspen Digital reserves all rights not expressly granted in this Agreement. ",
      "8.2.This Agreement shall only grant a limited license to access the Platform and use Aspen Digital Services and you agree that Aspen Digital does not transfer the ownership or intellectual property rights of any Aspen Digital intellectual property to you or anyone else. All intellectual property rights subsisting in the Platform, including but not limited to the text, graphics, sounds, user interfaces, visual interface, photos, computer code, programs, software, products, information and documents, as well as the design, structure, selection, coordination, expression, look and feel, and layout of any content included in the services or provided through the Platform, are exclusively owned, controlled and/or licensed by the Company or its affiliates. You must not upload, post, publish, reproduce, transmit or distribute any content or component of our Platform in any way, or create any derivative works with respect to any such content or component.",
      "8.3.Our name “Aspen Digital” and our marks and logos are our trade marks (be it registered or unregistered) and shall not be used without our express prior written consent.",
      "8.4.By submitting, posting or displaying Your Content, you grant us a worldwide, non-exclusive, royalty-free licence (with the right to sublicense) to use, process, copy, reproduce, adapt, modify, publish, transmit, display and distribute such content in any and all media or through any distribution channels (now known or later developed), subject to the applicable provisions in our Privacy Policy."
    ]
  },
  {
    title: "9.Trading Services",
    content: [
      {
        title: "9.1.Crypto-to-Crypto Trading",
        content: [
          "9.1.1.Crypto-to-crypto Trading is offered on a self-service basis and Aspen Digital may charge spreads and/or transaction fees for executing any orders. You acknowledge and understand the price shown on the Platform, which is subject to delays and do not take into account any spread and/or fees that may be incurred in such transaction, may not accurately reflect the actual mark price at which the orders will be executed.",
          "9.1.2.You agree to pay Aspen Digital the transaction fees that may be imposed on any order as may be determined, updated and published by Aspen Digital at any time at its sole discretion. Any updated fees will apply to any transactions that occur following the effective date of the updated fees. You authorize Aspen Digital to deduct from your account any applicable fees that you owe under these Terms."
        ]
      },
      {
        title: "9.2.Margins Trading",
        content: [
          "9.2.1.You agree that you should only engage in margin trading if you fully understand the underlying risk and have familiarised yourself and agreed with the margin trading requirements and rules which may be published and updated from time to time at our sole discretion on the “Margin Trading FAQ” page. ",
          "9.2.2.You understand and acknowledge Aspen Digital may take measures, in its sole discretion and on your behalf, to reduce your potential losses or liquidate your positions in accordance with the procedures set out on the “Margin Trading FAQ” page. ",
          "9.2.3.You agree to conduct all margins trading and/or borrowing on your own, and be fully responsible for your activities. Aspen Digital assumes no liability for any loss or damage caused by your use of any Aspen Digital Services or your unawareness of the risks associated with the use of digital assets or with your use of Aspen Digital Services."
        ]
      }
    ]
  },
  {
    title: "10.Savings Service",
    content: [
      "10.1.Aspen Digital offers savings services to provide Users with value-added services for their idle digital assets. Assets put under the Aspen Digital’s savings service will be used in cryptocurrency leveraged borrowing and other businesses.",
      "10.2.By using Aspen Digital’s savings service, you will unconditionally authorize Aspen Digital to distribute and grant the leveraged interest in the manner published on the Platform which shall be determined and maybe changed from time to time at the sole discretion of the Company.",
      "10.3.You shall abide by relevant laws and regulations to ensure that the sources of digital assets are legitimate and compliant when using Aspen Digital’s savings service",
      "10.4.Before using Aspen Digital’s savings service, you should fully understand the underlying risk risks involved. You agree that all investment operations conducted on Aspen Digital represent your true investment intentions and that unconditionally accept the potential risks and benefits of your investment decisions.",
      "10.5.Aspen Digital reserves the right to suspend or terminate its savings service at any time without providing prior notice to its Users. Aspen Digital does not take any responsibility if the final execution fails to match your expectations due to network delay, computer system failures and other force majeure."
    ]
  },
  {
    title: "11.Disclaimer and Limitation of Liabilities",
    content: [
      "11.1.To the maximum extent permitted under applicable law, the Company expressly disclaims, and you waive, any and all other warranties of any kind, whether express or implied, including, without limitation, warranties of merchantability, fitness for a particular purpose, title or non-infringement or warranties arising from course of performance, course of dealing or usage in trade. ",
      {
        title:
          "11.2.The Company does not represent or warrant that any materials or information on the Platform or otherwise provided as part of Aspen Digital Services are accurate, complete, reliable, current, error-free, or free of viruses or other harmful components and the Company will not be liable for any losses or damages relating to the same, including but not limited to the following: ",
        content: [
          "11.2.1.any inaccuracy, defect, omission, interruption, error or delay in the transmission of digital assets price data;",
          "11.2.2.regular or unscheduled maintenance of the Platform and service interruption and change resulting from such maintenance; and",
          "11.2.3.any damage caused by illegal actions of other third parties or actions without authorized by the Company."
        ]
      },
      "11.3.Any links to third-party websites, platform, clients or service providers from the Platform or otherwise as part of Aspen Digital Services does not imply endorsement by Aspen Digital or the Company of any product, service, information or disclaimer presented therein, nor does Aspen Digital guarantee the accuracy of the information contained on them. We will not be liable for any loss suffered from using such third-party product and service. You are advised to should read and understand the applicable policies and terms and conditions of any third party website or service providers carefully.",
      "11.4.Notwithstanding the foregoing, to the maximum extent permitted by applicable law, the Company, its affiliates and their respective shareholders, members, directors, officers, employees, attorneys, agents or representatives shall not be liable for any incidental, indirect, special, punitive, consequential or similar damages or liabilities, including but not limited to damages for loss of data, information, revenue, profits or other businesses or financial benefits, arising out of its provision of Aspen Digital Services, whether under contract, statute, strict liability or other theory except to the extent of that such damages were a result of Aspen Digital gross negligence, fraud, willful misconduct or intentional violation of law as may be ruled by a competent court. ",
      "11.5.Notwithstanding the foregoing, in no event will the liability of the Company, its affiliates and their respective shareholders, members, directors, officers, employees, attorneys, agents or representatives exceed the amount of the fees paid by you to Aspen Digital under this Agreement in the twelve-month period immediately preceding the event giving rise to the claim for liability."
    ]
  },
  {
    title: "12.Indemnification",
    content: [
      {
        title:
          "12.1.You shall indemnify and hold harmless the Company, its affiliates, contractors, licensors, and their respective directors, officers, employees and agents from and against any claims, actions, proceedings, investigations, demands, suits, costs, expenses and damages (including attorneys’ fees, fines or penalties imposed by any regulatory authority) arising out of or related to the following:-",
        content: [
          "12.1.1.your conduct on Aspen Digital;",
          "12.1.2.your breach or our enforcement of this Agreement; or ",
          "12.1.3.your violation of any applicable law, regulation, or rights of any third party during your use of Aspen Digital Services. "
        ]
      },
      "12.2.Where Section 9.3 becomes applicable, the Company shall have the right, in its sole discretion, to control and determine the approach for any action or proceeding."
    ]
  },
  {
    title: "13.Suspension and Termination of Services",
    content: [
      {
        title:
          "13.1.You agree that Aspen Digital shall have the right to immediately suspend your Account, freeze, lock or place on hold any portion or all of the digital assets or funds in your Accounts, and suspend your access to Aspen Digital for any reason, including but not limited to the following:-",
        content: [
          "13.1.1.if we suspect any such Accounts to be in violation of any terms in this Agreement or any applicable laws and regulations, ",
          "13.1.2.if we suspect any unusual activities in or unauthorized access to the Account;",
          "13.1.3.if we suspect or are informed that any digital assets or funds held in your Account are are not lawfully possessed by you;",
          "13.1.4.if we are required to do so by a court order or any regulatory or governmental bodies;",
          "13.1.5.if you become subject to any criminal investigation, proceeding or litigation;",
          "13.1.6.if any information that you have provided is untruthful, inaccurate, outdated or incomplete;",
          "13.1.7.any other circumstances where Aspen Digital deems at its sole discretion it should terminate Aspen Digital Services."
        ]
      },
      "13.2.You agree that Aspen Digital shall not be liable to you for any suspension or termination of your access to all or any portion of Aspen Digital Services, any suspension or termination of your Account or any digital assets or funds being frozen, locked or placed on hold in accordance with this Agreement. Aspen Digital shall reserve the right to keep and use the transaction data or other information related to such Accounts in accordance with the Privacy Policy. Any remaining digital assets or funds in your Account subsequent to a suspension of your account shall be handled in accordance with Section 5.2.5.",
      "13.3.You agree that Aspen Digital shall maintain full custody of the digital assets, funds and User data/information which may be turned over to governmental authorities in the event of the suspension of the Account or the freezing, locking or placing on hold of any digital assets or funds arising from fraud investigations, investigations of violation of the terms of this Agreement or any applicable laws and regulations.",
      "13.4.You may terminate this Agreement at any time by contacting us for assistance in closing your Account. We reserve our right to refuse to close your Account for any reason, including but not limited to if we determine, in our sole discretion, that such closure is being performed in an effort to avoid paying any amounts due to us or otherwise to evade any legal obligations. You should withdraw any remaining balance of digital assets and funds prior to issuing a request to close your Account. "
    ]
  },
  {
    title: "14.Privacy Policy",
    content: [
      "14.1.We will collect, use and share your personal information in accordance with the Privacy Policy."
    ]
  },
  {
    title: "15.No Financial Advice",
    content: [
      "15.1.Aspen Digital does not act as a broker, intermediary, agent, or advisor for the Users and has no fiduciary relationship or obligation to the Users  in connection with any trades or other decisions or activities effected by you using Aspen Digital Services. No communication or information provided to you by Aspen Digital is intended as, or shall be considered or construed as, investment advice, financial advice, trading advice, or any other sort of advice. ",
      "15.2.Aspen Digital does not give any recommendations and any communications shall not be deemed as recommendations that any digital asset should be bought, earned, sold, or held by you. Aspen Digital will not be held responsible for any trading or investment decisions made by you whether or not based on the information provided by Aspen Digital."
    ]
  },
  {
    title: "16.Compliance with Local Laws",
    content: [
      "16.1.You shall be responsible for abiding by local laws in relation to the legal usage of Aspen Digital Services in their local jurisdiction as well as other laws and regulations applicable to Users, including but not limited to any taxation obligations. ",

      "16.2.You agree and acknowledge that the fact that the Platform is accessible in a certain jurisdiction, that the Platform or Aspen Digital allows the use of or uses the official language of such jurisdiction, that the Platform accepts the official currency of such jurisdiction and that the Platform is marketed towards consumers in such jurisdiction shall not be construed as a license to use the Platform or Aspen Digital Services in such jurisdiction. ",
      "16.3.You acknowledge and declare that all your funds deposited into Aspen Digital come from legitimate sources and do not originate from illegal activities. You agree that Aspen Digital may, at its sole discretion, require you to provide or otherwise collect the necessary information and materials to verify the legality of the sources and use of their funds, and the failure to provide the same shall entitle us to suspend your Account without further notice."
    ]
  },
  {
    title: "17.Dispute Resolution and Governing Law",
    content: [
      "17.1.This Agreement shall be governed by the laws of Hong Kong as to all matters including validity, construction, effect, performance and remedies without giving effect to the principles of choice of law thereof. ",
      "17.2.In the event of any dispute, controversy, or claim arising out of or relating to the Agreement or the construction, interpretation, performance, breach, termination, enforceability or validity thereof, or intend to commence or initiate any legal proceedings or arbitration against the Company relating to the defaults of the Company or Aspen Digital, you shall first contact us, no later than sixty (60) days from the date of its discovery of the dispute, controversy or claim, by giving a default notice outlining the nature and basis of the claim or dispute and the specific relief sought to us at support@aspendigital.co and we will attempt to resolve the same dispute internally as soon as possible. You agree to negotiate in good faith to resolve the dispute (which discussions shall remain confidential and be subject to applicable rules protecting settlement discussions from use as evidence in any legal proceeding). In the event the dispute cannot be resolved satisfactorily within thirty (30) Business Days of receipt of the default notice, the Parties shall resolve the dispute by referring to the procedure set forth at Section 16.2. The mechanism contained in this Section 16.1 shall be a prerequisite to commencement of an arbitration proceeding (or any other legal proceeding). During the arbitration, the amount of any settlement offer made by Aspen Digital shall not be disclosed to the arbitrator.",
      "17.3.You and the Company agree that any dispute which cannot otherwise be resolved as provided in the Section 16.1 above shall be referred to and finally resolved by arbitration administered by the Hong Kong International Arbitration Centre (“HKIAC”) under the UNCITRAL Arbitration Rules in force when the Notice of Arbitration is submitted, as modified by the HKIAC Procedures for the Administration of International Arbitration. The law of this arbitration clause shall be Hong Kong law. The place of arbitration shall be Hong Kong. Unless the parties agree otherwise, there shall be only one arbitrator appointed in accordance with the HKIAC Rules. Any arbitration will be conducted in the English language.",
      "17.4.Any arbitration against the Company must be commenced by filing a request for arbitration within one (1) year (inclusive of the internal dispute resolution procedure set forth in Section 16.1) after the date you first know or reasonably should know of the act, omission or default giving rise to the claim. There shall be no right to any remedy for any claim not asserted within that time period. If applicable law prohibits a one-year limitation period for asserting claims, any claim must be asserted within the shortest time period permitted by applicable law.",
      "17.5.You agree that any claims relating to this Agreement or to your relationship with Aspen Digital as a user of Aspen Digital Services (whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and whether the claims arise during or after the termination of these Terms) shall be brought against the other party in an arbitration on an individual basis only and not as a plaintiff or class member in a purported class or representative action. You further agree to waive any right for such claims to be brought, heard, or arbitrated as a class, collective, representative, or private attorney general action, to the extent permissible by applicable law. Combining or consolidating individual arbitrations into a single arbitration is not permitted without the consent of all parties, including Aspen Digital.",
      "17.6.Even if we delay in enforcing the rights of this Agreement, it should not constitute a waiver of our rights and we can still enforce the same in the future for as long as permitted under the applicable law. If we do not immediately request or insisted that you do or cease to do anything that may be required or prohibited under this Agreement, or if we delay in taking steps against you in respect of your breaching any terms of this Agreement, that shall not constitute a waiver of your obligations under this Agreement and shall not prevent us taking steps against you in the future."
    ]
  },
  {
    title: "18.Severability.",
    content: [
      "18.1.If any portion of this Agreement are adjudged or otherwise found to be invalid or unenforceable by a competent court or arbitral tribunal for any reason or to any extent, the remainder of this Agreement will remain valid and enforceable and the invalid or unenforceable portion will be given effect to the greatest extent permitted by law. "
    ]
  },
  {
    title: "19.Entire Agreement",
    content: [
      "19.1.This Agreement constitutes the entire agreement between the parties regarding use of Aspen Digital Services and will supersede all prior written or oral agreements between the parties. No usage of trade or other regular practice or method of dealing between the parties will be used to modify, interpret, supplement, or alter the terms herein."
    ]
  },
  {
    title: "20.Independent Parties",
    content: [
      "20.1.The Company is an independent contractor but not an agent of you in the performance of these Terms. These Terms shall not be interpreted as facts or evidence of an association, joint venture, partnership, or franchise between you and the Company."
    ]
  },
  {
    title: "21.Assignment",
    content: [
      "21.1.You may not assign or transfer any right to use Aspen Digital Services or any of your rights or obligations under these Terms without prior written consent from Aspen Digital, including any right or obligation related to the enforcement of laws or the change of control. Aspen Digital may assign or transfer any or all of its rights or obligations under these Terms, in whole or in part, without notice or obtaining your consent or approval."
    ]
  },
  {
    title: "22.Force Majeure",
    content: [
      "22.1.If the Company or Aspen Digital is unable to perform Aspen Digital Services as stipulated in this Agreement due to circumstances out of our control, including but not limited to, change of law, regulations or policy, or an event of force majeure including but not limited to riots, acts of God, malicious acts of damage, fires, the Company shall not be held liable."
    ]
  }
];

const ServiceAgreement = () => {
  return (
    <div className={styles.privatePolicy}>
      <div className={styles.title}>Aspen Digital User Service Agreement</div>
      <div className={styles.content}>
        {map(LIST, ({ title, content }) => (
          <div>
            <div className={styles.conTitle1} key={title}>
              {title}
            </div>
            {map(content, (item, index) => {
              if (item.title) {
                return (
                  <div key={index}>
                    <div className={styles.conTitle2}>{item.title}</div>
                    {map(item.content, (itemCon, ind) => (
                      <div
                        className={styles.conContent}
                        key={ind}
                        dangerouslySetInnerHTML={{ __html: itemCon }}
                      />
                    ))}
                  </div>
                );
              } else {
                return (
                  <div
                    className={styles.conTitle2}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                );
              }
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceAgreement;
