import qs from "qs";
import axios from "axios";

// 把当前请求信息添加到pendingRequest对象中
const pendingRequest = new Map();
const pendingRetryRequest = new Map();

// 根据当前请求信息，生成请求key
function generateReqKey(config, extra?) {
  if (!config.method && !config.params && !config.data) return "";
  const { method, url, params, data } = config;
  if (data) {
    let _data = data;
    if (Object.prototype.toString.call(data) == "[object String]") {
      _data = qs.parse(data);
    }
    _data.method = method ?? "";
    _data.url = url;
    return qs.stringify(_data);
  }
  if (params) {
    params.method = method ?? "";
    params.url = url;
    return qs.stringify(params);
  }
  if (extra) return qs.stringify({ method, url, extra });
  return qs.stringify({ method, url });
}
function addPendingRequest(config) {
  const requestKey = generateReqKey(config);
  const pendingRequestKey = generateReqKey(config, "pending");
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingRequest.has(requestKey)) {
        pendingRequest.set(requestKey, cancel);
        pendingRetryRequest.set(pendingRequestKey, qs.stringify(config));
      }
    });
}

// 移除请求
function removePendingRequest(config) {
  pendingRequest.forEach((cancelToken, key) => {
    if (!key.includes("/logout")) {
      const _config = qs.parse(key);
      const requestKey = generateReqKey(_config);
      const cancelToken = pendingRequest.get(requestKey);
      if (cancelToken) {
        cancelToken(requestKey);
        pendingRequest.delete(key);
      }
    }
  });
}

/**
 * @param {请求信息} config
 * @returns
 */
function retryRequest(config) {
  // pendingRequest.forEach((value, key, map) => {
  //   // 重新发起axios请求
  //   return axios(value);
  // });

  // 重新发起axios请求
  //return axios(config);
  // pendingRetryRequest.forEach((config, key, map) => {
  //   const _config = qs.parse(config)
  //   return axios.request(_config);
  // });

  const _config = qs.parse(config);
  return axios.request(_config);
}

export { addPendingRequest, removePendingRequest, retryRequest };
