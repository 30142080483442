import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";
import { host } from "@/config/index";
import qs from "qs";
import { message } from "antd";
import { addPendingRequest, removePendingRequest } from "./adapterTools";
import Bugsnag from "@bugsnag/js";
import { DOMAIN_GREEN, DOMAIN_GREEN_PARTNER } from "@/config/constants";
import { formatBugsnagMessage, getCookie, isMobile } from "@/utils";
import { errorcode, errmsg } from "@/config/constants";
import { Intl } from "@/model";

axios.defaults.withCredentials = true;
axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
axios.defaults.headers["Accept-Aspen-Language"] = "aspen_en";

const localStorage = window.localStorage;

const service: AxiosInstance = axios.create({
  baseURL: host,
  timeout: 2 * 60 * 1000
});
// 请求拦截器
service.interceptors.request.use((config: AxiosRequestConfig) => {
  // 把当前请求信息添加到pendingRequest对象中
  addPendingRequest(config);
  if (
    config.method &&
    config.method.toLowerCase() == "post" &&
    config.headers["Content-Type"] == "application/x-www-form-urlencoded"
  ) {
    config.data = qs.stringify(config.data);
  }
  // 配置蓝绿请求头参数
  if (location.href.includes(DOMAIN_GREEN) || location.href.includes(DOMAIN_GREEN_PARTNER)) {
    config.headers["traffic"] = "green";
  }
  const jwtToken = localStorage.getItem(`${location.origin}jwtToken`) ?? "";
  const xsrfToken = getCookie("XSRF-TOKEN") ?? "";
  config.headers.jwtToken = jwtToken;
  config.headers["X-XSRF-TOKEN"] = xsrfToken;
  config.headers.source = isMobile() ? "webapp" : "web";
  if (localStorage.getItem(`${location.origin}agentOperatingToken`)) {
    const agentOperatingToken = localStorage.getItem(`${location.origin}agentOperatingToken`) ?? "";
    if (localStorage.getItem("user_auth") === "partnercustomer") {
      config.headers.agentOperatingToken = agentOperatingToken;
    } else if (localStorage.getItem("user_auth") === "managercustomer") {
      config.headers.customerManagerOperatingToken = agentOperatingToken;
    }
  }
  return config;
});

// 响应拦截器
service.interceptors.response.use(
  // 请求成功
  (res: AxiosResponse) => {
    // 全局异常
    if (res.data.code == "-2") {
      // message.destroy();
      // message.error("System error");
    }
    // 未登陆 , -1: 客户未登陆 -3: 代理操作超时
    if (res?.data?.code == "-1" || res?.data?.code == "-3") {
      if (!window.location.href.includes("login")) {
        localStorage.setItem("error", "logout.out");
        window.location.href = "/login";
      }
      // 如果某个接口提示未登陆，则取消已经发送的请求
      removePendingRequest(res.config);
      //防止调用then错误弹窗提示
      return Promise.reject("logout.out");
    }
    // msg长度超过100时，返回"System error"
    if (res?.data?.msg?.length > 500) {
      message.destroy();
      message.error("System error");
      return Promise.reject({
        msg: "System error"
      });
    }

    // errorcode 错误上报的code集合  errmsg 错误上报的信息集合
    const isErrorValid = Object.keys(errorcode).some(
      (code) => code == String(res?.data?.code) || errorcode[code] == String(res?.data?.msg)
    );
    const isErrorMsg = errmsg.some((msg) => res?.data?.msg?.includes(msg));
    if (isErrorValid || isErrorMsg) {
      Bugsnag.notify(formatBugsnagMessage(JSON.stringify(res, null, "\t")));
    }

    return res;
  },
  // 请求失败
  (error: any) => {
    // 请求已发出，但是不在2xx的范围
    const { response } = error;
    const intl: Intl = window.__intl__.message;
    const errorMsgTips =
      "Connection Failed.Please try again later.Any question, please contact support@aspendigital.co.";

    if (response) {
      // 上报请求错误
      Bugsnag.notify(formatBugsnagMessage(JSON.stringify(response, null, "\t")));

      const { error, status, code, message: errMessage } = response.data;
      const errMsg =
        `${error ?? code}: ${status || intl[errMessage] || errMessage}` ?? errorMsgTips;
      message.destroy();
      message.error(errMsg);
      return Promise.reject({ msg: errMsg });
    } else {
      // 请求取消不上报
      if (error.__CANCEL__) return;

      // 上报请求错误
      Bugsnag.notify(formatBugsnagMessage(JSON.stringify(error, null, "\t")));

      message.destroy();
      message.error(errorMsgTips);
      return Promise.reject({
        msg: intl[error?.message] || error?.message || errorMsgTips
      });
    }
  }
);

export default service;
