import React, { useEffect, useState, Suspense, useRef } from "react";
import { Row, Col, Spin, Checkbox, Button, message, Modal } from "antd";
import styles from "./Profile.module.less";
import { processApplyRole } from "@/services/request";
import {} from "@/config/constants";
import { Intl, IInjectIntlProps, IVerifyParam } from "@/model/index";
import { injectIntl } from "react-intl";
import { transformDate } from "@/utils";
import { useScrollToBottomHook } from "@/components/useScrollToBottom/UseScrollToBottom";
import AuthorizationAgreement from "@/views/clients/AuthorizationAgreement";
import { ModalLoadingContext } from "@/store/context";

const WithEmailOrGoogleVerify = React.lazy(
  () =>
    import(
      /* webpackChunkName: "WithEmailOrGoogleVerify" */ "@/components/withEmailOrGoogleVerify/WithEmailOrGoogleVerify"
    )
);

interface IProps extends IInjectIntlProps {}

const PartnerAuthorization = (props: IProps) => {
  const intl: Intl = window.__intl__.messages;
  const [loading, setLoading] = useState(false);
  const [hasAgreement, setHasAgreement] = useState(false);
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [hasreadAllAgreement, setHasreadAllAgreement] = useState(false);

  const partnerInfo = props.location?.state?.partnerInfo;
  useEffect(() => {}, []);

  const handleCheckAgreement = (e) => {
    setHasAgreement(e.target.checked);
  };

  const verifyRef = React.useRef<any>();
  const handleSubmit = () => {
    if (!hasreadAllAgreement) {
      message.warning(intl["profile.partner.authorization.agreement.read.tips"], 2);
      return;
    }
    verifyRef?.current?.handleCheckVerify();
  };
  const handleApprove = (verifyParam: IVerifyParam) => {
    const { verifyCode, verifyType, businessType } = verifyParam;
    const params = {
      id: partnerInfo.id,
      status: 2,
      businessType,
      verifyCode,
      verifyType
    };
    setRejectModalVisible(false);
    handleFetch(params);
  };

  const handleReject = () => {
    const params = {
      id: partnerInfo.id,
      status: 5
    };
    setRejectModalVisible(false);
    handleFetch(params);
  };

  const handleFetch = (params) => {
    setLoading(true);
    processApplyRole(params)
      .then((res) => {
        if (res?.code == 0) {
          verifyRef?.current?.hideVerifyModal();
          message.destroy();
          message.success(intl["system.success"], 2, () => {
            props.history.go(-1);
          });
        } else {
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //阅读完协议
  const scrollRef = useRef();
  const readAll = () => {
    if (!hasreadAllAgreement) {
      setHasreadAllAgreement(true);
    }
  };

  useScrollToBottomHook(scrollRef, readAll);

  return (
    <section className={styles.profile + " " + "customer"}>
      <section className="customerTop">
        <div className={"top " + styles.profileTop}>
          <p className="title">{intl["profile.partner.authorization.title"]}</p>
        </div>
      </section>
      <Spin spinning={loading}>
        <div className="section">
          <div className={styles.applicationInfo}>
            <p className={styles.title}>{intl["profile.partner.authorization.information"]}</p>
            <div className={styles.dividerLine} />
            <div>
              <div className={styles.accountInfo + " " + styles.marginBottom24}>
                <div className={styles.key}>{intl["profile.partner.authorization.name"]}</div>
                <div className={styles.value}>{partnerInfo.agentName}</div>
              </div>
              <div className={styles.accountInfo}>
                <div className={styles.key}>{intl["profile.partner.authorization.email"]}</div>
                <div className={styles.value}>{partnerInfo.agentEmail}</div>
              </div>
            </div>
            <div className={styles.dividerLine} />
            <p className={styles.title}>{intl["profile.partner.authorization.permission"]}</p>
            <div className={styles.authorizatioTh}>
              <div className={styles.authorizatioThFirst}>
                {intl["profile.partner.authorization.authorization"]}
              </div>
              <div>{intl["profile.partner.authorization.description"]}</div>
            </div>
            {intl["profile.partner.authorization.detail"].map((item, index) => {
              return (
                <div key={index}>
                  <div className={styles.authorizatioTr}>
                    <div className={styles.authorizatioTrFirst}>{item.key}</div>
                    <div className={styles.authorizatioTrSecond}>{item.detail}</div>
                  </div>
                  <div className={styles.dividerLine} />
                </div>
              );
            })}
            <div style={{ color: "rgba(255, 255, 255, 0.85)" }}>
              <p style={{ marginBottom: 16 }}>
                {intl["profile.partner.authorization.period.title"]}
              </p>
              <p>{`${transformDate(partnerInfo.effectiveDate)} ~ ${transformDate(
                partnerInfo.expirationDate
              )}`}</p>
            </div>
          </div>
          <div className={styles.agreementContent}>
            <p className={styles.title}>{intl["profile.partner.authorization.agreement"]}</p>
            <div className={styles.dividerLine} />
            <div className={styles.agreementArea} ref={scrollRef}>
              <AuthorizationAgreement />
            </div>
            <Checkbox style={{ margin: "24px 0", color: "#fff" }} onChange={handleCheckAgreement}>
              {intl["profile.partner.authorization.agreement.read"]}
            </Checkbox>
            <div className={styles.btnContent}>
              <Button
                className={styles.btn}
                disabled={!hasAgreement}
                type="primary"
                onClick={handleSubmit}>
                {intl["button.authorize"]}
              </Button>
              <Button
                className={styles.btn}
                type="primary"
                onClick={() => {
                  setRejectModalVisible(true);
                }}>
                {intl["button.reject"]}
              </Button>
            </div>
          </div>
        </div>
        <Modal
          wrapClassName={styles.modal}
          title="Reject Authorization"
          visible={rejectModalVisible}
          confirmLoading={loading}
          onOk={handleReject}
          onCancel={() => setRejectModalVisible(false)}
          centered={true}
          destroyOnClose={true}
          okText={intl["button.confirm"]}
          cancelText={intl["button.cancel"]}
          width={480}>
          <p>
            {intl["profile.partner.authorization.reject"]} {partnerInfo.agentEmail}?
          </p>
        </Modal>
        <ModalLoadingContext.Provider value={{ confirmLoading: loading }}>
          <Suspense fallback={<div />}>
            <WithEmailOrGoogleVerify
              ref={verifyRef}
              businessType={-1}
              handleConfirm={handleApprove}
            />
          </Suspense>
        </ModalLoadingContext.Provider>
      </Spin>
    </section>
  );
};

export default injectIntl(PartnerAuthorization);
