import React, { useEffect, useState, Suspense } from "react";
import { Select, Button, message, Spin } from "antd";
import styles from "./Trade.module.less";
// import { OptionData, OptionGroupData } from "rc-select/lib/interface/";
import WithFiatAndSpotTrade from "@/components/withFiatAndSpotTrade/WithFiatAndSpotTrade";
import {
  IInjectIntlProps,
  Intl,
  IResOrdersHistory,
  ITrade,
  IMarketPushItemType
} from "@/model/index";
import {
  numberToThousands,
  decimalPointNoMoreX,
  convertUSD2USDC,
  formatBugsnagMessage
} from "@/utils";
import Bugsnag from "@bugsnag/browser";
import { judgeFilledMarkerOrder } from "./utils";

import { availableSpots, cancelTradeOrder, ordersOpen, fecthDepthByCoin } from "@/services/request";
const { Option } = Select;

//const Kline = React.lazy(() => import(/* webpackChunkName: "Kline" */ "./components/Kline"));
import DepthChart from "./components/DepthChart";

const Kline = React.lazy(() => import(/* webpackChunkName: "Kline" */ "./components/Kline"));

const KlineTradingView = React.lazy(
  () => import(/* webpackChunkName: "KlineTradingView" */ "./components/KlineTradingView")
);

const OrderBook = React.lazy(
  () => import(/* webpackChunkName: "OrderBook" */ "./components/OrderBook")
);

// const DepthChart = React.lazy(
//   () => import(/* webpackChunkName: "DepthChart" */ "./components/DepthChart")
// );

const PlaceOrder = React.lazy(
  () => import(/* webpackChunkName: "PlaceOrder" */ "./components/PlaceOrder")
);
const OrderList = React.lazy(
  () => import(/* webpackChunkName: "OrderList" */ "./components/OrderList")
);

interface IProps extends IInjectIntlProps, ITrade {}

interface IInterval {
  interval: number | string;
  timeFrame: string;
  text: number | string;
}

export function Trade(props: IProps) {
  const intervalTypes: IInterval[] = [
    {
      interval: 15,
      timeFrame: "P15m",
      text: "15m"
    },
    {
      interval: 60,
      timeFrame: "P60m",
      text: "60m"
    },
    {
      interval: 4 * 60,
      timeFrame: "P4h",
      text: "4H"
    },
    {
      interval: 1 * 24 * 60,
      timeFrame: "P1d",
      text: "1D" //P1M,一个月
    },
    {
      interval: 7 * 24 * 60,
      timeFrame: "P1w",
      text: "1W"
    }
  ];
  const openOrderLimit: number = 100;
  const _coinCode = window?.__history__?.location?.query?.coinCode ?? "BTC_USDT";
  // 获取盘口名称列表信息, 供展示和计算使用
  const symbolList = props?.marketSymbolsList; //useAppSelector(marketSymbolsList);
  // 所有交易对
  const allSymbolPairs = props?.symbolNames ?? []; //useAppSelector(symbolNames);
  const [coinCode, setCoinCode] = useState<string>(_coinCode);
  const [fromSymbol, setFromSymbol] = useState<string>("");
  const depthData = props?.depthData;
  const [currentSymbolInfo, updateCurrentSymbolInfo] = useState<IMarketPushItemType>(
    symbolList?.[coinCode]
  );
  const [tradePrecision, updateTradePrecision] = useState(
    symbolList?.[coinCode]?.tradePrecision ?? 0
  );
  const [tradeVolumePrecision, updatetTadeVolumePrecision] = useState(
    symbolList?.[coinCode]?.tradeVolumePrecision ?? 0
  );
  const [switcher, setSwitcher] = useState<string>("kline");
  const [openOrderList, setCurrentOrderList] = useState([]);
  const [tradeHistory, setOrderHistory] = useState([]);
  const minKlineHeight = "380px";
  const minDepthHeight = "380px";
  const [priceLast, updatePriceLast] = useState(
    decimalPointNoMoreX(currentSymbolInfo?.priceLast, currentSymbolInfo?.symbolPrecision ?? 1) ?? 0
  );
  const [defaultPriceLast, updateDefaultPriceLast] = useState(
    decimalPointNoMoreX(currentSymbolInfo?.priceLast, currentSymbolInfo?.symbolPrecision ?? 1) ?? 0
  );
  const [interval, updateInterval] = useState<string | number>("15");
  const [timeFrame, updateTimeFrame] = useState<string>("P15m");
  const [quoteCurrencyBalance, updateQuoteCurrencyBalance] = useState(0);
  const [baseCurrencyBalance, setBaseCurrencyBalance] = useState(0);
  const [quoteCurrency, setQuoteCurrency] = useState<string>(); // 定价币种
  const [baseCurrency, setBaseCurrency] = useState<string>(); // 计价币种
  const [loadingMarket, setLoadingMarket] = useState<boolean>(true);
  const [loadingOb, setLoadingOb] = useState<boolean>(true);
  const [loadinList, setLoadingList] = useState<boolean>(true);
  const [klineType, switchKlineType] = useState("TradingView");
  const intl: Intl = window.__intl__.messages;

  const updateIntervalInfo: (item: IInterval) => void = (item) => {
    // setLoadingMarket(true);
    updateInterval(item["interval"]);
    updateTimeFrame(item["timeFrame"]);
    // setTimeout(() => {
    //   setLoadingMarket(false);
    // }, 3 * 1000);
  };

  // 初始化数据
  const getInitData: () => void = async () => {
    setLoadingMarket(true);
    setLoadingList(true);
    getOrdersOpen();
  };

  // useEffect(() => {
  //   if (klineType == "Original") {
  //     let iframe = document.querySelector<HTMLIFrameElement>("#iframe");
  //     iframe?.contentWindow.postMessage(
  //       JSON.stringify({
  //         coinCode: coinCode,
  //         resolution: interval,
  //         precision: symbolList?.[coinCode]?.symbolPrecision ?? 2,
  //         timeFrame: timeFrame,
  //         ws: window.ws
  //       }),
  //       "*"
  //     );
  //   }
  // }, [klineType]);

  useEffect(() => {
    getInitData();
  }, []);

  // 盘口变化
  useEffect(() => {
    if (JSON.stringify(props?.marketSymbolsList?.[coinCode]) != JSON.stringify(currentSymbolInfo)) {
      updateCurrentSymbolInfo(props?.marketSymbolsList?.[coinCode]);
    }
  }, [props.marketSymbolsList[coinCode]]);

  // open order 发生变化
  useEffect(() => {
    const changedEntrustOrder = props.openOrder;
    const entrustList = [...openOrderList];
    let changeEntrustIndex = -1;
    for (let item of entrustList) {
      if (item.orderId == changedEntrustOrder.orderId) {
        changeEntrustIndex = entrustList.indexOf(item);
        break;
      }
    }
    if (changeEntrustIndex != -1) {
      // 有成交时重新获取当前盘口的账户余额
      getFlexibleBalance([baseCurrency, quoteCurrency]);
      entrustList[changeEntrustIndex] = changedEntrustOrder;
      setCurrentOrderList(entrustList);
      if (entrustList[changeEntrustIndex].remainingQty == 0) {
        getOrdersOpen();
        getTradeHistory;
        return;
      }
    }
  }, [props.openOrder]);

  // 切换盘口时，重新获取新的币种信息websocket、orderbook及websocket
  useEffect(() => {
    localStorage.setItem("coinCode", coinCode);
    const currenPriceLast = decimalPointNoMoreX(
      props?.marketSymbolsList?.[coinCode]?.priceLast ?? 0,
      props?.marketSymbolsList?.[coinCode]?.symbolPrecision
    );
    props.sendOrderBookWsData(coinCode, fromSymbol);
    updatePriceLast(currenPriceLast);
    // 从接口获取当前盘口orderbook
    fetchOrderbook(coinCode);
    const availableList = coinCode.split("_");
    const baseCurrency = availableList[0];
    const quoteCurrency = availableList[1];
    setBaseCurrency(baseCurrency);
    setQuoteCurrency(quoteCurrency);
    // 获取当前交易对的交易币种/计价币种余额
    getFlexibleBalance([baseCurrency, quoteCurrency]);
    updateDefaultPriceLast(currenPriceLast);
    setTimeout(() => {
      setLoadingMarket(false);
    }, 3 * 1000);
  }, [coinCode]);

  window.addEventListener("message", (msg) => {
    // 验证消息来源地址
    if (
      !(
        msg.origin.includes("192.168.3.48") ||
        msg.origin.includes("localhost") ||
        msg.origin.includes("aspendigital.co")
      )
    ) {
      return;
    }

    if (
      Object.prototype.toString.call(msg?.data) == "[object String]" &&
      msg?.data != "completed"
    ) {
      Bugsnag.notify(formatBugsnagMessage(msg?.data));
    }

    if (msg?.data == "completed") {
      setLoadingMarket(false);
    }
  });

  const fetchOrderbook: (coinCode: string) => void = (coinCode) => {
    setLoadingOb(true);
    const param = {
      symbol: coinCode ?? "BTC_USDT"
    };
    fecthDepthByCoin(param)
      .then((res) => {
        if (res?.code == 0 && res?.data) {
          let data = res.data;
          data.asks = data?.asks.slice(0, 10);
          data.bids = data?.bids.slice(0, 10);
          props.updateCurrentOrderBook(coinCode, data);
          const { asks, bids } = data;
          // 取到ob后 默认展示卖一价，因为默认操作是买
          const ask = asks?.[9]?.p.toString(); // 卖一
          let lastPrice = ask;
          updateDefaultPriceLast(lastPrice);
        }
      })
      .finally(() => {
        updateTradePrecision(props?.marketSymbolsList?.[coinCode]?.tradePrecision);
        updatetTadeVolumePrecision(props?.marketSymbolsList?.[coinCode]?.tradeVolumePrecision);
        updateCurrentSymbolInfo(props?.marketSymbolsList?.[coinCode]);
        setLoadingOb(false);
      });
  };

  // 获取最新价格, 买一/卖一
  const getLastPrice: (type?: "buy" | "sell") => string = (type = "buy") => {
    const { asks, bids } = props?.orderBook;
    let lastPrice = "";
    const ask = asks?.[9]?.p.toString(); // 卖一
    const bid = bids?.[0]?.p.toString(); // 买一
    lastPrice = type == "buy" ? ask : bid;
    return lastPrice;
  };

  // 下单
  const handlerAfterCreateSpotTradeOrder: () => void = async () => {
    // 下单成功后重新获取open order 和余额
    getOrdersOpen();
    getFlexibleBalance([baseCurrency, quoteCurrency]);
  };

  // 交易对切换
  const handleChange: (value: string) => void = (value) => {
    setFromSymbol(coinCode);
    setLoadingMarket(true);
    setCoinCode(value);
    props.updateCoinCode(value);
  };

  // const filterOption: (inputValue: string, option: OptionData | OptionGroupData) => boolean = (
  const filterOption: (inputValue: string, option) => boolean = (inputValue, option) => {
    return (
      option?.children?.toLowerCase().indexOf(inputValue?.toLowerCase()) >= 0 ||
      option?.children.toLowerCase().indexOf(inputValue?.toLowerCase()) >= 0
    );
  };

  // 切换k线/depth
  const handleChangeSwiter: (e: string) => void = (e: string) => {
    setSwitcher(e);
  };

  // 取消订单
  const handleCancelOrder = (orderId: string) => {
    const param = {
      orderId
    };
    cancelTradeOrder(param)
      .then((res) => {
        if (res.code == 0) {
          setTimeout(() => {
            // 取消后立即成功的要重新获取账户余额
            getFlexibleBalance([baseCurrency, quoteCurrency]);
            getOrdersOpen();
          }, 2 * 1000);
          message.destroy();
          message.success(intl["trade.entrust.cancel.success"]);
        } else {
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch((e) => {});
  };

  // 获取现货币种余额
  const getFlexibleBalance: (currency: string[]) => void = async (currency) => {
    const param = {
      currency: currency.toString()
    };
    await availableSpots(param)
      .then((res) => {
        if (res.code == 0) {
          const data = res?.data;
          const availableList = coinCode.split("_");
          const baseCurrency = availableList[0];
          const quoteCurrency = availableList[1];
          setBaseCurrencyBalance(data?.[baseCurrency] ?? 0);
          updateQuoteCurrencyBalance(data?.[quoteCurrency] ?? 0);
        }
      })
      .catch((e) => {})
      .finally(() => {});
  };

  const getOrdersOpen = (isRefresh?: boolean) => {
    setLoadingList(true);
    const param = { limit: openOrderLimit, orderQueryStatus: "open" };
    ordersOpen(param)
      .then((res) => {
        if (res?.code == 0) {
          const orderOpenList = res?.data || [];
          setCurrentOrderList(orderOpenList);
          // judgeFilledMarkerOrder(orderOpenList, "open");
        }
      })
      .finally(() => {
        setLoadingList(false);
      });
    // 如果是刷新openorder，再去获取余额，应对cancel processing成功后的余额解冻
    if (isRefresh) {
      getFlexibleBalance([baseCurrency, quoteCurrency]);
    }
  };

  const getTradeHistory = () => {
    setLoadingList(true);
    const param = { limit: openOrderLimit, orderQueryStatus: "history" };
    ordersOpen(param)
      .then((res: IResOrdersHistory) => {
        if (res?.code == 0) {
          const orderHistoryList = res?.data || [];
          setOrderHistory(orderHistoryList);
          // judgeFilledMarkerOrder(orderHistoryList, "history");
        }
      })
      .finally(() => {
        setLoadingList(false);
      });
  };

  const currentColor =
    Number(currentSymbolInfo?.riseAndFall) > 0
      ? "up"
      : Number(currentSymbolInfo?.riseAndFall) < 0
      ? "down"
      : "remain";
  return (
    <section className={styles.exchange}>
      <div className={styles.grid}>
        <div className={styles.girdKline}>
          <div className={styles.trade}>
            <div className={styles.switcher}>
              <Button>{intl["trade.chart.time"]}</Button>
              {intervalTypes.map((item: IInterval) => (
                <Button
                  className={interval == item.interval ? styles.activeInterval : " "}
                  onClick={() => updateIntervalInfo(item)}
                  key={item.text}>
                  {item["text"]}
                </Button>
              ))}

              <div style={{ float: "right", marginRight: "40px" }}>
                <Button
                  className={klineType == "TradingView" ? styles.activeInterval : " "}
                  onClick={() => switchKlineType("TradingView")}>
                  {intl["trade.chart.tradingview"]}
                </Button>
                <Button
                  className={klineType == "Original" ? styles.activeInterval : " "}
                  onClick={() => switchKlineType("Original")}>
                  {intl["trade.chart.origin"]}
                </Button>
              </div>
            </div>

            <div className={styles.tradeHeader}>
              <Select
                ref={React.createRef()}
                defaultValue={coinCode}
                notFoundContent={"No Data"}
                showSearch
                filterOption={filterOption}
                style={{ width: 130 }}
                onChange={handleChange}>
                {allSymbolPairs.map((item) => (
                  <Option key={item} value={item}>
                    {convertUSD2USDC(item).replace("_", "/")}
                  </Option>
                ))}
              </Select>
              <span className={styles.price}>
                {numberToThousands(
                  decimalPointNoMoreX(priceLast, currentSymbolInfo?.symbolPrecision ?? 0)
                )}
              </span>
              <div className={styles.change}>
                <p>{intl["trade.chart.24h.up&down"]}</p>
                <p className={styles.ticker + " " + styles[currentColor]}>
                  {numberToThousands(decimalPointNoMoreX(currentSymbolInfo?.riseAndFall, 2))}%
                </p>
              </div>
              <div className={styles.change}>
                <p>{intl["trade.chart.24h.hign&low"]}</p>
                <p className={styles.ticker}>
                  {numberToThousands(
                    decimalPointNoMoreX(
                      currentSymbolInfo?.highPrice,
                      currentSymbolInfo?.symbolPrecision ?? 0
                    )
                  )}
                  <span style={{ padding: "0 3px" }}>-</span>
                  {numberToThousands(
                    decimalPointNoMoreX(
                      currentSymbolInfo?.lowPrice,
                      currentSymbolInfo?.symbolPrecision ?? 0
                    )
                  )}
                </p>
              </div>
              <div className={styles.change} style={{ marginRight: "" }}>
                <p>
                  {intl["trade.chart.24h.volume"]}({baseCurrency})
                </p>
                <p className={styles.ticker}>
                  {numberToThousands(
                    decimalPointNoMoreX(
                      currentSymbolInfo?.volume,
                      currentSymbolInfo?.tradeVolumePrecision ?? 5
                    )
                  )}
                </p>
              </div>
              <div className={styles.switcher}>
                <span
                  className={switcher == "kline" ? styles.current : ""}
                  onClick={() => handleChangeSwiter("kline")}>
                  {intl["trade.chart.kline"]}
                </span>
                <span> / </span>
                <span
                  className={switcher == "depth" ? styles.current : ""}
                  onClick={() => handleChangeSwiter("depth")}>
                  {intl["trade.chart.depth"]}
                </span>
              </div>
            </div>

            <div
              className={
                switcher == "kline" ? styles.klineChartContainer : styles.depthChartContainer
              }>
              <Suspense
                fallback={
                  <div className="middleAlign" style={{ height: minKlineHeight }}>
                    <Spin />
                  </div>
                }>
                {switcher == "kline" && (
                  <>
                    {/* <Spin spinning={loadingMarket}> */}
                    <Kline
                      klineType={klineType}
                      minHeight={minKlineHeight}
                      coinCode={coinCode}
                      timeFrame={timeFrame}
                      interval={interval}
                      tradeVolumePrecision={symbolList?.[coinCode]?.tradeVolumePrecision}
                      precision={symbolList?.[coinCode]?.symbolPrecision ?? 2}
                    />
                    {/* </Spin> */}
                  </>
                )}
                {switcher == "depth" && (
                  <DepthChart
                    coinCode={coinCode}
                    minHeight={minDepthHeight}
                    depthData={depthData}
                    tradeVolumePrecision={symbolList?.[coinCode]?.tradeVolumePrecision}
                    precision={symbolList?.[coinCode]?.symbolPrecision ?? 2}
                    fetchOrderbook={fetchOrderbook}
                  />
                )}
              </Suspense>
            </div>
          </div>

          <Suspense
            fallback={
              <div className={styles.orderbook + " middleAlign"}>
                <Spin />
              </div>
            }>
            <OrderBook
              updatePriceLast={updatePriceLast}
              loading={loadingOb}
              priceLast={priceLast}
              tradePrecision={tradePrecision}
              tradeVolumePrecision={tradeVolumePrecision}
            />
          </Suspense>
          <Suspense
            fallback={
              <div className={styles.placeOrder + " middleAlign"}>
                <Spin />
              </div>
            }>
            <PlaceOrder
              loadingPlaceOrder={loadingMarket}
              getLastPrice={getLastPrice}
              baseCurrencyBalance={baseCurrencyBalance}
              quoteCurrencyBalance={quoteCurrencyBalance}
              baseCurrency={baseCurrency}
              quoteCurrency={quoteCurrency}
              lastPrice={defaultPriceLast}
              coinDetail={currentSymbolInfo}
              coinCode={coinCode}
              handlerAfterCreateSpotTradeOrder={handlerAfterCreateSpotTradeOrder}
            />
          </Suspense>
        </div>
        <div className={styles.orderList}>
          <Suspense
            fallback={
              <div className={styles.orderbook + " middleAlign"} style={{ height: "500px" }}>
                <Spin />
              </div>
            }>
            <OrderList
              symbolList={symbolList}
              offlineSymbolsList={props.offlineSymbolsList}
              loading={loadinList}
              getOrdersOpen={getOrdersOpen}
              getTradeHistory={getTradeHistory}
              openOrderList={openOrderList}
              tradeHistory={tradeHistory}
              handleCancelOrder={handleCancelOrder}
            />
          </Suspense>
        </div>
      </div>
    </section>
  );
}

// const mapStateToProps = (state) => {
//   const { marketSymbolsList, symbolNames, orderBook, depthData, openOrder } =
//     state.marketSpotAndTradeInfo;
//   return {
//     marketSymbolsList: marketSymbolsList, // 现货币种价格/交易量等信息
//     symbolNames: symbolNames, // 支持的所有币种
//   };
// };

//export default (connect(mapStateToProps)(WithFiatAndSpotTrade(Trade)));

export default WithFiatAndSpotTrade(Trade);
