const originCode = {
  "portal.aspendigital.co": "G-KB3MB3932Y",
  "partner.aspendigital.co": "G-GN0XHEQGBT",
  "service.aspendigital.co": "G-1K2LBE78BY",
  "portal.dev.test-aspendigital.co": "G-74W4FMRKVX",
  "portal.beta.test-aspendigital.co": "G-E9DZZFY8JG"
};

const apikeyGa = originCode[location.hostname] ?? "";
export default apikeyGa;
