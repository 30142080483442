import React from "react";

import { Modal, Input, message, Checkbox, Button } from "antd";
import styles from "../Strategy.module.less";
import { partial } from "lodash";
import CheckEmailModal from "./CheckEmailModal";
import { USER_ROLE_POWER } from "@/config/constants";
import { onlyNumbersAndDecimalPoint, getQueryValue } from "@/utils";
import { subscribeIntention, redeemIntention } from "@/services/request";
const NumericInput = React.lazy(
  () => import(/* webpackChunkName: "NumericInput" */ "@/components/numericInput/NumericInput")
);
const ModalSafetyVerification = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ModalSafetyVerification" */ "@/components/modalSafetyVerification/ModalSafetyVerification"
    )
);
const [SUBSCIBE, REDEEM] = [1, 2];
interface IProps {
  visible: boolean;
  action?: string;
  currency?: string;
  handleOK: Function;
  handleCancel: Function;
  type: number;
}
interface IState {
  disabled: boolean;
  amount: string;
  checked: boolean;
  subscribeType: number;
  showCheckEmail: boolean;
  // chartData: IdataItem[];
  showSafetyVerificationModal: boolean;
  loading: boolean;
}

const [BANK_TRANSFER, SPOT_ACCOUNT] = [1, 2];
class Subscribe extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      amount: "",
      checked: false,
      subscribeType: null,
      showCheckEmail: false,
      showSafetyVerificationModal: false,
      loading: false
    };
    this.onChange = this.onChange.bind(this);
    this.handleChoseType = this.handleChoseType.bind(this);
    this.handleCloseCheckEmailModal = this.handleCloseCheckEmailModal.bind(this);
  }

  onChange(value: any) {
    this.setState({
      amount: value
    });
  }

  handleChoseType(type) {
    this.setState({ subscribeType: type });
  }
  handleCloseCheckEmailModal() {
    this.setState({ showCheckEmail: false });
    this.clickOnhandleCancel();
  }

  //验证码
  hideOK: (code: string) => void = async (code) => {
    this.fetchSubmit(code);
  };

  hideModal: () => void = async () => {
    this.setState({
      showSafetyVerificationModal: false
    });
  };

  handleOk = () => {
    // 打开二次弹窗
    // const { subscribeType, amount } = this.state;
    // this.props.handleOK(amount);
    if (USER_ROLE_POWER[localStorage.getItem("user_auth")]?.actionForOthers) {
      this.setState({
        showSafetyVerificationModal: true
      });
    } else {
      this.fetchSubmit();
    }
  };
  fetchSubmit = (emailCode?: string) => {
    this.setState({ loading: true });
    const intl: any = window.__intl__.messages;
    const { type } = this.props;
    const { amount } = this.state;
    const id = getQueryValue("id");
    const params = {
      strategyId: id,
      amount,
      verifyCode: emailCode || null
    };
    const method = type === SUBSCIBE ? subscribeIntention : redeemIntention;
    method(params)
      .then((res) => {
        if (res?.code == 0) {
          this.setState({ showCheckEmail: true, showSafetyVerificationModal: false });
        } else {
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  clickOnhandleCancel: () => void = () => {
    this.props.handleCancel();
  };
  handleCheck = (e) => {
    this.setState({
      checked: e.target.checked
    });
  };

  getEmail() {
    const email = localStorage.getItem("operateCustomerEmail") || localStorage.getItem("user");
    return "****" + email?.slice(2);
  }

  render(): React.ReactNode {
    const intl: any = window.__intl__.messages;
    const { subscribeType, amount, showCheckEmail, loading } = this.state;
    const { type } = this.props;
    const isSubmitDisable = !!amount;
    const con = type === REDEEM ? "Redemption" : "Subscription";

    const activeTime = new Date();
    activeTime.setTime(activeTime.getTime() + 3600 * 1000 * 24 * 2);
    return (
      <>
        <Modal
          wrapClassName={styles.subscribe}
          title={`Strategy ${con}`}
          visible={this.props.visible}
          footer={null}
          width={600}
          onCancel={this.clickOnhandleCancel}>
          <div className={styles.hint}>
            {/* {`Please tell us your purchase intention by filling in the following information. After
          receiving the information, we will inform you of the ${con.toLocaleLowerCase()} method and other
          information by email. The email will be sent to you (`} */}
            {`Input your desired ${con.toLocaleLowerCase()} amount below. Upon submission, we will confirm your ${con.toLocaleLowerCase()} amount by email (`}
            <span style={{ color: "#FF5351" }}>{this.getEmail()}</span>)
          </div>
          <p className={styles.label} style={{ marginBottom: 8 }}>
            {con} Amount
          </p>
          <NumericInput onChange={(e) => this.onChange(e)} value={amount} />
          {false && (
            <>
              <p className={styles.label} style={{ marginBottom: 8, marginTop: 24 }}>
                Source of Cryptocurrency
              </p>
              <Button
                className={`${styles.btn} ${subscribeType === BANK_TRANSFER ? styles.active : ""}`}
                onClick={partial(this.handleChoseType, BANK_TRANSFER)}>
                Bank Transfer
              </Button>
              <Button
                className={`${styles.btn} ${subscribeType === SPOT_ACCOUNT ? styles.active : ""}`}
                style={{ marginLeft: 16 }}
                onClick={partial(this.handleChoseType, SPOT_ACCOUNT)}>
                Spot Account
              </Button>
            </>
          )}
          <div className={styles.submitWrap}>
            <Button
              className={`${styles.btn}  ${isSubmitDisable ? styles.active : styles.btnDisabled}`}
              disabled={!isSubmitDisable}
              loading={loading}
              onClick={this.handleOk}>
              Submit
            </Button>
            <Button
              className={`${styles.btn} ${styles.normal}`}
              style={{ marginLeft: 16 }}
              onClick={this.clickOnhandleCancel}>
              Cancel
            </Button>
          </div>
          {/* <p className={styles.label} style={{ marginBottom: "10px", marginTop: "10px" }}>
          Starting at $1,000 and increasing by $1,000 and the purchase limit is $10,000,000
        </p>
        <Checkbox onChange={(e) => this.handleCheck(e)}>
          I have read and agree to 《Asset Service Agreement》
        </Checkbox> */}
          <CheckEmailModal
            type={con}
            visible={showCheckEmail}
            handleClose={this.handleCloseCheckEmailModal}
          />
        </Modal>
        <ModalSafetyVerification
          confirmLoading={loading}
          visible={this.state.showSafetyVerificationModal}
          handleCancel={this.hideModal}
          onOk={this.hideOK}
          businessType={-1}
          isCustomerValidate={true}
        />
      </>
    );
  }
}

export default Subscribe;
